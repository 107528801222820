import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';

// Public Staging/Development Config
var config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase and Analytics
if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.analytics();
}

// console.log(firebase.firestore.Timestamp.fromDate('2020-'))

export const auth = firebase.auth();
export const db = firebase.firestore();
export const rtdb = firebase.database();
export const storage = firebase.storage();
export const func = firebase.functions();
export const fsFieldValue = firebase.firestore.FieldValue;
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const timeStampNow = () => {
    return firebase.firestore.Timestamp.now();
};
export const timeStampNowSeconds = () => {
    return firebase.firestore.Timestamp.now().seconds;
};
export const currentDate = () => {
    return firebase.firestore.Timestamp.now().toDate();
};
export const toFirebaseTimestamp = timestamp => {
    return new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds);
};
export const timeStampJs = firebase.firestore.Timestamp;
export const addArrayUpdate = firebase.firestore.FieldValue.arrayUnion;
export const removeArrayUpdate = firebase.firestore.FieldValue.arrayRemove;
export default firebase;
