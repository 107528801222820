// React
import React from 'react';

const ContactsListSearch = ({ handleChange, listLabel }) => {
    return (
        <div className="contacts-list-search">
            <div className="align-items-center">
                <div className="js-focus-state">
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="searchProperty">
                                <span className="fas fa-search" />
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            name="text"
                            id="searchContact"
                            placeholder={`Search ${listLabel}`}
                            aria-label="search"
                            aria-describedby="searchContact"
                            autoComplete="off"
                            onChange={e => handleChange(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactsListSearch;
