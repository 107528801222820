export const AUTHENTICATED = '/user';
export const APP_STORE_REDIRECT = '/app';
export const ACCESS_SUCCESS = '/access-success';
export const ACCESS_ERROR = '/access-error';
export const AUTH_SERVICES = '/auth-service';
export const DASHBOARD = '/dashboard';
export const PROFILE_SETTINGS = '/profile-settings';
export const RENTAL_DASHBOARD = '/rental-dashboard';
export const RENTAL_MANAGER = '/rental-manager';
export const LANDING = '/';
export const ACCEPT_INVITATION = '/accept-invitation';
export const GUEST_RESERVATION = '/guest-reservation';
export const GUEST_RESERVATION_BULK = '/guest-reservation-bulk';
export const CONFIRM_RESERVATION = '/confirm-reservation';
export const RESERVATION_DETAILS_EDIT = '/reservation-details';
export const LOGIN = '/login';
export const MOBILE_EULA = '/mobile-app-eula';
export const MOBILE_PRIVACY_POLICY = '/mobile-app-privacy';
export const SETTINGS = '/settings';
export const RESET_PASSWORD = '/reset-password';
export const RESET_PASSWORD_AUTHENTICATED = '/reset-password-authenticated';
export const ORG_DETAIL = '/org';
export const PANEL_VIDEO = '/panel-video';
export const ADD_NEW_ORG = '/add-organization';
export const ADD_NEW_MANAGER = '/add-manager';
export const ADD_NEW_RENTAL_MANAGER = '/add-rental-manager';
export const ADD_NEW_MEMBER = '/add-member';
export const ADD_NEW_RESIDENT = '/add-resident';
export const ADD_NEW_VENDOR = '/add-vendor';
export const IMPORT_MEMBERS_CSV = '/import-members-csv';
export const ACCESS_GROUPS = '/access-groups';
export const COMMUNITY = '/community';
export const CONTENT = '/content';
export const DEVICES = '/devices';
export const UNITS = '/units';
export const VENDORS = '/vendors';
export const SUPER_ADMIN = '/admin';
export const SUPER_AUTHENTICATED = '/super';
export const SUPER_SETTINGS = '/settings';
export const SUPER_DASHBOARD = '/dashboard';
export const SUPER_REPORTS = '/reports';
export const SUPER_ORG_DETAIL = '/org';
export const SUPER_ORG_DASHBOARD = '/dashboard';
export const SUPER_ORG_ACCESS = '/access';
export const SUPER_ORG_COMMUNITY = '/community';
export const SUPER_ORG_CONTENT = '/content';
export const SUPER_ORG_DEVICES = '/devices';
export const SUPER_ORG_UNITS = '/units';
export const WEB_PRIVACY_POLICY = '/privacy';
export const WEB_TERMS = '/terms';
export const ADD_REGISTRATION = '/register/:shortCode';
export const GUEST_MANAGER = '/manage-guests';
export const GUEST_MANAGER_ADD = '/add-guest';
export const GUEST_MANAGER_ADD_OTC = '/add-one-time-access-code';
export const GUEST_MANAGER_REVOKE_OTC = '/revoke-one-time-access-code';
export const GUEST_MANAGER_EDIT = '/edit-guest';
export const GUEST_MANAGER_ADD_SUCCESS = '/add-guest-succes';
export const REQUEST_REMOVAL = '/request-account-removal';
