import React from 'react';
import { Link } from 'react-router-dom';
// import { Button } from 'reactstrap';
import {
    AUTHENTICATED,
    // IMPORT_MEMBERS_CSV,
    SUPER_ADMIN,
    SUPER_AUTHENTICATED,
    ADD_NEW_MEMBER
} from '../../router/config/routes';
import DropdownButton from '../common/DropdownButton';

const CommunityHeader = ({
    allowImport,
    org_id,
    org_name,
    userId,
    isSuperAdmin,
    rentalManagerEnabled,
    invite_code,
    firstName,
    lastName
}) => {
    const menuOptions = [
        {
            key: 'Resident',
            component: (
                <Link
                    to={{
                        pathname: isSuperAdmin
                            ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${ADD_NEW_MEMBER}`
                            : `${AUTHENTICATED}${ADD_NEW_MEMBER}`,
                        state: { type: 'resi' }
                    }}
                >
                    Resident
                </Link>
            )
        },
        {
            key: 'Vendor',
            component: (
                <Link
                    to={{
                        pathname: isSuperAdmin
                            ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${ADD_NEW_MEMBER}`
                            : `${AUTHENTICATED}${ADD_NEW_MEMBER}`,
                        state: { type: 'vendor' }
                    }}
                >
                    Vendor
                </Link>
            )
        },
        {
            key: 'Otc',
            component: (
                <Link
                    to={{
                        pathname: isSuperAdmin
                            ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${ADD_NEW_MEMBER}`
                            : `${AUTHENTICATED}${ADD_NEW_MEMBER}`,
                        state: { type: 'otc' }
                    }}
                >
                    Codes
                </Link>
            )
        }
    ];

    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex flex-column">
                <h4 className="mb-0">{org_name}</h4>
                <p className="font-size-1 text-muted mb-0 visibility-hidden">{org_id}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                {allowImport && (
                    <>
                        <DropdownButton
                            className="m-4"
                            menuOptionComponents={menuOptions}
                            toggleComponent={
                                <>
                                    <i className="fa fa-plus mr-1" /> Add New
                                </>
                            }
                        />
                        {/* <Link
                            to={{
                                pathname: isSuperAdmin
                                    ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${IMPORT_MEMBERS_CSV}`
                                    : `${AUTHENTICATED}${IMPORT_MEMBERS_CSV}`,
                                state: { orgId: org_id }
                            }}
                        >
                            <Button
                                color="primary"
                                type="button"
                                className="transition-3d-hover btn-sm btn-primary mr-4 ml-4"
                            >
                                <i className="fa fa-file-import mr-1" /> Import Data
                            </Button>
                        </Link> */}
                    </>
                )}
            </div>
        </div>
    );
};

export default CommunityHeader;
