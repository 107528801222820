import React, { useEffect, useState } from 'react';
import { AcceptOrRevokeOtc, AddOtcForm, Loader } from '../components';
import { addOneTimeAccessCode, resetAddOneTimeAccessCode } from '../store/actions/Guests';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AUTHENTICATED, GUEST_MANAGER } from '../router/config/routes';
import { connect } from 'react-redux';

const AddOtc = ({
    loading,
    addOtcSuccess,
    addOneTimeAccessCode,
    otcError,
    resetAddOneTimeAccessCode
}) => {
    const history = useHistory();
    const [newOtc, setNewOtc] = useState(null);

    useEffect(() => {
        if (otcError) {
            resetAddOneTimeAccessCode();
            history.push(`${AUTHENTICATED}${GUEST_MANAGER}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otcError]);

    useEffect(() => {
        if (addOtcSuccess) {
            resetAddOneTimeAccessCode();
            history.push(`${AUTHENTICATED}${GUEST_MANAGER}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addOtcSuccess]);

    const handleAcceptOtcCreation = () => {
        addOneTimeAccessCode(newOtc);
    };

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="container-fluid min-vh-100 space-1 bg-dark">
            <div className="d-flex mt-2 align-items-center justify-content-center flex-column">
                <img src="../assets/sage-icon.png" alt="logo" width={80} />
            </div>
            {!newOtc && <AddOtcForm createOtc={setNewOtc} />}
            {newOtc && (
                <AcceptOrRevokeOtc
                    acceptOtc={handleAcceptOtcCreation}
                    otc={newOtc}
                    isCreate
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ guests }) => {
    const { loading, otcError, addOtcSuccess } = guests;
    return { loading, otcError, addOtcSuccess };
};

export default connect(mapStateToProps, {
    addOneTimeAccessCode,
    resetAddOneTimeAccessCode
})(AddOtc);
