import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Loader from '../common/Loader';

import FirstAccessForm from './FirstAccessFormNew';
import { resetInviteAddingState } from '../../store/actions/Org';
import { validateInvite, declineInvite, acceptInvite } from '../../store/actions/Invites';
import ExpiredInvite from './ExpiredInvite';
import AcceptedInvite from './AcceptedInvite';
import InviteValidationError from './InviteValidationError';
import DeclineError from './DeclineError';
import DeclineSuccess from './DeclineSuccess';
import AcceptInvitationSuccess from './AcceptInvitationSuccess';
import AcceptInvitationError from './AcceptInvitationError';
import AcceptInviteLogin from './AcceptInviteLogin';
import LogoutPrompt from './LogoutPrompt';

const AcceptInvitation = ({
    queryData,
    updatingPassword,
    loading,
    authUser,
    validating,
    validationErrors,
    validated,
    inviteProcessingStatus,
    inviteDeclining,
    inviteDecliningError,
    declined,
    inviteAccepting,
    inviteAcceptingError,
    accepted
}) => {
    const dispatch = useDispatch();

    const accept = values => {
        dispatch(
            acceptInvite({ ...validated, ...values, org_id: queryData.orgId, authUser })
        );
    };

    const decline = invite => {
        dispatch(
            declineInvite({
                ...invite,
                inviteCode: queryData.inviteCode
            })
        );
    };

    useEffect(() => {
        if (!validated) {
            dispatch(validateInvite(queryData));
        }
        if (
            validated &&
            !inviteAccepting &&
            !accepted &&
            authUser &&
            authUser.email === validated.email
        ) {
            dispatch(acceptInvite({ ...validated, org_id: queryData.orgId, authUser }));
        }

        return () => dispatch(resetInviteAddingState());
    }, [dispatch, authUser, accepted, queryData, inviteAccepting, validated]);

    if (loading || updatingPassword || validating || inviteDeclining || inviteAccepting) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (inviteDecliningError) {
        return <DeclineError error={inviteDecliningError.message} />;
    }

    if (inviteAcceptingError) {
        return <AcceptInvitationError error={inviteAcceptingError.message} />;
    }

    if (validationErrors) {
        return <InviteValidationError error={validationErrors.message} />;
    }

    if (inviteProcessingStatus === 'expired') {
        return <ExpiredInvite />;
    }

    if (inviteProcessingStatus === 'accepted') {
        return <AcceptedInvite />;
    }

    if (declined) {
        return <DeclineSuccess />;
    }

    if (accepted) {
        return (
            <AcceptInvitationSuccess
                orgId={queryData.orgId}
                managerType={validated.type}
            />
        );
    }

    if (validated && validated.existing && !authUser) {
        return <AcceptInviteLogin accept={accept} />;
    }

    if (validated && authUser && authUser.email !== queryData.email) {
        return <LogoutPrompt />;
    }

    return (
        <FirstAccessForm
            submit={accept}
            decline={decline}
            inviteData={validated}
            orgId={queryData.orgId}
        />
    );
};

const mapStateToProps = ({ auth, invites }) => {
    const { error, loading, authUser } = auth;
    const {
        validating,
        validated,
        validationErrors,
        inviteProcessingStatus,
        inviteDeclining,
        inviteDecliningError,
        declined,
        inviteAccepting,
        inviteAcceptingError,
        accepted
    } = invites;

    return {
        error,
        loading,
        authUser,
        validating,
        validated,
        validationErrors,
        inviteProcessingStatus,
        inviteDeclining,
        inviteDecliningError,
        declined,
        inviteAccepting,
        inviteAcceptingError,
        accepted
    };
};

export default connect(mapStateToProps)(AcceptInvitation);
