// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';

const StateProvSelect = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    myStyle,
    options,
    touched,
    nestedOptions
}) => {
    return (
        <div style={myStyle}>
            <Field
                autoComplete="off"
                required={required}
                type={type}
                className={className}
                style={{
                    borderColor:
                        error && touched
                            ? '#de4437'
                            : value
                            ? 'rgba(0, 201, 167, 0.5)'
                            : ''
                }}
                name={name}
                id={id}
                placeholder={placeholder}
                error={error}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                as="select"
            >
                <option value="">Select</option>
                {nestedOptions ? (
                    <>
                        {options.map(item => (
                            <optgroup
                                key={Object.keys(item)[0]}
                                label={Object.keys(item)[0]}
                            >
                                {item[Object.keys(item)[0]].map(state => (
                                    <option
                                        key={
                                            state.englishName
                                                ? `${state.englishName}${
                                                      state.region
                                                          ? state.region
                                                          : state.item
                                                  }`
                                                : `${state.name}${
                                                      state.region
                                                          ? state.region
                                                          : state.item
                                                  }`
                                        }
                                        value={
                                            state.englishName
                                                ? state.englishName
                                                : state.name
                                        }
                                    >
                                        {state.englishName
                                            ? state.englishName
                                            : state.name}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </>
                ) : (
                    <>
                        {options.map(option => (
                            <option key={option.value} value={option.name}>
                                {`${
                                    option?.flagIcon
                                        ? `${option.flagIcon} ${option.name}`
                                        : option.value
                                }`}
                            </option>
                        ))}
                    </>
                )}
            </Field>
            <FormFeedback style={{ display: error && touched ? 'block' : 'none' }}>
                {error}
            </FormFeedback>
        </div>
    );
};

export default StateProvSelect;
