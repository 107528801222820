// React
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';

// Assets
import CallChime from '../assets/boop.mp3';

// Components
import { ToolBar, Loader, OrgNav } from '../components';
import OrgSelectorList from '../components/OrgSelector/OrgSelectorList';
import ReservationDetails from '../components/RentalManagerDashboard/ReservationDetails';

// Pages
import {
    AddManager,
    AddMember,
    AddRentalManager,
    AddReservation,
    AddReservationFromCSV,
    ImportMembers,
    Dashboard,
    Settings,
    AccessGroups,
    Community,
    Content,
    Devices,
    Units,
    Vendors,
    RentalDashboard,
    ResetPasswordAuthenticated,
    ProfileSettingsPage,
    PanelVideo,
    GuestManager
} from '../pages';

// Packages
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Notyf } from 'notyf';

// Redux - Actions, Reducers, Sagas
import { settingPrimaryOrg } from '../store/actions/Org';
import { setActiveManagerOrg } from '../store/actions/User';
import { fieldPanelCall } from '../store/actions/Calls';

//Router
import * as routes from '../router/config/routes';

const AuthenticatedRouter = ({
    authUser,
    userData,
    match,
    history,
    userType,
    userRole,
    orgs,
    primaryOrg,
    settingPrimaryOrg,
    setActiveManagerOrg,
    activeOrg,
    active,
    pending,
    fieldPanelCall
}) => {
    const [mount, setMount] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [playChime, setPlayChime] = useState(false);
    const [notyfiedCalls, setNotyfiedCalls] = useState([]);
    const [notyfDismiss, setNotyfDismiss] = useState(null);

    const chimeEl = useRef(new Audio(CallChime));

    const notyf = useMemo(() => {
        if (notyfDismiss) {
            return new Notyf({
                duration: notyfDismiss,
                position: { x: 'center', y: 'top' },
                className: 'pointer',
                types: [
                    {
                        type: 'success',
                        icon: {
                            className: 'fa-solid fa-phone-volume pointer',
                            tag: 'i',
                            color: '#ffffff'
                        }
                    }
                ]
            });
        }
    }, [notyfDismiss]);

    const getNotyfDismiss = useCallback(
        org => {
            const { stations } = org;
            const statArr = Object.values(stations);
            let opSum = 0;

            statArr.forEach((station, index) => {
                const opCount = Object.keys(station.operators).length;
                opSum = opSum + opCount;
                if (index === statArr.length - 1) {
                    if (opSum <= 1) {
                        setNotyfDismiss(60000);
                    } else {
                        setNotyfDismiss(10000);
                    }
                }
            });
        },
        [setNotyfDismiss]
    );

    useEffect(() => {
        if (primaryOrg && primaryOrg.stations && !notyfDismiss) {
            getNotyfDismiss(primaryOrg);
        }
    }, [primaryOrg, notyfDismiss, getNotyfDismiss]);

    useEffect(() => {
        const chime = chimeEl.current;
        const handleAudio = () => {
            if (playChime) {
                chime.play();
            } else {
                chime.currentTime = 0;
            }
        };

        if (chime) {
            chime.addEventListener('ended', handleAudio);
            if (playChime) {
                chime.play();
            } else {
                chime.currentTime = 0;
            }
        }

        return () => {
            if (chime) {
                chime.pause();
                chime.removeEventListener('ended', handleAudio);
            }
        };
    }, [playChime]);

    useEffect(() => {
        const handleCallFielding = call => {
            fieldPanelCall({ panelCall: call, userData });
            setNotyfiedCalls([]);
            history.push({
                pathname: `${routes.AUTHENTICATED}${routes.PANEL_VIDEO}`
            });
        };

        const incomingCall = call => {
            if (!notyfiedCalls.includes(call.id)) {
                setNotyfiedCalls([...notyfiedCalls, call.id]);
                const notification = notyf.success(`Incoming Call - ${call.panel_name}`);
                notification.on('click', ({ target, event }) => {
                    handleCallFielding(call);
                    notyf.dismissAll();
                });
                return notification;
            }
        };

        const checkActive = () => {
            var onCall = active.filter(call => call.fielded_by === userData.uid).length;
            if (onCall > 0) return true;
            return false;
        };

        if (activeOrg) {
            if (pending && pending.length > 0) {
                if (active && active.length > 0) {
                    if (!checkActive()) {
                        setPlayChime(true);
                        pending.forEach(call => {
                            incomingCall(call);
                        });
                    } else {
                        if (notyfiedCalls.length) setNotyfiedCalls([]);
                        setPlayChime(false);
                    }
                } else {
                    setPlayChime(true);
                    pending.forEach(call => {
                        incomingCall(call);
                    });
                }
            } else {
                if (notyfiedCalls.length) setNotyfiedCalls([]);
                setPlayChime(false);
                if (notyf) notyf.dismissAll();
            }
        }
    }, [
        active,
        pending,
        userData,
        notyf,
        history,
        fieldPanelCall,
        activeOrg,
        setPlayChime,
        notyfiedCalls,
        setNotyfiedCalls
    ]);

    useEffect(() => {
        if (
            activeOrg &&
            history.location.pathname.includes('guest-reservation') &&
            !activeOrg.rentalManagerEnabled
        ) {
            userType && userType === 'super_admin'
                ? history.push(`${routes.SUPER_AUTHENTICATED}${routes.SUPER_ADMIN}`)
                : history.push(`${routes.AUTHENTICATED}${routes.DASHBOARD}`);
        }
    }, [activeOrg, history, userType]);

    useEffect(() => {
        if (userData?.active_org_id && notyf) {
            // console.log(notyf);
            notyf.dismissAll();
        }
    }, [userData?.active_org_id, notyf]);

    useEffect(() => {
        if (!mount && userRole === 'resi') {
            setRedirect(`${routes.AUTHENTICATED}${routes.GUEST_MANAGER}`);
        }
        if (!mount && orgs) {
            const primeOrg = orgs.find(o => o.org_id === userData.primary_org);
            if (userType && userType === 'rental_manager') {
                setMount(true);
                settingPrimaryOrg(primeOrg);
                setActiveManagerOrg(primeOrg);
                setRedirect({
                    pathname: `${routes.RENTAL_MANAGER}${routes.RENTAL_DASHBOARD}`,
                    state: {
                        isSuperAdmin: false,
                        org_id: primeOrg.org_id,
                        org_name: primeOrg.org_name,
                        firstName: userData.first_name,
                        lastName: userData.last_name,
                        userId: userData.uid
                    }
                });
            }

            if (userType && userType === 'manager') {
                setMount(true);
                setRedirect(`${routes.AUTHENTICATED}${routes.DASHBOARD}`);
                settingPrimaryOrg(primeOrg);

                if (orgs.length === 1) {
                    setActiveManagerOrg(orgs[0]);
                }
            }
            if (userType && userType === 'operator') {
                setMount(true);
                setRedirect(`${routes.AUTHENTICATED}${routes.DASHBOARD}`);
                settingPrimaryOrg(primeOrg);

                if (orgs.length === 1) {
                    setActiveManagerOrg(orgs[0]);
                }
            }
            if (userType && userType === 'super_admin') {
                setMount(true);
                setRedirect(`${routes.SUPER_AUTHENTICATED}${routes.SUPER_ADMIN}`);
            }
        }
    }, [
        mount,
        userType,
        userRole,
        orgs,
        setMount,
        setRedirect,
        settingPrimaryOrg,
        setActiveManagerOrg,
        userData
    ]);

    if (!redirect && !mount) return <Loader />;

    if (!activeOrg && userData && userData.type !== 'super_admin' && orgs) {
        const userOrgs = [];
        for (const property in userData.properties) {
            userOrgs.push(
                orgs.find(org => {
                    return org.org_id === property;
                })
            );
        }

        return (
            <div style={{ height: '100vh' }}>
                <ToolBar />
                <div
                    className="d-flex flex-column align-items-center w-100 h-100"
                    style={{ minWidth: '0' }}
                >
                    <div className="m-4 w-50">
                        <OrgSelectorList
                            selectOrg={setActiveManagerOrg}
                            orgs={userOrgs}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{ height: '100vh' }}>
            <div
                className="d-flex flex-column w-100 h-100 flex-auto"
                style={{ minWidth: '0' }}
            >
                {userRole !== 'resi' && <ToolBar />}
                <div className="d-flex justify-content-start h-100">
                    {userType !== 'rental_manager' && userRole !== 'resi' && (
                        <div>
                            <OrgNav superAdmin={false} {...primaryOrg} />
                        </div>
                    )}
                    <div className={`${userRole !== 'resi' && 'main'} w-100`}>
                        <Switch>
                            <Redirect exact from={`${match.url}`} to={redirect} />
                            <Route
                                authUser={authUser}
                                path={`${match?.url}${routes.DASHBOARD}`}
                                render={props => (
                                    <Dashboard
                                        {...props}
                                        userData={userData}
                                        primaryOrg={primaryOrg}
                                    />
                                )}
                            />

                            <Route
                                path={`${match.url}${routes.SETTINGS}`}
                                render={props => (
                                    <Settings {...props} primaryOrg={primaryOrg} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.ADD_NEW_MANAGER}`}
                                render={props => (
                                    <AddManager
                                        isSuperAdmin={false}
                                        {...props}
                                        primaryOrg={primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.ADD_NEW_MEMBER}`}
                                render={props => (
                                    <AddMember
                                        isSuperAdmin={false}
                                        {...props}
                                        primaryOrg={primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.ADD_NEW_RENTAL_MANAGER}`}
                                render={props => (
                                    <AddRentalManager
                                        isSuperAdmin={false}
                                        {...props}
                                        primaryOrg={primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.RENTAL_DASHBOARD}`}
                                render={props => (
                                    <RentalDashboard {...props} primaryOrg={primaryOrg} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.RESERVATION_DETAILS_EDIT}`}
                                render={props => (
                                    <ReservationDetails
                                        {...props}
                                        primaryOrg={userData.primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.GUEST_RESERVATION}`}
                                render={props => (
                                    <AddReservation {...props} primaryOrg={primaryOrg} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.GUEST_RESERVATION_BULK}`}
                                render={props => (
                                    <AddReservationFromCSV
                                        {...props}
                                        primaryOrg={userData.primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.IMPORT_MEMBERS_CSV}`}
                                render={props => (
                                    <ImportMembers {...props} primaryOrg={primaryOrg} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.RESET_PASSWORD_AUTHENTICATED}`}
                                render={props => (
                                    <ResetPasswordAuthenticated {...props} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.PROFILE_SETTINGS}`}
                                render={props => <ProfileSettingsPage {...props} />}
                            />
                            <Route
                                path={`${match.url}${routes.ACCESS_GROUPS}`}
                                render={props => (
                                    <AccessGroups {...props} primaryOrg={primaryOrg} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.COMMUNITY}`}
                                render={props => (
                                    <Community
                                        {...props}
                                        userData={userData}
                                        userType={userType}
                                        primaryOrg={primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.CONTENT}`}
                                render={props => (
                                    <Content
                                        {...props}
                                        userData={userData}
                                        primaryOrg={primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.DEVICES}`}
                                render={props => (
                                    <Devices
                                        {...props}
                                        userData={userData}
                                        primaryOrg={primaryOrg}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.UNITS}`}
                                render={props => (
                                    <Units {...props} primaryOrg={primaryOrg} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.VENDORS}`}
                                render={props => (
                                    <Vendors {...props} primaryOrg={primaryOrg} />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.PANEL_VIDEO}`}
                                render={props => (
                                    <PanelVideo
                                        {...props}
                                        primaryOrg={primaryOrg}
                                        active={active}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}${routes.GUEST_MANAGER}`}
                                render={props => (
                                    <GuestManager {...props} userData={userData} />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ org, user, calls }) => {
    const { orgs, primaryOrg } = org;
    const { active, pending } = calls;
    const { activeOrg } = user;
    return { orgs, primaryOrg, active, pending, activeOrg };
};

export default withRouter(
    connect(mapStateToProps, { settingPrimaryOrg, setActiveManagerOrg, fieldPanelCall })(
        AuthenticatedRouter
    )
);
