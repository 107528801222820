import {
    CREATE_ACCESS_API_CALL,
    CREATE_ACCESS_API_SUCCESS,
    CREATE_ACCESS_API_MATCHED_ACCOUNT,
    CREATE_ACCESS_API_VERIFY_NUMBER,
    CREATE_ACCESS_API_NEW_VERIFICATION,
    CREATE_ACCESS_API_VERIFICATION_SUCCESS,
    CREATE_ACCESS_API_ERROR_MESSAGE,
    CREATE_ACCESS_API_NEW_VERIFICATION_ADDED,
    CREATE_ACCESS_API_START_OVER
} from '../actions/types';

const INITIAL_STATE = {
    apiCallInProgress: false,
    accessCreated: false,
    existingAccount: null,
    newVerificationReady: false,
    verified: false,
    verificationMessage: null,
    verifyCode: null
};

const confirmReservationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_ACCESS_API_START_OVER:
            return INITIAL_STATE;
        case CREATE_ACCESS_API_CALL:
            return {
                ...state,
                apiCallInProgress: true
            };
        case CREATE_ACCESS_API_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                accessCreated: true
            };
        case CREATE_ACCESS_API_MATCHED_ACCOUNT:
            return {
                ...state,
                apiCallInProgress: false,
                existingAccount: action.payload
            };
        case CREATE_ACCESS_API_VERIFY_NUMBER:
            return {
                ...state,
                apiCallInProgress: true
            };
        case CREATE_ACCESS_API_NEW_VERIFICATION:
            return {
                ...state,
                apiCallInProgress: true
            };
        case CREATE_ACCESS_API_NEW_VERIFICATION_ADDED:
            return {
                ...state,
                apiCallInProgress: false,
                newVerificationReady: true,
                verifyCode: action.payload
            };
        case CREATE_ACCESS_API_VERIFICATION_SUCCESS:
            return {
                ...state,
                apiCallInProgress: false,
                verified: true
            };
        case CREATE_ACCESS_API_ERROR_MESSAGE:
            return {
                ...state,
                apiCallInProgress: false,
                verificationMessage: action.payload
            };
        default:
            return state;
    }
};

export default confirmReservationReducer;
