// React
import React from 'react';

// Components
import { TextField, StateCountrySelect } from '..';

// Constants
import { stateProvince } from '../../utils/Constants';

// Packages
import { Label } from 'reactstrap';

const OrgForm = ({ errors, handleBlur, handleChange, touched, values }) => {
    return (
        <div className="w-md-50 p-5 mx-md-auto">
            <div className="row m-auto">
                <div className="js-form-message col-12 form-group">
                    <Label className="form-label" for="orgName">
                        organization name
                    </Label>
                    <TextField
                        required
                        type="text"
                        className="form-control"
                        name="orgName"
                        id="orgName"
                        placeholder=""
                        aria-label="orgName"
                        error={errors.orgName}
                        value={values.orgName}
                        onBlur={handleBlur('orgName')}
                        onChange={handleChange('orgName')}
                        invalid={touched.orgName && !!errors.orgName}
                    />
                </div>
            </div>
            <div className="row m-auto">
                <div className="js-form-message col-12 form-group">
                    <Label className="form-label" for="orgName">
                        address 1
                    </Label>
                    <TextField
                        required
                        type="text"
                        className="form-control"
                        name="address1"
                        id="address1"
                        placeholder=""
                        aria-label="address1"
                        error={errors.address1}
                        value={values.address1}
                        onBlur={handleBlur('address1')}
                        onChange={handleChange('address1')}
                        invalid={touched.address1 && !!errors.address1}
                    />
                </div>
            </div>
            <div className="row m-auto">
                <div className="js-form-message col-12 form-group">
                    <Label className="form-label" for="address2">
                        address 2
                    </Label>
                    <TextField
                        required
                        type="text"
                        className="form-control"
                        name="address2"
                        id="address2"
                        placeholder=""
                        aria-label="address2"
                        error={errors.address2}
                        value={values.address2}
                        onBlur={handleBlur('address2')}
                        onChange={handleChange('address2')}
                        invalid={touched.address2 && !!errors.address2}
                    />
                </div>
            </div>
            <div className="row m-auto">
                <div className="js-form-message col-12 form-group">
                    <Label className="form-label" for="city">
                        city
                    </Label>
                    <TextField
                        required
                        type="text"
                        className="form-control"
                        name="city"
                        id="city"
                        placeholder=""
                        aria-label="city"
                        error={errors.city}
                        value={values.city}
                        onBlur={handleBlur('city')}
                        onChange={handleChange('city')}
                        invalid={touched.city && !!errors.city}
                    />
                </div>
            </div>
            <div className="row m-auto">
                <div className="js-form-message col-6 form-group">
                    <Label className="form-label" for="state">
                        state
                    </Label>
                    <StateCountrySelect
                        className="form-control custom-select"
                        name={'state'}
                        id={'state'}
                        placeholder=""
                        aria-label={'state'}
                        nestedOptions={true}
                        options={stateProvince}
                        error={errors.state}
                        value={values.state}
                        onBlur={handleBlur('state')}
                        onChange={handleChange('state')}
                        touched={touched.state}
                        invalid={touched.state && !!errors.state}
                    />
                </div>
                <div className="js-form-message col-6 form-group">
                    <Label className="form-label" for="zip">
                        zip
                    </Label>
                    <TextField
                        required
                        type="text"
                        className="form-control"
                        name="zip"
                        id="zip"
                        placeholder=""
                        aria-label="zip"
                        error={errors.zip}
                        value={values.zip}
                        onBlur={handleBlur('zip')}
                        onChange={handleChange('zip')}
                        invalid={touched.zip && !!errors.zip}
                    />
                </div>
            </div>
            <div className="row m-auto">
                <div className="js-form-message col-9 form-group">
                    <Label className="form-label" for="orgOfficePhone">
                        office phone
                    </Label>
                    <TextField
                        required
                        type="text"
                        className="form-control"
                        name="orgOfficePhone"
                        id="orgOfficePhone"
                        placeholder=""
                        aria-label="orgOfficePhone"
                        error={errors.orgOfficePhone}
                        value={values.orgOfficePhone}
                        onBlur={handleBlur('orgOfficePhone')}
                        onChange={handleChange('orgOfficePhone')}
                        invalid={touched.orgOfficePhone && !!errors.orgOfficePhone}
                    />
                </div>
                <div className="js-form-message col-3 form-group">
                    <Label className="form-label" for="orgOfficePhone">
                        ext
                    </Label>
                    <TextField
                        required
                        type="text"
                        className="form-control"
                        name="orgOfficePhoneExt"
                        id="orgOfficePhoneExt"
                        placeholder=""
                        aria-label="orgOfficePhoneExt"
                        error={errors.orgOfficePhoneExt}
                        value={values.orgOfficePhoneExt}
                        onBlur={handleBlur('orgOfficePhoneExt')}
                        onChange={handleChange('orgOfficePhoneExt')}
                        invalid={touched.orgOfficePhoneExt && !!errors.orgOfficePhoneExt}
                    />
                </div>
            </div>
        </div>
    );
};

export default OrgForm;
