// React
import React, { useEffect, useState } from 'react';

// Components
import DeviceDetailsForm from '../components/Devices/DeviceDetailsForm';
import DeviceList from '../components/Devices/DeviceList';
import DevicesTabHeader from '../components/Devices/DevicesTabHeader';

// Packages
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { getDeviceTypes, watchOrgDevicesRTDB } from '../store/actions/Panels';

// Router
import { RENTAL_DASHBOARD, RENTAL_MANAGER } from '../router/config/routes';

const Devices = ({ primaryOrg, userData, activeOrg }) => {
    const dispatch = useDispatch();
    const orgData = userData?.type === 'manager' ? activeOrg : primaryOrg;
    const [device, setDevice] = useState(null);
    const showAddButton = userData?.type === 'super_admin';

    useEffect(() => {
        dispatch(getDeviceTypes());
        dispatch(watchOrgDevicesRTDB(orgData?.org_id));
    }, [dispatch, orgData?.org_id]);

    if (!userData) return null;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    if (device) {
        return (
            <>
                <DevicesTabHeader
                    org_name={orgData?.org_name}
                    org_id={orgData?.org_id}
                    showAddButton={false}
                />
                <DeviceDetailsForm
                    setDevice={setDevice}
                    device={device}
                    orgId={orgData?.org_id}
                />
            </>
        );
    }

    return (
        <>
            <DevicesTabHeader
                org_name={orgData?.org_name}
                org_id={orgData?.org_id}
                showAddButton={showAddButton}
            />
            <DeviceList setDevice={setDevice} />
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { activeOrg, userData } = user;
    return { activeOrg, userData };
};

export default connect(mapStateToProps)(Devices);
