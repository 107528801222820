// React
import React, { useState, useEffect } from 'react';

// Constants
import {
    phoneRegExp,
    phoneExtRegExp,
    nameRegExp,
    streetAddressRegExp,
    errorMessage
} from '../../utils/Constants';

// Packages
import { Container } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import OrgForm from './OrgForm';
import ManagerForm from './ManagerForm';
import ConfirmFooter from '../common/ConfirmFooter';
import { createNewOrg, resetOrgCreationState } from '../../store/actions/Org';
import Loader from '../common/Loader';
import { useHistory } from 'react-router-dom';
import { DASHBOARD, SUPER_ADMIN, SUPER_AUTHENTICATED } from '../../router/config/routes';
import SuccessScreen from '../FeedbackScreens/SuccessScreen';
import FailureScreen from '../FeedbackScreens/FailureScreen';

const NewOrgForm = ({ loading, orgCreationError, newOrgName }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [idCount, setIdCount] = useState(1);

    useEffect(() => {
        return () => dispatch(resetOrgCreationState());
    }, [dispatch]);

    const validationSchema = Yup.object().shape({
        orgName: Yup.string().trim().required(errorMessage.orgName.required),
        address1: Yup.string()
            .trim()
            .matches(streetAddressRegExp, errorMessage.address.valid),
        address2: Yup.string().trim(),
        city: Yup.string().trim().matches(nameRegExp.format, errorMessage.city.valid),
        state: Yup.string().trim(),
        zip: Yup.string().trim(),
        orgOfficePhone: Yup.string()
            .trim()
            .matches(phoneRegExp.format, errorMessage.phone.valid),
        orgOfficePhoneExt: Yup.string()
            .trim()
            .matches(phoneExtRegExp.format, errorMessage.phoneExt.valid),
        managers: Yup.array().of(
            Yup.object().shape({
                first_name: Yup.string()
                    .trim()
                    .matches(nameRegExp.format, errorMessage.firstName.valid),
                last_name: Yup.string()
                    .trim()
                    .matches(nameRegExp.format, errorMessage.lastName.valid),
                officePhone: Yup.string()
                    .trim()
                    .matches(phoneRegExp.format, errorMessage.phone.valid),
                officePhoneExt: Yup.string()
                    .trim()
                    .matches(phoneExtRegExp.format, errorMessage.phoneExt.valid),
                mobilePhone: Yup.string()
                    .trim()
                    .matches(phoneRegExp.format, errorMessage.phone.valid),
                email: Yup.string().trim().lowercase().email(errorMessage.email.valid)
            })
        )
    });

    const submitForm = formValues => {
        dispatch(createNewOrg(formValues));
    };

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (newOrgName) {
        return (
            <SuccessScreen
                addAction={() => dispatch(resetOrgCreationState())}
                addType="Organization"
                message={`${newOrgName} has been created.`}
            />
        );
    }

    if (orgCreationError) {
        return (
            <FailureScreen
                action={() => dispatch(resetOrgCreationState())}
                actionTitle="Back to form"
                error={`Error code: ${orgCreationError}`}
            />
        );
    }

    return (
        <Container className="d-flex flex-column align-items-start flex-grow-1 justify-content-start">
            <Formik
                initialValues={{
                    orgName: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    orgOfficePhone: '',
                    orgOfficePhoneExt: '',
                    managers: [
                        {
                            managerFormID: 0,
                            first_name: '',
                            last_name: '',
                            officePhone: '',
                            officePhoneExt: '',
                            mobilePhone: '',
                            email: ''
                        }
                    ]
                }}
                onSubmit={submitForm}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({ handleSubmit, ...restFormProps }) => (
                    <div className="col">
                        <div className="mb-4 mt-4">
                            <h3 className="font-weight-semi-bold">New Organization</h3>
                        </div>
                        <Form className="row h-75">
                            <OrgForm {...restFormProps} />
                            <ManagerForm
                                className="overflow-auto"
                                {...restFormProps}
                                idCount={idCount}
                                setIdCount={setIdCount}
                            />
                        </Form>
                        <div className="row flex-grow-1 mt-3">
                            <ConfirmFooter
                                cancel={() =>
                                    history.replace(
                                        `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${DASHBOARD}`
                                    )
                                }
                                submitFunc={handleSubmit}
                                submitText="Save"
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </Container>
    );
};

const mapStateToProps = ({ org: { creatingOrg, orgCreationError, newOrgName } }) => {
    return {
        loading: creatingOrg,
        orgCreationError,
        newOrgName
    };
};

export default connect(mapStateToProps)(NewOrgForm);
