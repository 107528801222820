import React, { useState } from 'react';

import { Container, Button, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    createAccessAPIVerifyNumber,
    createAccessAPIStartOver
} from '../../store/actions/ConfirmReservation';
import { createVeryficationForMobileNumber } from '../../store/sagas/ConfirmReservation';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const VerificationCodeForm = ({
    verificationObject,
    resendVerificationObj,
    org_name
}) => {
    const dispatch = useDispatch();
    const [sent, setSent] = useState(false);
    const [recieved, setRecieved] = useState(true);

    const submit = values => {
        dispatch(
            createAccessAPIVerifyNumber({
                ...verificationObject,
                code: values.verificationCode
            })
        );
    };

    const resendCode = async () => {
        const isSent = await createVeryficationForMobileNumber(resendVerificationObj);
        if (!isSent?.data?.message && !isSent?.error) {
            setSent(true);
        }
    };

    const validationSchema = yup.object().shape({
        verificationCode: yup.string().trim().required('Verification code required')
    });

    return (
        <Container className="space-2 col-small">
            <Col>
                {recieved ? (
                    <Formik
                        initialValues={{
                            verificationCode: ''
                        }}
                        onSubmit={submit}
                        validationSchema={validationSchema}
                        validateOnChange
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values
                        }) => (
                            <Form className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                                <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                                    <img
                                        src="../assets/sage-icon.png"
                                        alt="logo"
                                        width={80}
                                    />
                                    <div className="d-flex w-md-75 flex-column mb-2 align-items-center justify-content-center">
                                        <div
                                            style={{ fontSize: '32px' }}
                                            className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                                        >
                                            Enter verification code.
                                        </div>
                                    </div>
                                </div>
                                <div className="js-form-message form-group mx-5 mt-5">
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="js-form-message w-50 form-group mb-0">
                                            <LabeledFormField
                                                type="text"
                                                className="form-control"
                                                name="verificationCode"
                                                id="verificationCode"
                                                placeholder="6 digit code"
                                                aria-label="verificationCode"
                                                error={returnError(
                                                    errors,
                                                    'verificationCode'
                                                )}
                                                value={values.verificationCode}
                                                onBlur={handleBlur('verificationCode')}
                                                onChange={handleChange(
                                                    'verificationCode'
                                                )}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        'verificationCode'
                                                    ) &&
                                                    !!returnError(
                                                        errors,
                                                        'verificationCode'
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="js-form-message form-group mx-5 mt-2 mb-8">
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="js-form-message w-50 form-group text-center">
                                            {!sent ? (
                                                <div
                                                    className="link-muted link-text font-weight-normal"
                                                    onClick={resendCode}
                                                >
                                                    I didn&apos;t receive a code
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="d-flex w-md-100 flex-column mb-2 align-items-center justify-content-center">
                                                        <div
                                                            style={{
                                                                fontSize: '14px',
                                                                lineHeight: 1.2
                                                            }}
                                                            className="d-flex text-center
                                                        text-dark-gray
                                                        align-items-center justify-content-center font-weight-semi-bold my-2"
                                                        >
                                                            New Code Sent!
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: '12px',
                                                                lineHeight: 1.2
                                                            }}
                                                            className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal mb-3"
                                                        >
                                                            Messages could take a few
                                                            minutes depending on your
                                                            mobile carrier.
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="link-muted link-text font-weight-normal"
                                                        onClick={() => setRecieved(false)}
                                                    >
                                                        I still didn&apos;t receive a code
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex row justify-content-end align-items-center border-top my-5 mx-2">
                                    <Button
                                        disabled={
                                            !values.verificationCode ||
                                            values.verificationCode.length < 6 ||
                                            values.verificationCode.length > 6
                                        }
                                        type="submit"
                                        color="primary"
                                        className="transition-3d-hover mt-5 mr-2 btn"
                                        onClick={handleSubmit}
                                    >
                                        Verify phone
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                        <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                            <img src="../assets/sage-icon.png" alt="logo" width={80} />
                            <div className="d-flex w-md-75 flex-column mb-2 align-items-center justify-content-center">
                                <div
                                    style={{ fontSize: '32px' }}
                                    className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                                >
                                    Oh no!
                                </div>
                                <div
                                    style={{ fontSize: '16px' }}
                                    className="text-center text-muted w-md-75 font-weight-normal my-3"
                                >
                                    We apologize for this issue. Some carriers can block
                                    text messages depending on their filtering policies.
                                    Please contact{' '}
                                    <span className="font-weight-semi-bold">
                                        {org_name}
                                    </span>{' '}
                                    or your booking agent and someone will help to ensure
                                    you receive the proper information for accessing the
                                    property during your stay. If you&apos;d like to try a
                                    different mobile number click &quot;start over&quot;
                                    below.
                                </div>
                            </div>
                        </div>

                        <div className="d-flex row justify-content-between align-items-center border-top my-5 mx-2">
                            <div
                                className="link-muted link-text font-weight-normal mt-5 ml-5"
                                onClick={() => setRecieved(true)}
                            >
                                I just recieved my code!
                            </div>
                            <Button
                                type="submit"
                                color="primary"
                                className="transition-3d-hover mt-5 mr-2 btn"
                                onClick={() => dispatch(createAccessAPIStartOver())}
                            >
                                Start over
                            </Button>
                        </div>
                    </div>
                )}
            </Col>
        </Container>
    );
};

const mapStateToProps = ({
    reservations: {
        validationSuccess: { org_name }
    },
    confirmAccess: {
        existingAccount: { phone, uid }
    }
}) => ({
    resendVerificationObj: phone,
    verificationObject: {
        type: 'phone',
        value: `${phone.code}${phone.number}`,
        matched_user_id: uid
    },
    org_name
});

export default connect(mapStateToProps)(VerificationCodeForm);
