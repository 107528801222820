import {
    SET_USER_ORG,
    CREATE_ORG,
    CREATE_ORG_SUCCESS,
    CREATE_ORG_FAILURE,
    RESET_ORG_CREATION_STATE,
    SET_PRIMARY_ORG,
    ADD_INVITE_TO_ORG,
    ADD_INVITE_TO_ORG_SUCCESS,
    ADD_INVITE_TO_ORG_FAILURE,
    RESET_INVITE_ADDING_STATE,
    GET_ORGS_COLLECTION,
    GET_ORGS_COLLECTION_SUCCESS,
    GET_ORGS_COLLECTION_FAILURE,
    SET_ACTIVE_USER_ORG_SUCCESS,
    SET_ACTIVE_USER_ORG_FAILURE
} from './types';

export const getOrgCollection = () => {
    return {
        type: GET_ORGS_COLLECTION
    };
};

export const getOrgCollectionFailure = error => {
    return {
        type: GET_ORGS_COLLECTION_FAILURE,
        payload: error
    };
};

export const getOrgCollectionSuccess = data => {
    return {
        type: GET_ORGS_COLLECTION_SUCCESS,
        payload: data
    };
};

export const storeOrgData = org => {
    return {
        type: SET_USER_ORG,
        payload: org
    };
};

export const createNewOrg = ogrData => {
    return {
        type: CREATE_ORG,
        payload: ogrData
    };
};

export const createOrgSuccess = orgName => {
    return {
        type: CREATE_ORG_SUCCESS,
        payload: orgName
    };
};

export const createOrgFailure = error => {
    return {
        type: CREATE_ORG_FAILURE,
        payload: error
    };
};

export const resetOrgCreationState = () => {
    return {
        type: RESET_ORG_CREATION_STATE
    };
};

export const settingPrimaryOrg = org => {
    return {
        type: SET_PRIMARY_ORG,
        payload: org
    };
};

export const addInviteToOrg = inviteInfo => {
    return {
        type: ADD_INVITE_TO_ORG,
        payload: inviteInfo
    };
};

export const addInviteToOrgSuccess = email => {
    return {
        type: ADD_INVITE_TO_ORG_SUCCESS,
        payload: email
    };
};

export const addInviteToOrgFailure = error => {
    return {
        type: ADD_INVITE_TO_ORG_FAILURE,
        payload: error
    };
};

export const resetInviteAddingState = () => {
    return {
        type: RESET_INVITE_ADDING_STATE
    };
};

export const settingActiveOrgSuccess = org => {
    return {
        type: SET_ACTIVE_USER_ORG_SUCCESS,
        payload: org
    };
};

export const settingActiveOrgFailure = error => {
    return {
        type: SET_ACTIVE_USER_ORG_FAILURE,
        payload: error
    };
};
