import {
    ADD_NEW_REGISTRATION,
    ADD_NEW_REGISTRATION_SUCCESS,
    ADD_NEW_REGISTRATION_FAILURE,
    RESET_ADD_NEW_REGISTRATION
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    member: null,
    success: null
};

const registrationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_NEW_REGISTRATION:
            return {
                ...state,
                member: action.payload.member,
                loading: true
            };
        case ADD_NEW_REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case ADD_NEW_REGISTRATION_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case RESET_ADD_NEW_REGISTRATION:
            return {
                ...state,
                loading: null,
                error: null,
                member: action.payload === 'soft' ? state.member : null,
                success: null
            };
        default:
            return state;
    }
};

export default registrationReducer;
