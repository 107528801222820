import {
    GET_ACCESS_KEYS_DOCUMENT,
    GET_ACCESS_KEYS_DOCUMENT_SUCCESS,
    GET_ACCESS_KEYS_DOCUMENT_FAILURE,
    ADD_ACCESS_KEYS_DOCUMENT,
    ADD_ACCESS_KEYS_DOCUMENT_SUCCESS,
    ADD_ACCESS_KEYS_DOCUMENT_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    accessKeyDoc: null,
    accessKeyLoading: false,
    accessKeyLoadError: null,
    creatingAccess: false,
    creatingAccessSuccess: false,
    creatingAccessError: null
};

const accessKeyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ACCESS_KEYS_DOCUMENT:
            return {
                ...state,
                accessKeyLoading: true
            };
        case GET_ACCESS_KEYS_DOCUMENT_SUCCESS:
            return {
                ...state,
                accessKeyLoading: false,
                accessKeyDoc: action.payload
            };
        case GET_ACCESS_KEYS_DOCUMENT_FAILURE:
            return {
                ...state,
                accessKeyLoading: false,
                accessKeyLoadError: action.payload
            };
        case ADD_ACCESS_KEYS_DOCUMENT:
            return {
                ...state,
                creatingAccess: true
            };
        case ADD_ACCESS_KEYS_DOCUMENT_SUCCESS:
            return {
                ...state,
                creatingAccess: false,
                creatingAccessSuccess: true
            };
        case ADD_ACCESS_KEYS_DOCUMENT_FAILURE:
            return {
                ...state,
                creatingAccess: false,
                creatingAccessError: action.payload
            };
        default:
            return state;
    }
};

export default accessKeyReducer;
