import React from 'react';

export default function WebPrivacyPolicy() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12" style={{ fontSize: '.80rem' }}>
                    <div className="my-5">
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ fontSize: '28px' }}
                        >
                            Website Privacy Policy
                        </div>
                        <div className="mb-3">Last modified: January 9th, 2023</div>
                        <div className="mb-3">
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Introduction
                            </div>
                            We at Ramco Protective of Orlando Inc. (
                            <span style={{ fontWeight: 'bold' }}>”Company”</span> or{' '}
                            <span style={{ fontWeight: 'bold' }}>“We”</span>) respect your
                            privacy and are committed to protecting it through our
                            compliance with this policy.
                            <br />
                            <br />
                            This policy describes the types of information we may collect
                            from you or that you may provide when you visit the website
                            www.sagesystems.io (our{' '}
                            <span style={{ fontWeight: 'bold' }}>”Website”</span>) and our
                            practices for collecting, using, maintaining, protecting, and
                            disclosing that information.
                            <br />
                            <br />
                            This policy applies to information we collect:
                            <ul className="my-3">
                                <li>On this Website.</li>
                                <li>
                                    In email, text, and other electronic messages between
                                    you and this Website.
                                </li>
                                <li>
                                    When you interact with our advertising and
                                    applications on third-party websites and services, if
                                    those applications or advertising include links to
                                    this policy.
                                </li>
                            </ul>
                            It does not apply to information collected by:
                            <ul className="my-3">
                                <li>
                                    Us offline or through any other means, including on
                                    any other website operated by Company or any third
                                    party (including our affiliates and subsidiaries); or{' '}
                                </li>
                                <li>
                                    Any third party (including our affiliates and
                                    subsidiaries), including through any application or
                                    content (including advertising) that may link to or be
                                    accessible from or through the Website.
                                </li>
                            </ul>
                            Please read this policy carefully to understand our policies
                            and practices regarding your information and how we will treat
                            it. If you do not agree with our policies and practices, your
                            choice is not to use our Website. By accessing or using this
                            Website, you agree to this privacy policy. This policy may
                            change from time to time (see Changes to Our Privacy Policy).
                            Your continued use of this Website after we make changes is
                            deemed to be acceptance of those changes, so please check the
                            policy periodically for updates.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Children Under the Age of 16
                            </div>
                            Our Website is not intended for children under 16 years of
                            age. No one under age 16 may provide any personal information
                            to or on the Website. We do not knowingly collect personal
                            information from children under 16. If you are under 16, do
                            not use or provide any information on this Website or through
                            any of its features, register on the Website, or provide any
                            information about yourself to us, including your name,
                            address, telephone number, email address, or any screen name
                            or user name you may use. If we learn we have collected or
                            received personal information from a child under 16 without
                            verification of parental consent, we will delete that
                            information. If you believe we might have any information from
                            or about a child under 16, please contact us at{' '}
                            <a href="mailto:support@sagesystems.io">
                                support@sagesystems.io
                            </a>
                            .
                            <br />
                            <br />
                            California residents under 16 years of age may have additional
                            rights regarding the collection and sale of their personal
                            information. Please see Your State Privacy Rights for more
                            information.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Information We Collect About You and How We Collect It
                            </div>
                            We collect several types of information from and about users
                            of our Website, including information:
                            <ul className="my-3">
                                <li>
                                    By which you may be personally identified, such as,
                                    but not limited to, name, postal address, email
                                    address, telephone number, and/or driver’s license
                                    information (”
                                    <span style={{ fontWeight: 'bold' }}>
                                        personal information
                                    </span>
                                    ”).
                                </li>
                                <li>
                                    That is about you but individually does not identify
                                    you, such as, but not limited to, community name, job
                                    title, latitudinal and longitudinal information,
                                    and/or vehicle information (make model, year, color,
                                    license plate state and number).; and/or
                                </li>
                                <li>
                                    About your internet connection, the equipment you use
                                    to access our Website, and usage details.
                                </li>
                            </ul>
                            We collect this information:
                            <ul className="my-3">
                                <li>Directly from you when you provide it to us.</li>
                                <li>
                                    Automatically as you navigate through the site.
                                    Information collected automatically may include usage
                                    details, IP addresses, and information collected
                                    through cookies and other tracking technologies.
                                </li>
                            </ul>
                            <div
                                className="mb-3"
                                style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                            >
                                Information You Provide to Us
                            </div>
                            The information we collect on or through our Website may
                            include:
                            <ul className="my-3">
                                <li>
                                    Information that you provide by filling in forms on
                                    our Website. This includes information provided at the
                                    time of registering to use our Website or requesting
                                    further services. We may also ask you for information
                                    when you enter a contest or promotion sponsored by us,
                                    and when you report a problem with our Website.
                                </li>
                                <li>
                                    Records and copies of your correspondence (including
                                    email addresses), if you contact us.
                                </li>
                                <li>
                                    Your responses to surveys that we might ask you to
                                    complete for research purposes.
                                </li>
                                <li>
                                    Details of transactions you carry out through our
                                    Website and of the fulfillment of your requests.
                                </li>
                            </ul>
                            You also may provide information to be published or displayed
                            (hereinafter,{' '}
                            <span style={{ fontWeight: 'bold' }}>”posted”</span>) on
                            public areas of the Website, or transmitted to other users of
                            the Website or third parties (collectively,{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                ”User Contributions”
                            </span>
                            ). Your User Contributions are posted on and transmitted to
                            others at your own risk. Although we limit access to certain
                            pages, please be aware that no security measures are perfect
                            or impenetrable. Additionally, we cannot control the actions
                            of other users of the Website with whom you may choose to
                            share your User Contributions. Therefore, we cannot and do not
                            guarantee that your User Contributions will not be viewed by
                            unauthorized persons.
                            <div
                                className="my-3"
                                style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                            >
                                Information We Collect Through Automatic Data Collection
                                Technologies
                            </div>
                            As you navigate through and interact with our Website, we may
                            use automatic data collection technologies to collect certain
                            information about your equipment, browsing actions, and
                            patterns, including:
                            <ul className="my-3">
                                <li>
                                    Details of your visits to our Website, including
                                    traffic data, location data, logs, and other
                                    communication data and the resources that you access
                                    and use on the Website.
                                </li>
                                <li>
                                    Information about your computer and internet
                                    connection, including your IP address, operating
                                    system, and browser type.
                                </li>
                            </ul>
                            Information we may collect from you automatically may include
                            personal information. It helps us to improve our Website and
                            to deliver a better and more personalized service, including
                            by enabling us to:
                            <ul className="my-3">
                                <li>Estimate our audience size and usage patterns.</li>
                                <li>
                                    Store information about your preferences, allowing us
                                    to customize our Website according to your individual
                                    interests.
                                </li>
                                <li>Speed up your searches.</li>
                                <li>Recognize you when you return to our Website.</li>
                            </ul>
                            The technologies we use for this automatic data collection may
                            include:
                            <ul className="my-3">
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Cookies (or browser cookies).
                                    </span>{' '}
                                    A cookie is a small file placed on the hard drive of
                                    your computer. You may refuse to accept browser
                                    cookies by activating the appropriate setting on your
                                    browser. However, if you select this setting you may
                                    be unable to access certain parts of our Website.
                                    Unless you have adjusted your browser setting so that
                                    it will refuse cookies, our system will issue cookies
                                    when you direct your browser to our Website.
                                </li>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Web Beacons.
                                    </span>{' '}
                                    Pages of our Website and our emails may contain small
                                    electronic files known as web beacons (also referred
                                    to as clear gifs, pixel tags, and single-pixel gifs)
                                    that permit the Company, for example, to count users
                                    who have visited those pages or opened an email and
                                    for other related website statistics (for example,
                                    recording the popularity of certain website content
                                    and verifying system and server integrity).{' '}
                                </li>
                            </ul>
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                How We Use Your Information
                            </div>
                            We use information that we collect about you or that you
                            provide to us, including any personal information:
                            <ul className="my-3">
                                <li>To present our Website and its contents to you.</li>
                                <li>
                                    To provide you with information, products, or services
                                    that you request from us.
                                </li>
                                <li>
                                    To fulfill any other purpose for which you provide it.
                                </li>
                                <li>
                                    To provide you with notices about your account,
                                    including expiration and renewal notices.
                                </li>
                                <li>
                                    To carry out our obligations and enforce our rights
                                    arising from any contracts entered into between you
                                    and us, including for billing and collection.
                                </li>
                                <li>
                                    To notify you about changes to our Website or any
                                    products or services we offer or provide though it.
                                </li>
                                <li>
                                    To allow you to participate in interactive features on
                                    our Website.
                                </li>
                                <li>
                                    In any other way we may describe when you provide the
                                    information.
                                </li>
                                <li>For any other purpose with your consent.</li>
                            </ul>
                            We may also use your information to contact you about our own
                            and third-parties goods and services that may be of interest
                            to you. If you do not want us to use your information in this
                            way, please adjust your user preferences in your account
                            profile. For more information, see Choices About How We Use
                            and Disclose Your Information.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Disclosure of Your Information
                            </div>
                            We may disclose aggregated information about our users, and
                            information that does not identify any individual, without
                            restriction. <br /> <br />
                            We may disclose personal information that we collect or you
                            provide as described in this privacy policy:
                            <ul className="my-3">
                                <li>To our subsidiaries and affiliates.</li>
                                <li>
                                    To contractors, service providers, and other third
                                    parties we use to support our business.
                                </li>
                                <li>
                                    To a buyer or other successor in the event of a
                                    merger, divestiture, restructuring, reorganization,
                                    dissolution, or other sale or transfer of some or all
                                    of Company&apos;s assets, whether as a going concern
                                    or as part of bankruptcy, liquidation, or similar
                                    proceeding, in which personal information held by
                                    Company about our Website users is among the assets
                                    transferred.
                                </li>
                                <li>
                                    For any other purpose disclosed by us when you provide
                                    the information.
                                </li>
                                <li>With your consent.</li>
                            </ul>
                            We may also disclose your personal information:
                            <ul className="my-3">
                                <li>
                                    To comply with any court order, law, or legal process,
                                    including to respond to any government or regulatory
                                    request.
                                </li>
                                <li>
                                    To enforce or apply our terms of use{' '}
                                    <a href="https://sagesystems.io/terms">
                                        TERMS OF USE
                                    </a>{' '}
                                    and other agreements, including for billing and
                                    collection purposes.
                                </li>
                                <li>
                                    If we believe disclosure is necessary or appropriate
                                    to protect the rights, property, or safety of Company,
                                    our customers, or others. This includes exchanging
                                    information with other companies and organizations for
                                    the purposes of fraud protection and credit risk
                                    reduction.
                                </li>
                            </ul>
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Choices About How We Use and Disclose Your Information
                            </div>
                            We strive to provide you with choices regarding the personal
                            information you provide to us. We have created mechanisms to
                            provide you with the following control over your information:
                            <ul className="my-3">
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Tracking Technologies and Advertising.
                                    </span>{' '}
                                    You can set your browser to refuse all or some browser
                                    cookies, or to alert you when cookies are being sent.
                                    If you disable or refuse cookies, please note that
                                    some parts of this site may then be inaccessible or
                                    not function properly.
                                </li>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Promotional Offers from the Company.
                                    </span>{' '}
                                    If you do not wish to have your email address or other
                                    contact information used by the Company to promote our
                                    own or third parties&apos; products or services, you
                                    can opt-out by logging into the Website and adjusting
                                    your user preferences in your account profile by
                                    checking or unchecking the relevant boxes or by
                                    sending us an email stating your request to{' '}
                                    <a href="mailto:support@sagesystems.io">
                                        support@sagesystems.io
                                    </a>
                                    . If we have sent you a promotional email, you may
                                    send us a return email asking to be omitted from
                                    future email distributions. This opt out does not
                                    apply to information provided to the Company as a
                                    result of any service or product purchase or other
                                    transaction.
                                </li>
                            </ul>
                            We do not control third parties&apos; collection or use of
                            your information to serve interest-based advertising. However
                            these third parties may provide you with ways to choose not to
                            have your information collected or used in this way. You can
                            opt out of receiving targeted ads from members of the Network
                            Advertising Initiative (&quot;
                            <span style={{ fontWeight: 'bold' }}>NAI</span>&quot;) on the
                            NAI&apos;s website.
                            <br />
                            <br /> Residents of certain states, such as California,
                            Nevada, Colorado, Connecticut, Virginia, and Utah may have
                            additional personal information rights and choices. Please see
                            Your State Privacy Rights for more information.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Accessing and Correcting Your Information
                            </div>
                            You can review and change your personal information by logging
                            into the Website and visiting your account profile page.
                            <br />
                            <br /> You may also send us an email at{' '}
                            <a href="mailto:support@sagesystems.io">
                                support@sagesystems.io
                            </a>{' '}
                            to request access to, correct or delete any personal
                            information that you have provided to us. We cannot delete
                            your personal information except by also deleting your user
                            account. We may not accommodate a request to change
                            information if we believe the change would violate any law or
                            legal requirement or cause the information to be incorrect.
                            <br />
                            <br /> If you delete your User Contributions from the Website,
                            copies of your User Contributions may remain viewable in
                            cached and archived pages, or might have been copied or stored
                            by other Website users. Proper access and use of information
                            provided on the Website, including User Contributions, is
                            governed by our terms of use{' '}
                            <a href="https://sagesystems.io/terms">TERMS OF USE</a> .
                            <br />
                            <br /> Residents of certain states, such as California,
                            Nevada, Colorado, Virginia, and Utah may have additional
                            personal information rights and choices. Please see Your State
                            Privacy Rights for more information.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Your State Privacy Rights
                            </div>
                            State consumer privacy laws may provide their residents with
                            additional rights regarding our use of their personal
                            information. California&apos;s &quot;Shine the Light&quot; law
                            (Civil Code Section § 1798.83) permits users of our App that
                            are California residents to request certain information
                            regarding our disclosure of personal information to third
                            parties for their direct marketing purposes. To make such a
                            request, please send an email to{' '}
                            <a href="mailto:support@sagesystems.io">
                                support@sagesystems.io
                            </a>{' '}
                            or write us at: 8961 Quality Ct, Bonita Springs, FL 34135.
                            <br />
                            <br />
                            Colorado, Connecticut, Virginia, and Utah each provide their
                            state residents with rights to:
                            <ul className="my-3">
                                <li>
                                    Confirm whether we process their personal information.
                                </li>
                                <li>Access and delete certain personal information.</li>
                                <li>Data portability.</li>
                                <li>
                                    Opt-out of personal data processing for targeted
                                    advertising and sales.
                                </li>
                            </ul>
                            Colorado, Connecticut, and Virginia also provide their state
                            residents with rights to:
                            <ul className="my-3">
                                <li>
                                    Correct inaccuracies in their personal information,
                                    taking into account the information&apos;s nature
                                    processing purpose.
                                </li>
                                <li>
                                    Opt-out of profiling in furtherance of decisions that
                                    produce legal or similarly significant effects.
                                </li>
                            </ul>
                            To exercise any of these rights please submit an email to{' '}
                            <a href="mailto:support@sagesystems.io">
                                support@sagesystems.io
                            </a>
                            . To appeal a decision regarding a consumer rights request
                            please submit an email to{' '}
                            <a href="mailto:support@sagesystems.io">
                                support@sagesystems.io
                            </a>
                            . Nevada provides its residents with a limited right to
                            opt-out of certain personal information sales. Residents who
                            wish to exercise this sale opt-out rights may submit a request
                            to this designated address:{' '}
                            <a href="mailto:support@sagesystems.io">
                                support@sagesystems.io
                            </a>
                            . However, please know we do not currently sell data
                            triggering that statute&apos;s opt-out requirements.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Data Security
                            </div>
                            We have implemented measures designed to secure your personal
                            information from accidental loss and from unauthorized access,
                            use, alteration, and disclosure. All information you provide
                            to us will be encrypted using SSL technology and Google’s
                            authentication services.
                            <br />
                            <br /> The safety and security of your information also
                            depends on you. Where we have given you (or where you have
                            chosen) a password for access to certain parts of our Website,
                            you are responsible for keeping this password confidential. We
                            ask you not to share your password with anyone. We urge you to
                            be careful about giving out information in public areas of the
                            Website like message boards. The information you share in
                            public areas may be viewed by any user of the Website.
                            <br />
                            <br /> Unfortunately, the transmission of information via the
                            internet is not completely secure. Although we do our best to
                            protect your personal information, we cannot guarantee the
                            security of your personal information transmitted to our
                            Website. Any transmission of personal information is at your
                            own risk. We are not responsible for circumvention of any
                            privacy settings or security measures contained on the
                            Website.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Changes to Our Privacy Policy
                            </div>
                            It is our policy to post any changes we make to our privacy
                            policy on this page. If we make material changes to how we
                            treat our users&apos; personal information, we will notify you
                            by email to the primary email address specified in your
                            account and/or through a notice on the Website home page. The
                            date the privacy policy was last revised is identified at the
                            top of the page. You are responsible for ensuring we have an
                            up-to-date active and deliverable email address for you, and
                            for periodically visiting our Website and this privacy policy
                            to check for any changes.
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Contact Information
                            </div>
                            <div className="mb-5">
                                To ask questions or comment about this privacy policy and
                                our privacy practices, contact us at:
                                <br />
                                <br /> 8961 Quality Road
                                <br /> Bonita Springs, FL 34135
                                <br />{' '}
                                <a href="mailto:support@sagesystems.io">
                                    support@sagesystems.io
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
