import React from 'react';

// Packages
import { NavLink } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

const SideNav = ({ match }) => {
    const mainLinks = [
        { name: 'dashboard', route: `${match.url}${routes.SUPER_DASHBOARD}` },
        { name: 'equalizer', route: `${match.url}${routes.SUPER_REPORTS}` },
        { name: 'manage_accounts', route: `${match.url}${routes.SUPER_SETTINGS}` }
    ];

    return (
        <div
            className="d-flex flex-column flex-shrink-0 align-items-center shadow-1 z-20"
            style={{ width: '4.5rem', height: '100%', background: '#2c3137' }}
        >
            <div className="d-flex mt-3 sidenav" style={{ position: 'relative' }}>
                <div className="d-flex flex-column align-items-center">
                    {mainLinks.map(item => (
                        <div className="nav-item" key={item.name}>
                            <NavLink
                                to={item.route}
                                className="nav-link"
                                activeClassName="active"
                            >
                                <span className="material-icons-round">{item.name}</span>
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SideNav;
