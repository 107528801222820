import React from 'react';
import { connect } from 'react-redux';
import AddRenterAccessForm from './AddRenterAccessForm';
import ReservationValidateForm from './ReservationValidateForm';
import Loader from '../common/Loader';
import ValidationError from './ValidationError';
import CreateAccessSuccess from './CreateAccessSuccess';

const ConfirmReservation = ({
    validating,
    existingReservation,
    validationError,
    addingTenant,
    addingTenantSuccess,
    addingTenantError,
    apiCallInProgress,
    apiErrorMessage,
    accessCreated
}) => {
    if (validationError || apiErrorMessage) {
        return (
            <ValidationError error={validationError || apiErrorMessage} title="Oops!" />
        );
    }

    if (validating || addingTenant || apiCallInProgress) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (existingReservation && !accessCreated) {
        return existingReservation.validated ? (
            <ValidationError
                error="This reservation has already been confirmed."
                title="Oops!"
            />
        ) : (
            <AddRenterAccessForm reservation={existingReservation} />
        );
    }

    if (addingTenantSuccess || accessCreated) {
        return (
            <CreateAccessSuccess
                reservationData={addingTenantSuccess || existingReservation}
            />
        );
    }

    if (addingTenantError) {
        return (
            <ValidationError
                error={addingTenantError}
                title="Access has not been created! Please try again."
            />
        );
    }

    return <ReservationValidateForm />;
};

const mapStateToProps = ({
    reservations: {
        validatingReservation,
        validationSuccess,
        validationError,
        addingTenant,
        addingTenantSuccess,
        addingTenantError
    },
    confirmAccess: {
        apiCallInProgress,
        accessCreated,
        existingAccount,
        newVerificationNeeded,
        verified,
        verificationMessage
    }
}) => {
    return {
        validating: validatingReservation,
        existingReservation: validationSuccess,
        validationError,
        addingTenant,
        addingTenantSuccess,
        addingTenantError,
        apiCallInProgress,
        accessCreated,
        existingAccount,
        newVerificationNeeded,
        verified,
        apiErrorMessage: verificationMessage
    };
};

export default connect(mapStateToProps)(ConfirmReservation);
