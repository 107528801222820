import { timeStampNow, db } from '../../config/Firebase';
import { all, fork, takeLatest, call } from 'redux-saga/effects';
import { CREATE_PANEL_INTERCOM } from '../actions/types';

const intercom = db.collection('intercom');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Create Panel Intercom //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createIntercomRequest = async () => {
    const ref = intercom.doc();
    const refId = ref.id;
    return new Promise((resolve, reject) => {
        ref.set({
            connected_by: 'MbpHMSfyflXC517EOXbw3MYEM012',
            connection_end: null,
            connection_start: null,
            created_at: timeStampNow(),
            id: refId,
            org_id: '6C8RzH04yhwP93atjhLl',
            panel_id: 'YqipRYmEzSVjDsP9QYhuPyRwIH53',
            panel_name: 'Ramco Panel',
            status: 'pending'
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* createIntercom() {
    const { res, error } = yield call(() => createIntercomRequest());
    if (res) {
        console.log(res, 'intercom creation success');
    } else {
        console.log(error, 'intercom creation error');
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* creatingPanelIntercom() {
    yield takeLatest(CREATE_PANEL_INTERCOM, createIntercom);
}

export default function* rootSaga() {
    yield all([fork(creatingPanelIntercom)]);
}
