import { ADD_NEW_REGISTRATION } from '../actions/types';

import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import { func } from '../../config/Firebase';

import {
    addingNewRegistrationSuccess,
    addingNewRegistrationFailure
} from '../actions/Registration';

const createNewRegistration = func.httpsCallable('createNewRegistration');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Add New Registration ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addRegistrationRequest = async ({ member, shortCode }) => {
    // console.log(member, shortCode);
    try {
        const { data } = await createNewRegistration({
            member,
            shortCode
        });
        // console.log(data, 'got data');
        if (data?.res) {
            return { res: data.res };
        } else {
            return { error: data.error };
        }
    } catch (error) {
        console.log(error, 'addError');
        return { error };
    }
};

export function* addRegistration({ payload }) {
    const { member, shortCode } = payload;

    // const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() =>
        addRegistrationRequest({ member, shortCode })
    );
    if (res) {
        // console.log(res, 'end success');
        yield put(addingNewRegistrationSuccess());
    } else {
        console.log(error, 'end error');
        yield put(addingNewRegistrationFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error adding new registration (RTDB)`, {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* addingRegistration() {
    yield takeLatest(ADD_NEW_REGISTRATION, addRegistration);
}

export default function* rootSaga() {
    yield all([fork(addingRegistration)]);
}
