import React from 'react';
import { Button } from 'reactstrap';

const AddRegistrationSuccess = ({ error, resetForm }) => {
    const heading = error ? `Hmmm, thats awkward...` : `Success!`;
    const message = error
        ? error.inviteError ||
          error.userError ||
          `There was an issue while trying to submit your registration.  Please double check your information and try submitting again.  If this issue persists, please contact your Community Manager for further assistance.`
        : `We have received your information. Your invite will be sent on
    approval.`;
    const button = error ? `Try again` : `New Registration`;

    return (
        <div className="w-75 mx-md-auto pt-8">
            <div className="d-flex align-items-center justify-content-center flex-column">
                <img src="../../assets/sage-icon.png" alt="logo" height={101} />
                <div className="mb-3">
                    <div
                        style={{ fontSize: '32px' }}
                        className="d-flex justify-content-center text-white font-weight-semi-bold mt-3 mb-8"
                    >
                        {heading}
                    </div>
                    <div
                        style={{ fontSize: '16px' }}
                        className="d-flex justify-content-center text-white font-weight-normal mt-5 mb-10"
                    >
                        {message}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center mt-3 pt-3">
                <div style={{ flex: 1 }} className="d-flex justify-content-center m-2">
                    <Button
                        color="primary"
                        onClick={() => (error ? resetForm('soft') : resetForm('hard'))}
                    >
                        {button}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddRegistrationSuccess;
