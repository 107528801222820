import React, { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWindowSize } from '../../hooks/useWindowSize';
import { timeStampNowSeconds } from '../../config/Firebase';
import { Link } from 'react-router-dom';
import { RESERVATION_DETAILS_EDIT } from '../../router/config/routes';

const ReservationsList = ({ reservations, searchString, sortType, match }) => {
    const [visibleReservations, setVisibleReservations] = useState(reservations);
    const { windowHeight } = useWindowSize();
    const reservationStatus = end_time => {
        const nowSeconds = timeStampNowSeconds();
        return end_time?.seconds > nowSeconds ? (
            <span className="badge badge-success badge-status">Active</span>
        ) : (
            <span className="badge badge-other badge-status">Completed</span>
        );
    };

    const filterByType = useCallback(
        reservations => {
            const filteredRes = reservations.filter(res => {
                const endTime = res.access_expires;
                const notCancelled = res.active;
                const isCompleted =
                    notCancelled && endTime.seconds < timeStampNowSeconds();

                switch (sortType) {
                    case 'active':
                        return notCancelled && !isCompleted;
                    case 'completed':
                        return notCancelled && isCompleted;
                    case 'cancelled':
                        return !notCancelled;
                    default:
                        return false;
                }
            });
            return filteredRes;
        },
        [sortType]
    );

    const reservationsInputSearch = useCallback(
        input => {
            if (!input.trim()) {
                setVisibleReservations([]);
                return;
            }

            const filteredRes = filterByType(reservations).filter(res => {
                const firstName = res.first_name ? res.first_name.toLowerCase() : '';
                const lastName = res.first_name ? res.last_name.toLowerCase() : '';

                if (
                    firstName.includes(input.toLowerCase()) ||
                    lastName.includes(input.toLowerCase())
                ) {
                    return true;
                }

                return false;
            });

            setVisibleReservations(filteredRes);
        },
        [reservations, filterByType]
    );

    useEffect(() => {
        if (reservations && searchString) {
            reservationsInputSearch(searchString);
        } else {
            if (reservations) {
                setVisibleReservations(filterByType(reservations));
            }
        }
    }, [
        searchString,
        sortType,
        reservations,
        filterByType,
        setVisibleReservations,
        reservationsInputSearch
    ]);

    if (!visibleReservations) return null;
    return (
        <div style={{ height: [windowHeight] - 350, overflowY: 'auto' }}>
            <InfiniteScroll
                dataLength={visibleReservations.length}
                loader={<h4>Loading...</h4>}
            >
                {visibleReservations.map(res => {
                    const {
                        validated,
                        first_name,
                        last_name,
                        check_in_date,
                        check_out_date,
                        access_expires,
                        active,
                        reservation_id
                    } = res;
                    return (
                        <Link
                            to={{
                                pathname: `${match.url
                                    .split('/')
                                    .slice(0, -1)
                                    .join('/')}${RESERVATION_DETAILS_EDIT}`,
                                state: { resDetails: { ...res } }
                            }}
                            key={reservation_id}
                        >
                            <div className="org-item justify-content-between align-items-center">
                                <div className="col-2">
                                    <h2 className="h6 mb-0">{`${last_name}${
                                        first_name ? ', ' + first_name : ''
                                    }`}</h2>
                                </div>
                                <div className="col-3 text-center">
                                    <h2 className="h6 mb-0">{check_in_date}</h2>
                                </div>
                                <div className="col-2 text-center">{check_out_date}</div>
                                <div className="col-2 text-center">
                                    {validated ? 'Validated' : 'Pending'}
                                </div>
                                <div className="col-3 px-8">
                                    {active ? (
                                        reservationStatus(access_expires)
                                    ) : (
                                        <span className="badge badge-danger badge-status">
                                            Cancelled
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default ReservationsList;
