import React, { useEffect, useState } from 'react';

import { AcceptOrRevokeOtc } from '../components';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { AUTHENTICATED, GUEST_MANAGER } from '../router/config/routes';

const RevokeOtc = ({ oneTimeAccessCodes }) => {
    const { id } = useParams();
    const history = useHistory();
    const [otc, setOtc] = useState(null);

    useEffect(() => {
        const foundOtc = oneTimeAccessCodes.find(otc => otc.code_id === id);
        if (foundOtc) {
            setOtc(foundOtc);
        } else {
            history.push(`${AUTHENTICATED}${GUEST_MANAGER}`);
        }
    }, [history, id, oneTimeAccessCodes]);

    return (
        <div className="container-fluid min-vh-100 space-1 bg-dark">
            <div className="d-flex mt-2 align-items-center justify-content-center flex-column">
                <img src="../assets/sage-icon.png" alt="logo" width={80} />
            </div>
            {otc && <AcceptOrRevokeOtc otc={otc} />}
        </div>
    );
};

const mapStateToProps = ({ guests }) => {
    const { oneTimeAccessCodes } = guests;
    return { oneTimeAccessCodes };
};

export default connect(mapStateToProps, {})(RevokeOtc);
