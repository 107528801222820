import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

const ImgUploader = ({ imgFile, onImageDrop }) => {
    const onDrop = useCallback(
        acceptedFiles => {
            acceptedFiles.forEach(file => {
                const reader = new FileReader();

                reader.onload = () => {
                    onImageDrop(reader.result);
                };
                reader.readAsDataURL(file);
            });
        },
        [onImageDrop]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="dropzone flex-grow-1" {...getRootProps()}>
                <input
                    accept="image/x-png,image/gif,image/jpeg"
                    style={{ width: '70%' }}
                    {...getInputProps()}
                />
                {!imgFile && (
                    <h5>
                        <span className="import-file">Upload</span> or drop an image.
                    </h5>
                )}
                {imgFile && (
                    <div className="d-flex flex-column align-items-center justify-content-center p-2">
                        <img
                            style={{ maxWidth: '100%', maxHeight: '400px' }}
                            src={imgFile}
                            alt=""
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImgUploader;
