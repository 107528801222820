import { all, fork, takeLatest, put } from 'redux-saga/effects';

import { apiPost } from '../../api';
import { generateInviteCode, generateVerificationCode } from '../../utils/Helpers';
import {
    createAccessAPIErrorMessage,
    createAccessAPIMatchedAccount,
    createAccessAPINewVerificationAdded,
    createAccessAPISuccess,
    createAccessAPIVerificationSuccess
} from '../actions/ConfirmReservation';
import {
    CREATE_ACCESS_API_CALL,
    CREATE_ACCESS_API_NEW_VERIFICATION,
    CREATE_ACCESS_API_VERIFY_NUMBER
} from '../actions/types';

export async function verifyNumberApiCall(data) {
    try {
        const verified = await apiPost('/guest-invites/verify', data);
        return verified;
    } catch (error) {
        console.log(error);
    }
}

export function* verifyPhoneNumber({ payload }) {
    try {
        const newVerificationResult = yield verifyNumberApiCall(payload);
        if (!newVerificationResult?.data?.message && !newVerificationResult?.error) {
            yield put(createAccessAPIVerificationSuccess());
        }
        if (newVerificationResult.error) {
            yield put(createAccessAPIErrorMessage(newVerificationResult.error));
        }
    } catch (error) {
        yield put(createAccessAPIErrorMessage(`${error}`));
        console.log(error);
    }
}

export async function createVeryficationForMobileNumber({ number, code }) {
    try {
        const verifyCode = generateVerificationCode();
        const verificationObject = {
            code: verifyCode,
            value: `${code}${number}`,
            type: 'phone'
        };
        const resp = await apiPost(
            '/guest-invites/create-verification',
            verificationObject
        );
        if (!resp?.data?.message && !resp?.error) {
            return verifyCode;
        } else {
            return resp;
        }
    } catch (error) {
        console.log(error);
    }
}

export function* createNewVerification({ payload }) {
    try {
        const newVerificationResult = yield createVeryficationForMobileNumber(payload);
        if (!newVerificationResult?.data?.message && !newVerificationResult?.error) {
            yield put(createAccessAPINewVerificationAdded(newVerificationResult));
        }
        if (newVerificationResult.error) {
            yield put(createAccessAPIErrorMessage(newVerificationResult.error));
        }
    } catch (error) {
        yield put(createAccessAPIErrorMessage(`${error}`));
        console.log(error);
    }
}

////////// FOUND FUNCTION TO CREATE GUEST AFTER INVITE IS FOUND? CONFIRMED BY GUARD /////////

export async function createAccessWithPhoneApiCall(values, invite) {
    try {
        const { code, number, country } = values;
        const inviteCode = generateInviteCode();
        const resp = await apiPost('/guest-invites/create', {
            ...invite,
            phone: {
                country,
                number: Number.parseInt(number),
                code: Number.parseInt(code)
            },
            phone_number: `${code}${number}`,
            invite_code: inviteCode
        });

        return resp;
    } catch (error) {
        console.log(error);
    }
}

export function* createAccessWithPhone({ payload }) {
    try {
        const { values, invite } = payload;
        const createCallResult = yield createAccessWithPhoneApiCall(values, invite);
        if (createCallResult.data && !createCallResult.data.length) {
            yield put(createAccessAPISuccess());
        }
        if (createCallResult.data && createCallResult.data.length) {
            yield put(createAccessAPIMatchedAccount(createCallResult.data[0]));
        }
        if (createCallResult.data?.message) {
            yield put(createAccessAPIErrorMessage(createCallResult.data.message));
        }
        if (createCallResult.error) {
            yield put(createAccessAPIErrorMessage(createCallResult.error));
        }
    } catch (error) {
        yield put(createAccessAPIErrorMessage(`${error}`));
        console.log(error);
    }
}

//////////////////////////////

export function* verifyNumber() {
    yield takeLatest(CREATE_ACCESS_API_VERIFY_NUMBER, verifyPhoneNumber);
}

export function* createVerification() {
    yield takeLatest(CREATE_ACCESS_API_NEW_VERIFICATION, createNewVerification);
}

export function* createAccess() {
    yield takeLatest(CREATE_ACCESS_API_CALL, createAccessWithPhone);
}

export default function* rootSaga() {
    yield all([fork(createAccess), fork(createVerification), fork(verifyNumber)]);
}
