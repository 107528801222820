import { ADD_GUEST_INVITES, ADD_GUEST_INVITES_SUCCESS } from '../actions/types';

const INITIAL_STATE = {
    loading: null
};

const superAdminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_GUEST_INVITES:
            return {
                ...state,
                loading: true
            };
        case ADD_GUEST_INVITES_SUCCESS:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default superAdminReducer;
