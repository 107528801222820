import {
    SET_USER_ORG,
    GET_DEVICE_TYPES,
    GET_DEVICE_TYPES_SUCCESS,
    GET_DEVICE_TYPES_FAILURE,
    WATCH_ORG_DEVICES_RTDB,
    WATCH_ORG_DEVICES_RTDB_SUCCESS,
    WATCH_ORG_DEVICES_RTDB_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS,
    UPDATE_ORG_DEVICE_DETAILS_SUCCESS,
    UPDATE_ORG_DEVICE_DETAILS_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS_RESET
} from '../actions/types';

const INITIAL_STATE = {
    deviceInfoUpdating: false,
    deviceInfoUpdatingSuccess: false,
    deviceInfoUpdatingError: null,
    deviceTypes: null,
    loadingDeviceTypes: false,
    loadingDeviceTypesError: null,
    orgDevices: null,
    loadingOrgDevices: false,
    loadingOrgDevicesError: null,
    aggregatePanel: null,
    selectedPanel: null
};

const metricSummation = data => {
    return {
        online_panels: 227,
        offline_panels: 2,
        property_count: data.length,
        active_units: data.reduce((n, { active_units }) => n + active_units, 0),
        active_credentials: data.reduce((n, { credentials }) => n + credentials, 0)
    };
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_ORG:
            return {
                ...state,
                aggregatePanel: metricSummation(action.payload)
            };
        case GET_DEVICE_TYPES:
            return {
                ...state,
                loadingDeviceTypes: true,
                loadingDeviceTypesError: null
            };
        case GET_DEVICE_TYPES_SUCCESS:
            return {
                ...state,
                loadingDeviceTypes: false,
                deviceTypes: action.payload
            };
        case GET_DEVICE_TYPES_FAILURE:
            return {
                ...state,
                loadingDeviceTypes: false,
                loadingDeviceTypesError: action.payload
            };
        case WATCH_ORG_DEVICES_RTDB:
            return {
                ...state,
                loadingOrgDevices: true,
                loadingOrgDevicesError: null
            };
        case WATCH_ORG_DEVICES_RTDB_SUCCESS:
            return {
                ...state,
                orgDevices: action.payload,
                loadingOrgDevices: false
            };
        case WATCH_ORG_DEVICES_RTDB_FAILURE:
            return {
                ...state,
                loadingOrgDevicesError: action.payload,
                loadingOrgDevices: false
            };
        case UPDATE_ORG_DEVICE_DETAILS:
            return {
                ...state,
                deviceInfoUpdating: true,
                deviceInfoUpdatingError: null
            };
        case UPDATE_ORG_DEVICE_DETAILS_SUCCESS:
            return {
                ...state,
                deviceInfoUpdating: false,
                deviceInfoUpdatingSuccess: true
            };
        case UPDATE_ORG_DEVICE_DETAILS_FAILURE:
            return {
                ...state,
                deviceInfoUpdating: false,
                deviceInfoUpdatingError: action.payload
            };
        case UPDATE_ORG_DEVICE_DETAILS_RESET:
            return {
                ...state,
                deviceInfoUpdating: false,
                deviceInfoUpdatingSuccess: false,
                deviceInfoUpdatingError: null
            };
        default:
            return state;
    }
};

export default orgReducer;
