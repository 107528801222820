import React, { useState, useEffect, useCallback } from 'react';

import {
    phoneRegExp,
    nameRegExp,
    streetAddressRegExp,
    errorMessage,
    unitedStates
} from '../../utils/Constants';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, Container, Label } from 'reactstrap';
import TextField from '../common/TextField';
import { StateCountrySelect } from '..';
import RoleSelect from '../common/RoleSelect';
import Loader from '../common/Loader';
var parser = require('parse-address');

const ContactDetailsForm = ({
    initialValues,
    toggleModal,
    submitForm,
    accessKeyDoc,
    orgId,
    withAccess,
    loading,
    userType
}) => {
    const [confAddress, setConfAddress] = useState(null);
    const [confPhone, setConfPhone] = useState(null);
    const { first_name, last_name, address, email, role, phone, suspended } = {
        ...initialValues,
        ...accessKeyDoc
    };

    const getUsState = unitedState => {
        if (unitedState.length > 2 || unitedState.trim() === '') return unitedState;
        const state = unitedStates.find(
            state => state.value.toLowerCase().trim() === unitedState.toLowerCase().trim()
        );
        return state.name;
    };

    const fullAddFromObj = useCallback(address => {
        return `${address?.address_1}${
            address?.address_2 ? ` ${address.address_2},` : ''
        } ${address?.city ? `${address.city},` : ''} ${address?.state} ${
            address?.zip
        }`.trim();
    }, []);

    const configureAddress = useCallback(
        address => {
            var fullAddress;

            const processAddress = add => {
                const parsedAdd = parser.parseLocation(add);
                const components = {
                    cityName: parsedAdd.city,
                    primaryNumber: parsedAdd.number,
                    addPlus4: parsedAdd.plus4,
                    streetPredirection: parsedAdd.prefix,
                    secondaryNumber: parsedAdd.sec_unit_num,
                    secondaryDesignator: parsedAdd.sec_unit_type,
                    state: parsedAdd.state,
                    streetName: parsedAdd.street,
                    streetPostdirection: parsedAdd.suffix,
                    streetSuffix: parsedAdd.type,
                    zipCode: parsedAdd.zip
                };

                const address1 = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                } `;
                const address2 = components.secondaryNumber
                    ? `${components.secondaryDesignator} ${components.secondaryNumber} `
                    : '';
                const cityStateZip = `${
                    components.cityName ? `${components.cityName},` : ''
                } ${components.state ? components.state : ''} ${
                    components.zipCode ? components.zipCode : ''
                }${components.addPlus4 ? `-${components.addPlus4}` : ''}`;
                fullAddress = `${address1.trim()}${address2.trim()}${cityStateZip.trim()}`;

                setConfAddress({
                    address_1: address1.trim(),
                    address_2: address2.trim(),
                    city: components?.cityName || '',
                    state: components?.state || '',
                    zip: components?.zipCode
                        ? `${components.zipCode}${
                              components.addPlus4 ? `-${components.addPlus4}` : ''
                          }`
                        : '',
                    full_address: fullAddress.trim()
                });
            };

            if (address instanceof Object) {
                fullAddress = fullAddFromObj(address).trim();
                processAddress(fullAddress);
            } else {
                if (address.trim() === '') return;
                fullAddress = address;
                processAddress(fullAddress);
            }
        },
        [setConfAddress, fullAddFromObj]
    );

    const configurePhone = useCallback(
        tele => {
            setConfPhone(`${tele}`);
        },
        [setConfPhone]
    );

    useEffect(() => {
        if (!loading && !confAddress && address) {
            configureAddress(address);
        }
    }, [loading, confAddress, address, configureAddress]);

    useEffect(() => {
        if (!loading && !confPhone && phone) {
            if (phone instanceof Object) {
                if (phone?.number) configurePhone(phone.number);
            } else if (typeof phone === 'number') {
                configurePhone(phone);
            }
        }
    }, [loading, confPhone, phone, configurePhone]);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().trim().required(errorMessage.firstName.required),
        last_name: Yup.string().trim().required(errorMessage.lastName.required),
        address_1: Yup.string()
            .trim()
            .matches(streetAddressRegExp, errorMessage.address.valid),
        address_2: Yup.string().trim(),
        city: Yup.string().trim().matches(nameRegExp.format, errorMessage.city.valid),
        state: Yup.string().trim(),
        zip: Yup.string().trim(),
        phone: Yup.string().trim().matches(phoneRegExp.format, errorMessage.phone.valid),
        email: Yup.string()
            .trim()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        role: Yup.string().trim(),
        suspended: Yup.bool()
    });

    if (!accessKeyDoc && withAccess && loading) return <Loader />;

    return (
        <Container>
            <Formik
                initialValues={{
                    first_name: first_name
                        ? first_name.toLowerCase().trim().includes('n/a')
                            ? 'N/A'
                            : first_name
                        : '',
                    last_name: last_name
                        ? last_name.toLowerCase().trim().includes('n/a')
                            ? 'N/A'
                            : last_name
                        : '',
                    address_1: address?.address_1 || confAddress?.address_1 || '',
                    address_2: address?.address_2 || confAddress?.address_2 || '',
                    city: address?.city || confAddress?.city || '',
                    state: address?.state
                        ? getUsState(address.state)
                        : confAddress?.state
                        ? getUsState(confAddress.state)
                        : '',
                    zip: address?.zip || confAddress?.zip || '',
                    phone: `${confPhone}` || '',
                    email: !email ? '' : email,
                    role: role ? (role === 'resi' ? 'resident' : role) : 'resident',
                    suspended: !suspended ? false : true
                }}
                onSubmit={values =>
                    submitForm({
                        ...initialValues,
                        ...values,
                        full_address: fullAddFromObj(values),
                        role: values.role === 'resident' ? 'resi' : values.role
                    })
                }
                validationSchema={validationSchema}
                validateOnChange
                enableReinitialize
            >
                {({
                    handleSubmit,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    values,
                    setFieldValue,
                    setFieldTouched
                }) => (
                    <>
                        <Form className="row">
                            <div className="p-5">
                                <div className="row m-auto">
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="first_name">
                                            First Name
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            id="first_name"
                                            placeholder=""
                                            aria-label="first_name"
                                            error={errors.first_name}
                                            value={values.first_name}
                                            onBlur={handleBlur('first_name')}
                                            onChange={handleChange('first_name')}
                                            invalid={
                                                touched.first_name && !!errors.first_name
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="last_name">
                                            Last Name
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="last_name"
                                            placeholder=""
                                            aria-label="last_name"
                                            error={errors.last_name}
                                            value={values.last_name}
                                            onBlur={handleBlur('last_name')}
                                            onChange={handleChange('last_name')}
                                            invalid={
                                                touched.last_name && !!errors.last_name
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="address_1">
                                            Address 1
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="address_1"
                                            id="address_1"
                                            placeholder=""
                                            aria-label="address_1"
                                            error={errors.address_1}
                                            value={values.address_1}
                                            onBlur={handleBlur('address_1')}
                                            onChange={handleChange('address_1')}
                                            invalid={
                                                touched.address_1 && !!errors.address_1
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="address_2">
                                            Address 2
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="address_2"
                                            id="address_2"
                                            placeholder=""
                                            aria-label="address_2"
                                            error={errors.address_2}
                                            value={values.address_2}
                                            onBlur={handleBlur('address_2')}
                                            onChange={handleChange('address_2')}
                                            invalid={
                                                touched.address_2 && !!errors.address_2
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="city">
                                            City
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            id="city"
                                            placeholder=""
                                            aria-label="city"
                                            error={errors.city}
                                            value={values.city}
                                            onBlur={handleBlur('city')}
                                            onChange={handleChange('city')}
                                            invalid={touched.city && !!errors.city}
                                        />
                                    </div>
                                    <div className="js-form-message col-3 form-group">
                                        <Label className="form-label" for="state">
                                            State
                                        </Label>
                                        <StateCountrySelect
                                            className="form-control custom-select"
                                            name={'state'}
                                            id={'state'}
                                            placeholder={values.state}
                                            aria-label={'state'}
                                            nestedOptions={false}
                                            options={unitedStates}
                                            error={errors.state}
                                            value={values.state}
                                            onBlur={handleBlur('state')}
                                            onChange={handleChange('state')}
                                            touched={touched.state}
                                            invalid={touched.state && !!errors.state}
                                        />
                                    </div>
                                    <div className="js-form-message col-3 form-group">
                                        <Label className="form-label" for="zip">
                                            ZIP
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="zip"
                                            id="zip"
                                            placeholder=""
                                            aria-label="zip"
                                            error={errors.zip}
                                            value={values.zip}
                                            onBlur={handleBlur('zip')}
                                            onChange={handleChange('zip')}
                                            invalid={touched.zip && !!errors.zip}
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="phone">
                                            Phone
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            id="phone"
                                            placeholder=""
                                            aria-label="phone"
                                            error={errors.phone}
                                            value={values.phone}
                                            onBlur={handleBlur('phone')}
                                            onChange={handleChange('phone')}
                                            invalid={touched.phone && !!errors.phone}
                                        />
                                    </div>
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="email">
                                            Email
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder=""
                                            aria-label="email"
                                            error={errors.email}
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                            invalid={touched.email && !!errors.email}
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-3 form-group">
                                        <Label className="form-label" for="role">
                                            Role
                                        </Label>
                                        <RoleSelect
                                            className="form-control custom-select"
                                            name={'role'}
                                            id={'role'}
                                            placeholder={values.role}
                                            aria-label={'role'}
                                            error={errors.role}
                                            value={values.role}
                                            onBlur={handleBlur('role')}
                                            onChange={handleChange('role')}
                                            touched={touched.role}
                                            invalid={touched.role && !!errors.role}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <div className="row border-top d-flex justify-content-between">
                            <div className="col-4 d-flex justify-content-start">
                                {withAccess && (
                                    <Button
                                        className={`transition-3d-hover ${
                                            values.suspended
                                                ? 'allow-button'
                                                : 'cancel-button'
                                        } m-3 text-nowrap btn-wide"`}
                                        onClick={() => {
                                            setFieldValue('suspended', !values.suspended);
                                            setFieldTouched('suspended', true);
                                        }}
                                    >
                                        {values.suspended
                                            ? 'Allow Access'
                                            : 'Suspend Access'}
                                    </Button>
                                )}
                            </div>
                            <div className="col-8 d-flex justify-content-end">
                                <Button
                                    className="transition-3d-hover cancel-button m-3 btn-wide"
                                    onClick={toggleModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="transition-3d-hover text-nowrap m-3 btn-wide"
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={handleSubmit}
                                >
                                    {`Save ${
                                        role ? (role === 'resi' ? 'Resident' : role) : ''
                                    }`}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </Container>
    );
};

export default ContactDetailsForm;
