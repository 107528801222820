import {
    ADD_NEW_GUEST,
    ADD_NEW_GUEST_SUCCESS,
    ADD_NEW_GUEST_FAILURE,
    RESET_ADD_NEW_GUEST,
    GET_GUESTS,
    REMOVE_GUEST,
    REMOVE_GUEST_SUCCESS,
    RESET_REMOVE_GUEST,
    REMOVE_GUEST_FAILURE,
    SET_GUEST_INVITES,
    SET_GUEST_ACCESS_KEYS,
    SET_ONE_TIME_ACCESS_CODES,
    ADD_ONE_TIME_ACCESS_CODE,
    ADD_ONE_TIME_ACCESS_CODE_SUCCESS,
    ADD_ONE_TIME_ACCESS_CODE_FAILURE,
    RESET_ADD_ONE_TIME_ACCESS_CODE,
    REVOKE_ONE_TIME_ACCESS_CODE,
    REVOKE_ONE_TIME_ACCESS_CODE_SUCCESS,
    REVOKE_ONE_TIME_ACCESS_CODE_FAILURE,
    RESET_REVOKE_ONE_TIME_ACCESS_CODE
} from './types';

export const revokeOneTimeAccessCode = otcData => ({
    type: REVOKE_ONE_TIME_ACCESS_CODE,
    payload: otcData
});

export const revokeOneTimeAccessCodeSuccess = () => ({
    type: REVOKE_ONE_TIME_ACCESS_CODE_SUCCESS
});

export const revokeOneTimeAccessCodeFailure = error => ({
    type: REVOKE_ONE_TIME_ACCESS_CODE_FAILURE,
    payload: error
});

export const resetRevokeOneTimeAccessCode = type => {
    return {
        type: RESET_REVOKE_ONE_TIME_ACCESS_CODE,
        payload: type
    };
};

export const addOneTimeAccessCode = otcData => ({
    type: ADD_ONE_TIME_ACCESS_CODE,
    payload: otcData
});

export const addOneTimeAccessCodeSuccess = () => ({
    type: ADD_ONE_TIME_ACCESS_CODE_SUCCESS
});

export const addOneTimeAccessCodeFailure = error => ({
    type: ADD_ONE_TIME_ACCESS_CODE_FAILURE,
    payload: error
});

export const resetAddOneTimeAccessCode = type => {
    return {
        type: RESET_ADD_ONE_TIME_ACCESS_CODE,
        payload: type
    };
};

export const getGuests = () => {
    return {
        type: GET_GUESTS
    };
};

export const storeGuestsInvites = guestsInvites => {
    return {
        type: SET_GUEST_INVITES,
        payload: guestsInvites
    };
};

export const storeGuestsAccessKeys = accessKeys => {
    return {
        type: SET_GUEST_ACCESS_KEYS,
        payload: accessKeys
    };
};

export const storeOneTimeAccessCodes = oneTimeAccessKeys => {
    return {
        type: SET_ONE_TIME_ACCESS_CODES,
        payload: oneTimeAccessKeys
    };
};

export const addingNewGuest = guest => {
    return {
        type: ADD_NEW_GUEST,
        payload: guest
    };
};

export const addingNewGuestSuccess = () => {
    return {
        type: ADD_NEW_GUEST_SUCCESS
    };
};

export const addingNewGuestFailure = error => {
    return {
        type: ADD_NEW_GUEST_FAILURE,
        payload: error
    };
};

export const resettingNewGuest = type => {
    return {
        type: RESET_ADD_NEW_GUEST,
        payload: type
    };
};

export const resettingRemoveGuest = type => {
    return {
        type: RESET_REMOVE_GUEST,
        payload: type
    };
};

export const removeGuest = guest => {
    return {
        type: REMOVE_GUEST,
        payload: guest
    };
};

export const removeGuestSuccess = () => {
    return {
        type: REMOVE_GUEST_SUCCESS
    };
};

export const removeGuestFailure = error => {
    return {
        type: REMOVE_GUEST_FAILURE,
        payload: error
    };
};
