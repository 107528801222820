// React
import React from 'react';

// Packages
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

// Pages
import {
    Landing,
    Login,
    AcceptInvitationPage,
    AccessSuccess,
    AccessError,
    AppStoreRedirect,
    AuthenticationServices,
    SuperAdmin,
    MobilePrivacyPolicy,
    MobileEULA,
    ResetPassword,
    WebPrivacyPolicy,
    WebTerms,
    AddRegistration,
    GuestManager,
    AddGuest,
    AddGuestSuccess,
    Page404,
    RemovalRequest
} from '../pages';

//Router
import * as routes from '../router/config/routes';
import AuthenticatedRouter from '../router/AuthenticatedRouter';
import ConfirmReservationPage from '../pages/ConfirmReservationPage';
import RentalManager from '../pages/RentalManager';
import EditGuest from '../pages/EditGuest';
import AddOtc from '../pages/AddOtc';
import RevokeOtc from '../pages/RevokeOtc';

// PrivateRoute Components makes sure a user who is not signed in/authenticated cannot visit the authenticated area like Dashboard Page...if they try and access Dashboard route they will be redirected to the Login Page
const PrivateRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser ? <Component {...props} /> : <Redirect to={routes.LOGIN} />
        }
    />
);

// PrevAuthedRoute Components makes sure a user who is signed in/authenticated cannot login again or sign up again...if they try and access the login route or sign up route they will be redirected to the Dashboard Page
const PrevAuthedRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !authUser ? <Component {...props} /> : <Redirect to={routes.AUTHENTICATED} />
        }
    />
);

// SuperAuthedRoute Components makes sure a user who is a super_admin type can access that specific route...if they try and access the super routes without super_admin type they will be redirected to the user Dashboard Page

const SuperAuthedRoute = ({ component: Component, authUser, type, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser && type === 'super_admin' ? (
                <Component {...props} />
            ) : (
                <Redirect to={routes.LOGIN} />
            )
        }
    />
);

const RentalManagerRoute = ({ component: Component, authUser, type, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser && type === 'rental_manager' ? (
                <Component {...props} />
            ) : (
                <Redirect to={routes.LOGIN} />
            )
        }
    />
);

const GuestManagerRoute = ({ component: Component, authUser, type, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser && type === 'resi' ? (
                <Component {...props} />
            ) : (
                <Redirect to={routes.LOGIN} />
            )
        }
    />
);

const AppRouter = props => {
    const { match, history, authUser, userType, userData, userRole } = props;
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={routes.LANDING}
                    match={match}
                    history={history}
                    component={() => <Landing authUser={authUser} />}
                />
                <Route
                    exact
                    path={routes.ACCEPT_INVITATION}
                    match={match}
                    history={history}
                    component={() => <AcceptInvitationPage />}
                />
                <Route
                    exact
                    path={routes.CONFIRM_RESERVATION}
                    match={match}
                    history={history}
                    component={() => <ConfirmReservationPage />}
                />
                <Route
                    exact
                    path={routes.MOBILE_EULA}
                    match={match}
                    history={history}
                    component={() => <MobileEULA />}
                />
                <Route
                    exact
                    path={routes.MOBILE_PRIVACY_POLICY}
                    match={match}
                    history={history}
                    component={() => <MobilePrivacyPolicy />}
                />
                <Route
                    exact
                    path={routes.WEB_PRIVACY_POLICY}
                    match={match}
                    history={history}
                    component={() => <WebPrivacyPolicy />}
                />
                <Route
                    exact
                    path={routes.WEB_TERMS}
                    match={match}
                    history={history}
                    component={() => <WebTerms />}
                />
                <Route
                    exact
                    path={routes.REQUEST_REMOVAL}
                    match={match}
                    history={history}
                    component={() => <RemovalRequest />}
                />
                <Route
                    exact
                    path={routes.APP_STORE_REDIRECT}
                    match={match}
                    history={history}
                    component={() => <AppStoreRedirect />}
                />
                <Route
                    exact
                    path={routes.ACCESS_SUCCESS}
                    match={match}
                    history={history}
                    component={() => <AccessSuccess />}
                />
                <Route
                    exact
                    path={routes.ACCESS_ERROR}
                    match={match}
                    history={history}
                    component={() => <AccessError />}
                />
                <GuestManagerRoute
                    exact
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userRole}
                    userData={userData}
                    path={`${routes.GUEST_MANAGER}`}
                    component={props => <GuestManager {...props} />}
                />
                <GuestManagerRoute
                    exact
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userRole}
                    userData={userData}
                    path={`${routes.GUEST_MANAGER_ADD}`}
                    component={props => <AddGuest {...props} />}
                />
                <GuestManagerRoute
                    exact
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userRole}
                    userData={userData}
                    path={`${routes.GUEST_MANAGER_EDIT}/:id`}
                    component={props => <EditGuest {...props} />}
                />
                <GuestManagerRoute
                    exact
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userRole}
                    userData={userData}
                    path={`${routes.GUEST_MANAGER_ADD_SUCCESS}`}
                    component={props => <AddGuestSuccess {...props} />}
                />
                <GuestManagerRoute
                    exact
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userRole}
                    userData={userData}
                    path={`${routes.GUEST_MANAGER_ADD_OTC}`}
                    component={props => <AddOtc {...props} />}
                />
                <GuestManagerRoute
                    exact
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userRole}
                    userData={userData}
                    path={`${routes.GUEST_MANAGER_REVOKE_OTC}/:id`}
                    component={props => <RevokeOtc {...props} />}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.ADD_REGISTRATION}
                    match={match}
                    history={history}
                    component={props => (
                        <AddRegistration authUser={authUser} {...props} />
                    )}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.LOGIN}
                    component={props => <Login authUser={authUser} {...props} />}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.RESET_PASSWORD}
                    component={props => <ResetPassword authUser={authUser} {...props} />}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.AUTH_SERVICES}
                    component={props => (
                        <AuthenticationServices authUser={authUser} {...props} />
                    )}
                />
                <RentalManagerRoute
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userType}
                    userData={userData}
                    path={`${routes.RENTAL_MANAGER}`}
                    component={props => (
                        <RentalManager
                            authUser={authUser}
                            userType={userType}
                            userData={userData}
                            {...props}
                        />
                    )}
                />
                <SuperAuthedRoute
                    match={match}
                    history={history}
                    authUser={authUser}
                    type={userType}
                    userData={userData}
                    path={`${routes.SUPER_AUTHENTICATED}${routes.SUPER_ADMIN}`}
                    component={props => (
                        <SuperAdmin
                            authUser={authUser}
                            userType={userType}
                            userData={userData}
                            {...props}
                        />
                    )}
                />
                <PrivateRoute
                    authUser={authUser}
                    userData={userData}
                    path={routes.AUTHENTICATED}
                    component={props => (
                        <AuthenticatedRouter
                            userData={userData}
                            authUser={authUser}
                            userType={userType}
                            userRole={userRole}
                            {...props}
                        />
                    )}
                />
                <Route component={Page404} />
            </Switch>
        </Router>
    );
};

export default AppRouter;
