import { SET_USER_COLLECTION, SET_ACTIVE_USER_ORG } from './types';

export const storeUserCollection = user => {
    return {
        type: SET_USER_COLLECTION,
        payload: user
    };
};

export const setActiveManagerOrg = orgId => {
    return {
        type: SET_ACTIVE_USER_ORG,
        payload: orgId
    };
};
