import React, { useState } from 'react';

import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

const DropdownButton = ({
    menuOptionComponents,
    color,
    toggleComponent,
    handleSelect,
    style,
    caret = true
}) => {
    const [dropdownOpen, setOpen] = useState(false);

    const toggleDropdown = () => setOpen(!dropdownOpen);
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
                style={style}
                caret={caret}
                color={color || 'primary'}
                className="transition-3d-hover btn btn-sm btn-primary"
                variant="success"
                id="dropdown-basic"
            >
                {toggleComponent}
            </DropdownToggle>

            <DropdownMenu>
                {menuOptionComponents.map(option => {
                    const { key, component } = option;
                    return (
                        <DropdownItem
                            onClick={() => handleSelect && handleSelect(option)}
                            key={key}
                        >
                            {component}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};
export default DropdownButton;
