import {
    ADD_NEW_GUEST,
    ADD_ONE_TIME_ACCESS_CODE,
    GET_GUESTS,
    LOGOUT_USER,
    REMOVE_GUEST,
    REMOVE_GUEST_FAILURE,
    REMOVE_GUEST_SUCCESS,
    REVOKE_ONE_TIME_ACCESS_CODE
} from '../actions/types';

import {
    all,
    fork,
    takeLatest,
    put,
    call,
    select,
    take,
    race,
    cancelled
} from 'redux-saga/effects';

import { db, timeStampNow } from '../../config/Firebase';

import {
    addingNewGuestSuccess,
    addingNewGuestFailure,
    storeGuestsInvites,
    storeGuestsAccessKeys,
    storeOneTimeAccessCodes,
    addOneTimeAccessCodeSuccess,
    addOneTimeAccessCodeFailure,
    revokeOneTimeAccessCodeSuccess,
    revokeOneTimeAccessCodeFailure
} from '../actions/Guests';

import { generateInviteCode } from '../../utils/Helpers';

import { ACCESS_KEY_SCHEMA, GUEST_INVITE_SCHEMA } from '../../utils/Constants';

import * as selectors from './Selectors';

import { eventChannel } from 'redux-saga';
import { getOrgById } from './Org';

const users = db.collection('users');
const guest_invites = db.collection('guest_invites');
const access_keys = db.collection('access_keys');
const otc = db.collection('otc');

export function* listenToAccessKeysAndGuestInvites(user) {
    let unsubscribeAccessKeys;
    let unsubscribeGuestInvites;
    let unsubscribeOtc;

    const accessKeysChannel = eventChannel(emit => {
        const query = access_keys.where('creator_id', '==', user.uid);

        unsubscribeAccessKeys = query.onSnapshot(querySnapshot => {
            const accessKeysData = [];
            querySnapshot.forEach(doc => {
                accessKeysData.push({ id: doc.id, ...doc.data() });
            });
            emit(accessKeysData);
        });

        return unsubscribeAccessKeys;
    });

    const guestInvitesChannel = eventChannel(emit => {
        const query = guest_invites.where('creator_id', '==', user.uid);

        unsubscribeGuestInvites = query.onSnapshot(querySnapshot => {
            const guestInvitesData = [];
            querySnapshot.forEach(doc => {
                guestInvitesData.push({ id: doc.id, ...doc.data() });
            });
            emit(guestInvitesData);
        });

        return unsubscribeGuestInvites;
    });

    const otcChannel = eventChannel(emit => {
        const query = otc.where('creator_id', '==', user.uid);

        unsubscribeOtc = query.onSnapshot(querySnapshot => {
            const otcData = [];
            querySnapshot.forEach(doc => {
                otcData.push({ id: doc.id, ...doc.data() });
            });
            emit(otcData);
        });

        return unsubscribeOtc;
    });

    try {
        while (true) {
            const { userSignOut, guestInvites, accessKeys, oneTimeAccessKeys } =
                yield race({
                    userSignOut: take(LOGOUT_USER),
                    guestInvites: take(guestInvitesChannel),
                    accessKeys: take(accessKeysChannel),
                    oneTimeAccessKeys: take(otcChannel)
                });

            if (userSignOut) {
                accessKeysChannel.close();
                guestInvitesChannel.close();
                otcChannel.close();
            } else {
                if (guestInvites) {
                    const guestInvitesDataWithFlag = guestInvites.map(item => ({
                        ...item,
                        isGuestInvite: true
                    }));
                    yield put(storeGuestsInvites(guestInvitesDataWithFlag));
                }

                if (accessKeys) {
                    yield put(storeGuestsAccessKeys(accessKeys));
                }

                if (oneTimeAccessKeys) {
                    yield put(storeOneTimeAccessCodes(oneTimeAccessKeys));
                }
            }
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
        unsubscribeAccessKeys();
        unsubscribeGuestInvites();
        unsubscribeOtc();
        if (yield cancelled()) {
            accessKeysChannel.close();
            guestInvitesChannel.close();
            otcChannel.close();
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Guests ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const existingByEmail = email => {
    return new Promise((resolve, reject) => {
        users
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                if (!querySnapshot.size) {
                    resolve([]);
                }

                const emailExist = [];
                querySnapshot.forEach((doc, index) => {
                    emailExist.push(doc.data());
                    resolve(emailExist);
                });
            })
            .catch(error => reject(error));
    });
};

const existingByPhone = async phone => {
    return new Promise((resolve, reject) => {
        users
            .where('phone_number', '==', phone)
            .get()
            .then(querySnapshot => {
                if (!querySnapshot.size) {
                    resolve([]);
                }

                const phoneExist = [];
                querySnapshot.forEach((doc, index) => {
                    phoneExist.push(doc.data());
                    resolve(phoneExist);
                });
            })
            .catch(error => reject(error));
    });
};

const checkExistingGuestRequest = async member => {
    const { phone, email } = member;
    try {
        const emailExistence = email ? await existingByEmail(email) : [];
        const phoneExistence = phone ? await existingByPhone(phone) : [];

        const existence = [...emailExistence, ...phoneExistence];
        const filteredExistence = existence.reduce((reducedExistence, current) => {
            if (!reducedExistence.some(x => x.uid === current.uid)) {
                reducedExistence.push(current);
            }
            return reducedExistence;
        }, []);

        return { existing: filteredExistence };
    } catch (error) {
        return { error };
    }
};

export function* checkExistingGuest({ payload }) {
    const { member } = payload;
    const { existing, error } = yield call(() =>
        checkExistingGuestRequest({ email: member.email, phone: member.phone_number })
    );

    if (existing) {
        if (existing.length) {
            return existing;
        }
    } else {
        // Error Handling for sentry with put and maybe UI message
        console.log(
            'Check Existing Error: error checking for existing member when adding',
            {
                error
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Guests ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addNewGuestsRequest = async ({ invite, org, user }) => {
    return new Promise((resolve, reject) => {
        const data = GUEST_INVITE_SCHEMA;
        const firstName = invite.first_name.toLowerCase();
        const lastName = invite.last_name.toLowerCase();
        const email = invite.email ? invite.email.toLowerCase() : null;
        const phone = invite?.phone?.number ? invite.phone : null;
        const guestInviteKeyId = invite?.invite_id || guest_invites.doc().id;
        const inviteCode = generateInviteCode();
        const phoneNumber =
            invite?.phone?.code && invite?.phone?.number
                ? `${invite.phone.code}${invite.phone.number}`
                : null;

        const orgId = user.active_org_id;
        const activeProperty = user.properties[orgId];
        const orgName = org.org_name;
        const now = timeStampNow();

        const guestInviteData = Object.assign(data, {
            access_days: invite.access_days,
            access_end_time: invite.access_end_time,
            access_expires: null,
            access_start_time: invite.access_start_time,
            access_begins: now,
            access_created: null,
            address: activeProperty.address,
            creator_first_name: user.first_name,
            creator_id: user.uid,
            creator_last_name: user.last_name,
            email: email,
            first_name: firstName,
            invite_code: inviteCode,
            invite_id: guestInviteKeyId,
            invite_status: 'pending',
            invited_at: now,
            last_name: lastName,
            org_id: orgId,
            org_name: orgName,
            phone: phone,
            phone_number: phoneNumber,
            role: 'guest',
            suspended: false,
            plates: [],
            type: 'short-term',
            vehicles: []
        });

        if (invite?.invite_id) {
            guest_invites
                .doc(guestInviteKeyId)
                .update(guestInviteData)
                .then(() => {
                    resolve({ res: true });
                })
                .catch(error => {
                    reject({
                        error: `Error: updating Firestore Guest Invite Doc ${error}`
                    });
                });
        } else {
            guest_invites
                .doc(guestInviteKeyId)
                .set(guestInviteData)
                .then(() => {
                    resolve({ res: true });
                })
                .catch(error => {
                    reject({ error: 'Error: creating guest invite' });
                });
        }
    });
};

const addExistingGuestsRequest = async ({ member, user, org, exist }) => {
    return new Promise((resolve, reject) => {
        const orgId = user.active_org_id;
        const activeProperty = user.properties[orgId];
        const accessKeyId = member.invite_id || access_keys.doc().id;
        const data = ACCESS_KEY_SCHEMA;
        const tsNow = timeStampNow();
        const orgName = org.org_name;
        let phoneNumber = null;
        if (exist?.phone?.number) {
            phoneNumber =
                exist?.phone?.code && exist?.phone?.number
                    ? `${exist.phone.code}${exist.phone.number}`
                    : null;
        } else {
            phoneNumber =
                member?.phone?.code && member?.phone?.number
                    ? `${member.phone.code}${member.phone.number}`
                    : null;
        }
        const phone = exist?.phone?.number
            ? exist.phone
            : member?.phone?.number
            ? member.phone
            : null;

        const accessKeyData = Object.assign(data, {
            access_begins: null,
            access_created: tsNow,
            access_days: member.access_days,
            access_end_time: member.access_end_time,
            access_expires: null,
            access_start_time: member.access_start_time,
            address: activeProperty.address,
            company_name: '',
            consumer_id: exist.uid,
            creator_first_name: user.first_name,
            creator_id: user.uid,
            creator_last_name: user.last_name,
            email: exist.email,
            favorite: false,
            first_name: exist.first_name,
            image: null,
            key_id: accessKeyId,
            last_name: exist.last_name,
            org_id: orgId,
            org_name: orgName,
            phone: phone,
            phone_number: phoneNumber,
            photo_id: null,
            plates: [],
            role: 'guest',
            suspended: false,
            type: 'short-term',
            vehicles: []
        });

        if (member.invite_id) {
            access_keys
                .doc(accessKeyId)
                .update(accessKeyData)
                .then(() => {
                    resolve({ res: true });
                })
                .catch(error => {
                    reject({ error: `Error: updating Firestore User Doc ${error}` });
                });
        } else {
            access_keys
                .doc(accessKeyId)
                .set(accessKeyData)
                .then(() => {
                    resolve({ res: true });
                })
                .catch(error => {
                    reject({ error: `Error: adding Firestore User Doc ${error}` });
                });
        }
    });
};

export function* addGuests({ payload }) {
    const { member } = payload;
    const exist = yield call(() => checkExistingGuest({ payload }));

    const userData = yield select(selectors._userData);
    const orgData = yield getOrgById(userData.active_org_id);

    try {
        let res, error;

        if (!exist) {
            ({ res, error } = yield call(() =>
                addNewGuestsRequest({ invite: member, org: orgData, user: userData })
            ));
        } else {
            ({ res, error } = yield call(() =>
                addExistingGuestsRequest({
                    member,
                    user: userData,
                    org: orgData,
                    exist: exist[0]
                })
            ));
        }

        if (res) {
            yield put(addingNewGuestSuccess());
        } else {
            yield put(addingNewGuestFailure(error));
        }
    } catch (error) {
        yield put(addingNewGuestFailure(error.error));
    }
}

export function* deleteGuest({ payload }) {
    const { guest } = payload;
    try {
        const collectionName = guest.isGuestInvite ? 'guest_invites' : 'access_keys';

        yield call(() => db.collection(collectionName).doc(guest.id).delete());

        yield put({ type: REMOVE_GUEST_SUCCESS, payload: { guestId: guest.id } });
    } catch (error) {
        yield put({ type: REMOVE_GUEST_FAILURE, error });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add One Time Access Code ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function* createOtcDocument({ payload }) {
    const userData = yield select(selectors._userData);
    const { name, expires_at, created_at, code } = payload;
    const orgId = userData.active_org_id;
    const creatorId = userData.uid;

    const oneTimeAccessCodeId = otc.doc().id;
    try {
        const createOtc = {
            code,
            code_id: oneTimeAccessCodeId,
            created_at,
            creator_id: creatorId,
            expires_at,
            name: name,
            org_id: orgId
        };

        yield otc.doc(oneTimeAccessCodeId).set(createOtc);
        yield put(addOneTimeAccessCodeSuccess());
    } catch (error) {
        yield put(addOneTimeAccessCodeFailure(error));
    }
}

function* revokeOtc({ payload }) {
    try {
        const { code_id } = payload;
        const now = timeStampNow();

        const updatedData = {
            expires_at: now
        };

        yield otc.doc(code_id).update(updatedData);

        yield put(revokeOneTimeAccessCodeSuccess());
    } catch (error) {
        yield put(revokeOneTimeAccessCodeFailure(error));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add One Time Access Code ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* revokeOneTimeAccessCode() {
    yield takeLatest(REVOKE_ONE_TIME_ACCESS_CODE, revokeOtc);
}

export function* createOneTimeAccessCode() {
    yield takeLatest(ADD_ONE_TIME_ACCESS_CODE, createOtcDocument);
}

export function* addingGuests() {
    yield takeLatest(ADD_NEW_GUEST, addGuests);
}

export function* deleteingGuests() {
    yield takeLatest(REMOVE_GUEST, deleteGuest);
}

export function* getGuestInvites() {
    yield takeLatest(GET_GUESTS, listenToAccessKeysAndGuestInvites);
}

export default function* rootSaga() {
    yield all([
        fork(revokeOneTimeAccessCode),
        fork(createOneTimeAccessCode),
        fork(deleteingGuests),
        fork(addingGuests),
        fork(getGuestInvites)
    ]);
}
