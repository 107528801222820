import React, { useEffect, useState } from 'react';

import { AddGuestForm } from '../components';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';

const EditGuest = ({ guestInvites, guestAccessKeys }) => {
    const { id } = useParams();
    const [resiGuests, setResiGuests] = useState([]);

    useEffect(() => {
        if (guestInvites && guestAccessKeys) {
            setResiGuests([...guestInvites, ...guestAccessKeys]);
        }
    }, [guestInvites, guestAccessKeys]);
    return (
        <div className="container-fluid min-vh-100 space-1 bg-dark">
            <div className="d-flex mt-2 align-items-center justify-content-center flex-column">
                <img src="../assets/sage-icon.png" alt="logo" width={80} />
            </div>
            {resiGuests?.length > 0 && (
                <AddGuestForm guest={resiGuests.find(guest => guest.id === id)} />
            )}
        </div>
    );
};

const mapStateToProps = ({ guests }) => {
    const { guestInvites, guestAccessKeys } = guests;
    return { guestInvites, guestAccessKeys };
};

export default connect(mapStateToProps, {})(EditGuest);
