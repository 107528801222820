import React from 'react';
import { Container, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp, streetAddressRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import { connect } from 'react-redux';
import Loader from '../common/Loader';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';
import AddRegistrationSuccess from './AddRegistrationSuccess';
import { Button } from 'reactstrap';
import {
    addingNewRegistration,
    resetAddingNewRegistration
} from '../../store/actions/Registration';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const AddRegistrationForm = ({
    loading,
    error,
    member,
    success,
    addingNewRegistration,
    resetAddingNewRegistration,
    shortCode
}) => {
    const submit = values => {
        const formattedPhone = phone => {
            return phone.replace(/[^0-9]/g, '').length > 10
                ? `${phone
                      .replace(/[^0-9]/g, '')
                      .substring(phone.replace(/[^0-9]/g, '').length - 10)}`
                : `${phone.replace(/[^0-9]/g, '')}`;
        };
        const member = {
            first_name: values.first_name.trim().toLowerCase(),
            last_name: values.last_name.trim().toLowerCase(),
            address: values.address.trim() || '',
            email: values.email.trim() === '' ? false : values.email.trim().toLowerCase(),
            phone:
                values?.phone.toString().trim() === ''
                    ? false
                    : parseInt(formattedPhone(values.phone.toString()))
        };
        addingNewRegistration({ member, shortCode });
    };

    const initialValues = {
        first_name: member?.first_name || '',
        last_name: member?.last_name || '',
        address: member?.address || '',
        email: member?.email || '',
        phone: member?.phone || '',
        code: member?.code || '1',
        country: member?.country || 'US'
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        address: Yup.string()
            .trim()
            .matches(streetAddressRegExp, errorMessage.address.valid)
            .required(errorMessage.address.required),
        email: Yup.string()
            .trim()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        phone: Yup.string().trim(),
        country: Yup.string().trim(),
        code: Yup.string().trim()
    });

    const validatePhoneEmail = ({ phone, code, country }) => {
        // const checkExistingEmail = email => {
        //     if (
        //         members &&
        //         members.email &&
        //         members.email.length &&
        //         email.trim() !== '' &&
        //         members.email.indexOf(email.toLowerCase()) > -1
        //     ) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // };
        // const checkExistingPhone = phone => {
        //     if (
        //         members &&
        //         members.phone &&
        //         phone &&
        //         phone.trim() !== '' &&
        //         members.phone.indexOf(phone) > -1
        //     ) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // };
        const phoneSchema = Yup.string().phone(country);
        // const emailSchema = Yup.string().email();

        const isPhoneValid = phone ? phoneSchema.isValidSync(code + phone) : true;
        // const phoneIsUnique = phone ? checkExistingPhone(phone) : true;
        // const emailIsUnique = email ? checkExistingEmail(email) : true;

        // if (!emailIsUnique) {
        //     return {
        //         email: emailIsUnique ? undefined : `Member email already exixsts.`
        //     };
        // } else if (!isPhoneValid) {
        return isPhoneValid
            ? undefined
            : {
                  phone: 'Mobile phone needs to be a valid number'
              };
        // } else if (!phoneIsUnique) {
        //     return {
        //         phone: phoneIsUnique ? undefined : 'Member phone already exists'
        //     };
        // }
    };

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (success || error) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <AddRegistrationSuccess
                    error={error}
                    resetForm={resetAddingNewRegistration}
                />
            </div>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                    validate={validatePhoneEmail}
                    validationSchema={validationSchema}
                    validateOnChange
                    enableReinitialize
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                            <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                                <img
                                    src="../assets/sage-icon.png"
                                    alt="logo"
                                    width={80}
                                />
                                <div className="d-flex w-md-75 flex-column mb-3 align-items-center justify-content-center">
                                    <div
                                        style={{ fontSize: '32px' }}
                                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                                    >
                                        Welcome to SAGE
                                    </div>
                                    <div
                                        style={{ fontSize: '16px' }}
                                        className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal mb-4"
                                    >
                                        <span>
                                            To request access to Sage, please fill out the
                                            form below for each occupant of your household
                                            to receive a registration invite. Please only
                                            submit your request once per resident. Once
                                            you have submitted the form, your community
                                            management team will begin to verify your
                                            information and promptly send out your
                                            invitation code.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group mx-4 mx-lg-7">
                                <div className="row">
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <LabeledFormField
                                            fieldTitle="first name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            id="first_name"
                                            placeholder="First Name"
                                            aria-label="first_name"
                                            error={returnError(errors, 'first_name')}
                                            value={values.first_name}
                                            onBlur={handleBlur('first_name')}
                                            onChange={handleChange('first_name')}
                                            invalid={
                                                returnTouched(touched, 'first_name') &&
                                                !!returnError(errors, 'first_name')
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <LabeledFormField
                                            fieldTitle="last name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="last_name"
                                            placeholder="Last Name"
                                            aria-label="last_name"
                                            error={returnError(errors, 'last_name')}
                                            value={values.last_name}
                                            onBlur={handleBlur('last_name')}
                                            onChange={handleChange('last_name')}
                                            invalid={
                                                returnTouched(touched, 'last_name') &&
                                                !!returnError(errors, 'last_name')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group mx-4 mx-lg-7">
                                <div className="row">
                                    <div className="js-form-message col-md-12 col-lg-12 form-group">
                                        <LabeledFormField
                                            fieldTitle="Street address"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="address"
                                            id="address"
                                            placeholder="1234 Main St"
                                            aria-label="address"
                                            error={returnError(errors, 'address')}
                                            value={values.address}
                                            onBlur={handleBlur('address')}
                                            onChange={handleChange('address')}
                                            invalid={
                                                returnTouched(touched, 'address') &&
                                                !!returnError(errors, 'address')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="js-form-message form-group mx-4 mx-lg-7">
                                <div className="row">
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <LabeledFormField
                                            fieldTitle="email"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder="Email Address"
                                            aria-label="email"
                                            error={returnError(errors, 'email')}
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                            invalid={
                                                returnTouched(touched, 'email') &&
                                                !!returnError(errors, 'email')
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <ContryPhoneCodeSelect
                                            fieldTitle="Mobile phone"
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            id="phone"
                                            aria-label="phone"
                                            phoneError={returnError(errors, 'phone')}
                                            phoneTouched={returnTouched(touched, 'phone')}
                                            codeValue={values?.code}
                                            phoneValue={values?.phone}
                                            countryValue={values?.country}
                                            onBlurPhone={handleBlur('phone')}
                                            onChangePhone={e => {
                                                handleChange('phone')(e);
                                            }}
                                            onBlurCode={handleBlur('code')}
                                            onChangeCode={value =>
                                                setFieldValue('code', value)
                                            }
                                            onChangeCountry={value =>
                                                setFieldValue('country', value)
                                            }
                                            invalid={
                                                returnTouched(touched, 'phone') &&
                                                !!returnError(errors, 'phone')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex row justify-content-end align-items-center border-top my-5 mx-2">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="transition-3d-hover mt-5 mr-2 btn"
                                    onClick={handleSubmit}
                                >
                                    Submit for Approval
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ register }) => {
    const { loading, error, member, success } = register;
    return {
        loading,
        error,
        member,
        success
    };
};

export default connect(mapStateToProps, {
    addingNewRegistration,
    resetAddingNewRegistration
})(AddRegistrationForm);
