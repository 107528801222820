// React
import React from 'react';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Avatar from 'react-avatar';

// Redux - Actions, Reducers, Sagas
import { logoutUser } from '../../store/actions/Auth';
import DropdownButton from '../common/DropdownButton';
import { setActiveManagerOrg } from '../../store/actions/User';
import { useDispatch } from 'react-redux';
import {
    AUTHENTICATED,
    PROFILE_SETTINGS,
    SUPER_ADMIN,
    SUPER_AUTHENTICATED,
    RESET_PASSWORD_AUTHENTICATED
} from '../../router/config/routes';

const ToolBar = ({ logoutUser, userData, orgs, activeOrg }) => {
    const dispatch = useDispatch();
    const roles = {
        super_admin: 'Admin',
        manager: 'Manager',
        rental_manager: 'Rental Manager',
        operator: 'Operator'
    };

    const menuOptions = [
        {
            key: 'Settings',
            component: (
                <Link
                    to={{
                        pathname:
                            userData.type === 'super_admin'
                                ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${PROFILE_SETTINGS}`
                                : `${AUTHENTICATED}${PROFILE_SETTINGS}`
                    }}
                >
                    Profile settings
                </Link>
            )
        },
        {
            key: 'Reset password',
            component: (
                <Link
                    to={
                        userData.type === 'super_admin'
                            ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${RESET_PASSWORD_AUTHENTICATED}`
                            : `${AUTHENTICATED}${RESET_PASSWORD_AUTHENTICATED}`
                    }
                >
                    Reset password
                </Link>
            )
        },
        {
            key: 'Logout',
            component: (
                <div
                    color="primary"
                    className="transition-3d-hover btn btn-primary btn w-100 mt-3"
                    onClick={logoutUser}
                >
                    Logout
                </div>
            )
        }
    ];

    const getOrgOptions = properties => {
        const orgsObj = {};
        orgs.forEach(element => {
            orgsObj[element.org_id] = element;
        });
        const options = [];

        for (const property in properties) {
            options.push({
                key: property,
                data: orgsObj[property],
                component: (
                    <div onClick={() => dispatch(setActiveManagerOrg(orgsObj[property]))}>
                        {orgsObj[property].org_name}
                    </div>
                )
            });
        }
        return options;
    };

    return (
        <div className="d-flex flex-column flex-md-row align-items-center p-1 bg-white border-bottom shadow-2 justify-content-between z-10">
            <div className="logo ml-2" style={{ width: '58px' }}>
                <img
                    src="../../assets/sage-icon.png"
                    alt="logo"
                    style={{
                        width: '58px',
                        background: '#252c33',
                        padding: '8px 12px',
                        borderRadius: '12px'
                    }}
                />
            </div>
            <div className="d-flex flex-row align-items-center">
                {activeOrg &&
                    userData &&
                    userData.properties &&
                    userData.type === 'manager' && (
                        <DropdownButton
                            style={{ color: '#252c33' }}
                            color="white"
                            menuOptionComponents={getOrgOptions(userData.properties)}
                            toggleComponent={
                                <span className="font-weight-semi-bold">
                                    {activeOrg.org_name}
                                </span>
                            }
                        />
                    )}
                <DropdownButton
                    caret={false}
                    style={{ color: '#252c33' }}
                    color="white"
                    menuOptionComponents={menuOptions}
                    toggleComponent={
                        <div className="btn-link user-button">
                            <div
                                className="d-flex flex-column mx-2 align-items-end justify-content-center"
                                style={{ marginTop: '2px' }}
                            >
                                <span className="d-flex users-name">
                                    {userData &&
                                        `${userData.first_name} ${userData.last_name}`}
                                </span>
                                {userData && (
                                    <span className="capitalize users-role">
                                        {roles[userData.type]}
                                    </span>
                                )}
                            </div>
                            <div className="mx-2 users-img">
                                <Avatar
                                    color={'#252c33'}
                                    src={userData?.avatar}
                                    name={
                                        userData &&
                                        `${userData.first_name} ${userData.last_name}`
                                    }
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, org }) => ({
    userData: user.userData,
    activeOrg: user.activeOrg,
    orgs: org.orgs
});

export default withRouter(connect(mapStateToProps, { logoutUser })(ToolBar));
