import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NumberFormat from 'react-number-format';
import { useWindowSize } from '../../hooks/useWindowSize';
import Loader from '../common/Loader';

const CommunityMembersList = ({
    orgId,
    modalOpen,
    searchString,
    selectHandler,
    selected,
    filteredContacts
}) => {
    const { windowHeight } = useWindowSize();

    return (
        <div style={{ height: [windowHeight] - 450, overflowY: 'auto' }}>
            {filteredContacts !== null && (
                <InfiniteScroll dataLength={filteredContacts.length} loader={<Loader />}>
                    {filteredContacts.map(contact => {
                        const {
                            uid,
                            suspended,
                            address,
                            email,
                            first_name,
                            last_name,
                            phone
                        } = contact;
                        return (
                            <div
                                key={uid}
                                onClick={() => {
                                    modalOpen(contact);
                                }}
                                className="contact-list-item"
                            >
                                <div className="col-2">
                                    <span className="d-block mb-0 text-capitalize">{`${
                                        first_name.toLowerCase() === 'n/a' &&
                                        last_name.toLowerCase() === 'n/a'
                                            ? 'N/A'
                                            : `${last_name}, ${first_name}`
                                    } `}</span>
                                    <small className="d-block text-muted text-ellipsis">
                                        {address}
                                    </small>
                                </div>
                                <div className="col-2">
                                    <small>
                                        <NumberFormat
                                            value={phone}
                                            displayType={'text'}
                                            format="###-###-####"
                                            mask="_"
                                        />
                                    </small>
                                </div>
                                <div
                                    className="col-3"
                                    style={{ textTransform: 'lowercase' }}
                                >
                                    <small>{email}</small>
                                </div>
                                <div className="col-1">
                                    {!suspended ? (
                                        <div className="invite-badge badge-active">
                                            Active
                                        </div>
                                    ) : (
                                        <div className="invite-badge badge-suspended">
                                            Suspended
                                        </div>
                                    )}
                                </div>
                                <div className="col-1">
                                    <div
                                        onClick={e => {
                                            e.stopPropagation();
                                            selectHandler(uid);
                                        }}
                                        className={`invite-badge ${
                                            selected.includes(uid)
                                                ? 'badge-green'
                                                : 'badge-gray'
                                        }`}
                                    >
                                        <i
                                            className="fa fa-check-circle mr-2 "
                                            aria-hidden="true"
                                        />
                                        Select
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </InfiniteScroll>
            )}
        </div>
    );
};

export default CommunityMembersList;
