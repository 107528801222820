import { all, fork, take, cancelled, takeLatest, race, put } from 'redux-saga/effects';

import {
    GET_DEVICE_TYPES,
    GET_PANEL_DATA,
    LOGOUT_USER,
    UPDATE_ORG_DEVICE_DETAILS,
    WATCH_ORG_DEVICES_RTDB
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import { storeOrgData } from '../actions/Org';

import { db, rtdb } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';
import {
    getDeviceTypesFailure,
    getDeviceTypesSuccess,
    updateOrgDeviceDetailsFailure,
    updateOrgDeviceDetailsSuccess,
    watchOrgDevicesRTDBFailure,
    watchOrgDevicesRTDBSuccess
} from '../actions/Panels';

const panels = db.collection('panels');
const deviceTypes = panels.doc('devices');
export const getRtdbOrgPanelsRef = orgId => rtdb.ref(`orgs/${orgId}/panels`);

export function* updateDeviceDetails({ payload }) {
    try {
        const { deviceInfo, orgId } = payload;
        const orgPanelsRef = getRtdbOrgPanelsRef(orgId);

        yield orgPanelsRef.child(`${deviceInfo.id}`).update(deviceInfo);
        yield* getRtdbOrgDevices({ payload: orgId });
        yield put(updateOrgDeviceDetailsSuccess());
    } catch (error) {
        yield put(updateOrgDeviceDetailsFailure(error));
    }
}

export function* getDeviceTypes() {
    try {
        const types = yield deviceTypes.get().then(doc => doc.data());
        yield put(getDeviceTypesSuccess(types));
    } catch (error) {
        yield put(getDeviceTypesFailure(error));
    }
}

function* getRtdbOrgDevices({ payload }) {
    try {
        const orgPanelsRef = getRtdbOrgPanelsRef(payload);

        const devicesArr = [];

        yield orgPanelsRef.once('value').then(snapshot => {
            const devices = snapshot.val();
            for (const key in devices) {
                devicesArr.push(devices[key]);
            }
        });
        yield put(watchOrgDevicesRTDBSuccess(devicesArr));
    } catch (error) {
        yield put(watchOrgDevicesRTDBFailure(`${error}`));
    }
}

export function* watchRtdbOrgDevices({ payload }) {
    try {
        const orgPanelsRef = getRtdbOrgPanelsRef(payload);
        const devicesArr = [];

        yield orgPanelsRef.on('value', snapshot => {
            const devices = snapshot.val();
            for (const key in devices) {
                devicesArr.push(devices[key]);
            }
        });
        yield put(watchOrgDevicesRTDBSuccess(devicesArr));
    } catch (error) {
        yield put(watchOrgDevicesRTDBFailure(`${error}`));
        log('Watching RTDB Org Devices: getting panels collection data (RTDB)', {
            error,
            data: { ...payload }
        });
    }
}

export function* panelCollectionWatch(user) {
    let unsubscribeUserPanelData;
    const panelCollectionChannel = eventChannel(emit => {
        unsubscribeUserPanelData = panels
            .where('id', '==', user.type === 'super_admin' ? user.type : null)
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot) {
                    var panel = null;
                    querySnapshot.forEach(function (doc) {
                        panel = doc.data();
                    });
                    emit(panel);
                } else {
                    const doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeUserPanelData;
    });
    try {
        while (true) {
            const { userSignOut, panelData } = yield race({
                userSignOut: take(LOGOUT_USER),
                panelData: take(panelCollectionChannel)
            });

            if (userSignOut) {
                panelCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(storeOrgData(panelData));
            }
        }
    } catch (error) {
        log('Watching Panels: getting panels collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubscribeUserPanelData(); // Detach firebase listener
        if (yield cancelled()) {
            panelCollectionChannel.close(); // Detach saga event emitter
            unsubscribeUserPanelData(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* updateDetails() {
    yield takeLatest(UPDATE_ORG_DEVICE_DETAILS, updateDeviceDetails);
}

export function* watchOrgPanels() {
    yield takeLatest(WATCH_ORG_DEVICES_RTDB, watchRtdbOrgDevices);
}

export function* getOrgPanels() {
    yield takeLatest(WATCH_ORG_DEVICES_RTDB, getRtdbOrgDevices);
}

export function* getTypes() {
    yield takeLatest(GET_DEVICE_TYPES, getDeviceTypes);
}

export function* getPanelCollection() {
    yield takeLatest(GET_PANEL_DATA, panelCollectionWatch);
}

export default function* rootSaga() {
    yield all([
        fork(getPanelCollection),
        fork(getTypes),
        fork(watchOrgPanels),
        fork(getOrgPanels),
        fork(updateDetails)
    ]);
}
