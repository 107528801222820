import axios from 'axios';

const baseURL = 'https://api.sagesystems.app/api';

export const apiPost = (url, data) =>
    axios
        .post(`${baseURL}${url}`, data)
        .then(response => ({ data: response.data }))
        .catch(error => ({ error }));

export const apiPatch = (url, data) =>
    axios
        .patch(`${baseURL}${url}`, data)
        .then(response => {
            console.log(response);
            return { data: response.data };
        })
        .catch(error => ({ error }));
