// React
import React from 'react';

const OrgsSearchFilter = ({ handleChange }) => {
    return (
        <div id="orgsFilters">
            <div className="mb-3">
                <div className="row mx-gutters-2 align-items-center">
                    <div className="col-5 mb-3 mb-lg-0">
                        <div className="js-focus-state">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="searchProperty"
                                    >
                                        <span className="fas fa-search" />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="text"
                                    id="searchOrg"
                                    placeholder="Search Organizations"
                                    aria-label="search"
                                    aria-describedby="searchOrganization"
                                    autoComplete="off"
                                    onChange={e => handleChange(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrgsSearchFilter;
