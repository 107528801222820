import {
    GET_EXISTING_MEMBER,
    SET_EXISTING_MEMBER,
    ADD_NEW_MEMBER,
    ADD_NEW_MEMBER_SUCCESS,
    ADD_NEW_MEMBER_FAILURE,
    RESET_ADD_NEW_MEMBER
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    member: null,
    found: null
};

const membersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_EXISTING_MEMBER:
            return {
                ...state,
                member: action.payload.member,
                loading: true
            };
        case SET_EXISTING_MEMBER:
            return {
                ...state,
                found: action.payload,
                loading: false
            };
        case ADD_NEW_MEMBER:
            return {
                ...state,
                // member: action.payload,
                loading: true
            };
        case ADD_NEW_MEMBER_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case ADD_NEW_MEMBER_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case RESET_ADD_NEW_MEMBER:
            return {
                ...state,
                loading: null,
                error: null,
                found: null,
                member: action.payload === 'member' ? null : state.member,
                success: action.payload === 'member' ? null : state.success
            };
        default:
            return state;
    }
};

export default membersReducer;
