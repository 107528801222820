import { ADD_POST, DELETE_POST, GET_POSTS, LOGOUT_USER } from '../actions/types';

import { all, fork, takeLatest, put, race, cancelled, take } from 'redux-saga/effects';

import { db, storage, timeStampNow } from '../../config/Firebase';
import {
    addPostFailure,
    addPostSuccess,
    deletePostFailure,
    deletePostSuccess,
    getPostsFailure,
    getPostsSuccess
} from '../actions/Posts';
import { eventChannel } from 'redux-saga';

const posts = db.collection('posts');
const orgImagesRef = storage.ref().child('org_images/post_images');

export function* getAllPosts() {
    try {
        const postDocuments = [];
        yield posts
            .orderBy('post_date', 'desc')
            .get()
            .then(snapshot => {
                if (!snapshot.empty)
                    snapshot.forEach(doc => {
                        postDocuments.push({ post_id: doc.id, ...doc.data() });
                    });
            });
        yield put(getPostsSuccess(postDocuments));
    } catch (err) {
        console.error(err);
        yield put(getPostsFailure(err));
    }
}

export function* postsCollectionWatch() {
    let unsubcscribePostsCollectionData;
    const postsCollectionChannel = eventChannel(emit => {
        unsubcscribePostsCollectionData = posts
            .orderBy('post_date', 'desc')
            .onSnapshot(function (docs) {
                const postDocs = [];
                if (!docs.empty) {
                    docs.forEach(doc => {
                        postDocs.push({ post_id: doc.id, ...doc.data() });
                    });
                    emit(postDocs);
                } else {
                    emit([]);
                }
            });
        return unsubcscribePostsCollectionData;
    });
    try {
        while (true) {
            const { userSignOut, postsCollectionData } = yield race({
                userSignOut: take(LOGOUT_USER),
                postsCollectionData: take(postsCollectionChannel)
            });

            if (userSignOut) {
                postsCollectionChannel.close();
            } else {
                yield put(getPostsSuccess(postsCollectionData));
            }
        }
    } catch (error) {
        console.log(error);
    } finally {
        unsubcscribePostsCollectionData();
        
        if (yield cancelled()) {
            postsCollectionChannel.close();
            unsubcscribePostsCollectionData();
        }
    }
}

export function* addNewPost({ payload }) {
    try {
        const { imgFile, values, org_id, callback } = payload;
        const postData = {
            post_date: timeStampNow(),
            post_image: null,
            ...values,
            public: values.public
        };

        if (imgFile) {
            yield uploadPostImage(imgFile, `${org_id}_${postData.post_date}_image`).then(
                downloadURL => {
                    postData.post_image = downloadURL;
                }
            );
        }

        if (values.post_id) {
            yield posts.doc(`${values.post_id}`).set(postData);
            callback();
            yield put(addPostSuccess());
            return;
        }

        yield posts.add(postData);
        callback();
        yield put(addPostSuccess());
    } catch (error) {
        console.log(error);
        yield put(addPostFailure(error));
    }
}

export function* deletePost({ payload }) {
    try {
        const { values, org_id, callback } = payload;
        yield deletePostImage(`${org_id}_${values.post_date}_image`);
        yield posts.doc(`${values.post_id}`).delete();
        callback();
        yield put(deletePostSuccess());
    } catch (error) {
        console.log(error);
        yield put(deletePostFailure(error));
    }
}

export function deletePostImage(img_name) {
    try {
        return orgImagesRef.child(`/${img_name}`).delete();
    } catch (error) {
        console.log(error);
    }
}

export function uploadPostImage(imgFile, img_name) {
    try {
        return orgImagesRef
            .child(`/${img_name}`)
            .putString(imgFile, 'data_url')
            .then(snapshot => {
                return snapshot.ref.getDownloadURL().then(downloadURL => downloadURL);
            });
    } catch (error) {
        console.log(error);
    }
}

export function setPublicStatusTrue(postId) {
    try {
        const postRef = posts.doc(`${postId}`);

        postRef.update({ public: true });
    } catch (error) {
        console.log(error);
    }
}

export function setPublicStatusFalse(postId) {
    try {
        const postRef = posts.doc(`${postId}`);

        postRef.update({ public: false });
    } catch (error) {
        console.log(error);
    }
}

////////////////////////

export function* getPosts() {
    yield takeLatest(GET_POSTS, getAllPosts);
}

export function* addPost() {
    yield takeLatest(ADD_POST, addNewPost);
}

export function* postDelete() {
    yield takeLatest(DELETE_POST, deletePost);
}

export function* watchPosts() {
    yield takeLatest(GET_POSTS, postsCollectionWatch);
}

export default function* rootSaga() {
    yield all([fork(getPosts), fork(watchPosts), fork(addPost), fork(postDelete)]);
}
