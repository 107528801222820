import { UPDATE_ACCESS_LOG } from '../actions/types';

const INITIAL_STATE = {
    accessLog: null
};

const sortLog = log => {
    return log.sort(
        (a, b) => new Date(b.access_time).getTime() - new Date(a.access_time).getTime()
    );
};

const accessLogReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_ACCESS_LOG:
            return {
                ...state,
                accessLog: sortLog(action.payload)
            };

        default:
            return state;
    }
};

export default accessLogReducer;
