import React from 'react';

import { Container, Button, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { errorMessage, nameRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import { connect, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getOrgCollection } from '../../store/actions/Org';
import { validateReservation } from '../../store/actions/Reservations';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const ReservationValidateForm = ({ orgsOptions }) => {
    const dispatch = useDispatch();

    const submit = values => {
        const data = {
            ...values,
            check_in_date: values.check_in_date[0].toDateString(),
            check_out_date: values.check_out_date[0].toDateString()
        };
        dispatch(validateReservation(data));
    };

    const getNextDay = date => date.fp_incr(1);

    useEffect(() => {
        if (!orgsOptions) {
            dispatch(getOrgCollection());
        }
    }, [orgsOptions, dispatch]);

    const validationSchema = Yup.object().shape({
        org_id: Yup.string().trim().required(errorMessage.orgName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        check_in_date: Yup.string().trim().required(errorMessage.fromDate.required),
        check_out_date: Yup.string().trim().required(errorMessage.toDate.required)
    });

    return (
        <Container className="space-2 col-small">
            <Col>
                <Formik
                    initialValues={{
                        org_id: '',
                        last_name: '',
                        check_in_date: '',
                        check_out_date: ''
                    }}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                            <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                                <img
                                    src="../assets/sage-icon.png"
                                    alt="logo"
                                    width={80}
                                />
                                <div className="d-flex w-md-75 flex-column mb-3 align-items-center justify-content-center">
                                    <div
                                        style={{ fontSize: '32px' }}
                                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                                    >
                                        Welcome to SAGE
                                    </div>
                                    <div
                                        style={{ fontSize: '16px' }}
                                        className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal mb-4"
                                    >
                                        The property you will be visiting uses SAGE for
                                        gate and facility access. To make your check-in
                                        more efficient please take a few minutes to
                                        register so that you can receive your access
                                        credentials.
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group mx-4 mx-lg-7">
                                <div className="row">
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <LabeledFormField
                                            dropDownField={true}
                                            dropdownOptions={
                                                orgsOptions &&
                                                orgsOptions
                                                    .filter(
                                                        org => org.rentalManagerEnabled
                                                    )
                                                    .map(org => ({
                                                        label: org.org_name,
                                                        value: org.org_id
                                                    }))
                                            }
                                            fieldTitle="property name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="org_id"
                                            id="org_id"
                                            placeholder="Select..."
                                            aria-label="org_id"
                                            error={returnError(errors, 'org_id')}
                                            value={values.org_id}
                                            onBlur={handleBlur('org_id')}
                                            onChange={name =>
                                                setFieldValue('org_id', name.value)
                                            }
                                            invalid={
                                                returnTouched(touched, 'org_id') &&
                                                !!returnError(errors, 'org_id')
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <LabeledFormField
                                            fieldTitle="last name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="last_name"
                                            placeholder="Last Name"
                                            aria-label="last_name"
                                            error={returnError(errors, 'last_name')}
                                            value={values.last_name}
                                            onBlur={handleBlur('last_name')}
                                            onChange={handleChange('last_name')}
                                            invalid={
                                                returnTouched(touched, 'last_name') &&
                                                !!returnError(errors, 'last_name')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group mx-4 mx-lg-7">
                                <div className="row">
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <LabeledFormField
                                            fieldTitle="check-in date"
                                            required={true}
                                            type="text"
                                            className="form-control rounded"
                                            name="check_in_date"
                                            id="check_in_date"
                                            placeholder="Select..."
                                            aria-label="check_in_date"
                                            error={returnError(errors, 'check_in_date')}
                                            value={values.check_in_date}
                                            selected={values.check_in_date}
                                            onChange={date =>
                                                setFieldValue('check_in_date', date)
                                            }
                                            invalid={
                                                returnTouched(touched, 'check_in_date') &&
                                                !!returnError(errors, 'check_in_date')
                                            }
                                            datePickerField={true}
                                            withDate={true}
                                        />
                                    </div>
                                    <div className="js-form-message col-md-12 col-lg-6 form-group">
                                        <LabeledFormField
                                            fieldTitle="check-out date"
                                            required={true}
                                            type="text"
                                            className="form-control rounded"
                                            name="check_out_date"
                                            id="check_out_date"
                                            placeholder="Select..."
                                            aria-label="check_out_date"
                                            error={returnError(errors, 'check_out_date')}
                                            value={
                                                values.check_out_date ||
                                                (values.check_in_date &&
                                                    setFieldValue('check_out_date', [
                                                        getNextDay(
                                                            values.check_in_date[0]
                                                        )
                                                    ]))
                                            }
                                            selected={
                                                values.check_out_date ||
                                                (values.check_in_date &&
                                                    setFieldValue('check_out_date', [
                                                        getNextDay(
                                                            values.check_in_date[0]
                                                        )
                                                    ]))
                                            }
                                            onChange={date =>
                                                setFieldValue('check_out_date', date)
                                            }
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'check_out_date'
                                                ) &&
                                                !!returnError(errors, 'check_out_date')
                                            }
                                            datePickerField={true}
                                            withDate={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex row justify-content-end align-items-center border-top my-5 mx-2">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="transition-3d-hover mt-5 mr-2 btn"
                                    onClick={handleSubmit}
                                >
                                    Save and continue
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ org }) => {
    return {
        orgsOptions: org.orgs
    };
};

export default connect(mapStateToProps)(ReservationValidateForm);
