import React, { useState, useEffect, useCallback } from 'react';
import ContactsListSearch from './ContactsListSearch';
import { Button } from 'reactstrap';
import CommunityMembersList from './CommunityMembersList';
import ContactModal from './ContactModal';
import Loader from '../common/Loader';
import { connect } from 'react-redux';
import { removingMembers } from '../../store/actions/Members';

const Residents = ({ selectedTab, orgId, communityMembers, removingMembers }) => {
    const [searchString, setSearchsString] = useState('');
    const [open, setOpen] = useState(false);
    const [modalProps, setModalProps] = useState(null);
    const [selectList, setSelectList] = useState([]);
    const [selectAllFlag, setSelectAllFlag] = useState(false);
    const [filteredContacts, setFilteredContacts] = useState([]);

    const toggle = () => {
        if (open) {
            setOpen(false);
            setModalProps(null);
        }
        if (!open) setOpen(true);
    };

    const modalOpen = props => {
        setModalProps(props);
        toggle();
    };

    const selectAll = () => {
        if (selectAllFlag) {
            setSelectList([]);
            setSelectAllFlag(false);
            return;
        }
        filteredContacts && filteredContacts.length
            ? setSelectList(filteredContacts.map(contact => contact.uid))
            : setSelectList(communityMembers.map(member => member.uid));

        setSelectAllFlag(true);
    };

    const addToSelectList = selected => {
        if (selected === null) {
            setSelectList([]);
            return;
        }
        const alreadyAdded = selectList.includes(selected);
        if (alreadyAdded) {
            setSelectList(selectList.filter(el => el !== selected));
        } else setSelectList([...selectList, selected]);
    };

    const handleRemove = () => {
        selectList.forEach(member => {
            removingMembers({ members: [member] });
        });
        setSelectList([]);
        setSelectAllFlag(false);
    };

    const communityMembersSearch = useCallback(
        input => {
            if (!input.trim()) {
                setFilteredContacts([]);
                return;
            }

            const filteredContacts = communityMembers.filter(contact => {
                const { first_name, last_name, email, address } = contact;
                const lowerCaseSearchString = input.trim().toLowerCase();

                if (
                    first_name &&
                    first_name.toLowerCase().includes(lowerCaseSearchString)
                )
                    return true;
                if (last_name && last_name.toLowerCase().includes(lowerCaseSearchString))
                    return true;
                if (email && email.toLowerCase().includes(lowerCaseSearchString))
                    return true;
                if (address && address.toLowerCase().includes(lowerCaseSearchString))
                    return true;

                return false;
            });

            setFilteredContacts(filteredContacts);
        },
        [communityMembers]
    );

    useEffect(() => {
        if (communityMembers && searchString) {
            communityMembersSearch(searchString);
        } else {
            if (communityMembers) {
                setFilteredContacts(communityMembers);
            }
        }
    }, [searchString, setFilteredContacts, communityMembers, communityMembersSearch]);

    if (communityMembers) {
        return (
            <div>
                <div className="my-4 w-100 d-flex justify-content-between">
                    <div className="d-flex justify-content-start w-30">
                        <ContactsListSearch
                            className="contacts-list-search"
                            handleChange={value => {
                                setSearchsString(value);
                                setSelectList([]);
                                if (selectAllFlag) {
                                    selectAll();
                                }
                            }}
                            listLabel={selectedTab}
                        />
                    </div>
                    <div
                        className={
                            selectAllFlag ? 'invite-all badge-green' : 'invite-all'
                        }
                        onClick={selectAll}
                    >
                        <i className="fa fa-check-circle mr-2" aria-hidden="true" />
                        Select All
                    </div>
                </div>
                <CommunityMembersList
                    modalOpen={modalOpen}
                    orgId={orgId}
                    selectHandler={addToSelectList}
                    searchString={searchString}
                    communityMembers={communityMembers.filter(
                        member => member.role === 'resi'
                    )}
                    selected={selectList}
                    filteredContacts={filteredContacts}
                />
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="text-muted">{`${
                        communityMembers?.length ? communityMembers.length : 0
                    } ${
                        communityMembers?.length && communityMembers.length === 1
                            ? 'Resident'
                            : 'Residents'
                    }`}</div>
                    <Button
                        disabled={!selectList.length}
                        color="danger"
                        className="transition-3d-hover m-3 btn-sm"
                        onClick={handleRemove}
                    >
                        {selectList.length > 1 ? `Remove Residents` : 'Remove Resident'}
                    </Button>
                </div>
                <ContactModal
                    isOpen={open}
                    orgId={orgId}
                    toggleOpen={toggle}
                    modalProps={modalProps}
                    withAccess={modalProps && modalProps?.access_key}
                    type={'resident'}
                />
            </div>
        );
    }

    return <Loader />;
};

export default connect(null, { removingMembers })(Residents);
