// React
import React, { useEffect } from 'react';

// Components
import { AddRemovalForm } from '../components';

// Packages
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import { resetRequestAccountRemoval } from '../store/actions/Removal';

const RemovalRequest = ({ resetRequestAccountRemoval }) => {
    useEffect(() => {
        return () => {
            resetRequestAccountRemoval('hard');
        };
    }, [resetRequestAccountRemoval]);

    return (
        <div className="vh-100 bg-dark">
            <AddRemovalForm />
        </div>
    );
};

export default connect(null, { resetRequestAccountRemoval })(RemovalRequest);
