import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { logoutUser } from '../../store/actions/Auth';

const LogoutPrompt = () => {
    const dispatch = useDispatch();
    return (
        <div className="w-75 mx-md-auto pt-8">
            <div className="d-flex align-items-center justify-content-center flex-column">
                <img src="../assets/sage-icon.svg" alt="logo" height={101} width={86} />
                <div className="mb-3">
                    <div
                        style={{ fontSize: '32px' }}
                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold mt-3 mb-8"
                    >
                        Invite is not valid for this account!
                    </div>
                    <div
                        style={{ fontSize: '16px' }}
                        className="d-flex justify-content-center text-muted font-weight-normal mt-5 mb-10"
                    >
                        You must log out of your current account and login to your
                        alternate account to accept this invitation.
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-between mt-3 pt-3 border-top">
                <div style={{ flex: 1 }} />
                <div style={{ flex: 1 }} className="d-flex justify-content-end m-2">
                    <Button
                        color="primary"
                        className="transition-3d-hover mr-5 font-weight-semi-bold"
                        onClick={() => dispatch(logoutUser())}
                    >
                        Logout
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LogoutPrompt;
