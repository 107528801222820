import {
    GET_POSTS,
    GET_POSTS_SUCCESS,
    GET_POSTS_FAILURE,
    ADD_POST,
    ADD_POST_FAILURE,
    ADD_POST_SUCCESS,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    posts: null,
    loadingPosts: false,
    postsLoadError: null,
    addingPost: false,
    postAddingError: null,
    deletingPost: null,
    deletingPostError: null
};

const postReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_POSTS:
            return {
                ...state,
                posts: null,
                loadingPosts: true,
                postsLoadError: null
            };
        case GET_POSTS_SUCCESS:
            return {
                ...state,
                posts: action.payload,
                loadingPosts: false,
                postsLoadError: null
            };
        case GET_POSTS_FAILURE:
            return {
                ...state,
                posts: null,
                loadingPosts: false,
                postsLoadError: action.payload
            };
        case ADD_POST:
            return {
                ...state,
                addingPost: true,
                postAddingError: null
            };
        case ADD_POST_SUCCESS:
            return {
                ...state,
                addingPost: false,
                postAddingError: null
            };
        case ADD_POST_FAILURE:
            return {
                ...state,
                addingPost: false,
                postAddingError: action.payload
            };
        case DELETE_POST:
            return {
                ...state,
                deletingPost: true,
                deletingPostError: null
            };
        case DELETE_POST_SUCCESS:
            return {
                ...state,
                deletingPost: false,
                deletingPostError: null
            };
        case DELETE_POST_FAILURE:
            return {
                ...state,
                deletingPost: false,
                deletingPostError: action.payload
            };

        default:
            return state;
    }
};

export default postReducer;
