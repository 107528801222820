import {
    all,
    fork,
    takeLatest,
    take,
    cancelled,
    race,
    put,
    call
} from 'redux-saga/effects';

import {
    GET_CALLS_COLLECTION,
    LOGOUT_USER,
    FIELD_PANEL_CALL,
    END_PANEL_CALL,
    ALLOW_GATE_ACCESS
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import { db, timeStampNow, rtdb } from '../../config/Firebase';

import {
    storeCallsCollection,
    fieldPanelCallSuccess,
    fieldPanelCallFailure,
    endPanelCallSuccess,
    endPanelCallFailure,
    allowGateAccessSuccess,
    allowGateAccessFailure
} from '../actions/Calls';

import { log } from '../../utils/Loggers';

const calls = db.collection('calls');

////////////// Get User Profile ////////////////

export function* callsCollectionWatch(user) {
    let unsubcscribeCallsCollectionData;
    const callsCollectionChannel = eventChannel(emit => {
        unsubcscribeCallsCollectionData = calls
            .where(`org_id`, '==', user.active_org_id)
            .where(`active_station_roll`, '==', user.active_station_id)
            .where('status', 'in', ['pending', 'active'])
            .onSnapshot(function (querySnapshot) {
                const pending = [];
                const active = [];
                var calls = {};
                if (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.data().status === 'pending') {
                            pending.push(doc.data());
                        } else {
                            active.push(doc.data());
                        }
                    });
                    calls = { active, pending };
                    emit(calls);
                } else {
                    calls = { active, pending };
                    emit(calls);
                }
            });
        return unsubcscribeCallsCollectionData;
    });
    try {
        while (true) {
            const { userSignOut, callsCollectionData } = yield race({
                userSignOut: take(LOGOUT_USER),
                callsCollectionData: take(callsCollectionChannel)
            });

            if (userSignOut) {
                callsCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(storeCallsCollection(callsCollectionData));
            }
        }
    } catch (error) {
        log('Watching Calls: error fetching calls collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubcscribeCallsCollectionData(); // Detach firebase listener
        if (yield cancelled()) {
            callsCollectionChannel.close(); // Detach saga event emitter
            unsubcscribeCallsCollectionData(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Fielding Panel Call ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const fieldingPanelCallRequest = async ({ panelCall, userData }) => {
    const ref = calls.doc(panelCall.id);
    return new Promise((resolve, reject) => {
        ref.update({
            fielded_by: userData.uid,
            field_start: timeStampNow(),
            status: 'active'
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* fieldingPanelCall({ payload }) {
    const { panelCall, userData } = payload;
    const { res, error } = yield call(() =>
        fieldingPanelCallRequest({ panelCall, userData })
    );
    if (res) {
        yield put(fieldPanelCallSuccess());
    } else {
        yield put(fieldPanelCallFailure(error));

        log('Fielding Calls: error updating call data on fielding panel call (FS)', {
            error,
            userData,
            panelCall
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Ending Panel Call ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const endingPanelCallRequest = async ({ panelCall, userData }) => {
    const ref = calls.doc(panelCall.id);
    return new Promise((resolve, reject) => {
        ref.update({
            field_end: timeStampNow(),
            status: 'completed'
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* endingPanelCall({ payload }) {
    const { panelCall, userData } = payload;
    const { res, error } = yield call(() =>
        endingPanelCallRequest({ panelCall, userData })
    );
    if (res) {
        yield put(endPanelCallSuccess());
    } else {
        yield put(endPanelCallFailure(error));
        log('Ending Calls: error updating call data on ending panel call (FS)', {
            error,
            panelCall,
            userData
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////// Allow Gate Access //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const allowingGateAccessRequest = ({
    callData,
    userData,
    register,
    consumerId,
    guest
}) => {
    const { companyName, visitorName } = guest;
    const company_name = companyName && companyName.trim() !== '' ? companyName : null;
    const visitor_name = visitorName && visitorName.trim() !== '' ? visitorName : null;
    const accessLogEntryObj = rtdb.ref(`access_log/success`).push();
    const logId = accessLogEntryObj.key;
    const accessLogRef = rtdb.ref(`access_log/success/${logId}`);

    const orgAccessLogEntryObj = rtdb.ref(`orgs/${callData.org_id}/access_log`).push();
    const orgLogId = orgAccessLogEntryObj.key;
    const orgAccessLogRef = rtdb.ref(`orgs/${callData.org_id}/access_log/${orgLogId}`);

    return new Promise((resolve, reject) => {
        const timeStamp = timeStampNow();
        const jsDate = new Date().toISOString();
        const logData = {
            access_time: jsDate,
            accessed_time_seconds: timeStamp.seconds,
            id: logId,
            org_id: callData.org_id,
            org_log_id: orgLogId,
            panel_id: callData.panel_id,
            guard_access: true,
            new_guest_confirm:
                consumerId === 'txzMjUEqNPaIuU7DNMeGCmA3Rt53' ||
                register ||
                (!register && !company_name && !visitor_name)
                    ? false
                    : true,
            guest: { company_name, visitor_name }
        };

        accessLogRef.set({ ...logData, handled: false }, error => {
            if (error) {
                reject({ error });
            } else {
                orgAccessLogRef.set(
                    {
                        ...logData,
                        company: userData.company_name ? userData.company_name : '',
                        consumer_id: consumerId,
                        creator_id: userData.uid,
                        first_name: userData.first_name,
                        id: orgLogId,
                        last_name: userData.last_name,
                        org_log_id: null,
                        role: userData.role,
                        success: true,
                        system_log_id: logId,
                        guest: { company_name, visitor_name }
                    },
                    error => {
                        if (error) {
                            reject({ error });
                        } else {
                            resolve({ res: true });
                        }
                    }
                );
            }
        });
    });
};

export function* allowingGateAccess({ payload }) {
    const { callData, userData, register, consumerId, guest } = payload;
    const { res, error } = yield call(() =>
        allowingGateAccessRequest({ callData, userData, register, consumerId, guest })
    );
    if (res) {
        yield put(allowGateAccessSuccess());
    } else {
        yield put(allowGateAccessFailure(error));
        log('Allowing Gate Access: error granting access to gate (RTDB)', {
            error,
            callData,
            userData,
            register,
            consumerId
        });
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getCallCollection() {
    yield takeLatest(GET_CALLS_COLLECTION, callsCollectionWatch);
}

export function* fieldPanelCall() {
    yield takeLatest(FIELD_PANEL_CALL, fieldingPanelCall);
}

export function* endPanelCall() {
    yield takeLatest(END_PANEL_CALL, endingPanelCall);
}

export function* allowGateAccess() {
    yield takeLatest(ALLOW_GATE_ACCESS, allowingGateAccess);
}

export default function* rootSaga() {
    yield all([
        fork(getCallCollection),
        fork(fieldPanelCall),
        fork(endPanelCall),
        fork(allowGateAccess)
    ]);
}
