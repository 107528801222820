// React
import React from 'react';

// Packages
import { Button } from 'reactstrap';

// SVG
import successImg from '../../assets/success.svg';

import { Link } from 'react-router-dom';
import { AUTHENTICATED, DASHBOARD } from '../../router/config/routes';

const SuccessScreen = ({
    message,
    addType,
    addAction,
    otherAction,
    otherActionLabel,
    dashboardHidden
}) => {
    return (
        <div className="d-flex flex-column flex-grow-1 w-100 h-100 align-items-center justify-content-center">
            <img style={{ height: '140px', width: '140px' }} src={successImg} alt="" />
            <h2 className="font-weight-semi-bold">Success!</h2>
            {message && <p className="font-weight-semi-bold">{message}</p>}
            <div className="row mx-auto mt-3">
                {addAction && (
                    <Button
                        color="primary"
                        className="transition-3d-hover d-flex align-items-center font-weight-semi-bold m-3 btn btn-sm btn-primary"
                        onClick={addAction}
                    >
                        <i className="fa fa-plus mr-1" /> {addType}
                    </Button>
                )}
                {otherAction && (
                    <Button
                        color="primary"
                        className="transition-3d-hover d-flex align-items-center font-weight-semi-bold m-3 btn btn-sm btn-primary"
                        onClick={otherAction}
                    >
                        {otherActionLabel}
                    </Button>
                )}
                {!dashboardHidden && (
                    <Link
                        className="transition-3d-hover d-flex align-items-center font-weight-semi-bold m-3 btn btn-sm btn-primary"
                        to={`${AUTHENTICATED}${DASHBOARD}`}
                    >
                        <i className="material-icons-round mr-1">dashboard</i> Dashboard
                    </Link>
                )}
            </div>
        </div>
    );
};

export default SuccessScreen;
