// React
import React, { useEffect } from 'react';

// Components
import AppRouter from './router/Router';
// import { Loader } from './components';

// Hooks
import { useMountEffect } from './hooks/useMountEffect';

// Packages
import { connect } from 'react-redux';
import 'dotenv/config.js';
import * as Sentry from '@sentry/react';

// Redux - Actions, Reducers, Sagas
import { checkUserAuth } from './store/actions/Auth';

import { addingMembersFromList } from './store/actions/Managers';

const App = ({
    history,
    match,
    authUser,
    checkUserAuth,
    appInitialized,
    userData,
    addingMembersFromList
}) => {
    useMountEffect(checkUserAuth);
    // useMountEffect(addingMembersFromList);

    // const getUserType = useCallback(() => {
    //     const org = userData.orgs.filter(org => org.id === userData.active_org_id);
    //     const type = org[0].sub_type;
    //     return type;
    // }, [userData]);

    useEffect(() => {
        if (!authUser) {
            Sentry.configureScope(scope => scope.setUser(null));
        } else {
            if (userData) {
                Sentry.configureScope(scope => {
                    scope.setUser({
                        email: userData.email,
                        id: userData.uid,
                        role: userData.role,
                        org_id: userData.primary_org,
                        type: userData.type
                    });
                });
            }
        }
    }, [authUser, userData]);

    if (!appInitialized)
        return (
            <div id="sage-splash-screen">
                <div className="center">
                    <div className="logo">
                        <img
                            src="../assets/logo-dark.png"
                            alt="logo"
                            style={{ maxWidth: '380px' }}
                        />
                        <div className="spinner-border text-primary mt-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    return (
        <AppRouter
            history={history}
            userData={userData}
            userType={userData?.type}
            userRole={userData?.role}
            match={match}
            authUser={authUser}
        />
    );
};

const mapStateToProps = ({ auth, init, user }) => {
    const { authUser } = auth;
    const { appInitialized } = init;
    const { userData } = user;
    return { authUser, appInitialized, userData };
};

export default connect(mapStateToProps, { checkUserAuth, addingMembersFromList })(App);
