// React
import React from 'react';

// Components
import CSVUploader from '../components/ImportCSV/CSVUploader';

// Packages
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

// Router
import {
    AUTHENTICATED,
    DASHBOARD,
    RENTAL_DASHBOARD,
    RENTAL_MANAGER
} from '../router/config/routes';

const ImportMembers = ({ userData }) => {
    const location = useLocation();

    if (!userData) return null;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    if (!location.state) {
        return <Redirect to={`${AUTHENTICATED}${DASHBOARD}`} />;
    }

    const { orgId } = location.state;

    return <CSVUploader orgId={orgId} />;
};

const mapStateToProps = ({ user }) => {
    return {
        userData: user.userData
    };
};

export default connect(mapStateToProps)(ImportMembers);
