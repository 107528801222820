import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { isIOS, isAndroid } from 'react-device-detect';

export default function AppStoreRedirect() {
    useEffect(() => {
        if (isAndroid) {
            window.location.href =
                'https://play.google.com/store/apps/details?id=com.sage_native&pli=1';
        } else if (isIOS) {
            window.location.href =
                'https://apps.apple.com/us/app/sage-systems/id1592739629';
        } else {
            return;
        }
    }, []);

    return (
        <div className="space-3 vh-100 bg-dark">
            <Container className="space-2 col-small">
                <div className="w-md-75 mx-md-auto p-2">
                    <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                        <img src="../assets/sage-icon.png" alt="logo" width={80} />
                        <div className="d-flex flex-column mb-2 align-items-center justify-content-center">
                            <div className="row align-items-center justify-content-around my-5">
                                <div className="col-12 mt-5">
                                    <div>
                                        <a href="https://apps.apple.com/us/app/sage-systems/id1592739629">
                                            <img
                                                src="../assets/apple-store.svg"
                                                alt="Apple Store"
                                                style={{ width: '100%' }}
                                                className="mb-5"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="https://play.google.com/store/apps/details?id=com.sage_native&pli=1">
                                            <img
                                                src="../assets/google-play.svg"
                                                alt="Google Play"
                                                style={{ width: '100%' }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
