import React from 'react';

export default function MobileEULA() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12" style={{ fontSize: '.80rem' }}>
                    <div className="my-5">
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ fontSize: '28px' }}
                        >
                            End User License Agreement
                        </div>
                        <div className="mb-3">Last modified: January 9th, 2023</div>
                        This Mobile Application End User License Agreement (
                        <span style={{ fontWeight: 'bold' }}>”Agreement”</span>) is a
                        binding agreement between you (
                        <span style={{ fontWeight: 'bold' }}>”End User”</span> or{' '}
                        <span style={{ fontWeight: 'bold' }}>“you”</span>) and Ramco
                        Protective of Orlando Inc. (”
                        <span style={{ fontWeight: 'bold' }}>”Company”</span>”). This
                        Agreement governs your use of the SAGE mobile application,
                        (including all related documentation, the{' '}
                        <span style={{ fontWeight: 'bold' }}>“Application”</span>). The
                        Application is licensed, not sold, to you.
                        <div className="my-3">
                            BY CLICKING THE “AGREE” BUTTON, IF APPLICABLE, OR OTHERWISE
                            DOWNLOADING, INSTALLING, OR USING THE APPLICATION, YOU (A)
                            ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT;
                            (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A
                            BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE
                            THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE
                            TO THESE TERMS, DO NOT DOWNLOAD, INSTALL, OR USE THE
                            APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.
                        </div>
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            1. License Grant
                        </div>
                        Subject to the terms of this Agreement, Company grants you a
                        limited, non-exclusive, and nontransferable license to:
                        <ol className="my-3" type="a">
                            <li>
                                download, install, and use the Application for your
                                personal, non-commercial use on a single mobile device
                                owned or otherwise controlled by you (
                                <span style={{ fontWeight: 'bold' }}>
                                    ”Mobile Device”
                                </span>
                                ) strictly in accordance with the Application’s
                                documentation; and
                            </li>
                            <li>
                                access, stream, download, and use on such Mobile Device
                                the Content and Services (as defined in Section 5) made
                                available in or otherwise accessible through the
                                Application, strictly in accordance with this Agreement
                                and the Terms of Use applicable to such Content and
                                Services as set forth in Section 5.
                            </li>
                        </ol>
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            2. License Restrictions
                        </div>
                        You shall not:
                        <ol className="my-3" type="a">
                            <li>
                                copy the Application, except as expressly permitted by
                                this license;
                            </li>
                            <li>
                                modify, translate, adapt, or otherwise create derivative
                                works or improvements, whether or not patentable, of the
                                Application;
                            </li>
                            <li>
                                reverse engineer, disassemble, decompile, decode, or
                                otherwise attempt to derive or gain access to the source
                                code of the Application or any part thereof;
                            </li>
                            <li>
                                remove, delete, alter, or obscure any trademarks or any
                                copyright, trademark, patent, or other intellectual
                                property or proprietary rights notices from the
                                Application, including any copy thereof;
                            </li>
                            <li>
                                rent, lease, lend, sell, sublicense, assign, distribute,
                                publish, transfer, or otherwise make available the
                                Application, or any features or functionality of the
                                Application, to any third party for any reason, including
                                by making the Application available on a network where it
                                is capable of being accessed by more than one device at
                                any time;
                            </li>
                            <li>
                                remove, disable, circumvent, or otherwise create or
                                implement any workaround to any copy protection, rights
                                management, or security features in or protecting the
                                Application; or
                            </li>
                            <li>
                                use the Application in, or in association with, the
                                design, construction, maintenance, or operation of any
                                hazardous environments or systems, including any power
                                generation systems; aircraft navigation or communication
                                systems, air traffic control systems, or any other
                                transport management systems; safety-critical
                                applications, including medical or life-support systems,
                                vehicle operation applications or any police, fire, or
                                other safety response systems; and military or aerospace
                                applications, weapons systems, or environments.
                            </li>
                        </ol>
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            3. Reservation of Rights
                        </div>
                        You acknowledge and agree that the Application is provided under
                        license, and not sold, to you. You do not acquire any ownership
                        interest in the Application under this Agreement, or any other
                        rights thereto other than to use the Application in accordance
                        with the license granted, and subject to all terms, conditions,
                        and restrictions, under this Agreement. Company and its licensors
                        and service providers, if any, reserve and shall retain their
                        entire right, title, and interest in and to the Application,
                        including all copyrights, trademarks, and other intellectual
                        property rights therein or relating thereto, except as expressly
                        granted to you in this Agreement.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            4. Collection and Use of Your Information
                        </div>
                        You acknowledge that when you download, install, or use the
                        Application, Company may use automatic means (including, for
                        example, cookies and web beacons) to collect information about
                        your Mobile Device and about your use of the Application. You also
                        may be required to provide certain information about yourself as a
                        condition to downloading, installing, or using the Application or
                        certain of its features or functionality, and the Application may
                        provide you with opportunities to share information about yourself
                        with others. All information we collect through or in connection
                        with this Application is subject to our{' '}
                        <a href="https://sagesystems.io/mobile-app-privacy">
                            PRIVACY POLICY
                        </a>
                        . By downloading, installing, using, and providing information to
                        or through this Application, you consent to all actions taken by
                        us with respect to your information in compliance with the Privacy
                        Policy.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            5. Content and Services
                        </div>
                        The Application may provide you with access to Company’s website
                        located at{' '}
                        <a href="https://sagesystems.io/">https://sagesystems.io</a> (the
                        “<span style={{ fontWeight: 'bold' }}>Website</span>”) and
                        products and services accessible thereon, including, but not
                        limited, to certain gate and security access control systems, and
                        certain features, functionality, and content accessible on or
                        through the Application may be hosted on the Website
                        (collectively, “
                        <span style={{ fontWeight: 'bold' }}>Content and Services</span>
                        ”). Your access to and use of such Content and Services are
                        governed by Website’s Terms of Use and Privacy Policy located at{' '}
                        <a href="https://sagesystems.io/terms">TERMS OF USE</a> and{' '}
                        <a href="https://sagesystems.io/privacy">PRIVACY POLICY</a>, which
                        are incorporated herein by this reference. Your access to and use
                        of such Content and Services may require you to acknowledge your
                        acceptance of such Terms of Use and Privacy Policy and/or to
                        register with the Website, and your failure to do so may restrict
                        you from accessing or using certain of the Application’s features
                        and functionality. Any violation of such Terms of Use will also be
                        deemed a violation of this Agreement.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            6. Geographic Restrictions
                        </div>
                        The Content and Services are based in the state of Florida in the
                        United States and provided for access and use only by persons
                        located in the United States. You acknowledge that you may not be
                        able to access all or some of the Content and Services outside of
                        the United States and that access thereto may not be legal by
                        certain persons or in certain countries. If you access the Content
                        and Services from outside the United States, you are responsible
                        for compliance with local laws.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            7. Updates
                        </div>
                        Company may from time to time in its sole discretion develop and
                        provide Application updates, which may include upgrades, bug
                        fixes, patches, other error corrections, and/or new features
                        (collectively, including related documentation, “Updates”).
                        Updates may also modify or delete in their entirety certain
                        features and functionality. You agree that Company has no
                        obligation to provide any Updates or to continue to provide or
                        enable any particular features or functionality. Based on your
                        Mobile Device settings, when your Mobile Device is connected to
                        the internet either:
                        <ol className="my-3" type="a">
                            <li>
                                the Application will automatically download and install
                                all available Updates; or
                            </li>
                            <li>
                                you may receive notice of or be prompted to download and
                                install available Updates.
                            </li>
                        </ol>
                        You shall promptly download and install all Updates and
                        acknowledge and agree that the Application or portions thereof may
                        not properly operate should you fail to do so. You further agree
                        that all Updates will be deemed part of the Application and be
                        subject to all terms and conditions of this Agreement.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            8. Third-Party Materials
                        </div>
                        The Application may display, include, integrate, or make available
                        third-party content (including data, information, applications,
                        and other products, services, and/or materials) or provide links
                        to third-party websites or services, including through third-party
                        advertising (”
                        <span style={{ fontWeight: 'bold' }}>Third-Party Materials</span>
                        ”). You acknowledge and agree that Company is not responsible for
                        Third-Party Materials, including their accuracy, completeness,
                        timeliness, validity, copyright compliance, legality, decency,
                        quality, or any other aspect thereof. Company does not assume and
                        will not have any liability or responsibility to you or any other
                        person or entity for any Third-Party Materials. Third-Party
                        Materials and links thereto are provided solely as a convenience
                        to you, and you access and use them entirely at your own risk and
                        subject to such third parties’ terms and conditions.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            9. Term and Termination
                        </div>
                        <ol className="my-3" type="a">
                            <li>
                                The term of Agreement commences when you download the
                                Application and will continue in effect until terminated
                                by you or Company as set forth in this Section 9.
                            </li>
                            <li>
                                You may terminate this Agreement by deleting the
                                Application and all copies thereof from your Mobile
                                Device.
                            </li>
                            <li>
                                Company may terminate this Agreement at any time without
                                notice if it ceases to support the Application, which
                                Company may do in its sole discretion or any payments
                                required for use of the Software have not been received by
                                Company. In addition, this Agreement will terminate
                                immediately and automatically without any notice if you
                                violate any of the terms and conditions of this Agreement.
                            </li>
                            <li>
                                Upon termination:
                                <ol type="i">
                                    <li>
                                        all rights granted to you under this Agreement
                                        will also terminate; and
                                    </li>
                                    <li>
                                        you must cease all use of the Application and
                                        delete all copies of the Application from your
                                        Mobile Device and account.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Termination will not limit any of Company’s rights or
                                remedies at law or in equity.
                            </li>
                        </ol>
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            10. Disclaimer of Warranties
                        </div>
                        THE APPLICATION IS PROVIDED TO END USER “AS IS” AND WITH ALL
                        FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM
                        EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF
                        AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE
                        LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL
                        WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE,
                        WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES
                        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                        NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
                        DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
                        LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR
                        UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
                        APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
                        RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
                        APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION,
                        MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE,
                        OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                        <br />
                        <br />
                        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
                        IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY
                        RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
                        LIMITATIONS MAY NOT APPLY TO YOU.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            11. Limitation of Liability
                        </div>
                        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                        WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE
                        LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR
                        RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE
                        CONTENT AND SERVICES FOR:
                        <ol className="my-3" type="a">
                            <li>
                                PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF
                                SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF
                                GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR
                                MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL,
                                INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.
                            </li>
                            <li>
                                DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE
                                AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION.
                            </li>
                        </ol>
                        THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE
                        OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                        OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE
                        OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
                        JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO
                        SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO
                        YOU.‌
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            12. Indemnification
                        </div>
                        You agree to indemnify, defend, and hold harmless Company and its
                        officers, directors, employees, agents, affiliates, successors,
                        and assigns from and against any and all losses, damages,
                        liabilities, deficiencies, claims, actions, judgments,
                        settlements, interest, awards, penalties, fines, costs, or
                        expenses of whatever kind, including attorneys’ fees, arising from
                        or relating to your use or misuse of the Application or your
                        breach of this Agreement, including but not limited to the content
                        you submit or make available through this Application.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            13. Export Regulation
                        </div>
                        The Application may be subject to US export control laws,
                        including the Export Control Reform Act and its associated
                        regulations. You shall not, directly or indirectly, export,
                        re-export, or release the Application to, or make the Application
                        accessible from, any jurisdiction or country to which export,
                        re-export, or release is prohibited by law, rule, or regulation.
                        You shall comply with all applicable federal laws, regulations,
                        and rules, and complete all required undertakings (including
                        obtaining any necessary export license or other governmental
                        approval), prior to exporting, re-exporting, releasing, or
                        otherwise making the Application available outside the US.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            14. US Government Rights
                        </div>
                        The Application is commercial computer software, as such term is
                        defined in 48 C.F.R. §2.101. Accordingly, if you are an agency of
                        the US Government or any contractor therefor, you receive only
                        those rights with respect to the Application as are granted to all
                        other end users under license, in accordance with (a) 48 C.F.R.
                        §227.7201 through 48 C.F.R. §227.7204, with respect to the
                        Department of Defense and their contractors, or (b) 48 C.F.R.
                        §12.212, with respect to all other US Government licensees and
                        their contractors.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            15. Severability
                        </div>
                        If any provision of this Agreement is illegal or unenforceable
                        under applicable law, the remainder of the provision will be
                        amended to achieve as closely as possible the effect of the
                        original term and all other provisions of this Agreement will
                        continue in full force and effect.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            16. Governing Law
                        </div>
                        This Agreement is governed by and construed in accordance with the
                        internal laws of the State of Florida without giving effect to any
                        choice or conflict of law provision or rule. Any legal suit,
                        action, or proceeding arising out of or related to this Agreement
                        or the Application shall be instituted exclusively in the federal
                        courts of the United States or the courts of the State of Florida
                        in each case located in Boca Raton and Palm Beach County. You
                        waive any and all objections to the exercise of jurisdiction over
                        you by such courts and to venue in such courts.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            17. Limitation of Time to File Claims
                        </div>
                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                        RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED
                        WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE
                        SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            18. Entire Agreement
                        </div>
                        This Agreement, the Software as a Service Agreement, our Terms of
                        Use, and our Privacy Policy constitute the entire agreement
                        between you and Company with respect to the Application and
                        supersede all prior or contemporaneous understandings and
                        agreements, whether written or oral, with respect to the
                        Application.‌
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '16px' }}
                        >
                            19. Waiver
                        </div>
                        No failure to exercise, and no delay in exercising, on the part of
                        either party, any right or any power hereunder shall operate as a
                        waiver thereof, nor shall any single or partial exercise of any
                        right or power hereunder preclude further exercise of that or any
                        other right hereunder. In the event of a conflict between this
                        Agreement and any applicable purchase or other terms, the terms of
                        this Agreement shall govern.
                    </div>
                </div>
            </div>
        </div>
    );
}
