import React from 'react';
import { Button } from 'reactstrap';

export default function RentalManagerFooter({
    active,
    submitFunc,
    cancel,
    cancelReserv,
    reinstate
}) {
    return (
        <nav
            style={{ height: '100px' }}
            className="d-flex flex-md-row mt-auto align-items-center flex-grow-1 p-1 bg-white fixed-bottom border-top justify-content-around z-10"
        >
            <div className="d-flex justify-content-around">
                {active ? (
                    <Button
                        type="button"
                        className="transition-3d-hover cancel-button m-3 font-weight-semi-bold btn-wide"
                        onClick={cancelReserv}
                    >
                        Cancel Reservation
                    </Button>
                ) : (
                    <Button
                        type="button"
                        color="primary"
                        className="transition-3d-hover m-3 font-weight-semi-bold btn-wide"
                        onClick={reinstate}
                    >
                        Reinstate
                    </Button>
                )}

                <Button
                    type="button"
                    className="transition-3d-hover cancel-button m-3 font-weight-semi-bold btn-wide"
                    onClick={cancel}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    className="transition-3d-hover m-3 font-weight-semi-bold btn-wide"
                    onClick={submitFunc}
                >
                    Save
                </Button>
            </div>
        </nav>
    );
}
