import React from 'react';
import { Container, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp, streetAddressRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import ConfirmFooter from '../common/ConfirmFooter';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../common/Loader';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';
import AddMemberSuccess from './AddMemberSuccess';
import { Button } from 'reactstrap';
import {
    checkingExistingMember,
    addingNewMember,
    resettingNewMember
} from '../../store/actions/Members';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const AddMemberForm = ({
    type,
    loading,
    error,
    member,
    found,
    checkingExistingMember,
    addingNewMember,
    resettingNewMember,
    members,
    success
}) => {
    const history = useHistory();
    const submit = values => {
        const phone = values.phone
            ? {
                  number: Number.parseInt(
                      values.phone.replace(/[^0-9]/g, '').length > 10
                          ? values.phone
                                .replace(/[^0-9]/g, '')
                                .substring(
                                    values.phone.replace(/[^0-9]/g, '').length - 10
                                )
                          : values.phone.replace(/[^0-9]/g, '')
                  ),
                  code: Number.parseInt(values.code),
                  country: values.country
              }
            : null;
        const phone_number = values.phone
            ? values.phone.replace(/[^0-9]/g, '').length > 10
                ? `${values.code}${values.phone
                      .replace(/[^0-9]/g, '')
                      .substring(values.phone.replace(/[^0-9]/g, '').length - 10)}`
                : `${values.code}${values.phone.replace(/[^0-9]/g, '')}`
            : null;
        const address =
            type === 'resi'
                ? `${
                      values.address_2.trim() !== ''
                          ? `${values.address_1.trim()}, ${values.address_2.trim()}`
                          : values.address_1.trim()
                  }`
                : null;
        const email =
            values.email.trim() === '' ? null : values.email.toLowerCase().trim();

        checkingExistingMember({
            member: {
                ...values,
                first_name: values.first_name.toLowerCase().trim(),
                last_name: values.last_name.toLowerCase().trim(),
                address_1: values.address_1.trim(),
                address_2: values?.address_2 ? values.address_2.trim() : '',
                address,
                email,
                phone,
                phone_number,
                type
            }
        });
    };

    const initialValues =
        type === 'resi'
            ? {
                  first_name: member?.first_name.trim() || '',
                  last_name: member?.last_name?.trim() || '',
                  address_1: member?.address_1?.trim() || '',
                  address_2: member?.address_2?.trim() || '',
                  email: member?.email?.trim() || '',
                  phone: member?.phone?.number ? `${member.phone.number}` : '',
                  code: member?.phone?.code ? `${member.phone.code}` : '1',
                  country: member?.phone?.country ? `${member.phone.country}` : 'US'
              }
            : {
                  first_name: member?.first_name?.trim() || '',
                  last_name: member?.last_name?.trim() || '',
                  company_name: member?.company_name?.trim() || '',
                  email: member?.email?.trim() || '',
                  phone: member?.phone?.number ? `${member.phone.number}` : '',
                  code: member?.phone?.code ? `${member.phone.code}` : '1',
                  country: member?.phone?.country ? `${member.phone.country}` : 'US'
              };

    const validationSchema =
        type === 'resi'
            ? Yup.object().shape(
                  {
                      first_name: Yup.string()
                          .trim()
                          .matches(nameRegExp.format, errorMessage.firstName.valid)
                          .required(errorMessage.firstName.required),
                      last_name: Yup.string()
                          .trim()
                          .matches(nameRegExp.format, errorMessage.lastName.valid)
                          .required(errorMessage.lastName.required),
                      email: Yup.string()
                          .trim()
                          .lowercase()
                          .email(errorMessage.email.valid)
                          .when('phone', {
                              is: phone => !phone || phone.length === 0,
                              then: Yup.string().required(
                                  errorMessage.emailOrPhone.required
                              ),
                              otherwise: Yup.string()
                          }),
                      address_1: Yup.string()
                          .trim()
                          .matches(streetAddressRegExp, errorMessage.address.valid)
                          .required(errorMessage.address.required),
                      address_2: Yup.string().trim(),
                      phone: Yup.string()
                          .trim()
                          .when('email', {
                              is: email => !email || email.length === 0,
                              then: Yup.string().required(
                                  errorMessage.phoneOrEmail.required
                              ),
                              otherwise: Yup.string().trim()
                          }),
                      country: Yup.string().trim(),
                      code: Yup.string().trim()
                  },
                  [
                      ['phone', 'email'],
                      ['email', 'phone']
                  ]
              )
            : Yup.object().shape(
                  {
                      first_name: Yup.string()
                          .trim()
                          .matches(nameRegExp.format, errorMessage.firstName.valid)
                          .required(errorMessage.firstName.required),
                      last_name: Yup.string()
                          .trim()
                          .matches(nameRegExp.format, errorMessage.lastName.valid)
                          .required(errorMessage.lastName.required),
                      company_name: Yup.string()
                          .trim()
                          .required(errorMessage.companyName.required),
                      email: Yup.string()
                          .trim()
                          .lowercase()
                          .email(errorMessage.email.valid)
                          .when('phone', {
                              is: phone => !phone || phone.length === 0,
                              then: Yup.string().required(
                                  errorMessage.emailOrPhone.required
                              ),
                              otherwise: Yup.string()
                          }),
                      phone: Yup.string()
                          .trim()
                          .when('email', {
                              is: email => !email || email.length === 0,
                              then: Yup.string().required(
                                  errorMessage.phoneOrEmail.required
                              ),

                              otherwise: Yup.string().trim()
                          }),
                      country: Yup.string().trim(),
                      code: Yup.string().trim()
                  },
                  [
                      ['phone', 'email'],
                      ['email', 'phone']
                  ]
              );

    const validatePhoneEmail = ({ phone, code, country, email, company_name }) => {
        const checkExistingEmail = email => {
            if (
                members &&
                members.email &&
                members.email.length &&
                email.trim() !== '' &&
                members.email.indexOf(email.trim().toLowerCase()) > -1
            ) {
                return false;
            } else {
                return true;
            }
        };
        const checkExistingPhone = phone => {
            if (
                members &&
                members.phone &&
                phone &&
                phone.trim() !== '' &&
                members.phone.indexOf(phone.trim()) > -1
            ) {
                return false;
            } else {
                return true;
            }
        };
        const phoneSchema = Yup.string();

        const isPhoneValid = phone ? phoneSchema.isValidSync(code + phone) : true;
        const phoneIsUnique = phone ? checkExistingPhone(phone) : true;
        const emailIsUnique = email ? checkExistingEmail(email) : true;

        if (!emailIsUnique) {
            return {
                email: emailIsUnique ? undefined : `Member email already exixsts.`
            };
        } else if (!isPhoneValid) {
            return {
                phone: isPhoneValid
                    ? undefined
                    : 'Mobile phone needs to be a valid number'
            };
        } else if (!phoneIsUnique) {
            return {
                phone: phoneIsUnique ? undefined : 'Member phone already exists'
            };
        }
    };

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (success) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <AddMemberSuccess type={type} found={found} />
            </div>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                    validate={validatePhoneEmail}
                    validationSchema={validationSchema}
                    validateOnChange
                    enableReinitialize
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <>
                            {!found ? (
                                <Form className="w-md-70 mx-md-auto">
                                    <div className="mb-4">
                                        <h2 className="h5 font-weight-normal mb-0">
                                            <span className="d-flex justify-content-start h3 font-weight-semi-bold">
                                                {` New ${
                                                    type === 'resi'
                                                        ? 'Resident'
                                                        : 'Vendor'
                                                }`}
                                            </span>
                                        </h2>
                                    </div>
                                    <div className="js-form-message form-group">
                                        <div className="d-flex flex-row justify-content-start">
                                            <div
                                                style={{ flex: 1 }}
                                                className="js-form-message col-6 d-flex form-group"
                                            >
                                                <LabeledFormField
                                                    fieldTitle="first name"
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    name="first_name"
                                                    id="first_name"
                                                    // placeholder="First Name"
                                                    aria-label="first_name"
                                                    error={returnError(
                                                        errors,
                                                        'first_name'
                                                    )}
                                                    value={values.first_name}
                                                    onBlur={handleBlur('first_name')}
                                                    onChange={handleChange('first_name')}
                                                    invalid={
                                                        returnTouched(
                                                            touched,
                                                            'first_name'
                                                        ) &&
                                                        !!returnError(
                                                            errors,
                                                            'first_name'
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{ flex: 1 }}
                                                className="js-form-message col-6 d-flex form-group"
                                            >
                                                <LabeledFormField
                                                    fieldTitle="last name"
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    name="last_name"
                                                    id="last_name"
                                                    // placeholder="Last Name"
                                                    aria-label="last_name"
                                                    error={returnError(
                                                        errors,
                                                        'last_name'
                                                    )}
                                                    value={values.last_name}
                                                    onBlur={handleBlur('last_name')}
                                                    onChange={handleChange('last_name')}
                                                    invalid={
                                                        returnTouched(
                                                            touched,
                                                            'last_name'
                                                        ) &&
                                                        !!returnError(errors, 'last_name')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {type === 'vendor' ? (
                                        <div className="js-form-message form-group">
                                            <div className="d-flex flex-row justify-content-start">
                                                <div
                                                    style={{ flex: 1 }}
                                                    className="js-form-message col-6 d-flex form-group"
                                                >
                                                    <LabeledFormField
                                                        fieldTitle="company name"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        name="company_name"
                                                        id="company_name"
                                                        // placeholder="First Name"
                                                        aria-label="company_name"
                                                        error={returnError(
                                                            errors,
                                                            'company_name'
                                                        )}
                                                        value={values.company_name}
                                                        onBlur={handleBlur(
                                                            'company_name'
                                                        )}
                                                        onChange={handleChange(
                                                            'company_name'
                                                        )}
                                                        invalid={
                                                            returnTouched(
                                                                touched,
                                                                'company_name'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'company_name'
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="js-form-message form-group">
                                            <div className="d-flex flex-row justify-content-start">
                                                <div
                                                    style={{ flex: 3 }}
                                                    className="js-form-message col-8 d-flex form-group"
                                                >
                                                    <LabeledFormField
                                                        fieldTitle="Street address"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        name="address_1"
                                                        id="address_1"
                                                        // placeholder="Street address"
                                                        aria-label="address_1"
                                                        error={returnError(
                                                            errors,
                                                            'address_1'
                                                        )}
                                                        value={values.address_1}
                                                        onBlur={handleBlur('address_1')}
                                                        onChange={handleChange(
                                                            'address_1'
                                                        )}
                                                        invalid={
                                                            returnTouched(
                                                                touched,
                                                                'address_1'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'address_1'
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{ flex: 1 }}
                                                    className="js-form-message col-4 d-flex form-group"
                                                >
                                                    <LabeledFormField
                                                        fieldTitle="Unit"
                                                        type="text"
                                                        className="form-control"
                                                        name="address_2"
                                                        id="address_2"
                                                        // placeholder="Unit"
                                                        aria-label="address_2"
                                                        error={returnError(
                                                            errors,
                                                            'address_2'
                                                        )}
                                                        value={values.address_2}
                                                        onBlur={handleBlur('address_2')}
                                                        onChange={handleChange(
                                                            'address_2'
                                                        )}
                                                        invalid={
                                                            returnTouched(
                                                                touched,
                                                                'address_2'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'address_2'
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="js-form-message form-group">
                                        <div className="d-flex flex-row justify-content-start">
                                            <div
                                                style={{ flex: 1 }}
                                                className="js-form-message col-6 d-flex form-group"
                                            >
                                                <ContryPhoneCodeSelect
                                                    fieldTitle="Mobile phone"
                                                    type="text"
                                                    className="form-control"
                                                    name="phone"
                                                    id="phone"
                                                    aria-label="phone"
                                                    phoneError={returnError(
                                                        errors,
                                                        'phone'
                                                    )}
                                                    phoneTouched={returnTouched(
                                                        touched,
                                                        'phone'
                                                    )}
                                                    codeValue={values?.code}
                                                    phoneValue={values?.phone}
                                                    countryValue={values?.country}
                                                    onBlurPhone={handleBlur('phone')}
                                                    onChangePhone={e => {
                                                        handleChange('phone')(e);
                                                    }}
                                                    onBlurCode={handleBlur('code')}
                                                    onChangeCode={value =>
                                                        setFieldValue('code', value)
                                                    }
                                                    onChangeCountry={value =>
                                                        setFieldValue('country', value)
                                                    }
                                                    invalid={
                                                        returnTouched(touched, 'phone') &&
                                                        !!returnError(errors, 'phone')
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{ flex: 1 }}
                                                className="js-form-message col-6 d-flex form-group"
                                            >
                                                <LabeledFormField
                                                    fieldTitle="email"
                                                    type="text"
                                                    className="form-control"
                                                    name="email"
                                                    id="email"
                                                    // placeholder="Email"
                                                    aria-label="email"
                                                    error={returnError(errors, 'email')}
                                                    value={values.email}
                                                    onBlur={handleBlur('email')}
                                                    onChange={handleChange('email')}
                                                    invalid={
                                                        returnTouched(touched, 'email') &&
                                                        !!returnError(errors, 'email')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex row justify-content-center align-items-center my-5">
                                        <ConfirmFooter
                                            cancel={() => history.goBack()}
                                            submitFunc={handleSubmit}
                                            submitText="Save"
                                        />
                                    </div>
                                </Form>
                            ) : (
                                <div className="col w-md-70 mx-md-auto justify-content-center align-items-center">
                                    <div className="mb-4">
                                        <h2 className="h5 font-weight-normal mb-0">
                                            <span className="d-flex justify-content-start h4 font-weight-semi-bold">
                                                {`Found Existing Sage ${
                                                    found && found?.length > 1
                                                        ? 'Members'
                                                        : 'Member'
                                                }`}
                                            </span>
                                        </h2>
                                    </div>

                                    <div className="col m-auto justify-content-start mb-4">
                                        {found.map(user => (
                                            <div key={user.uid}>
                                                <span className="d-block h5 mb-0">{`name: ${user?.first_name} ${user?.last_name}`}</span>
                                                <span className="d-block h5 mb-0">{`email: ${user?.email}`}</span>
                                                <span className="d-block h5 mb-0">{`phone: ${user?.phone?.number}`}</span>
                                                {type === 'vendor' && (
                                                    <span className="d-block h5 mb-0">{`company name: ${values?.company_name}`}</span>
                                                )}
                                            </div>
                                        ))}
                                        <div className="row align-items-center, justify-content-start">
                                            <Button
                                                className="transition-3d-hover cancel-button m-3 font-weight-semi-bold btn-wide"
                                                onClick={() =>
                                                    resettingNewMember('found')
                                                }
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="transition-3d-hover m-3 font-weight-semi-bold btn-wide"
                                                onClick={() =>
                                                    addingNewMember({
                                                        member,
                                                        exist: found
                                                    })
                                                }
                                            >
                                                {`Add as new ${
                                                    type === 'resi'
                                                        ? 'Resident'
                                                        : 'Vendor'
                                                }`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ members }) => {
    const { loading, error, member, found, success } = members;
    return {
        loading,
        error,
        member,
        found,
        success
    };
};

export default connect(mapStateToProps, {
    checkingExistingMember,
    addingNewMember,
    resettingNewMember
})(AddMemberForm);
