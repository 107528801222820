import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { Button } from 'reactstrap';
import templateCSV from '../../assets/CSV_template.csv';
import Papa from 'papaparse';
import ImportReport from './ImportReport';
import { connect, useDispatch } from 'react-redux';
import { importMembers } from '../../store/actions/Managers';
import ImportError from './ImportError';
import Loader from '../common/Loader';

const CSVUploader = ({ orgId, importing, success, error }) => {
    const [csvData, setCsvData] = useState(null);
    const dispatch = useDispatch();

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                const { data } = Papa.parse(reader.result);
                const nonEmptyRows = data.filter(row => {
                    if (!row.length || (row.length === 1 && !row[0])) return 0;
                    return row;
                });

                setCsvData(nonEmptyRows);
            };
            reader.readAsText(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const importFile = () => dispatch(importMembers({ members: csvData, orgId }));

    if (importing) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (success) {
        return <ImportReport orgId={orgId} report={success} />;
    }

    if (error) {
        return <ImportError orgId={orgId} error={error} />;
    }

    return (
        <div className="container">
            <div className="row pt-5">
                <div className="col-12 m-auto">
                    <h3 className="font-weight-bold">Import Data</h3>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12 m-auto">
                    <div className="dropzone" {...getRootProps()}>
                        <input
                            style={{ height: '100%', width: '100%' }}
                            {...getInputProps()}
                        />
                        {!csvData && (
                            <h5>
                                <span className="import-file">Upload</span> or drop a CSV
                                file to import.
                            </h5>
                        )}
                        {csvData && (
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <i className="fa fa-file fa-3x text-green m-5" />
                                <h5>File selected. Press import to continue.</h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-12 m-auto">
                    <a href={templateCSV} download="CSV_template">
                        Download the CSV template here.
                    </a>
                </div>
            </div>
            <div className="row pt-8">
                <div className="col-6 m-auto justify-content-center text-center">
                    Important: each data row must include{' '}
                    <span className="font-weight-semi-bold">organization name</span>,{' '}
                    <span className="font-weight-semi-bold">address</span> and either an{' '}
                    <span className="font-weight-semi-bold">email address</span> or a{' '}
                    <span className="font-weight-semi-bold">mobile phone number</span>. If
                    any of these items are not present the row will not be imported.
                    <br />
                    <br />A detailed report will be displayed once the import is complete.
                </div>
            </div>
            <div className="row pt-10">
                <div className="col-12 m-auto d-flex justify-content-center">
                    <Button
                        disabled={!csvData}
                        type="button"
                        color="primary"
                        className="btn btn-wide transition-3d-hover"
                        onClick={importFile}
                    >
                        Import
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ manager }) => {
    return {
        importing: manager.importingMembers,
        success: manager.importMembersSuccess,
        error: manager.importMembersError
    };
};

export default connect(mapStateToProps)(CSVUploader);
