import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useWindowSize } from '../../hooks/useWindowSize';
import * as routes from '../../router/config/routes';
import NumberFormat from 'react-number-format';
import InfiniteScroll from 'react-infinite-scroll-component';

const OrgsList = ({ orgs, searchString }) => {
    const { windowHeight } = useWindowSize();
    const [visibleOrgs, setVisibleOrgs] = useState(orgs);

    const orgsInputSearch = useCallback(
        input => {
            if (!input.trim()) {
                setVisibleOrgs([]);
                return;
            }

            const filteredName = orgs.filter(org => {
                const orgName = org.org_name ? org.org_name.toLowerCase() : '';
                let managerName = '';

                if (org.contact_name) {
                    const contactName = org.contact_name
                        ? org.contact_name.toLowerCase()
                        : '';

                    managerName = contactName;
                }

                if (
                    orgName.includes(input.toLowerCase()) ||
                    managerName.includes(input.toLowerCase())
                ) {
                    return true;
                }

                return false;
            });

            setVisibleOrgs(filteredName);
        },
        [orgs]
    );

    useEffect(() => {
        if (orgs && searchString) {
            orgsInputSearch(searchString);
        } else {
            if (orgs) {
                setVisibleOrgs(orgs);
            }
        }
    }, [searchString, orgs, setVisibleOrgs, orgsInputSearch]);

    if (!visibleOrgs) return null;
    return (
        <div style={{ height: [windowHeight] - 470, overflowY: 'auto' }}>
            <InfiniteScroll dataLength={visibleOrgs.length} loader={<h4>Loading...</h4>}>
                {visibleOrgs.map(
                    ({ org_name, contact_name, phone, org_id, devices, status }) => (
                        <Link
                            key={org_id}
                            to={{
                                pathname: `${routes.SUPER_AUTHENTICATED}${routes.SUPER_ADMIN}${routes.SUPER_ORG_DETAIL}`,
                                state: { id: org_id }
                            }}
                        >
                            <div className="org-item justify-content-between align-items-center">
                                <div className="col-5">
                                    <h2 className="h6 mb-0">{org_name}</h2>
                                    {status === 'Active' ? (
                                        <small className="d-block text-muted">
                                            Access Panels: {devices && devices.length}
                                        </small>
                                    ) : (
                                        <small className="d-block text-danger">
                                            Access Panels: {devices && devices.length}
                                        </small>
                                    )}
                                </div>
                                <div className="col-3">
                                    {contact_name ? contact_name : 'No contact available'}
                                    <small className="d-block text-muted">
                                        {phone.primary ? (
                                            <NumberFormat
                                                value={phone.primary}
                                                displayType={'text'}
                                                format="###-###-####"
                                                mask="_"
                                            />
                                        ) : (
                                            `None`
                                        )}
                                    </small>
                                </div>
                                <div className="col-1">
                                    {status === 'Active' ? (
                                        <span className="badge badge-success badge-status">
                                            Online
                                        </span>
                                    ) : (
                                        <span className="badge badge-danger badge-status">
                                            Offline
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Link>
                    )
                )}
            </InfiniteScroll>
        </div>
    );
};

const mapStateToProps = ({ org }) => {
    const { orgs } = org;
    return { orgs };
};

export default withRouter(connect(mapStateToProps, {})(OrgsList));
