// React
import React, { useEffect } from 'react';

// Components
import { TextField, Loader } from '../../components';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Container, Button, Label, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Redux - Actions, Reducers, Sagas
import { loginUserEmailPassword, clearAuthError } from '../../store/actions/Auth';

// Router
import * as routes from '../../router/config/routes';

// Utils
// import { errorMessage, passwordRegExp } from '../utils/Constants';
import { errorMessage } from '../../utils/Constants';

const AcceptInviteLogin = ({
    loginUserEmailPassword,
    clearAuthError,
    error,
    loading,
    accept
}) => {
    useEffect(() => {
        return () => {
            clearAuthError();
        };
    }, [clearAuthError]);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        password: Yup.string().required(errorMessage.password.required)
    });
    return (
        <Container className="space-2">
            <Col>
                <Link
                    className="d-flex align-items-center justify-content-center position-relative mt-11 mb-8"
                    to="/"
                    aria-label="SAGE"
                >
                    <img
                        src="assets/sage-logo-color.svg"
                        className="logo"
                        style={{ width: '322px' }}
                        alt="logo"
                    />
                </Link>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={({ email, password }) => {
                        accept({ email, password });
                    }}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-50 mx-md-auto">
                            <div className="mb-3">
                                <h2 className="h3 text-primary font-weight-normal mb-0">
                                    <span className="font-weight-semi-bold">Admin </span>
                                    Dashboard
                                </h2>
                                {error && (
                                    <span
                                        style={{
                                            width: '100%',
                                            marginTop: '0.25rem',
                                            fontSize: '80%',
                                            color: '#de4437'
                                        }}
                                    >
                                        {error.message}
                                    </span>
                                )}
                            </div>
                            <div className="js-form-message form-group">
                                <Label className="form-label" for="email">
                                    Email address
                                </Label>
                                <TextField
                                    required
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    placeholder="Email address"
                                    aria-label="Email address"
                                    error={errors.email}
                                    value={values.email}
                                    onBlur={handleBlur('email')}
                                    onChange={handleChange('email')}
                                    invalid={touched.email && !!errors.email}
                                />
                            </div>
                            <div className="js-form-message form-group">
                                <Label className="form-label" for="password">
                                    <span className="d-flex justify-content-between align-items-center">
                                        Password
                                        <Link
                                            className="link-muted text-capitalize font-weight-normal"
                                            to={`${routes.RESET_PASSWORD}`}
                                        >
                                            Forgot Password?
                                        </Link>
                                    </span>
                                </Label>
                                <TextField
                                    required
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    id="password"
                                    placeholder="********"
                                    aria-label="********"
                                    error={errors.password}
                                    value={values.password}
                                    onBlur={handleBlur('password')}
                                    onChange={handleChange('password')}
                                    invalid={touched.password && !!errors.password}
                                />
                            </div>
                            <div className="row align-items-center mb-5">
                                <div className="col-6">
                                    <span className="small text-muted">
                                        Don&apos;t have an account?
                                    </span>
                                    <Link className="small" to="/">
                                        {' '}
                                        Learn more
                                    </Link>
                                </div>
                                <div className="col-6 text-right">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="transition-3d-hover btn-wide"
                                        onClick={handleSubmit}
                                    >
                                        Login
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
            {loading && <Loader />}
        </Container>
    );
};

const mapStateToProps = ({ auth }) => {
    const { error, loading } = auth;
    return { error, loading };
};

export default withRouter(
    connect(mapStateToProps, { loginUserEmailPassword, clearAuthError })(
        AcceptInviteLogin
    )
);
