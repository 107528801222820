import React from 'react';
import { ProfileSettings } from '../components';

const ProfileSettingsPage = () => {
    return (
        <div className="d-flex flex-column align-items-start flex-grow-1 justify-content-start container">
            <ProfileSettings />
        </div>
    );
};

export default ProfileSettingsPage;
