import React from 'react';
import { parseCheckInOutDateTime } from '../../utils/Helpers';
import { Container } from 'reactstrap';

const CreateAccessSuccess = ({ reservationData }) => {
    const accessStartsAt = parseCheckInOutDateTime(
        reservationData.check_in_date,
        reservationData.check_in_time
    );
    const accesStartDate = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric'
    }).format(accessStartsAt);
    const accesStartTime = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    }).format(accessStartsAt);

    return (
        <Container className="space-2 col-small">
            <div className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                    <img src="../assets/sage-icon.png" alt="logo" width={80} />
                    <div className="d-flex w-md-75 flex-column mb-2 align-items-center justify-content-center">
                        <div
                            style={{ fontSize: '32px' }}
                            className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                        >
                            Success!
                        </div>
                        <div
                            style={{ fontSize: '16px' }}
                            className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal my-4"
                        >
                            <div>
                                Your upcoming stay at{' '}
                                <span className="font-weight-semi-bold">
                                    {reservationData.org_name}
                                </span>{' '}
                                has been confirmed. Facility access will begin on{' '}
                                <span className="font-weight-semi-bold">
                                    {accesStartDate}
                                </span>{' '}
                                at{' '}
                                <span className="font-weight-semi-bold">
                                    {accesStartTime}
                                </span>
                                . Please be sure to have the mobile app downloaded onto
                                your device. Thank you!`
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-around my-5">
                            <div className="col-6 d-none d-md-block">
                                <img
                                    src="../assets/sage-app-qr.png"
                                    alt="Apple Store"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <div>
                                    <a href="https://apps.apple.com/us/app/sage-systems/id1592739629">
                                        <img
                                            src="../assets/apple-store.svg"
                                            alt="Apple Store"
                                            style={{ width: '100%' }}
                                            className="mb-5"
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.google.com/store/apps/details?id=com.sage_native&pli=1">
                                        <img
                                            src="../assets/google-play.svg"
                                            alt="Google Play"
                                            style={{ width: '100%' }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default CreateAccessSuccess;
