// React
import React, { useEffect } from 'react';

// Components
import { TextField, Loader } from '../components';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Label, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Redux - Actions, Reducers, Sagas
import {
    sendResetPasswordLink,
    resettingPasswordRequestState
} from '../store/actions/Auth';

// Router
import * as routes from '../router/config/routes';

// Utils
// import { errorMessage, passwordRegExp } from '../utils/Constants';
import { errorMessage } from '../utils/Constants';

const ResetPassword = ({
    sendResetPasswordLink,
    loading,
    resetPasswordComplete,
    resettingPasswordRequestState
}) => {
    useEffect(() => {
        return () => {
            resettingPasswordRequestState();
        };
    }, [resettingPasswordRequestState]);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required)
    });
    return (
        <div className="space-2 vh-100 bg-dark">
            {!resetPasswordComplete ? (
                <Col>
                    <div className="d-flex justify-content-center">
                        <Link
                            className="d-flex w-75 w-md-40 w-lg-30 align-items-center justify-content-center position-relative mt-11 mb-8"
                            to="/"
                            aria-label="SAGE"
                        >
                            <img
                                src="assets/sage.png"
                                className="logo"
                                style={{ width: '100%', maxWidth: '320px' }}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={({ email }) => {
                            sendResetPasswordLink({ email });
                        }}
                        validationSchema={validationSchema}
                        validateOnChange
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldTouched,
                            setFieldValue,
                            touched,
                            values
                        }) => (
                            <Form className="w-80 w-lg-40 mx-auto">
                                <div className="mb-3">
                                    <h2 className="h3 text-primary font-weight-normal mb-0">
                                        <span className="font-weight-semi-bold">
                                            Reset{' '}
                                        </span>
                                        Password
                                    </h2>
                                </div>
                                <div className="js-form-message form-group mb-5">
                                    <Label className="form-label  text-white" for="email">
                                        Email Address
                                    </Label>
                                    <TextField
                                        required
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Email address"
                                        aria-label="Email address"
                                        error={errors.email}
                                        value={values.email}
                                        onBlur={e => {
                                            handleBlur('email');
                                            setFieldTouched('email', true);
                                            setFieldValue(
                                                'email',
                                                e.target.value.toLowerCase().trim()
                                            );
                                        }}
                                        onChange={handleChange('email')}
                                        invalid={touched.email && !!errors.email}
                                    />
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-8">
                                        <span className="small text-muted">
                                            Remember your password?
                                        </span>
                                        <Link className="small" to={`${routes.LOGIN}`}>
                                            {' '}
                                            Login here
                                        </Link>
                                    </div>
                                    <div className="col-8">
                                        <span className="small text-muted">
                                            Don&apos;t have an account?
                                        </span>
                                        <Link className="small" to="/">
                                            {' '}
                                            Learn more
                                        </Link>
                                    </div>
                                    <div className="col-4 text-right">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="transition-3d-hover"
                                            onClick={handleSubmit}
                                        >
                                            Send link
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            ) : (
                <Col>
                    <div className="d-flex justify-content-center">
                        <Link
                            className="d-flex w-75 w-md-40 w-lg-30 align-items-center justify-content-center position-relative mt-11 mb-8"
                            to="/"
                            aria-label="SAGE"
                        >
                            <img
                                src="assets/sage.png"
                                className="logo"
                                style={{ width: '100%', maxWidth: '320px' }}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="w-80 w-lg-40 mx-auto">
                        <h2 className="h3 text-primary font-weight-normal mb-0">
                            <span className="font-weight-semi-bold">Request </span>{' '}
                            Complete
                        </h2>
                        <p>
                            An email has been sent with a link to reset your password. If
                            you don&apos;t see the email please check your Spam inbox as
                            well. Thank you.
                        </p>
                        <Link className="small" to={`${routes.LOGIN}`}>
                            <Button color="primary" className="transition-3d-hover mt-5">
                                Back to login
                            </Button>
                        </Link>
                    </div>
                </Col>
            )}
            {loading && <Loader />}
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    const { loading, resetPasswordComplete } = auth;
    return { loading, resetPasswordComplete };
};

export default withRouter(
    connect(mapStateToProps, { sendResetPasswordLink, resettingPasswordRequestState })(
        ResetPassword
    )
);
