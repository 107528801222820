import {
    REQUEST_ACCOUNT_REMOVAL,
    REQUEST_ACCOUNT_REMOVAL_SUCCESS,
    REQUEST_ACCOUNT_REMOVAL_FAILURE,
    RESET_REQUEST_ACCOUNT_REMOVAL
} from './types';

export const requestAccountRemoval = member => {
    return {
        type: REQUEST_ACCOUNT_REMOVAL,
        payload: member
    };
};

export const requestAccountRemovalSuccess = () => {
    return {
        type: REQUEST_ACCOUNT_REMOVAL_SUCCESS
    };
};

export const requestAccountRemovalFailure = error => {
    return {
        type: REQUEST_ACCOUNT_REMOVAL_FAILURE,
        payload: error
    };
};

export const resetRequestAccountRemoval = reset => {
    return {
        type: RESET_REQUEST_ACCOUNT_REMOVAL,
        payload: reset
    };
};
