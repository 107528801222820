import React from 'react';

import { Button, Label, Modal, ModalBody } from 'reactstrap';
import TextField from '../common/TextField';
import Loader from '../common/Loader';
import { Form, Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { addPost, deletePost } from '../../store/actions/Posts';

const EditPostModal = ({
    isOpen,
    toggleOpen,
    modalProps,
    org_id,
    deleting,
    uploading
}) => {
    const dispatch = useDispatch();

    const submitForm = values => {
        dispatch(addPost({ values, org_id, callback: toggleOpen }));
    };

    const handleDelete = values => {
        dispatch(deletePost({ org_id, values, callback: toggleOpen }));
    };

    return (
        <Modal
            style={{ minWidth: '70%' }}
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            toggle={toggleOpen}
        >
            <ModalBody>
                <div className="d-flex flex-column main">
                    {modalProps && !deleting && !uploading ? (
                        <div>
                            <img
                                className="m-2"
                                style={{ maxWidth: '100%', maxHeight: '150px' }}
                                src={modalProps.post_image}
                                alt=""
                            />
                            <Formik initialValues={modalProps} onSubmit={submitForm}>
                                {({
                                    handleSubmit,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    touched,
                                    values
                                }) => (
                                    <Form>
                                        <div className="m-2 w-50">
                                            <Label className="form-label">
                                                Post Title
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                id="title"
                                                placeholder="title"
                                                aria-label="title"
                                                onChange={handleChange('title')}
                                                value={values.title}
                                            />
                                        </div>
                                        <div className="m-2">
                                            <Label className="form-label">
                                                Post Content
                                            </Label>
                                            <TextField
                                                required
                                                type="textarea"
                                                className="form-control"
                                                rows={8}
                                                name="post_content"
                                                id="post_content"
                                                placeholder=""
                                                aria-label="post_content"
                                                onChange={handleChange('post_content')}
                                                value={values.post_content}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex justify-content-start">
                                                <Button
                                                    className="transition-3d-hover cancel-button m-3 btn-wide"
                                                    onClick={() => handleDelete(values)}
                                                >
                                                    Delete Post
                                                </Button>
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    className="transition-3d-hover cancel-button m-3 btn-wide"
                                                    onClick={toggleOpen}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="transition-3d-hover text-nowrap m-3 btn-wide"
                                                    onClick={handleSubmit}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = ({ post: { deletingPost, addingPost } }) => {
    return { deleting: deletingPost, uploading: addingPost };
};

export default connect(mapStateToProps)(EditPostModal);
