// React
import React from 'react';

// Components
import { OrgDashboard, Loader } from '../components';
import OrgHeader from '../components/OrgDetail/OrgHeader';

// Packages
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

// Router
import { RENTAL_DASHBOARD, RENTAL_MANAGER } from '../router/config/routes';

import { creatingPanelIntercom } from '../store/actions/Intercom';

const Dashboard = ({ orgs, userData, primaryOrg, activeOrg, creatingPanelIntercom }) => {
    const orgData = userData?.type === 'manager' ? activeOrg : primaryOrg;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    if (!userData && !orgs && !orgs?.length)
        return (
            <div className="d-flex flex-column position-relative flex-auto">
                <div className="main w-100">
                    <Loader />
                </div>
            </div>
        );

    return (
        <div className="d-flex main flex-column position-relative flex-auto">
            <OrgHeader
                isSuperAdmin={userData?.type === 'super_admin'}
                {...orgData}
                userId={userData?.uid}
                firstName={userData?.first_name}
                lastName={userData?.last_name}
                creatingPanelIntercom={creatingPanelIntercom}
            />
            <OrgDashboard userRole={userData?.type} {...orgData} />
        </div>
    );
};

const mapStateToProps = ({ org, user }) => {
    const { orgs } = org;
    const { userData, activeOrg } = user;
    return { orgs, activeOrg, userData };
};

export default withRouter(connect(mapStateToProps, { creatingPanelIntercom })(Dashboard));
