import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import {
    ADD_MEMBER,
    IMPORT_MEMBERS,
    ADD_INVITE_PENDING_MEMBERS,
    UPDATE_USER_PROFILE_INFO,
    UPDATE_USER_AVATAR,
    ADD_MEMBERS_FROM_LIST
} from '../actions/types';

import { db, rtdb, func, storage } from '../../config/Firebase';

import { getOrgById, updateOrgManagers } from './Org';

import {
    addInvitePendingMembersFailure,
    addInvitePendingMembersSuccess,
    addNewMemberFailure,
    addNewMemberSuccess,
    importMembersFailure,
    importMembersSuccess,
    updateUserAvatarFailure,
    updateUserAvatarSuccess,
    updateUserProfileInfoFailure,
    updateUserProfileInfoSuccess
} from '../actions/Managers';
import { suspendAccess } from './AccessKeys';
import { updateUserEmail } from './Auth';

import { list } from '../../membersList';

const users = db.collection('users');
// const accessKeys = db.collection('access_keys');
const getRtdbOrgImportRef = orgId => rtdb.ref(`orgs/${orgId}/import`);
const getRtdbOrgInvitesRef = orgId => rtdb.ref(`orgs/${orgId}/invites`);
const getRtdbOrgMembersRef = orgId => rtdb.ref(`orgs/${orgId}/members`);

const userImagesRef = storage.ref().child('users');

const createNewAccess = func.httpsCallable('createAccessWithPasswordAndEmailRequest');

////////////// Manager saga ///////////////////////

export function* uploadManagerAvatar({ payload }) {
    try {
        const { imgFile, userId } = payload;
        const imgUrl = yield userImagesRef
            .child(`/${userId}/avatar`)
            .putString(imgFile, 'data_url')
            .then(snapshot => {
                return snapshot.ref.getDownloadURL().then(downloadURL => downloadURL);
            });
        const isAvatarUpdated = yield users
            .doc(`${userId}`)
            .set({ avatar: imgUrl }, { merge: true })
            .then(() => true)
            .catch(err => {
                console.error(err);
                return false;
            });
        if (imgUrl && isAvatarUpdated) {
            yield put(updateUserAvatarSuccess(imgUrl));
            return true;
        }
        yield put(
            updateUserAvatarFailure('Error! Avatar was not updated. Try again later.')
        );
        return false;
    } catch (error) {
        yield put(updateUserAvatarFailure(`${error}`));
        return false;
    }
}

export function* updateManagerInfo({ payload }) {
    try {
        const userData = yield getUserById(payload.uid);
        const isAuthEmailUpdated =
            userData.email !== payload.email
                ? yield* updateUserEmail({ payload: payload.email })
                : true;

        const isUserInfoUpdated = isAuthEmailUpdated
            ? yield users
                  .doc(`${payload.uid}`)
                  .set(payload, { merge: true })
                  .then(() => true)
                  .catch(err => {
                      console.error(err);
                      return false;
                  })
            : false;

        if (isUserInfoUpdated) {
            yield put(updateUserProfileInfoSuccess());
            return true;
        }

        yield put(
            updateUserProfileInfoFailure(
                'Error! Information was not updated. Try again later.'
            )
        );
        return false;
    } catch (error) {
        yield put(updateUserProfileInfoFailure(`${error}`));
    }
}

export async function createNewAuthUsers(newUsers) {
    try {
        const newAuthUsers = await createNewAccess({
            members: newUsers
        });
        return newAuthUsers?.data?.processed;
    } catch (err) {
        console.error(err);
    }
}

export async function getExistingAndNewUsers(userObjects) {
    try {
        const newUsers = [];
        const queryPromises = userObjects.map(manager => {
            return users
                .where('email', '==', manager.email)
                .get()
                .then(querySnapshot => {
                    if (!querySnapshot.size) {
                        newUsers.push(manager);
                    }

                    const res = [];
                    querySnapshot.forEach(doc => {
                        res.push(doc.data());
                    });

                    return res;
                })
                .catch(error => {
                    console.log('Error getting documents: ', error);
                });
        });

        const existingUsers = await Promise.all(queryPromises).then(res => {
            return res.flat();
        });

        return { newUsers, existingUsers };
    } catch (err) {
        console.error(err);
    }
}

export function* addNewOrgManagerDocument({ payload }) {
    try {
        const { memberInfo, org_id } = payload;
        const { newUsers, existingUsers } = yield getExistingAndNewUsers([memberInfo]);

        if (newUsers.length) {
            const processed = yield createNewAuthUsers(newUsers);

            const orgData = yield getOrgById(org_id);

            if (processed) {
                const usersToAdd = newUsers.map(user => {
                    const matchingAuthUser = processed.find(
                        authUser => authUser.email === user.email
                    );

                    const newUserDocument = {
                        active_call: null,
                        voip_token: null,
                        phone: user.mobilePhone || null,
                        email: matchingAuthUser.email,
                        type: user.type,
                        first_name: user.first_name || null,
                        last_name: user.last_name || null,
                        notifications: {
                            vendors: true,
                            org_vendors: true,
                            family: true,
                            guests: true,
                            news: true,
                            favorites: true
                        },
                        uid: matchingAuthUser.id,
                        role: '',
                        primary_org: org_id,
                        avatar: null,
                        fcm_token: null,
                        properties: {
                            [org_id]: {
                                property_id: null,
                                notifications: true,
                                org_id,
                                address: orgData?.address
                            }
                        },
                        twilio: null
                    };

                    return newUserDocument;
                });

                usersToAdd.forEach(newUser => {
                    users
                        .doc(`${newUser.uid}`)
                        .set({
                            ...newUser
                        })
                        .catch(error => {
                            console.error(`Error creating user: ${error}`);
                        });

                    updateOrgManagers(org_id, newUser.uid, newUser);
                });
            }
        }

        if (existingUsers.length) {
            existingUsers.forEach(user => {
                updateManagerProperties(user.uid, org_id);
                updateOrgManagers(org_id, user.uid, user);
            });
        }

        yield put(addNewMemberSuccess(memberInfo.email));
    } catch (err) {
        console.error(err);

        yield put(addNewMemberFailure(err));
    }
}

export async function getUserById(userId) {
    try {
        const userRef = users.doc(`${userId}`);
        const userData = await userRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    return doc.data();
                }
            })
            .catch(err => {
                console.error(err);
            });
        return userData;
    } catch (err) {
        console.error(err);
    }
}

export async function updateManagerProperties(userId, orgId) {
    try {
        const userRef = users.doc(`${userId}`);
        const orgData = await getOrgById(orgId);
        const userData = await getUserById(userId);
        const alreadyManagerOfThisOrg = userData?.properties?.[orgId] !== undefined;

        if (!alreadyManagerOfThisOrg) {
            userRef
                .set(
                    {
                        properties: {
                            ...userData?.properties,
                            [orgId]: {
                                property_id: null,
                                notifications: true,
                                org_id: orgId,
                                address: orgData?.address
                            }
                        }
                    },
                    { merge: true }
                )
                .catch(err => {
                    console.error(err);
                });
        }
    } catch (err) {
        console.error(err);
    }
}

/////////////// RTDB import members ////////////////////////

export function* importMembersCSV({ payload }) {
    try {
        const { members, orgId } = payload;

        if (!members) {
            throw new Error('Pasrsed CSV data empty.');
        }
        if (!orgId) {
            throw new Error('Missing orgId.');
        }

        const { validRows, invalidRows } = parseAndValidateMembersData(members);

        const importRef = getRtdbOrgImportRef(orgId);

        yield Promise.all(
            validRows.map(member => {
                return importRef.push(member);
            })
        );

        yield put(importMembersSuccess(invalidRows));
    } catch (error) {
        yield put(importMembersFailure(error));
    }
}

function parseAndValidateMembersData(data) {
    const dataKeys = data.shift();
    const validRows = [];
    const invalidRows = [];
    for (let i = 0; i < data.length; i++) {
        const resRow = {};
        dataKeys.forEach((key, index) => {
            resRow[key] = data[i][index];
        });
        const validated = validateMember(resRow);
        if (validated.errors.length) {
            invalidRows.push(validated);
        } else {
            validRows.push(resRow);
        }
    }

    return { validRows, invalidRows };
}

function validateMember(member) {
    const error = { name: `${member.first_name} ${member.last_name}`, errors: [] };
    if (!member.last_name) error.errors.push('missing last_name');
    if (!member.address_1) error.errors.push('missing address_1');
    if (!member.email) error.errors.push('missing email');
    if (!member.mobile_phone) error.errors.push('missing mobile_phone');
    return error;
}

export function deleteMemberFromOrg({ memberId, orgId }) {
    try {
        const membersRef = getRtdbOrgMembersRef(orgId);

        membersRef.child(`${memberId}`).remove();
    } catch (error) {
        console.log(error);
    }
}

export function suspendAccessForMember({ orgId, accessKey, memberId }) {
    try {
        const membersRef = getRtdbOrgMembersRef(orgId);
        suspendAccess(accessKey);
        membersRef.child(`${memberId}`).update({ suspended: true });
    } catch (error) {
        console.log(error);
    }
}

function* addInvitePendingMembers({ payload }) {
    try {
        const { orgId, members } = payload;
        const invitesRef = getRtdbOrgInvitesRef(orgId);
        const importRef = getRtdbOrgImportRef(orgId);

        yield Promise.all(
            members.map(contact => {
                const { id, ...restContactData } = contact;
                return invitesRef
                    .child(id)
                    .set({ ...restContactData, timestamp: Date.now() });
            })
        );

        yield Promise.all(
            members.map(contact => {
                return importRef.child(contact.id).set(null);
            })
        );

        yield put(addInvitePendingMembersSuccess());
    } catch (error) {
        console.log(error);
        yield put(addInvitePendingMembersFailure(error));
    }
}

// const addMembersFromListRequest = () => {
//     const keys = [
//         {
//             access_begins: new Date('2023-07-03T13:00:00.000Z'),
//             access_created: new Date('2023-07-03T15:09:43.898Z'),
//             access_days: null,
//             access_end_time: null,
//             access_expires: new Date('2023-12-31T16:00:00.000Z'),
//             access_start_time: null,
//             address: {
//                 address_1: null,
//                 address_2: null,
//                 city: null,
//                 latitude: null,
//                 longitude: null,
//                 state: null,
//                 zip: null
//             },
//             company_name: null,
//             consumer_id: '8vqu7zDT6U4YCBMyeJpSDHedpTdN',
//             creator_first_name: 'Reunion',
//             creator_id: 'Reunion West System',
//             creator_last_name: 'West',
//             email: 'rwoperator1@sagesystems.io',
//             favorite: false,
//             first_name: 'Guard',
//             image: null,
//             key_id: 'xp1EluaofBKogZqqflLL',
//             last_name: 'One',
//             org_id: 'SFig3GvnrsJr0d2zdiIO',
//             org_name: 'Reunion West',
//             phone: {
//                 code: 1,
//                 country: 'US',
//                 number: 5555555555
//             },
//             phone_number: '15555555555',
//             photo_id:
//                 'https://firebasestorage.googleapis.com/v0/b/sage-systems-prod.appspot.com/o/users%2FAoTFVdpSxZUciMFfNG3qdyG2y8G3%2Fphoto_id.jpeg?alt=media&token=a3efb2a5-f16f-443a-a64d-573e2f8ff6e6',
//             plates: ['QMQA81USA/FL'],
//             role: 'operator',
//             suspended: false,
//             type: 'short-term',
//             vehicles: [
//                 {
//                     color: 'White',
//                     country: 'USA',
//                     make: 'Ford',
//                     model: 'Escape',
//                     state: 'FL',
//                     tag: 'QMQA81',
//                     updated_at: new Date('2023-07-03T15:12:18.534Z'),
//                     year: '2023'
//                 }
//             ]
//         },
//         {
//             access_begins: new Date('2023-07-03T13:00:00.000Z'),
//             access_created: new Date('2023-07-03T15:09:43.898Z'),
//             access_days: null,
//             access_end_time: null,
//             access_expires: new Date('2023-12-31T16:00:00.000Z'),
//             access_start_time: null,
//             address: {
//                 address_1: null,
//                 address_2: null,
//                 city: null,
//                 latitude: null,
//                 longitude: null,
//                 state: null,
//                 zip: null
//             },
//             company_name: null,
//             consumer_id: 'vqx2qcYpQgMnLSDw43uXSEPPpa3q',
//             creator_first_name: 'Reunion',
//             creator_id: 'Reunion West System',
//             creator_last_name: 'West',
//             email: 'rwoperator2@sagesystems.io',
//             favorite: false,
//             first_name: 'Guard',
//             image: null,
//             key_id: '93CHPrrwEyCXD4ra86oC',
//             last_name: 'Two',
//             org_id: 'SFig3GvnrsJr0d2zdiIO',
//             org_name: 'Reunion West',
//             phone: {
//                 code: 1,
//                 country: 'US',
//                 number: 5555555555
//             },
//             phone_number: '15555555555',
//             photo_id:
//                 'https://firebasestorage.googleapis.com/v0/b/sage-systems-prod.appspot.com/o/users%2FAoTFVdpSxZUciMFfNG3qdyG2y8G3%2Fphoto_id.jpeg?alt=media&token=a3efb2a5-f16f-443a-a64d-573e2f8ff6e6',
//             plates: ['QNIG80USA/FL'],
//             role: 'operator',
//             suspended: false,
//             type: 'short-term',
//             vehicles: [
//                 {
//                     color: 'White',
//                     country: 'USA',
//                     make: 'Nissan',
//                     model: 'Altima',
//                     state: 'FL',
//                     tag: 'QNIG80',
//                     updated_at: new Date('2023-07-03T15:12:18.534Z'),
//                     year: '2023'
//                 }
//             ]
//         },
//         {
//             access_begins: new Date('2023-07-03T13:00:00.000Z'),
//             access_created: new Date('2023-07-03T15:09:43.898Z'),
//             access_days: null,
//             access_end_time: null,
//             access_expires: new Date('2023-12-31T16:00:00.000Z'),
//             access_start_time: null,
//             address: {
//                 address_1: null,
//                 address_2: null,
//                 city: null,
//                 latitude: null,
//                 longitude: null,
//                 state: null,
//                 zip: null
//             },
//             company_name: null,
//             consumer_id: 'rwxcRufZWX5cPkHFVKbLKNAuRZ7Y',
//             creator_first_name: 'Reunion',
//             creator_id: 'Reunion West System',
//             creator_last_name: 'West',
//             email: 'rwoperator3@sagesystems.io',
//             favorite: false,
//             first_name: 'Guard',
//             image: null,
//             key_id: 'qXltfB11eK1c1PpNLoXm',
//             last_name: 'Three',
//             org_id: 'SFig3GvnrsJr0d2zdiIO',
//             org_name: 'Reunion West',
//             phone: {
//                 code: 1,
//                 country: 'US',
//                 number: 5555555555
//             },
//             phone_number: '15555555555',
//             photo_id:
//                 'https://firebasestorage.googleapis.com/v0/b/sage-systems-prod.appspot.com/o/users%2FAoTFVdpSxZUciMFfNG3qdyG2y8G3%2Fphoto_id.jpeg?alt=media&token=a3efb2a5-f16f-443a-a64d-573e2f8ff6e6',
//             plates: ['QNIG79USA/FL'],
//             role: 'operator',
//             suspended: false,
//             type: 'short-term',
//             vehicles: [
//                 {
//                     color: 'White',
//                     country: 'USA',
//                     make: 'Toyota',
//                     model: 'Camry',
//                     state: 'FL',
//                     tag: 'QNIG79',
//                     updated_at: new Date('2023-07-03T15:12:18.534Z'),
//                     year: '2023'
//                 }
//             ]
//         }
//     ];

//     return new Promise((resolve, reject) => {
//         keys.forEach((key, index) => {
//             const accessKeyRef = accessKeys.doc(`${key.key_id}`);
//             accessKeyRef.set(key);
//             if (index === keys.length - 1) {
//                 resolve({ res: true });
//             }
//         });
//     });
// };

const addMembersFromListRequest = () => {
    return new Promise((resolve, reject) => {
        list.forEach(member => {
            const {
                address_1,
                address_2,
                city,
                state,
                zip,
                role,
                // type,
                first_name,
                last_name,
                company_name,
                email,
                mobile_phone
            } = member;

            const memberObj = rtdb.ref(`orgs/SFig3GvnrsJr0d2zdiIO/members`).push();
            const memberId = memberObj.key;
            const memberRef = rtdb.ref(`orgs/SFig3GvnrsJr0d2zdiIO/members/${memberId}`);

            const address1 = `${address_1} `;
            const address2 =
                address_2 && address_2.trim().length !== 0 ? `${address_2}, ` : '';
            const cityStateZip = `${city}, ${state} ${zip}`;
            const fullAddress = `${address1}${address2}${cityStateZip}`;

            const memberData = {
                access_group: 1,
                access_key: false,
                address: fullAddress,
                company: company_name.trim().length !== 0 ? company_name : null,
                email: email && email.trim() !== '' ? email : false,
                first_name: first_name.toLowerCase(),
                last_name: last_name.toLowerCase(),
                phone: mobile_phone,
                role: role,
                suspended: false,
                uid: memberId
            };
            memberRef.set(memberData, error => {
                if (error) {
                    reject({ error });
                } else {
                    resolve({ res: true });
                }
            });
        });
    });
};

export function* addMembersFromList() {
    const { res, error } = yield call(() => addMembersFromListRequest());
    if (res) {
        console.log('done!');
    } else {
        console.log('got error!', error);
    }
}

// const memberObj = rtdb.ref(`access_log/success`).push();
// const memberId = memberObj.key;
// const memberRef = rtdb.ref(`access_log/success/${memberId}`);

///////////// Action Creators For Root Saga //////////////////

export function* addInvitePending() {
    yield takeLatest(ADD_INVITE_PENDING_MEMBERS, addInvitePendingMembers);
}

export function* importMembers() {
    yield takeLatest(IMPORT_MEMBERS, importMembersCSV);
}

export function* addNewManager() {
    yield takeLatest(ADD_MEMBER, addNewOrgManagerDocument);
}

export function* updateProfileInfo() {
    yield takeLatest(UPDATE_USER_PROFILE_INFO, updateManagerInfo);
}

export function* uploadAvatar() {
    yield takeLatest(UPDATE_USER_AVATAR, uploadManagerAvatar);
}

export function* addingMembersFromList() {
    yield takeLatest(ADD_MEMBERS_FROM_LIST, addMembersFromList);
}

export default function* rootSaga() {
    yield all([
        fork(addNewManager),
        fork(importMembers),
        fork(addInvitePending),
        fork(updateProfileInfo),
        fork(uploadAvatar),
        fork(addingMembersFromList)
    ]);
}
