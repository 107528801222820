import {
    GET_DEVICE_TYPES,
    GET_DEVICE_TYPES_SUCCESS,
    GET_DEVICE_TYPES_FAILURE,
    WATCH_ORG_DEVICES_RTDB,
    WATCH_ORG_DEVICES_RTDB_SUCCESS,
    WATCH_ORG_DEVICES_RTDB_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS,
    UPDATE_ORG_DEVICE_DETAILS_SUCCESS,
    UPDATE_ORG_DEVICE_DETAILS_FAILURE,
    UPDATE_ORG_DEVICE_DETAILS_RESET
} from './types';

export const getDeviceTypes = () => {
    return {
        type: GET_DEVICE_TYPES
    };
};

export const getDeviceTypesSuccess = types => {
    return {
        type: GET_DEVICE_TYPES_SUCCESS,
        payload: types
    };
};
export const getDeviceTypesFailure = error => {
    return {
        type: GET_DEVICE_TYPES_FAILURE,
        payload: error
    };
};

export const watchOrgDevicesRTDB = orgId => {
    return {
        type: WATCH_ORG_DEVICES_RTDB,
        payload: orgId
    };
};

export const watchOrgDevicesRTDBSuccess = devices => {
    return {
        type: WATCH_ORG_DEVICES_RTDB_SUCCESS,
        payload: devices
    };
};
export const watchOrgDevicesRTDBFailure = error => {
    return {
        type: WATCH_ORG_DEVICES_RTDB_FAILURE,
        payload: error
    };
};

export const updateOrgDeviceDetails = data => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS,
        payload: data
    };
};

export const updateOrgDeviceDetailsSuccess = () => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS_SUCCESS
    };
};

export const updateOrgDeviceDetailsFailure = error => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS_FAILURE,
        payload: error
    };
};

export const updateOrgDeviceDetailsResetState = error => {
    return {
        type: UPDATE_ORG_DEVICE_DETAILS_RESET,
        payload: error
    };
};
