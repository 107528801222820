import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { AUTHENTICATED, ORG_DETAIL } from '../../router/config/routes';
import { useHistory } from 'react-router-dom';
import { resetMemberImportState } from '../../store/actions/Managers';
import { useDispatch } from 'react-redux';

const ImportError = ({ orgId, error }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetMemberImportState());
    });

    return (
        <div className="container">
            <div className="row pt-8">
                <div className="col-6 m-auto text-center">
                    <h3 className="text-red font-weight-semi-bold">
                        Data import failed!
                    </h3>
                </div>
            </div>
            {error && (
                <div className="row pt-3">
                    <div className="col-6 m-auto text-center">
                        <p>{error}</p>
                    </div>
                </div>
            )}
            <div className="row pt-10">
                <div className="col-12 m-auto d-flex justify-content-center">
                    <Button
                        type="button"
                        color="primary"
                        className="btn btn-sm transition-3d-hover"
                        onClick={() =>
                            history.push({
                                pathname: `${AUTHENTICATED}${ORG_DETAIL}/${orgId}`,
                                state: { id: orgId }
                            })
                        }
                    >
                        Back to organisation details
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ImportError;
