import {
    GET_ACCESS_KEYS_DOCUMENT,
    GET_ACCESS_KEYS_DOCUMENT_SUCCESS,
    GET_ACCESS_KEYS_DOCUMENT_FAILURE,
    ADD_ACCESS_KEYS_DOCUMENT,
    ADD_ACCESS_KEYS_DOCUMENT_SUCCESS,
    ADD_ACCESS_KEYS_DOCUMENT_FAILURE
} from './types';

export const getAccesKeysDoc = id => {
    return {
        type: GET_ACCESS_KEYS_DOCUMENT,
        payload: id
    };
};

export const getAccesKeysDocSuccess = doc => {
    return {
        type: GET_ACCESS_KEYS_DOCUMENT_SUCCESS,
        payload: doc
    };
};

export const getAccesKeysDocFailure = error => {
    return {
        type: GET_ACCESS_KEYS_DOCUMENT_FAILURE,
        payload: error
    };
};

export const addAccesKeysDoc = doc => {
    return {
        type: ADD_ACCESS_KEYS_DOCUMENT,
        payload: doc
    };
};

export const addAccesKeysDocSuccess = () => {
    return {
        type: ADD_ACCESS_KEYS_DOCUMENT_SUCCESS
    };
};

export const addAccesKeysDocFailure = error => {
    return {
        type: ADD_ACCESS_KEYS_DOCUMENT_FAILURE,
        payload: error
    };
};
