import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Col } from 'reactstrap';
import {
    resetRevokeOneTimeAccessCode,
    revokeOneTimeAccessCode
} from '../../store/actions/Guests';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AUTHENTICATED, GUEST_MANAGER } from '../../router/config/routes';
import Loader from '../common/Loader';

const AcceptOrRevokeOtc = ({
    otc,
    loading,
    revokeOtcSuccess,
    revokeOtcError,
    revokeOneTimeAccessCode,
    acceptOtc,
    isCreate,
    resetRevokeOneTimeAccessCode
}) => {
    const history = useHistory();

    useEffect(() => {
        if (revokeOtcError) {
            resetRevokeOneTimeAccessCode();
            history.push(`${AUTHENTICATED}${GUEST_MANAGER}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revokeOtcError]);

    useEffect(() => {
        if (revokeOtcSuccess) {
            resetRevokeOneTimeAccessCode();
            history.push(`${AUTHENTICATED}${GUEST_MANAGER}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revokeOtcSuccess]);

    const handleRevokeCode = () => {
        revokeOneTimeAccessCode({ code_id: otc.code_id });
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp * 1000);

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZoneName: 'short'
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <div className="w-md-75 mx-md-auto card bg-white form-shadow p-2">
                    <div className="card-header d-flex align-items-center justify-content-end">
                        <Link
                            to={`${AUTHENTICATED}${GUEST_MANAGER}`}
                            className="d-flex align-items-center"
                        >
                            <span className="material-icons-round mr-1">close</span>
                            Cancel
                        </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div className="mb-3 pt-4 w-75">
                            <div
                                style={{ fontSize: '32px' }}
                                className="d-flex justify-content-center font-weight-semi-bold text-muted mt-5"
                            >
                                {otc.name}
                            </div>
                            <div
                                style={{ fontSize: '42px' }}
                                className="d-flex justify-content-center text-muted font-weight-semi-bold mb-8"
                            >
                                {otc.code}
                            </div>
                            <p
                                style={{ fontSize: '20px' }}
                                className="text-center mt-5 mb-5"
                            >
                                Share this code with your guest or vendor for giving
                                access. This code will expire at{' '}
                                <strong>{formatTimestamp(otc.expires_at.seconds)}</strong>
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-2 mb-4">
                        <div
                            style={{ flex: 1 }}
                            className="d-flex justify-content-center m-2"
                        >
                            {!isCreate && (
                                <Button color="danger" onClick={() => handleRevokeCode()}>
                                    Revoke Code
                                </Button>
                            )}
                            {isCreate && (
                                <Button color="primary" onClick={() => acceptOtc()}>
                                    Activate Code
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ guests }) => {
    const { revokeOtcSuccess, revokeOtcError, loading } = guests;
    return { revokeOtcSuccess, revokeOtcError, loading };
};

export default connect(mapStateToProps, {
    resetRevokeOneTimeAccessCode,
    revokeOneTimeAccessCode
})(AcceptOrRevokeOtc);
