import React from 'react';
import { Container } from 'reactstrap';

const ValidationError = ({ error, title }) => {
    return (
        <Container className="space-2 col-small">
            <div
                className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2"
                style={{ minHeight: '50vh' }}
            >
                <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                    <img src="../assets/sage-icon.png" alt="logo" width={80} />
                    <div className="d-flex w-md-75 flex-column mb-2 align-items-center justify-content-center">
                        <div
                            style={{ fontSize: '32px' }}
                            className="d-flex text-center align-items-center justify-content-center text-dark-gray font-weight-semi-bold my-3"
                        >
                            {title}
                        </div>
                        <div
                            style={{ fontSize: '16px' }}
                            className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal my-3"
                        >
                            {error}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ValidationError;
