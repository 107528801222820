// React
import React from 'react';
import DropdownButton from '../common/DropdownButton';

const ReservationFilterInput = ({ handleChange, changeSortType, activeType }) => {
    return (
        <div id="reservationFilters">
            <div className="my-4">
                <div className="row align-items-center">
                    <div className="js-focus-state col-6 d-flex">
                        <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="fas fa-search" />
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name="text"
                                id="searchReservation"
                                placeholder="Search Reservations"
                                aria-label="search"
                                aria-describedby="searchReservation"
                                autoComplete="off"
                                onChange={e => handleChange(e.target.value)}
                            />
                        </div>
                        <div className="ml-4">
                            <DropdownButton
                                menuOptionComponents={[
                                    {
                                        key: 'Active',
                                        component: <span>Active</span>,
                                        value: 'active'
                                    },
                                    {
                                        key: 'Completed',
                                        component: <span>Completed</span>,
                                        value: 'completed'
                                    },
                                    {
                                        key: 'Cancelled',
                                        component: <span>Cancelled</span>,
                                        value: 'cancelled'
                                    }
                                ]}
                                toggleComponent={activeType.component}
                                handleSelect={changeSortType}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReservationFilterInput;
