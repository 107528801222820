import React from 'react';
import { Link } from 'react-router-dom';
// Router
import * as routes from '../router/config/routes';

const Landing = () => {
    return (
        <div id="sage-splash-screen" className="bg-dark">
            <div className="center">
                <div className="logo">
                    <img src="assets/sage.png" alt="logo" style={{ width: '370px' }} />
                </div>
                <div className="login">
                    <Link className="btn btn-primary" to={routes.LOGIN}>
                        Login
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Landing;
