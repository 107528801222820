import React from 'react';

// Components
import { AccessLog } from '../index';
// import AccessCameras from './AccessCameras';

const OrgDashboard = ({ image, active_units, credentials, devices, offline }) => {
    return (
        <div>
            {/* <div className="card-deck">
                <div className="card org-detail-item shadow-2">
                    <div className="col-12 m-auto p-5 .text-break d-flex flex-column justify-content-around align-items-center">
                        <p className="widget-data text-green mt-0">
                            {devices && offline ? devices.length - offline.length : 0}
                        </p>
                        <small className="text-green ml-1">Online Panels</small>
                    </div>
                </div>
                <div className="card org-detail-item shadow-2">
                    <div className="col-12 m-auto p-5 .text-break d-flex flex-column justify-content-around align-items-center">
                        <p className="widget-data text-red mt-0">
                            {offline ? offline.length : 0}
                        </p>
                        <small className="text-red ml-1">Offline Panels</small>
                    </div>
                </div>
                <div className="card org-detail-item shadow-2">
                    <div className="col-12 m-auto p-5 .text-break d-flex flex-column justify-content-around align-items-center">
                        <p className="widget-data text-gray mt-0">{active_units || 0}</p>
                        <small className="text-gray ml-1">Active Units</small>
                    </div>
                </div>
                <div className="card org-detail-item shadow-2">
                    <div className="col-12 m-auto p-5 .text-break d-flex flex-column justify-content-around align-items-center">
                        <p className="widget-data text-gray mt-0">{credentials || 0}</p>
                        <small className="text-gray ml-1">Active Credentials</small>
                    </div>
                </div>
                {image && (
                    <div className="card org-detail-image">
                        <img
                            src={image}
                            className="m-0 shadow-2 rounded img-fluid"
                            alt="orgImage"
                        />
                    </div>
                )}
            </div> */}
            <div className="mt-5">
                <div
                    className="card bg-transparent border-0"
                    style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'row'
                    }}
                >
                    <AccessLog />
                    {/* <AccessCameras /> */}
                </div>
            </div>
        </div>
    );
};

export default OrgDashboard;
