import {
    GET_POSTS,
    GET_POSTS_SUCCESS,
    GET_POSTS_FAILURE,
    ADD_POST,
    ADD_POST_FAILURE,
    ADD_POST_SUCCESS,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILURE
} from './types';

export const getPosts = org_id => {
    return {
        type: GET_POSTS,
        payload: org_id
    };
};

export const getPostsSuccess = posts => {
    return {
        type: GET_POSTS_SUCCESS,
        payload: posts
    };
};

export const getPostsFailure = error => {
    return {
        type: GET_POSTS_FAILURE,
        payload: error
    };
};

export const addPost = data => {
    return {
        type: ADD_POST,
        payload: data
    };
};

export const addPostSuccess = () => {
    return {
        type: ADD_POST_SUCCESS
    };
};

export const addPostFailure = error => {
    return {
        type: ADD_POST_FAILURE,
        payload: error
    };
};

export const deletePost = data => {
    return {
        type: DELETE_POST,
        payload: data
    };
};

export const deletePostSuccess = () => {
    return {
        type: DELETE_POST_SUCCESS
    };
};

export const deletePostFailure = error => {
    return {
        type: DELETE_POST_FAILURE,
        payload: error
    };
};
