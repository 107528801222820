import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ContactsListSearch from './ContactsListSearch';
import { revokeOtc } from '../../store/actions/Otc';
import OtcList from './OtcList';

const Otc = ({ selectedTab, orgId, codes, revokeOtc }) => {
    const [searchString, setSearchsString] = useState('');
    const [selectAllFlag, setSelectAllFlag] = useState(false);
    const [selectList, setSelectList] = useState([]);
    const [filteredCodes, setfilteredCodes] = useState([]);

    const selectAll = () => {
        if (selectAllFlag) {
            setSelectList([]);
            setSelectAllFlag(false);
            return;
        }
        filteredCodes && filteredCodes.length
            ? setSelectList(filteredCodes.map(code => code.code_id))
            : setSelectList(codes.map(code => code.code_id));

        setSelectAllFlag(true);
    };

    const addToSelectList = selected => {
        if (selected === null) {
            setSelectList([]);
            return;
        }
        const alreadyAdded = selectList.includes(selected);
        if (alreadyAdded) {
            setSelectList(selectList.filter(el => el !== selected));
        } else setSelectList([...selectList, selected]);
    };

    const handleRevoke = () => {
        selectList.forEach(code => {
            const data = codes.filter(otcCode => otcCode.code_id === code)[0];
            revokeOtc({ code: data.code_id, expire: data.created_at });
        });
        setSelectList([]);
        setSelectAllFlag(false);
    };

    const otcSearch = useCallback(
        input => {
            if (!input.trim()) {
                setfilteredCodes([]);
                return;
            }

            const filteredCodes = codes.filter(otc => {
                const { name } = otc;
                const lowerCaseSearchString = input.trim().toLowerCase();
                if (name && name.toLowerCase().includes(lowerCaseSearchString))
                    return true;
                return false;
            });
            setfilteredCodes(filteredCodes);
        },
        [codes]
    );

    useEffect(() => {
        if (codes && searchString) {
            otcSearch(searchString);
        } else {
            if (codes) {
                setfilteredCodes(codes);
            }
        }
    }, [searchString, setfilteredCodes, codes, otcSearch]);

    return (
        <div>
            <div className="my-4 w-100 d-flex justify-content-between">
                <div className="d-flex justify-content-start w-30">
                    <ContactsListSearch
                        className="contacts-list-search"
                        handleChange={value => {
                            setSearchsString(value);
                            setSelectList([]);
                            if (selectAllFlag) {
                                selectAll();
                            }
                        }}
                        listLabel={selectedTab}
                    />
                </div>
                <div
                    className={selectAllFlag ? 'invite-all badge-green' : 'invite-all'}
                    onClick={selectAll}
                >
                    <i className="fa fa-check-circle mr-2" aria-hidden="true" />
                    Select All
                </div>
            </div>
            <OtcList
                orgId={orgId}
                selectHandler={addToSelectList}
                searchString={searchString}
                selected={selectList}
                codes={codes}
                filteredCodes={filteredCodes}
            />
            <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="text-muted">{`${
                    codes?.length ? codes.length : 0
                } Codes`}</div>
                <Button
                    disabled={!selectList.length}
                    color="danger"
                    className="transition-3d-hover m-3 btn-sm"
                    onClick={handleRevoke}
                >
                    Revoke Codes
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = ({ otc }) => {
    const { otcCodes } = otc;
    return { codes: otcCodes };
};

export default withRouter(connect(mapStateToProps, { revokeOtc })(Otc));
