import React, { useEffect, useState } from 'react';
import { Container, Col } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    GUEST_MANAGER_ADD,
    GUEST_MANAGER_ADD_OTC,
    GUEST_MANAGER_EDIT,
    GUEST_MANAGER_REVOKE_OTC
} from '../../router/config/routes';
import { logoutUser } from '../../store/actions/Auth';

const ManageGuestList = ({
    guestInvites,
    guestAccessKeys,
    userData,
    logoutUser,
    oneTimeAccessCodes
}) => {
    const [resiGuests, setResiGuests] = useState([]);
    const [otcs, setOtcs] = useState([]);

    useEffect(() => {
        if (guestInvites && guestAccessKeys) {
            const filteredGuestAccessKeys = guestAccessKeys.filter(
                accessKey => accessKey.role === 'guest'
            );
            setResiGuests([...guestInvites, ...filteredGuestAccessKeys]);
        }
    }, [guestInvites, guestAccessKeys, oneTimeAccessCodes]);

    useEffect(() => {
        if (oneTimeAccessCodes) {
            const currentTimestamp = Math.floor(Date.now() / 1000);

            const calculateRemainingTime = otc => {
                const remainingSeconds = otc.expires_at.seconds - currentTimestamp;
                const remainingMinutes = Math.floor(remainingSeconds / 60);
                const remainingHours = Math.floor(remainingMinutes / 60);
                const minutesRemainder = remainingMinutes % 60;

                return `${remainingHours}h ${minutesRemainder}min remaining`;
            };

            const filteredOtcs = oneTimeAccessCodes
                .filter(otc => otc.expires_at.seconds > currentTimestamp)
                .map(otc => ({
                    ...otc,
                    remainingTime: calculateRemainingTime(otc)
                }));

            setOtcs(filteredOtcs);

            const intervalId = setInterval(() => {
                setOtcs(prevOtcs =>
                    prevOtcs.map(otc => ({
                        ...otc,
                        remainingTime: calculateRemainingTime(otc)
                    }))
                );
            }, 60000);

            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneTimeAccessCodes]);

    const history = useHistory();

    const activeOrgId = userData?.active_org_id;
    const activeOrgProperty = userData?.properties[activeOrgId];

    return (
        <Container className="space-1">
            <Col>
                <div className="w-md-75 mx-md-auto card bg-white form-shadow p-2">
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <div>Manage Guests</div>
                        <div>
                            <Link
                                to="/"
                                onClick={logoutUser}
                                className="d-flex align-items-center"
                            >
                                <span className="material-icons-round mr-1">logout</span>
                                Log Out
                            </Link>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="mb-6 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between">
                            <div>
                                <h4 className="mb-0">
                                    <p className="card-text text-dark mb-0 font-weight-semi-bold text-capitalize">
                                        {userData.first_name || ''}{' '}
                                        {userData.last_name || ''}
                                    </p>
                                </h4>
                                <p className="card-text small text-muted">
                                    {activeOrgProperty?.address?.address_1 || ''}
                                </p>
                            </div>
                            <div className="pointer d-flex flex-column flex-sm-row align-items-center w-100 w-sm-auto mt-2 mt-sm-0">
                                <Link
                                    to={GUEST_MANAGER_ADD_OTC}
                                    className="btn btn-secondary w-100 w-sm-auto mr-0 mr-sm-1"
                                >
                                    One Time Code
                                </Link>{' '}
                                <Link
                                    to={GUEST_MANAGER_ADD}
                                    className="btn btn-primary w-100 w-sm-auto mt-1 mt-sm-0"
                                >
                                    Invite Guest
                                </Link>{' '}
                            </div>
                        </div>
                        <div style={{ height: '45vh' }} className="overflow-auto">
                            <ul className="list-group list-group-flush">
                                {otcs?.map(otc => (
                                    <li
                                        onClick={() =>
                                            history.push(
                                                `${GUEST_MANAGER_REVOKE_OTC}/${otc.code_id}`
                                            )
                                        }
                                        key={otc.code_id}
                                        className="list-group-item pointer"
                                    >
                                        <h2 className="h6 mb-0 text-dark-gray">
                                            {otc.name}
                                        </h2>
                                        <small className="d-block text-muted">
                                            {otc?.remainingTime || ''}
                                        </small>
                                    </li>
                                ))}
                                {resiGuests?.map(guest => (
                                    <li
                                        key={guest.id}
                                        className="list-group-item pointer"
                                        onClick={() =>
                                            history.push(
                                                `${GUEST_MANAGER_EDIT}/${guest.id}`
                                            )
                                        }
                                    >
                                        <h2 className="h6 mb-0 text-dark-gray text-capitalize">
                                            {guest.first_name} {guest.last_name}
                                        </h2>
                                        <small
                                            className={`d-block text-capitalize ${
                                                guest?.invite_status === 'pending'
                                                    ? 'text-warning'
                                                    : 'text-muted'
                                            }`}
                                        >
                                            {guest?.invite_status === 'pending'
                                                ? 'Pending'
                                                : guest.access_start_time ||
                                                  guest.access_end_time ||
                                                  guest.access_days
                                                ? 'Limited Access'
                                                : 'Anytime Access'}
                                        </small>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ guests, user }) => {
    const { guestInvites, guestAccessKeys, oneTimeAccessCodes } = guests;
    const { userData } = user;
    return { guestInvites, userData, guestAccessKeys, oneTimeAccessCodes };
};

export default connect(mapStateToProps, { logoutUser })(ManageGuestList);
