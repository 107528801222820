import React, { useState } from 'react';

import { Container, Col, Button, FormGroup, Input, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { errorMessage, nameRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import ConfirmFooter from '../common/ConfirmFooter';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
// import Loader from '../common/Loader';
import { tsFromJsDate, generateNewOtcCode } from '../../utils/Helpers';
import { addOtc, resetOtcState } from '../../store/actions/Otc';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const OtcForm = ({ activeOrg, userId, addOtc, newOtc, resetOtcState }) => {
    const [reusable, setReusable] = useState(false);
    const history = useHistory();
    const submit = values => {
        addOtc({
            code: generateNewOtcCode(),
            creator_id: userId,
            expires_at: tsFromJsDate(values.expiration_date[0]),
            name: values.name,
            org_id: activeOrg.org_id,
            reusable
        });
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp * 1000);

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZoneName: 'short'
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    const currentDateTime = new Date();

    const getMinDateTime = () => {
        const dateCopy = new Date(currentDateTime.getTime());
        const hoursToAdd = 7 * 60 * 60 * 1000;
        dateCopy.setTime(currentDateTime.getTime() + hoursToAdd);
        return dateCopy;
    };

    const getDefDateTime = () => {
        const dateCopy = new Date(currentDateTime.getTime());
        const hoursToAdd = 8 * 60 * 60 * 1000;
        dateCopy.setTime(currentDateTime.getTime() + hoursToAdd);
        return dateCopy;
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.fullNameOrCompany.required),
        expiration_date: Yup.string().trim().required(errorMessage.toDate.required)
    });

    const handleReset = () => {
        resetOtcState();
        history.goBack();
    };

    return (
        <Container className="space-1">
            <Col>
                {!newOtc ? (
                    <Formik
                        initialValues={{
                            name: '',
                            expiration_date: [getDefDateTime()]
                        }}
                        onSubmit={submit}
                        validationSchema={validationSchema}
                        validateOnChange
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            touched,
                            values
                        }) => (
                            <Form className="w-md-70 mx-md-auto">
                                <div className="mb-4">
                                    <h2 className="h5 font-weight-normal mb-0">
                                        <span className="d-flex justify-content-start h3 font-weight-semi-bold">
                                            New Access Code
                                        </span>
                                    </h2>
                                </div>
                                <div className="js-form-message form-group">
                                    <div className="d-flex flex-row justify-content-start">
                                        <div
                                            style={{ flex: 1 }}
                                            className="js-form-message col-6 d-flex form-group"
                                        >
                                            <LabeledFormField
                                                fieldTitle="first name"
                                                required
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                id="name"
                                                aria-label="name"
                                                error={returnError(errors, 'name')}
                                                value={values.name}
                                                onBlur={handleBlur('name')}
                                                onChange={handleChange('name')}
                                                invalid={
                                                    returnTouched(touched, 'name') &&
                                                    !!returnError(errors, 'name')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="js-form-message form-group">
                                    <div className="d-flex flex-row justify-content-start">
                                        <div
                                            style={{ flex: 1 }}
                                            className="js-form-message col-6 d-flex form-group"
                                        >
                                            <LabeledFormField
                                                fieldTitle="expiration date & time (minimum now + 8 hrs)"
                                                required={true}
                                                type="text"
                                                className="form-control"
                                                name="expiration_date"
                                                id="expiration_date"
                                                placeholder="Select..."
                                                aria-label="expiration_date"
                                                error={returnError(
                                                    errors,
                                                    'expiration_date'
                                                )}
                                                value={values.expiration_date}
                                                selected={values.expiration_date}
                                                onChange={date =>
                                                    setFieldValue('expiration_date', date)
                                                }
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        'expiration_date'
                                                    ) &&
                                                    !!returnError(
                                                        errors,
                                                        'expiration_date'
                                                    )
                                                }
                                                datePickerField={true}
                                                datePickerOptions={{
                                                    minDate: getMinDateTime()
                                                }}
                                                withDate={true}
                                                withTime={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="js-form-message form-group">
                                    <div className="d-flex flex-row justify-content-start">
                                        <div
                                            style={{
                                                flex: 1,
                                                alignItems: 'center'
                                            }}
                                            className="js-form-message col-6 d-flex form-group"
                                        >
                                            <FormGroup
                                                style={{
                                                    alignItems: 'center',
                                                    marginLeft: '1.3rem'
                                                }}
                                            >
                                                <Input
                                                    style={{
                                                        width: '1rem',
                                                        height: '1rem',
                                                        marginTop: '.05rem'
                                                    }}
                                                    type="checkbox"
                                                    onChange={() =>
                                                        setReusable(!reusable)
                                                    }
                                                    checked={reusable}
                                                    value={reusable}
                                                />
                                                <Label
                                                    className="form-label"
                                                    style={{
                                                        marginLeft: '.5rem',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Reusable Code
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <span className="small text-muted ml-3">
                                        * This feature may not be available yet at your
                                        site. Please contact SAGE Support to enable it.
                                    </span>
                                </div>
                                <div className="d-flex row justify-content-center align-items-center my-5">
                                    <ConfirmFooter
                                        cancel={() => history.goBack()}
                                        submitFunc={handleSubmit}
                                        submitText="Save"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <div className="mb-3 pt-4 w-50">
                                <div
                                    style={{ fontSize: '32px' }}
                                    className="d-flex justify-content-center font-weight-semi-bold text-muted mt-5"
                                >
                                    {newOtc.name}
                                </div>
                                <div
                                    style={{ fontSize: '42px', color: '#63de7c' }}
                                    className="d-flex justify-content-center font-weight-semi-bold mb-8"
                                >
                                    {newOtc.code}
                                </div>
                                <p
                                    style={{ fontSize: '20px' }}
                                    className="text-center mt-5 mb-5"
                                >
                                    Share this code with your guest or vendor for giving
                                    access. This code will expire at{' '}
                                    <strong>
                                        {formatTimestamp(newOtc.expires_at.seconds)}
                                    </strong>
                                </p>
                            </div>
                            <Button
                                color="primary"
                                className="transition-3d-hover m-3 font-weight-semi-bold btn-wide"
                                onClick={handleReset}
                            >
                                Finished
                            </Button>
                        </div>
                    </>
                )}
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ user, otc }) => {
    return { activeOrg: user.activeOrg, userId: user.userData.uid, newOtc: otc.otcCode };
};

export default connect(mapStateToProps, { addOtc, resetOtcState })(OtcForm);
