import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import 'yup-phone';
import { Container, Button, Col } from 'reactstrap';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';
import { maskEmail } from '../../utils/Helpers';
import { useDispatch } from 'react-redux';
import { createAccessAPINewVerification } from '../../store/actions/ConfirmReservation';
import { connect } from 'react-redux';

const returnError = (errors, field) => errors[field];

const ExistingAccountFound = ({ account, goToScreen }) => {
    const dispatch = useDispatch();

    const submit = async val => {
        dispatch(createAccessAPINewVerification(val));
    };

    const validatePhone = ({ code, number, country }) => {
        const phoneSchema = yup.string().phone(country).required();
        const isValid = phoneSchema.isValidSync(code + number);
        return isValid ? undefined : { number: 'invalid number' };
    };

    const validationSchema = yup.object().shape({
        creatingNew: yup.boolean(),
        country: yup.string().trim(),
        number: yup.string().trim(),
        code: yup.string().trim().required()
    });

    return (
        <Container className="col-small">
            <Col>
                <div className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                    <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                        <img src="../assets/sage-icon.png" alt="logo" width={80} />
                        <div className="d-flex w-md-100 flex-column mb-2 align-items-center justify-content-center">
                            <div
                                style={{ fontSize: '32px' }}
                                className="d-flex text-center justify-content-center text-dark-gray font-weight-semi-bold my-3"
                            >
                                Existing account found!
                            </div>
                            <div
                                style={{ fontSize: '16px' }}
                                className="d-flex text-center align-items-center justify-content-center text-muted w-md-75 font-weight-normal my-3"
                            >
                                The mobile number you provided is already associated with
                                an account. Please sign into your account or create a new
                                account with this number.
                            </div>
                            {account.email && (
                                <>
                                    <div
                                        style={{ fontSize: '13px' }}
                                        className="d-flex text-center align-items-center justify-content-center text-muted w-md-75 font-weight-normal mt-3"
                                    >
                                        Is this your email address?
                                    </div>
                                    <div
                                        style={{ fontSize: '16px', lineHeight: 1.2 }}
                                        className="d-flex text-center align-items-center justify-content-center w-md-75 font-weight-normal my-3"
                                    >
                                        {maskEmail(account.email)}
                                    </div>
                                    <div
                                        style={{ fontSize: '13px' }}
                                        className="d-flex text-center align-items-center justify-content-center text-muted w-md-75 font-weight-normal mb-3"
                                    >
                                        If so, sign in.
                                    </div>
                                </>
                            )}
                            <div className="mt-1 mb-4">
                                <Button
                                    color="primary"
                                    className="transition-3d-hover btn-wide font-weight-semi-bold"
                                    onClick={() => {
                                        goToScreen('sign-in');
                                    }}
                                >
                                    Sign in
                                </Button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center text-muted w-md-100 font-weight-normal my-3">
                                <Formik
                                    initialValues={{
                                        creatingNew: false,
                                        country: account.phone?.country || 'US',
                                        code: account.phone?.code || '1',
                                        number: account.phone?.number || ''
                                    }}
                                    onSubmit={submit}
                                    validate={validatePhone}
                                    validationSchema={validationSchema}
                                    validateOnChange
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        setFieldValue,
                                        handleSubmit,
                                        touched,
                                        values
                                    }) => (
                                        <Form className="w-md-100">
                                            <div className="js-form-message form-group mx-5">
                                                <div className="d-flex flex-row justify-content-center">
                                                    <div className="js-form-message w-50 form-group mb-0">
                                                        <ContryPhoneCodeSelect
                                                            required={true}
                                                            fieldTitle="Mobile phone"
                                                            type="text"
                                                            className="form-control"
                                                            name="number"
                                                            id="number"
                                                            aria-label="number"
                                                            phoneError={returnError(
                                                                errors,
                                                                'number'
                                                            )}
                                                            codeValue={values.code}
                                                            phoneValue={values.number}
                                                            countryValue={values.country}
                                                            onBlurPhone={handleBlur(
                                                                'number'
                                                            )}
                                                            onChangePhone={e => {
                                                                handleChange('number')(e);
                                                            }}
                                                            invalid={
                                                                touched.number &&
                                                                !!errors.number
                                                            }
                                                            onBlurCode={handleBlur(
                                                                'code'
                                                            )}
                                                            onChangeCode={value =>
                                                                setFieldValue(
                                                                    'code',
                                                                    value
                                                                )
                                                            }
                                                            onChangeCountry={value =>
                                                                setFieldValue(
                                                                    'country',
                                                                    value
                                                                )
                                                            }
                                                            note="By submitting this form and signing up for texts, you consent to receive text messages (e.g. invite codes) 
                                                            from SAGE Systems. Msg & data rates may apply. 
                                                            Unsubscribe at any time by replying STOP."
                                                        />
                                                        <a
                                                            href="https://sagesystems.io/privacy"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="js-form-message form-group mx-5">
                                                <div className="d-flex flex-row justify-content-center">
                                                    <div className="js-form-message w-50 form-group mb-0">
                                                        <Field
                                                            type="checkbox"
                                                            className="mr-2"
                                                            name="creatingNew"
                                                        />
                                                        <span
                                                            style={{
                                                                fontSize: '14px',
                                                                lineHeight: 1.2
                                                            }}
                                                            className="text-dark-gray"
                                                        >
                                                            I want to verify this number
                                                            and create a new account.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex row justify-content-end align-items-center border-top mt-3 mx-2">
                                                <Button
                                                    disabled={!values.creatingNew}
                                                    type="submit"
                                                    color="primary"
                                                    className="transition-3d-hover mt-5 mr-2 btn btn-wide"
                                                    onClick={handleSubmit}
                                                >
                                                    Send code
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ confirmAccess: { existingAccount } }) => ({
    account: existingAccount
});

export default connect(mapStateToProps)(ExistingAccountFound);
