// React
import React, { useEffect } from 'react';

// Components
import { Loader, OrgNav } from '../components';

// Packages
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';

// Pages
import { Dashboard, AccessGroups, Community, Content, Devices, Units } from '../pages';

// Redux - Actions, Reducers, Sagas
import { settingPrimaryOrg } from '../store/actions/Org';

// Router
import * as routes from '../router/config/routes';

const SuperOrgDetail = ({
    userData,
    history,
    match,
    userType,
    settingPrimaryOrg,
    primaryOrg,
    orgs
}) => {
    const location = useLocation();
    const id = location?.state?.id;

    useEffect(() => {
        if (orgs && id) {
            const org =
                orgs &&
                id &&
                orgs.find(org => {
                    return org?.org_id.toString() === id;
                });
            settingPrimaryOrg(org);
        }
    }, [orgs, id, settingPrimaryOrg]);

    if (!userData && !id && !primaryOrg) return <Loader />;

    return (
        <div
            className="d-flex flex-column w-100 h-100 flex-auto"
            style={{ minWidth: '0' }}
        >
            <div className="d-flex justify-content-start h-100">
                <div>
                    <OrgNav superAdmin={true} match={match} />
                </div>
                <div className="main w-100">
                    <Switch>
                        <Redirect
                            exact
                            from={`${match.url}`}
                            to={`${match.url}${routes.SUPER_ORG_DASHBOARD}`}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_ORG_DASHBOARD}`}
                            render={props => (
                                <Dashboard
                                    primaryOrg={primaryOrg}
                                    userData={userData}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_ORG_COMMUNITY}`}
                            render={props => (
                                <Community
                                    userType={userType}
                                    userData={userData}
                                    primaryOrg={primaryOrg}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_ORG_ACCESS}`}
                            render={props => (
                                <AccessGroups primaryOrg={primaryOrg} {...props} />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_ORG_DEVICES}`}
                            render={props => (
                                <Devices primaryOrg={primaryOrg} {...props} />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_ORG_UNITS}`}
                            render={props => <Units primaryOrg={primaryOrg} {...props} />}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_ORG_CONTENT}`}
                            render={props => (
                                <Content primaryOrg={primaryOrg} {...props} />
                            )}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, org }) => {
    const { userData } = user;
    const { orgs, primaryOrg } = org;
    return { userData, orgs, primaryOrg };
};

export default withRouter(
    connect(mapStateToProps, { settingPrimaryOrg })(SuperOrgDetail)
);
