import React, { useEffect } from 'react';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    errorMessage,
    nameRegExp,
    phoneExtRegExp,
    phoneRegExp
} from '../../utils/Constants';
import ConfirmFooter from '../common/ConfirmFooter';
import {
    AUTHENTICATED,
    DASHBOARD,
    SUPER_ADMIN,
    SUPER_AUTHENTICATED,
    SUPER_DASHBOARD
} from '../../router/config/routes';
import { Container, Label } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import TextField from '../common/TextField';
import { useHistory } from 'react-router-dom';
import SuccessScreen from '../FeedbackScreens/SuccessScreen';
import FailureScreen from '../FeedbackScreens/FailureScreen';
import { resetMemberCreationState } from '../../store/actions/Managers';
import Loader from '../common/Loader';
import { addInviteToOrg, resetInviteAddingState } from '../../store/actions/Org';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const NewRentalManagerForm = ({
    loading,
    inviteAddingError,
    inviteAddingSuccess,
    orgId,
    orgName,
    isSuperAdmin,
    inviteCode
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return () => dispatch(resetMemberCreationState());
    }, [dispatch]);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        officePhone: Yup.string()
            .trim()
            .matches(phoneRegExp.format, errorMessage.phone.valid),
        officePhoneExt: Yup.string()
            .trim()
            .matches(phoneExtRegExp.format, errorMessage.phoneExt.valid),
        mobilePhone: Yup.string()
            .trim()
            .matches(phoneRegExp.format, errorMessage.phone.valid),
        email: Yup.string()
            .trim()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required)
    });

    const submitForm = formValues => {
        dispatch(
            // addNewMember({
            //     memberInfo: { ...formValues, type: 'rental_manager' },
            //     org_id: orgId
            // })
            addInviteToOrg({
                memberInfo: formValues,
                org_id: orgId,
                org_name: orgName,
                type: 'rental_manager',
                inviteCode
            })
        );
    };

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (inviteAddingSuccess) {
        return (
            <SuccessScreen
                addAction={() => dispatch(resetInviteAddingState())}
                addType="Rental manager"
                message={`Rental manager ${inviteAddingSuccess} has been added.`}
            />
        );
    }

    if (inviteAddingError) {
        return (
            <FailureScreen
                action={() => dispatch(resetInviteAddingState())}
                actionTitle="Back to form"
                error={`Error code: ${inviteAddingError}`}
            />
        );
    }

    return (
        <Container className="d-flex flex-column align-items-start flex-grow-1 justify-content-start">
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    officePhone: '',
                    officePhoneExt: '',
                    mobilePhone: '',
                    email: '',
                    type: 'rental_manager'
                }}
                onSubmit={submitForm}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({
                    handleSubmit,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    values
                }) => (
                    <Form className="col-6 mx-auto">
                        <div className="row">
                            <div className="pl-6 mb-4 row mt-4">
                                <h3 className="font-weight-semi-bold">
                                    New Rental Manager
                                </h3>
                            </div>
                            <div className="col-12">
                                <div className="row m-auto">
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="first_name">
                                            manager first name
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            id="first_name"
                                            placeholder=""
                                            aria-label="first_name"
                                            error={returnError(errors, 'first_name')}
                                            value={values.first_name}
                                            onBlur={handleBlur('first_name')}
                                            onChange={handleChange('first_name')}
                                            invalid={
                                                returnTouched(touched, 'first_name') &&
                                                !!returnError(errors, 'first_name')
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message col-6 form-group">
                                        <Label className="form-label" for="last_name">
                                            manager last name
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="last_name"
                                            placeholder=""
                                            aria-label="last_name"
                                            error={returnError(errors, 'last_name')}
                                            value={values.last_name}
                                            onBlur={handleBlur('last_name')}
                                            onChange={handleChange('last_name')}
                                            invalid={
                                                returnTouched(touched, 'last_name') &&
                                                !!returnError(errors, 'last_name')
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-9 form-group">
                                        <Label className="form-label" for="officePhone">
                                            office phone
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="officePhone"
                                            id="officePhone"
                                            placeholder=""
                                            aria-label="officePhone"
                                            error={returnError(errors, 'officePhone')}
                                            value={values.officePhone}
                                            onBlur={handleBlur('officePhone')}
                                            onChange={handleChange('officePhone')}
                                            invalid={
                                                returnTouched(touched, 'officePhone') &&
                                                !!returnError(errors, 'officePhone')
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message col-3 form-group">
                                        <Label
                                            className="form-label"
                                            for="officePhoneExt"
                                        >
                                            ext
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="officePhoneExt"
                                            id="officePhoneExt"
                                            placeholder=""
                                            aria-label="officePhoneExt"
                                            error={returnError(errors, 'officePhoneExt')}
                                            value={values.officePhoneExt}
                                            onBlur={handleBlur('officePhoneExt')}
                                            onChange={handleChange('officePhoneExt')}
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'officePhoneExt'
                                                ) &&
                                                !!returnError(errors, 'officePhoneExt')
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-12 form-group">
                                        <Label className="form-label" for="mobilePhone">
                                            manager mobile
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="mobilePhone"
                                            id="mobilePhone"
                                            placeholder=""
                                            aria-label="mobilePhone"
                                            error={returnError(errors, 'mobilePhone')}
                                            value={values.mobilePhone}
                                            onBlur={handleBlur('mobilePhone')}
                                            onChange={handleChange('mobilePhone')}
                                            invalid={
                                                returnTouched(touched, 'mobilePhone') &&
                                                !!returnError(errors, 'mobilePhone')
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mx-auto">
                                    <div className="js-form-message col-12 form-group">
                                        <Label className="form-label" for="email">
                                            manager email
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder=""
                                            aria-label="email"
                                            error={returnError(errors, 'email')}
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                            invalid={
                                                returnTouched(touched, 'email') &&
                                                !!returnError(errors, 'email')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <ConfirmFooter
                                    cancel={() =>
                                        history.push(
                                            isSuperAdmin
                                                ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${SUPER_DASHBOARD}`
                                                : `${AUTHENTICATED}${DASHBOARD}`
                                        )
                                    }
                                    submitFunc={handleSubmit}
                                    submitText="Save"
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

const mapStateToProps = ({
    org: { addingInvite, inviteAddingError, inviteAddingSuccess }
}) => {
    return {
        loading: addingInvite,
        inviteAddingError,
        inviteAddingSuccess
    };
};

export default connect(mapStateToProps)(NewRentalManagerForm);
