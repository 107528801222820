import React from 'react';

import { Container, Button, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { errorMessage, passwordRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import { useDispatch } from 'react-redux';
import { createTenantAccess } from '../../store/actions/Reservations';
import { Link } from 'react-router-dom';
import { RESET_PASSWORD } from '../../router/config/routes';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const ConfirmReservationLogin = ({ reservation, goToScreen }) => {
    const dispatch = useDispatch();

    const submit = values => {
        dispatch(createTenantAccess(values));
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        password: Yup.string()
            .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
            .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
            .matches(passwordRegExp.numeric, errorMessage.password.numeric)
            .min(8, errorMessage.password.length)
            .required(errorMessage.password.required)
    });

    return (
        <Container className="space-2 col-small">
            <Col>
                <Formik
                    initialValues={{
                        ...reservation,
                        email: reservation.email || '',
                        password: ''
                    }}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                            <div className="d-flex mt-5 mb-4 align-items-center justify-content-center flex-column">
                                <img
                                    src="../assets/sage-icon.png"
                                    alt="logo"
                                    width={80}
                                />
                                <div className="d-flex w-md-75 flex-column mb-2 align-items-center justify-content-center">
                                    <div
                                        style={{ fontSize: '32px' }}
                                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                                    >
                                        Reservation found!
                                    </div>
                                    <div
                                        style={{ fontSize: '16px' }}
                                        className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal"
                                    >
                                        Sign into your account to confirm your upcoming
                                        reservation.
                                    </div>
                                    <div className="mt-1 mb-4">
                                        <div
                                            className="link-muted link-text font-weight-normal"
                                            onClick={() => {
                                                goToScreen('renter-form');
                                            }}
                                        >
                                            Back to registration.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <div className="js-form-message w-80 form-group mx-5">
                                    <div className="d-flex flex-row justify-content-start">
                                        <div
                                            style={{ flex: 1 }}
                                            className="js-form-message d-flex form-group mb-0"
                                        >
                                            <LabeledFormField
                                                required
                                                fieldTitle="email"
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                id="email"
                                                // placeholder="Email"
                                                aria-label="email"
                                                spellCheck="false"
                                                error={returnError(errors, 'email')}
                                                value={values.email}
                                                onBlur={handleBlur('email')}
                                                onChange={handleChange('email')}
                                                invalid={
                                                    returnTouched(touched, 'email') &&
                                                    !!returnError(errors, 'email')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="js-form-message w-80 form-group mx-5">
                                    <div className="d-flex flex-column justify-content-center">
                                        <div
                                            style={{ flex: 1 }}
                                            className="js-form-message d-flex form-group mb-0"
                                        >
                                            <LabeledFormField
                                                fieldTitle="password"
                                                required
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                id="password"
                                                placeholder=""
                                                aria-label="password"
                                                error={returnError(errors, 'password')}
                                                value={values.password}
                                                onBlur={handleBlur('password')}
                                                onChange={handleChange('password')}
                                                invalid={
                                                    returnTouched(touched, 'password') &&
                                                    !!returnError(errors, 'password')
                                                }
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <Link
                                                className="link-muted mx-2 text-capitalize text-note font-weight-normal"
                                                to={`${RESET_PASSWORD}`}
                                            >
                                                Forgot Password?
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex row justify-content-end align-items-center border-top my-5 mx-2">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="transition-3d-hover mt-5 mr-2 btn btn-wide"
                                    onClick={handleSubmit}
                                >
                                    Sign in
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

export default ConfirmReservationLogin;
