import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import * as selectors from './Selectors';

import { SET_PRIMARY_ORG, ADD_OTC, REVOKE_OTC, LOGOUT_USER } from '../actions/types';

import { eventChannel } from 'redux-saga';

import {
    addOtcFailure,
    addOtcSuccess,
    revokeOtcFailure,
    revokeOtcSuccess,
    getOtcCodesSuccess
} from '../actions/Otc';

import { db, timeStampNow } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

const otcCodes = db.collection('otc');

//////////////// Get User Organization ////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// OTC Codes Collection /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* otcCollectionWatch({ payload }) {
    const org = payload;
    const creators = org?.managers?.length ? org.managers.map(manager => manager.id) : [];
    let unsubscribeOtcData;
    const otcCollectionChannel = eventChannel(emit => {
        unsubscribeOtcData = otcCodes
            .where('creator_id', 'in', creators)
            .where('expires_at', '>', timeStampNow())
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot) {
                    var codes = [];
                    querySnapshot.forEach(function (doc) {
                        codes.push({
                            ...doc.data()
                        });
                    });
                    emit(codes);
                } else {
                    const doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeOtcData;
    });
    try {
        while (true) {
            const { userSignOut, orgCodesData } = yield race({
                userSignOut: take(LOGOUT_USER),
                orgCodesData: take(otcCollectionChannel)
            });

            if (userSignOut) {
                otcCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(getOtcCodesSuccess(orgCodesData));
            }
        }
    } catch (error) {
        log('Org Error: getting org codes collection data (FS)', {
            error,
            org,
            creators
        });
    } finally {
        unsubscribeOtcData(); // Detach firebase listener
        if (yield cancelled()) {
            otcCollectionChannel.close(); // Detach saga event emitter
            unsubscribeOtcData(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Add OTC //////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addingOtcRequest = otcData => {
    return new Promise((resolve, reject) => {
        const otcDocRef = otcCodes.doc();
        const otcId = otcDocRef.id;
        const data = { ...otcData, code_id: otcId, created_at: timeStampNow() };
        otcDocRef
            .set(data)
            .then(() => {
                resolve({ res: data });
            })
            .catch(error => {
                reject({ error: 'Error: creating One Time Access Code' });
            });
    });
};

export function* addingOtc({ payload }) {
    const { res, error } = yield call(() => addingOtcRequest(payload));
    if (res) {
        yield put(addOtcSuccess(res));
    } else {
        yield put(addOtcFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error adding Org otc (FS)`, {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Revoke OTC's /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const revokingOtcRequest = ({ code, expire, userData }) => {
    return new Promise((resolve, reject) => {
        const otcDocRef = otcCodes.doc(code);

        otcDocRef
            .update({ expires_at: expire, creator_id: `${userData.uid}_expired` })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error: 'Error: revoking One Time Access Code' });
            });
    });
};

export function* revokingOtc({ payload }) {
    const { code, expire } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        revokingOtcRequest({ code, expire, userData })
    );
    if (res) {
        yield put(revokeOtcSuccess());
    } else {
        yield put(revokeOtcFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error adding Org otc (FS)`, {
            error
        });
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getOtcCodes() {
    yield takeLatest(SET_PRIMARY_ORG, otcCollectionWatch);
}

export function* addOtcCode() {
    yield takeLatest(ADD_OTC, addingOtc);
}

export function* revokeOtcCode() {
    yield takeLatest(REVOKE_OTC, revokingOtc);
}

export default function* rootSaga() {
    yield all([fork(getOtcCodes), fork(addOtcCode), fork(revokeOtcCode)]);
}
