// React
import React from 'react';

// Components
import { Loader, ToolBar, SideNav } from '../components';

// Packages
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

// Pages
import {
    SuperDashboard,
    SuperReports,
    SuperSettings,
    SuperOrgDetail,
    NewOrg,
    AddManager,
    AddMember,
    AddRentalManager,
    ImportMembers,
    ResetPasswordAuthenticated
} from '../pages';

// Redux - Actions, Reducers, Sagas

// Router
import * as routes from '../router/config/routes';
import AddReservation from './AddReservation';
import RentalDashboard from './RentalDashboard';
import ReservationDetails from '../components/RentalManagerDashboard/ReservationDetails';
import AddReservationFromCSV from './AddReservationFromCSV';
import ProfileSettingsPage from './ProfileSettingsPage';

const SuperAdmin = ({ userData, history, match, userType }) => {
    if (!userData) return <Loader />;
    return (
        <div style={{ height: '100vh' }}>
            <div
                className="d-flex flex-column w-100 h-100 flex-auto"
                style={{ minWidth: '0' }}
            >
                <ToolBar />
                <div className="d-flex justify-content-start h-100">
                    <SideNav
                        userData={userData}
                        history={history}
                        match={match}
                        userType={userType}
                    />
                    <Switch>
                        <Redirect
                            exact
                            from={`${match.url}`}
                            to={`${match.url}${routes.SUPER_DASHBOARD}`}
                        />
                        <Route
                            path={`${match.url}${routes.ADD_NEW_ORG}`}
                            render={props => (
                                <NewOrg {...props} primaryOrg={userData.primaryOrg} />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.ADD_NEW_MANAGER}`}
                            render={props => (
                                <AddManager
                                    isSuperAdmin={true}
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.ADD_NEW_MEMBER}`}
                            render={props => (
                                <AddMember
                                    isSuperAdmin={true}
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.ADD_NEW_RENTAL_MANAGER}`}
                            render={props => (
                                <AddRentalManager
                                    isSuperAdmin={true}
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.RENTAL_DASHBOARD}`}
                            render={props => (
                                <RentalDashboard
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.RESERVATION_DETAILS_EDIT}`}
                            render={props => (
                                <ReservationDetails
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.GUEST_RESERVATION}`}
                            render={props => (
                                <AddReservation
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.GUEST_RESERVATION_BULK}`}
                            render={props => (
                                <AddReservationFromCSV
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.IMPORT_MEMBERS_CSV}`}
                            render={props => (
                                <ImportMembers
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.RESET_PASSWORD_AUTHENTICATED}`}
                            render={props => <ResetPasswordAuthenticated {...props} />}
                        />
                        <Route
                            path={`${match.url}${routes.PROFILE_SETTINGS}`}
                            render={props => <ProfileSettingsPage {...props} />}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_DASHBOARD}`}
                            render={props => <SuperDashboard {...props} />}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_REPORTS}`}
                            render={props => <SuperReports {...props} />}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_SETTINGS}`}
                            render={props => <SuperSettings {...props} />}
                        />
                        <Route
                            path={`${match.url}${routes.SUPER_ORG_DETAIL}`}
                            render={props => (
                                <SuperOrgDetail userType={userType} {...props} />
                            )}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default withRouter(connect(mapStateToProps, {})(SuperAdmin));
