// React
import React, { useState, useEffect } from 'react';

// Components
import ContentTabHeader from '../components/Content/ContentTabHeader';
import EditPostModal from '../components/Content/EditPostModal';
import NewPostModal from '../components/Content/NewPostModal';
import PostsList from '../components/Content/PostsList';

// Packages
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { getPosts } from '../store/actions/Posts';

// Router
import { RENTAL_DASHBOARD, RENTAL_MANAGER } from '../router/config/routes';

const Content = ({ primaryOrg, posts, userData, activeOrg }) => {
    const dispatch = useDispatch();
    const [modalProps, setModalProps] = useState(null);
    const [newOpen, setNewOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const orgData = userData?.type === 'manager' ? activeOrg : primaryOrg;

    useEffect(() => {
        dispatch(getPosts(orgData.org_id));
    }, [dispatch, orgData.org_id]);

    const newPostModalToggle = () => {
        if (newOpen) {
            setNewOpen(false);
            setModalProps(null);
        }
        if (!newOpen) setNewOpen(true);
    };

    const editPostModalToggle = () => {
        if (editOpen) {
            setEditOpen(false);
            setModalProps(null);
        }
        if (!editOpen) setEditOpen(true);
    };

    const modalOpen = props => {
        setModalProps(props);
        editPostModalToggle();
    };

    if (!userData) return null;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    return (
        <div className="main">
            <ContentTabHeader
                toggleOpen={newPostModalToggle}
                isOpen={newOpen}
                org_name={orgData?.org_name}
                org_id={orgData?.org_id}
            />
            {posts && (
                <PostsList
                    modalOpen={modalOpen}
                    posts={posts.filter(post => post.org_id === orgData?.org_id)}
                />
            )}
            <NewPostModal
                org_id={orgData?.org_id}
                toggleOpen={newPostModalToggle}
                isOpen={newOpen}
            />
            <EditPostModal
                org_id={orgData?.org_id}
                toggleOpen={editPostModalToggle}
                isOpen={editOpen}
                modalProps={modalProps}
            />
        </div>
    );
};

const mapStateToProps = ({
    post: { posts, loadingPosts, postsLoadError },
    user: { activeOrg }
}) => {
    return {
        posts,
        loading: loadingPosts,
        error: postsLoadError,
        activeOrg
    };
};

export default connect(mapStateToProps)(Content);
