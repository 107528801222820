import {
    ADD_NEW_REGISTRATION,
    ADD_NEW_REGISTRATION_SUCCESS,
    ADD_NEW_REGISTRATION_FAILURE,
    RESET_ADD_NEW_REGISTRATION
} from './types';

export const addingNewRegistration = member => {
    return {
        type: ADD_NEW_REGISTRATION,
        payload: member
    };
};

export const addingNewRegistrationSuccess = () => {
    return {
        type: ADD_NEW_REGISTRATION_SUCCESS
    };
};

export const addingNewRegistrationFailure = error => {
    return {
        type: ADD_NEW_REGISTRATION_FAILURE,
        payload: error
    };
};

export const resetAddingNewRegistration = reset => {
    return {
        type: RESET_ADD_NEW_REGISTRATION,
        payload: reset
    };
};
