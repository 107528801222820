import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';

const Loader = props => {
    return (
        <Row className="vh-100 align-items-center justify-content-center">
            <Col xs="auto">
                <Spinner {...props} />
            </Col>
        </Row>
    );
};

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
    type: 'grow',
    size: 'lg',
    color: 'primary'
};

export default Loader;
