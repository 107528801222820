import React, { useEffect } from 'react';
import { Container, Col, Button, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';
import Loader from '../common/Loader';
import AddGuestSuccess from './AddGuestSuccess';
import { Checkbox, Select, TimePicker } from 'antd';
import { connect } from 'react-redux';
import {
    addingNewGuest,
    resettingNewGuest,
    removeGuest,
    resettingRemoveGuest
} from '../../store/actions/Guests';
import {
    AUTHENTICATED,
    GUEST_MANAGER,
    GUEST_MANAGER_ADD
} from '../../router/config/routes';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const dayPickerOptions = [
    {
        label: 'Any Day',
        value: ''
    },
    {
        label: 'Mon',
        value: 'mon'
    },
    {
        label: 'Tue',
        value: 'tue'
    },
    {
        label: 'Wed',
        value: 'wed'
    },
    {
        label: 'Thu',
        value: 'thu'
    },
    {
        label: 'Fri',
        value: 'fri'
    },
    {
        label: 'Sat',
        value: 'sat'
    },
    {
        label: 'Sun',
        value: 'Sun'
    }
];

const AddGuestForm = ({
    loading,
    error,
    guest,
    addGuestSuccess,
    addingNewGuest,
    removeGuest,
    removeGuestSuccess,
    resettingRemoveGuest,
    resettingNewGuest
}) => {
    const history = useHistory();

    useEffect(() => {
        if (removeGuestSuccess) {
            resettingRemoveGuest();
            history.push(`${AUTHENTICATED}${GUEST_MANAGER}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeGuestSuccess]);

    const submit = values => {
        let accessEndTime = null;
        let accessStartTime = null;
        if (values.time_range && !values.anytime_access) {
            accessStartTime = {
                hours: values.time_range[0].$H,
                minutes: values.time_range[0].$m
            };
            accessEndTime = {
                hours: values.time_range[1].$H,
                minutes: values.time_range[1].$m
            };
        }
        let accessDays = null;
        const phone = {
            number:
                values.phone.trim() === ''
                    ? false
                    : parseInt(values.phone.replace(/\D/g, '')),
            code: parseInt(values.code),
            country: values.country.trim()
        };

        if (!values.access_days.includes('')) {
            accessDays = values.access_days;
        }
        const member = {
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            email: values.email.trim() === '' ? false : values.email.trim().toLowerCase(),
            phone: phone,
            access_end_time: accessEndTime,
            access_start_time: accessStartTime,
            access_days: accessDays,
            ...(guest?.id && { invite_id: guest.id })
        };

        addingNewGuest({ member });
    };

    const initialValues = {
        first_name: guest?.first_name || '',
        last_name: guest?.last_name || '',
        email: guest?.email || '',
        phone: guest?.phone?.number.toString() || '',
        code: guest?.phone?.code || '1',
        country: guest?.phone?.country || 'US',
        access_days: guest?.access_days === null ? [''] : guest?.access_days || [],
        time_range:
            guest?.access_start_time && guest?.access_end_time
                ? [
                      dayjs()
                          .hour(guest.access_start_time.hours)
                          .minute(guest.access_start_time.hours),
                      dayjs()
                          .hour(guest.access_end_time.hours)
                          .minute(guest.access_end_time.hours)
                  ]
                : null,
        anytime_access: guest
            ? !guest?.access_start_time && !guest?.access_end_time
            : false
    };

    const validationSchema = Yup.object().shape(
        {
            first_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.firstName.valid)
                .required(errorMessage.firstName.required),
            last_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.lastName.valid)
                .required(errorMessage.lastName.required),
            email: Yup.string()
                .trim()
                .lowercase()
                .email(errorMessage.email.valid)
                .when('phone', {
                    is: phone => !phone || phone.length === 0,
                    then: Yup.string().required(errorMessage.emailOrPhone.required),
                    otherwise: Yup.string()
                }),
            phone: Yup.string()
                .trim()
                .when('email', {
                    is: email => !email || email.length === 0,
                    then: Yup.string().required(errorMessage.phoneOrEmail.required),
                    otherwise: Yup.string().trim()
                }),
            country: Yup.string().trim(),
            code: Yup.string().trim(),
            access_days: Yup.array().min(1, errorMessage.accessDays.required),
            time_range: Yup.mixed().test(
                'time-range-validation',
                errorMessage.timeRange.required,
                function (value) {
                    const { time_range, anytime_access } = this.parent;
                    if (!time_range && !anytime_access) {
                        return false; // Both are empty
                    }
                    return true;
                }
            )
        },
        [
            ['phone', 'email'],
            ['email', 'phone']
        ]
    );

    const validatePhoneEmail = ({ phone, code, country }) => {
        const phoneSchema = Yup.string().phone(country);

        const isPhoneValid = phone ? phoneSchema.isValidSync(code + phone) : true;

        return isPhoneValid
            ? undefined
            : {
                  phone: 'Mobile phone needs to be a valid number'
              };
    };

    const handleResetingNewGuest = () => {
        resettingNewGuest();
        history.push(GUEST_MANAGER_ADD);
    };

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (addGuestSuccess || error) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <AddGuestSuccess error={error} resetForm={handleResetingNewGuest} />
            </div>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                    validate={validatePhoneEmail}
                    validationSchema={validationSchema}
                    validateOnChange
                    enableReinitialize
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <div className="w-md-75 mx-md-auto card bg-white form-shadow p-2">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <div>{guest?.email ? 'Edit' : 'Add'} Guest</div>
                                <Link to={`${AUTHENTICATED}${GUEST_MANAGER}`}>
                                    Back To List
                                </Link>
                            </div>
                            <div className="card-body">
                                <div className="mb-5">
                                    <Form className="p-2">
                                        <div className="js-form-message form-group mb-0 mb-s-4 mx-4 mx-lg-7">
                                            <div className="row">
                                                <div className="js-form-message col-md-12 col-lg-6 form-group">
                                                    <LabeledFormField
                                                        fieldTitle="first name"
                                                        required
                                                        type="text"
                                                        className="form-control capitalize"
                                                        name="first_name"
                                                        id="first_name"
                                                        placeholder="First Name"
                                                        aria-label="first_name"
                                                        error={returnError(
                                                            errors,
                                                            'first_name'
                                                        )}
                                                        value={values.first_name}
                                                        onBlur={handleBlur('first_name')}
                                                        onChange={handleChange(
                                                            'first_name'
                                                        )}
                                                        invalid={
                                                            returnTouched(
                                                                touched,
                                                                'first_name'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'first_name'
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="js-form-message col-md-12 col-lg-6 form-group">
                                                    <LabeledFormField
                                                        fieldTitle="last name"
                                                        required
                                                        type="text"
                                                        className="form-control capitalize"
                                                        name="last_name"
                                                        id="last_name"
                                                        placeholder="Last Name"
                                                        aria-label="last_name"
                                                        error={returnError(
                                                            errors,
                                                            'last_name'
                                                        )}
                                                        value={values.last_name}
                                                        onBlur={handleBlur('last_name')}
                                                        onChange={handleChange(
                                                            'last_name'
                                                        )}
                                                        invalid={
                                                            returnTouched(
                                                                touched,
                                                                'last_name'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'last_name'
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="js-form-message form-group mx-4 mx-lg-7 mb-0 mb-s-4">
                                            <div className="row">
                                                <div className="js-form-message col-md-12 col-lg-6 form-group">
                                                    <LabeledFormField
                                                        fieldTitle="email"
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Email Address"
                                                        aria-label="email"
                                                        error={returnError(
                                                            errors,
                                                            'email'
                                                        )}
                                                        value={values.email}
                                                        onBlur={handleBlur('email')}
                                                        onChange={handleChange('email')}
                                                        invalid={
                                                            returnTouched(
                                                                touched,
                                                                'email'
                                                            ) &&
                                                            !!returnError(errors, 'email')
                                                        }
                                                    />
                                                </div>
                                                <div className="js-form-message col-md-12 col-lg-6 form-group">
                                                    <ContryPhoneCodeSelect
                                                        fieldTitle="Mobile phone"
                                                        type="text"
                                                        className="form-control"
                                                        name="phone"
                                                        id="phone"
                                                        errorSectionMinHeight="0"
                                                        aria-label="phone"
                                                        phoneError={returnError(
                                                            errors,
                                                            'phone'
                                                        )}
                                                        phoneTouched={returnTouched(
                                                            touched,
                                                            'phone'
                                                        )}
                                                        codeValue={values?.code}
                                                        phoneValue={values?.phone}
                                                        countryValue={values?.country}
                                                        onBlurPhone={handleBlur('phone')}
                                                        onChangePhone={e => {
                                                            handleChange('phone')(e);
                                                        }}
                                                        onBlurCode={handleBlur('code')}
                                                        onChangeCode={value =>
                                                            setFieldValue('code', value)
                                                        }
                                                        onChangeCountry={value =>
                                                            setFieldValue(
                                                                'country',
                                                                value
                                                            )
                                                        }
                                                        invalid={
                                                            returnTouched(
                                                                touched,
                                                                'phone'
                                                            ) &&
                                                            !!returnError(errors, 'phone')
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="js-form-message form-group mx-4 mx-lg-7">
                                            <div className="row">
                                                <div className="js-form-message col-md-12 col-lg-12 form-group">
                                                    <label
                                                        htmlFor="daypicker"
                                                        className="form-field-label d-flex flex-row undefined"
                                                    >
                                                        Access Days
                                                    </label>
                                                    <Select
                                                        mode="tags"
                                                        name="access_days"
                                                        virtual={false}
                                                        defaultValue={values.access_days}
                                                        id="access_days"
                                                        className={`${
                                                            returnTouched(
                                                                touched,
                                                                'access_days'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'access_days'
                                                            )
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        placeholder="Select access days"
                                                        allowClear
                                                        value={values.access_days}
                                                        status={
                                                            returnTouched(
                                                                touched,
                                                                'access_days'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'access_days'
                                                            )
                                                                ? 'error'
                                                                : ''
                                                        }
                                                        onBlur={handleBlur('access_days')}
                                                        onChange={value => {
                                                            const lastElement =
                                                                value[value.length - 1];
                                                            if (lastElement === '') {
                                                                setFieldValue(
                                                                    'access_days',
                                                                    ['']
                                                                );
                                                            } else {
                                                                const updatedValue =
                                                                    value.filter(
                                                                        val => val !== ''
                                                                    );
                                                                setFieldValue(
                                                                    'access_days',
                                                                    updatedValue
                                                                );
                                                            }
                                                        }}
                                                        optionLabelProp="label"
                                                        options={dayPickerOptions}
                                                    />
                                                    <FormFeedback>
                                                        {returnError(
                                                            errors,
                                                            'access_days'
                                                        )}
                                                    </FormFeedback>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="js-form-message form-group mx-4 mx-lg-7 mb-s-4">
                                            <div className="row">
                                                <div className="js-form-message col-md-12 col-lg-12 form-group">
                                                    <label
                                                        htmlFor="daypicker"
                                                        className="form-field-label d-flex flex-row undefined"
                                                    >
                                                        Access Times
                                                    </label>
                                                    <TimePicker.RangePicker
                                                        defaultValue={values.time_range}
                                                        use12Hours
                                                        onChange={value =>
                                                            setFieldValue(
                                                                'time_range',
                                                                value
                                                            )
                                                        }
                                                        className={`mr-3 ${
                                                            returnTouched(
                                                                touched,
                                                                'time_range'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'time_range'
                                                            )
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        status={
                                                            returnTouched(
                                                                touched,
                                                                'time_range'
                                                            ) &&
                                                            !!returnError(
                                                                errors,
                                                                'time_range'
                                                            )
                                                                ? 'error'
                                                                : ''
                                                        }
                                                        disabled={values.anytime_access}
                                                        onBlur={handleBlur('time_range')}
                                                        format={'HH:mm A'}
                                                    />
                                                    <Checkbox
                                                        checked={values.anytime_access}
                                                        onChange={() =>
                                                            setFieldValue(
                                                                'anytime_access',
                                                                !values.anytime_access
                                                            )
                                                        }
                                                    >
                                                        Anytime Access
                                                    </Checkbox>
                                                    <FormFeedback>
                                                        {returnError(
                                                            errors,
                                                            'time_range'
                                                        )}
                                                    </FormFeedback>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="card-footer d-flex flex-column flex-sm-row-reverse align-items-center justify-content-between w-100">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary w-100 w-sm-auto mb-3 mb-sm-0"
                                    onClick={handleSubmit}
                                >
                                    Save & Invite Guest
                                </Button>
                                {guest && (
                                    <Button
                                        color="danger"
                                        outline
                                        className="btn btn-outline-danger w-100 w-sm-auto"
                                        onClick={() => removeGuest({ guest })}
                                    >
                                        Remove Guest
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

// export default AddGuestForm;
const mapStateToProps = ({ guests }) => {
    const { loading, error, addGuestSuccess, removeGuestSuccess } = guests;
    return { loading, error, addGuestSuccess, removeGuestSuccess };
};

export default connect(mapStateToProps, {
    addingNewGuest,
    resettingNewGuest,
    removeGuest,
    resettingRemoveGuest
})(AddGuestForm);
