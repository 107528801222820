import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setPublicStatusFalse, setPublicStatusTrue } from '../../store/sagas/Posts';

const PostsList = ({ posts, modalOpen }) => {
    const { windowHeight } = useWindowSize();

    const getPostDateString = timestamp => {
        const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
        const options = { dateStyle: 'full', timeStyle: 'short' };
        const dateString = new Intl.DateTimeFormat('en-US', options).format(date);
        return dateString;
    };

    return (
        <div
            className="mx-3 w-100"
            style={{ height: [windowHeight] - 453, overflowY: 'auto' }}
        >
            <InfiniteScroll dataLength={posts.length} loader={<h4>Loading...</h4>}>
                {posts.map(post => {
                    return (
                        <div
                            onClick={() => modalOpen(post)}
                            key={post.post_id}
                            className="org-item justify-content-between align-items-center"
                        >
                            <div className="col-3">{post.title}</div>
                            <div className="col-6">
                                {getPostDateString(post.post_date)}
                            </div>
                            <div className="col-3 d-flex justify-content-end pr-5">
                                <span
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (post.public) {
                                            setPublicStatusFalse(post.post_id);
                                        } else {
                                            setPublicStatusTrue(post.post_id);
                                        }
                                    }}
                                    className={`invite-badge ${
                                        post.public ? 'badge-green' : 'badge-gray'
                                    }`}
                                >
                                    {post.public ? 'Active' : 'Draft'}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default PostsList;
