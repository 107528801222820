import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import Avatar from 'react-avatar';
import AvatarEditModal from './AvatarEditModal';
import { useDropzone } from 'react-dropzone';

const ImageUploadEdit = ({ userData, imgSrc, setImgSrc }) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        if (open) {
            setOpen(false);
        }
        if (!open) setOpen(true);
    };

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles) {
                acceptedFiles.forEach(file => {
                    const reader = new FileReader();

                    reader.onload = () => {
                        setImgSrc(reader.result);
                        setOpen(true);
                    };
                    reader.readAsDataURL(file);
                });
            }
        },
        [setImgSrc]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div className="d-flex align-items-center justify-content-start">
            <div className="mx-2 profile-img">
                <Avatar
                    color={'#252c33'}
                    src={userData?.avatar}
                    alt="loading..."
                    name={userData && `${userData.first_name} ${userData.last_name}`}
                />
            </div>
            <div className="ml-3 d-flex flex-column align-items-start justify-content-center">
                <Button
                    type="button"
                    color="primary"
                    className="transition-3d-hover btn-sm mr-5 px-3 py-1 mb-2 font-weight-semi-bold"
                    {...getRootProps()}
                >
                    <input
                        accept="image/x-png,image/jpeg"
                        style={{ width: '70%' }}
                        {...getInputProps()}
                    />
                    {userData?.avatar ? 'upload new picture' : 'picture upload'}
                </Button>
                <p className="text-muted text-note mb-0">
                    Customize your profile with a picture.
                </p>
            </div>
            <AvatarEditModal
                userData={userData}
                isOpen={open}
                setOpen={setOpen}
                image={imgSrc}
                setImgSrc={setImgSrc}
                toggleOpen={toggle}
            />
        </div>
    );
};

export default ImageUploadEdit;
