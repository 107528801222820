import React from 'react';
import App from './App.jsx';
import AppProviders from './context';
import { createRoot } from 'react-dom/client';
import 'flatpickr/dist/themes/material_blue.css';
import './styles/styles.scss';
import 'simplebar/dist/simplebar.min.css';
import 'notyf/notyf.min.css';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <AppProviders>
        <App />
    </AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
