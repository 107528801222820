import React from 'react';

import 'flatpickr/dist/themes/material_green.css';

import { FormFeedback, Label } from 'reactstrap';
import TextField from '../common/TextField';
import DatePicker from 'react-flatpickr';
import SelectField from '../common/SelectField';
import { isDateUnavailable } from '../../utils/Helpers';

const LabeledFormField = props => {
    const {
        dropdownOptions,
        fieldTitle,
        name,
        type,
        id,
        required,
        disabled,
        selected,
        invalid,
        onChange,
        onBlur,
        note,
        datePickerField,
        datePickerOptions,
        withDate,
        withTime,
        dropDownField,
        className,
        placeholder,
        value,
        error
    } = props;

    return (
        <div className="js-form-message d-flex flex-grow-1 w-100 flex-column form-group">
            <Label
                className={`form-field-label d-flex flex-row ${disabled && 'text-muted'}`}
                for={name}
            >
                {required ? (
                    <>
                        {fieldTitle}
                        <span style={{ color: 'red', fontSize: '11px' }}>&nbsp;*</span>
                    </>
                ) : (
                    fieldTitle
                )}
            </Label>
            {!datePickerField && !dropDownField && <TextField {...props} />}
            {datePickerField && (
                <>
                    <DatePicker
                        required={required}
                        style={{
                            borderColor:
                                !disabled && !invalid && value
                                    ? 'rgba(0, 201, 167, 0.5)'
                                    : '',
                            color: disabled && 'grey'
                        }}
                        onChange={onChange}
                        selected={selected}
                        className={`${className}${invalid ? ' is-invalid' : ''}`}
                        placeholder={placeholder}
                        value={value}
                        error={error}
                        disabled={disabled}
                        options={{
                            ...datePickerOptions,
                            altInput: true,
                            altFormat: withDate
                                ? withTime
                                    ? 'F j, Y h:i K'
                                    : 'F j, Y'
                                : 'h:i K',
                            noCalendar: !withDate,
                            dateFormat: withDate
                                ? withTime
                                    ? 'm-d-Y H:i'
                                    : 'm-d-Y'
                                : 'H:i',
                            enableTime: withTime,
                            minuteIncrement: 1,
                            mode: 'single',
                            animate: true,
                            disable: [isDateUnavailable]
                        }}
                    />
                    <FormFeedback>{error}</FormFeedback>
                </>
            )}
            {dropDownField && (
                <>
                    <SelectField
                        autoComplete="off"
                        required={required}
                        type={type}
                        className={`${invalid ? 'is-invalid' : ''}`}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                boxShadow: 'none',
                                borderColor:
                                    (!disabled && !invalid && value) ||
                                    state.isSelected ||
                                    state.isFocused
                                        ? 'rgba(0, 201, 167, 0.5)'
                                        : invalid
                                        ? 'red'
                                        : '#d5dae2',
                                color: disabled && 'grey'
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: state.isSelected
                                    ? '#63DE7C'
                                    : state.isFocused
                                    ? '#d5dae2'
                                    : '',
                                color: state.isSelected ? 'white' : 'black',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            })
                        }}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        error={error}
                        value={value}
                        onBlur={onBlur}
                        onChange={e => {
                            onChange(e);
                        }}
                        options={dropdownOptions}
                    />
                    <FormFeedback>{error}</FormFeedback>
                </>
            )}
            {note && <p className="text-muted text-note mb-0">{note}</p>}
        </div>
    );
};

export default LabeledFormField;
