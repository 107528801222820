import { all, fork, takeEvery, put, call } from 'redux-saga/effects';

import { ADD_GUEST_INVITES } from '../actions/types';

import { addGuestInvitesSuccess } from '../actions/SuperAdmin';

import { db, timeStampNow } from '../../config/Firebase';

import { GUEST_INVITE_SCHEMA } from '../../utils/Constants';

import { generateInviteCode } from '../../utils/Helpers';

// import InviteList from '../../utils/HamptonLakes';

const guest_invites = db.collection('guest_invites');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Guest Invites ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addGuestInvitesRequest = async () => {
    return new Promise((resolve, reject) => {
        const invites = [];

        // [
        //     {
        //         address: '1 Test St, Apt B Muncie, IN 33576',
        //         first_name: 'Marctest',
        //         last_name: 'One',
        //         email: 'testmarc1@pixelmii.com',
        //         phone: false
        //     },
        //     {
        //         address: '2 Test Ave',
        //         first_name: 'Marctest',
        //         last_name: 'Two',
        //         email: 'testmarc2@pixelmii.com',
        //         phone: 2392894279
        //     },
        //     {
        //         address: '3 Test Circle, West Palm Beach, FL',
        //         first_name: 'Marctest',
        //         last_name: 'Three',
        //         email: false,
        //         phone: 2392894279
        //     },
        //     {
        //         address: '1 Test Ln Orlando FL',
        //         first_name: 'Kentest',
        //         last_name: 'One',
        //         email: 'testken1@pixelmii.com',
        //         phone: false
        //     },
        //     {
        //         address: '2 Test Way',
        //         first_name: 'Kentest',
        //         last_name: 'Two',
        //         email: ' testken2@pixelmii.com',
        //         phone: false
        //     },
        //     {
        //         address: '333 Test Court, Miami, FL 33956',
        //         first_name: 'Kentest',
        //         last_name: 'Three',
        //         email: false,
        //         phone: 4048035078
        //     }
        // ];
        const generated = [];

        invites.forEach((invite, index) => {
            const guestInviteDocRef = guest_invites.doc();
            const guestInviteKeyId = guestInviteDocRef.id;
            const data = GUEST_INVITE_SCHEMA;
            const firstName = invite.first_name;
            const lastName = invite.last_name;
            const email = invite.email ? invite.email : null;
            const phone = invite.phone
                ? { code: 1, country: 'US', number: invite.phone }
                : null;
            const phoneNumber = invite.phone ? `${1}${invite.phone}` : null;
            const inviteCode = generateInviteCode();

            generated.push(`${inviteCode}`);

            const address = {
                address_1: invite.address ? invite.address : null,
                address_2: null,
                city: null,
                latitude: null,
                longitude: null,
                state: null,
                zip: null
            };
            const orgId = 'aMz00pWicSHfBwTNleEk';
            const orgName = 'Hampton Lakes';

            const guestInviteData = Object.assign(data, {
                address: address,
                creator_first_name: 'Patti',
                creator_id: '2raKhCTSJgHN7HKppZGPwvZzB7Cn',
                creator_last_name: 'Kaiser',
                email: email,
                first_name: firstName,
                invite_code: inviteCode,
                invite_id: guestInviteKeyId,
                invite_status: 'pending',
                invited_at: timeStampNow(),
                last_name: lastName,
                org_id: orgId,
                org_name: orgName,
                phone: phone,
                phone_number: phoneNumber,
                role: 'resi',
                suspended: false,
                type: 'member'
            });

            guestInviteDocRef
                .set(guestInviteData)
                .then(() => {
                    if (index + 1 === invites.length) {
                        console.log(`Total Invites Created: ${invites.length}`);
                        console.log(JSON.stringify({ generated }, null, 0));
                        resolve({ res: true });
                    }
                })
                .catch(error => {
                    reject({ error: 'Error: creating guest invite' });
                });
        });
    });
};

export function* addGuestInvites() {
    const { res, error } = yield call(() => addGuestInvitesRequest());
    if (res) {
        console.log('Guest Invites Completed!!!');
        yield put(addGuestInvitesSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        console.log('Super Admin Error: error adding internal guest invites (FS)', {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* addingGuestInvites() {
    yield takeEvery(ADD_GUEST_INVITES, addGuestInvites);
}

export default function* rootSaga() {
    yield all([fork(addingGuestInvites)]);
}
