// React
import React from 'react';

// Packages
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Router
import * as routes from '../router/config/routes';

import { NewOrgForm } from '../components';

const NewOrg = ({ userData }) => {
    if (userData && userData.type === 'super_admin') {
        return <NewOrgForm userData={userData} />;
    }

    return <Redirect to={`${routes.AUTHENTICATED}${routes.DASHBOARD}`} />;
};

const mapStateToProps = ({ user }) => {
    return {
        userData: user.userData
    };
};

export default connect(mapStateToProps)(NewOrg);
