import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ContactsListSearch from './ContactsListSearch';
import { removingPending } from '../../store/actions/Members';

import PendingList from './PendingList';

const Pending = ({ selectedTab, orgId, invited, removingPending }) => {
    const [searchString, setSearchsString] = useState('');
    const [selectAllFlag, setSelectAllFlag] = useState(false);
    const [selectList, setSelectList] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);

    const selectAll = () => {
        if (selectAllFlag) {
            setSelectList([]);
            setSelectAllFlag(false);
            return;
        }
        filteredContacts && filteredContacts.length
            ? setSelectList(filteredContacts.map(contact => contact.invite_id))
            : setSelectList(invited.map(invite => invite.invite_id));

        setSelectAllFlag(true);
    };

    const addToSelectList = selected => {
        if (selected === null) {
            setSelectList([]);
            return;
        }
        const alreadyAdded = selectList.includes(selected);
        if (alreadyAdded) {
            setSelectList(selectList.filter(el => el !== selected));
        } else setSelectList([...selectList, selected]);
    };

    const handleRemove = () => {
        selectList.forEach(pend => {
            removingPending({ pending: [pend] });
        });
        setSelectList([]);
        setSelectAllFlag(false);
    };

    const inviteMembersSearch = useCallback(
        input => {
            if (!input.trim()) {
                setFilteredContacts([]);
                return;
            }

            const filteredContacts = invited.filter(contact => {
                const { first_name, last_name, email, address } = contact;
                const lowerCaseSearchString = input.trim().toLowerCase();
                if (
                    first_name &&
                    first_name.toLowerCase().includes(lowerCaseSearchString)
                )
                    return true;
                if (last_name && last_name.toLowerCase().includes(lowerCaseSearchString))
                    return true;
                if (email && email.toLowerCase().includes(lowerCaseSearchString))
                    return true;
                if (
                    address &&
                    address.address_1 &&
                    address.address_1.toLowerCase().includes(lowerCaseSearchString)
                )
                    return true;
                return false;
            });

            setFilteredContacts(filteredContacts);
        },
        [invited]
    );

    useEffect(() => {
        if (invited && searchString) {
            inviteMembersSearch(searchString);
        } else {
            if (invited) {
                setFilteredContacts(invited);
            }
        }
    }, [searchString, setFilteredContacts, invited, inviteMembersSearch]);

    return (
        <div>
            <div className="my-4 w-100 d-flex justify-content-between">
                <div className="d-flex justify-content-start w-30">
                    <ContactsListSearch
                        className="contacts-list-search"
                        handleChange={value => {
                            setSearchsString(value);
                            setSelectList([]);
                            if (selectAllFlag) {
                                selectAll();
                            }
                        }}
                        listLabel={selectedTab}
                    />
                </div>
                <div
                    className={selectAllFlag ? 'invite-all badge-green' : 'invite-all'}
                    onClick={selectAll}
                >
                    <i className="fa fa-check-circle mr-2" aria-hidden="true" />
                    Select All
                </div>
            </div>
            <PendingList
                orgId={orgId}
                selectHandler={addToSelectList}
                searchString={searchString}
                selected={selectList}
                invited={invited}
                filteredContacts={filteredContacts}
            />
            <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="text-muted">{`${
                    invited?.length ? invited.length : 0
                } Pending`}</div>
                <Button
                    disabled={!selectList.length}
                    color="danger"
                    className="transition-3d-hover m-3 btn-sm"
                    onClick={handleRemove}
                >
                    Remove Invites
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = ({ manager }) => {
    const { invitedMembers } = manager;
    return { invited: invitedMembers };
};

export default withRouter(connect(mapStateToProps, { removingPending })(Pending));
