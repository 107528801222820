import React from 'react';

export default function MobilePrivacyPolicy() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12" style={{ fontSize: '.80rem' }}>
                    <div className="my-5">
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ fontSize: '28px' }}
                        >
                            Mobile App Privacy Policy
                        </div>
                        <div className="mb-3">Last modified: January 9th, 2023</div>
                        <div className="mb-3">
                            <div
                                className="text-dark-gray font-weight-semi-bold my-3"
                                style={{ textDecoration: 'underline', fontSize: '18px' }}
                            >
                                Introduction
                            </div>
                            We at Ramco Protective of Orlando Inc. (
                            <span style={{ fontWeight: 'bold' }}>”Company”</span> or{' '}
                            <span style={{ fontWeight: 'bold' }}>“We”</span>) respect your
                            privacy and are committed to protecting it through our
                            compliance with this policy. This policy describes:
                        </div>
                        <div className="mb-3">
                            <ul>
                                <li>
                                    The types of information we may collect or that you
                                    may provide when you download, install, register with,
                                    access, or use the SAGE application (the{' '}
                                    <span style={{ fontWeight: 'bold' }}>“App”</span>).
                                </li>

                                <li>
                                    Our practices for collecting, using, maintaining,
                                    protecting, and disclosing that information.
                                </li>
                            </ul>
                        </div>
                        <div className="mb-3">
                            This policy applies only to information we collect in this
                            App, and in email, text, and other electronic communications
                            sent through or in connection with this App.
                        </div>
                        <div className="mb-3">
                            This policy{' '}
                            <span style={{ fontWeight: 'bold' }}>DOES NOT</span> apply to
                            information that:
                        </div>
                        <div className="mb-3">
                            <ul>
                                <li>
                                    We collect offline or on any other Company apps or
                                    websites, including websites you may access through
                                    this App.
                                </li>

                                <li>
                                    You provide to or is collected by any third party (see
                                    <span style={{ textDecoration: 'underline' }}>
                                        {' '}
                                        Third-Party Information Collection
                                    </span>
                                    ).
                                </li>
                            </ul>
                        </div>
                        <div className="mb-3">
                            {' '}
                            Our websites and apps, and these other third parties may have
                            their own privacy policies, which we encourage you to read
                            before providing information on or through them.
                        </div>
                        <div className="mb-3">
                            {' '}
                            Please read this policy carefully to understand our policies
                            and practices regarding your information and how we will treat
                            it. If you do not agree with our policies and practices, do
                            not download, register with, or use this App. By downloading,
                            registering with, or using this App, you agree to this privacy
                            policy. This policy may change from time to time (see{' '}
                            <span style={{ textDecoration: 'underline' }}>
                                Changes to Our Privacy Policy
                            </span>
                            ). Your continued use of this App after we revise this policy
                            means you accept those changes, so please check the policy
                            periodically for updates.
                        </div>{' '}
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Children Under the Age of 16
                        </div>
                        The App is not intended for children under 16 years of age, and we
                        do not knowingly collect personal information from children under
                        16. If we learn we have collected or received personal information
                        from a child under 16 without verification of parental consent, we
                        will delete that information. If you believe we might have any
                        information from or about a child under 16, please contact us at{' '}
                        <a href="mailto:support@sagesystems.io">support@sagesystems.io</a>
                        .
                        <br />
                        <br />
                        California residents under 16 years of age may have additional
                        rights regarding the collection and sale of their personal
                        information. Please see{' '}
                        <span style={{ textDecoration: 'underline' }}>
                            Your State Privacy Rights
                        </span>{' '}
                        for more information.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Information We Collect and How We Collect It
                        </div>
                        <div className="mb-3">
                            We collect information from and about users of our App:
                        </div>
                        <ul className="mb-3">
                            <li>Directly from you when you provide it to us.</li>
                            <li>Automatically when you use the App. </li>
                        </ul>
                        <div
                            className="mb-3"
                            style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                        >
                            Information You Provide to Us
                        </div>
                        <div className="mb-3">
                            When you download, register with, or use this App, we may ask
                            you provide information:
                        </div>
                        <ul>
                            <li>
                                By which you may be personally identified, such as, but
                                not limited to, name, postal address, email address,
                                telephone number, and/or driver’s license information
                                (”personal information”).
                            </li>
                            <li>
                                That is about you but individually does not identify you,
                                such as, but not limited to, community name, job title,
                                latitudinal and longitudinal information, and/or vehicle
                                information (make model, year, color, license plate state
                                and number).
                            </li>
                        </ul>
                        <div className="mb-3">This information includes:</div>
                        <ul>
                            <li>
                                Information that you provide by filling in forms in the
                                App. This includes information provided at the time of
                                registering to use the App and/or subscribing to our
                                service. We may also ask you for information when you
                                report a problem with the App.
                            </li>
                            <li>
                                Records and copies of your correspondence (including email
                                addresses and phone numbers), if you contact us.
                            </li>
                            <li>
                                Your responses to surveys that we might ask you to
                                complete for research purposes.
                            </li>
                            <li>
                                Details of transactions you carry out through the App and
                                of the fulfillment of your access requests.
                            </li>
                        </ul>
                        You may also provide information for publication or display (
                        <span style={{ fontWeight: 'bold' }}>”Posted”</span>) on public
                        areas of the App (collectively,{' '}
                        <span style={{ fontWeight: 'bold' }}>“User Contributions”</span>).
                        Your User Contributions are Posted and transmitted to others at
                        your own risk. [Although you may set certain privacy settings for
                        such information by logging into your account profile, please be
                        aware that no security measures are perfect or impenetrable.]
                        Additionally, we cannot control the actions of third parties with
                        whom you may choose to share your User Contributions. Therefore,
                        we cannot and do not guarantee that your User Contributions will
                        not be viewed by unauthorized persons.
                        <div
                            className="my-3"
                            style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                        >
                            Automatic Information Collection and Tracking
                        </div>
                        When you download, access, and use the App, it may use technology
                        to automatically collect:
                        <ul className="my-3">
                            <li>
                                <span style={{ fontWeight: 'bold' }}>Usage Details.</span>{' '}
                                When you access and use the App, we may automatically
                                collect certain details of your access to and use of the
                                App, including traffic data, location data, logs, and
                                other communication data and the resources that you access
                                and use on or through the App.
                            </li>

                            <li>
                                <span style={{ fontWeight: 'bold' }}>
                                    Device Information.
                                </span>{' '}
                                We may collect information about your mobile device and
                                internet connection, including the device’s unique device
                                identifier, IP address, operating system, browser type,
                                mobile network information, and the device’s telephone
                                number.
                            </li>

                            <li>
                                <span style={{ fontWeight: 'bold' }}>
                                    Stored Information and Files.
                                </span>{' '}
                                The App also may access metadata and other information
                                associated with other files stored on your device. This
                                may include, for example, photographs, audio and video
                                clips, personal contacts, and address book information.
                            </li>

                            <li>
                                <span style={{ fontWeight: 'bold' }}>
                                    Location Information.
                                </span>{' '}
                                This App collects, but does not store, real-time
                                information about the location of your device. When you
                                request access through the App, if such access is granted,
                                the in-App map API uses your current location and
                                auto-maps to the destination address, but the device
                                location data is not stored.
                            </li>
                        </ul>
                        If you do not want us to collect this information do not download
                        the App or delete it from your device. For more information, see{' '}
                        <span style={{ textDecoration: 'underline' }}>
                            Your Choices About Our Collection, Use, and Disclosure of Your
                            Information.
                        </span>{' '}
                        <br />
                        <br />
                        We also may use these technologies to collect information about
                        your activities over time and across third-party websites, apps,
                        or other online services (behavioral tracking). See{' '}
                        <span style={{ textDecoration: 'underline' }}>
                            Your Choices About Our Collection, Use, and Disclosure of Your
                            Information
                        </span>{' '}
                        for information on how you can opt out of behavioral tracking on
                        or through this app and how we respond to browser signals and
                        other mechanisms that enable consumers to exercise choice about
                        behavioral tracking. ‌
                        <div
                            className="my-3"
                            style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                        >
                            Information Collection and Tracking Technologies
                        </div>
                        The technologies we use for automatic information collection may
                        include:
                        <ul className="my-3">
                            <li>
                                <span style={{ fontWeight: 'bold' }}>
                                    Cookies (or mobile cookies).
                                </span>{' '}
                                A cookie is a small file placed on your smartphone. It may
                                be possible to refuse to accept mobile cookies by
                                activating the appropriate setting on your smartphone.
                                However, if you select this setting you may be unable to
                                access certain parts of our App.
                            </li>
                            <li>
                                <span style={{ fontWeight: 'bold' }}>Web Beacons.</span>{' '}
                                Pages of the App and our emails may contain small
                                electronic files known as web beacons (also referred to as
                                clear gifs, pixel tags, and single-pixel gifs) that permit
                                the Company, for example, to count users who have visited
                                those pages [or opened an email] and for other related app
                                statistics (for example, recording the popularity of
                                certain app content and verifying system and server
                                integrity).
                            </li>
                        </ul>
                        <div
                            className="my-3"
                            style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                        >
                            Third-Party Information Collection
                        </div>
                        When you use the App or its content, certain third parties may use
                        automatic information collection technologies to collect
                        information about you or your device. These third parties may
                        include:
                        <ul className="my-3">
                            <li>Your mobile device manufacturer.</li>
                            <li>Your mobile service provider.</li>
                        </ul>
                        These third parties may use tracking technologies to collect
                        information about you when you use this app. The information they
                        collect may be associated with your personal information or they
                        may collect information, including personal information, about
                        your online activities over time and across different websites,
                        apps, and other online services websites. They may use this
                        information to provide you with interest-based (behavioral)
                        advertising or other targeted content. We do not control these
                        third parties’ tracking technologies or how they may be used. If
                        you have any questions about an advertisement or other targeted
                        content, you should contact the responsible provider directly.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            How We Use Your Information
                        </div>
                        We use information that we collect about you or that you provide
                        to us, including any personal information, to:
                        <ul className="my-3">
                            <li>
                                Provide you with the App and its contents, and any other
                                information, products or services that you request from
                                us, such as to fulfill any access control requests.
                            </li>
                            <li>Fulfill any other purpose for which you provide it. </li>
                            <li>Give you notices about your account. </li>
                            <li>
                                Carry out our obligations and enforce our rights arising
                                from any contracts entered into between you and us,
                                including for billing and collection.{' '}
                            </li>
                            <li>
                                Notify you when App updates are available, and of changes
                                to any products or services we offer or provide though it.{' '}
                            </li>
                        </ul>
                        The usage information we collect helps us to improve our App and
                        to deliver a better and more personalized experience by enabling
                        us to:
                        <ul className="my-3">
                            <li>Estimate our audience size and usage patterns. </li>
                            <li>
                                Store information about your preferences, allowing us to
                                customize our App according to your individual interests.{' '}
                            </li>
                            <li>Speed up your searches. </li>
                            <li>Recognize you when you use the App. </li>
                        </ul>
                        We use location information we collect to confirm your location at
                        the requested access destination. <br />
                        <br />
                        We may also use your information to contact you about goods and
                        services that may be of interest to you. If you do not want us to
                        use your information in this way, please adjust your user
                        preferences in your account profile. For more information, see{' '}
                        <span style={{ textDecoration: 'underline' }}>
                            Your Choices About Our Collection, Use, and Disclosure of Your
                            Information
                        </span>
                        .
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Disclosure of Your Information
                        </div>
                        We may disclose aggregated information about our users, and
                        information that does not identify any individual or device,
                        without restriction.
                        <br />
                        <br /> In addition, we may disclose personal information that we
                        collect or you provide:
                        <ul className="mt-3">
                            <li>To our subsidiaries and affiliates.</li>
                            <li>
                                To contractors, service providers, and other third parties
                                we use to support our business{' '}
                            </li>
                            <li>
                                To a buyer or other successor in the event of a merger,
                                divestiture, restructuring, reorganization, dissolution,
                                or other sale or transfer of some or all of Company’s
                                assets, whether as a going concern or as part of
                                bankruptcy, liquidation, or similar proceeding, in which
                                personal information held by Company about our App users
                                is among the assets transferred.
                            </li>
                            <li>
                                To fulfill the purpose for which you provide it. For
                                example, if you request access to a certain destination
                                through the App, we will transmit the contents of your
                                request and your information to the user controlling such
                                access.
                            </li>
                            <li>
                                For any other purpose disclosed by us when you provide the
                                information. 1. With your consent.{' '}
                            </li>
                            <li>
                                To comply with any court order, law, or legal process,
                                including to respond to any government or regulatory
                                request.{' '}
                            </li>
                            <li>
                                To enforce our rights arising from any contracts entered
                                into between you and us, including the{' '}
                                <a href="https://sagesystems.io/mobile-app-eula">
                                    Mobile Application End User License Agreement
                                </a>{' '}
                                , and for billing and collection.
                            </li>
                            <li>
                                If we believe disclosure is necessary or appropriate to
                                protect the rights, property, or safety of Company, our
                                customers or others. This includes exchanging information
                                with other companies and organizations for the purposes of
                                fraud protection and credit risk reduction.
                            </li>
                        </ul>
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Your Choices About Our Collection, Use, and Disclosure of Your
                            Information
                        </div>
                        We strive to provide you with choices regarding the personal
                        information you provide to us. This section describes mechanisms
                        we provide for you to control certain uses and disclosures of over
                        your information.
                        <ul className="my-3">
                            <li>
                                <span style={{ fontWeight: 'bold' }}>
                                    Location Information.
                                </span>{' '}
                                While you can choose whether or not to allow the App to
                                collect and use real-time information about your device’s
                                location through the device’s privacy settings, if you
                                block the use of location information, the App will not
                                function properly.
                            </li>
                        </ul>
                        <br /> Residents in certain states, such as California, may have
                        additional personal information rights and choices. Please see{' '}
                        <span style={{ textDecoration: 'underline' }}>
                            Your State Privacy Rights
                        </span>{' '}
                        for more information.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Accessing and Correcting Your Personal Information
                        </div>
                        You can review and change your personal information by logging
                        into the App and visiting your account profile page.
                        <br />
                        <br /> You may also send us an email at{' '}
                        <a href="mailto:support@sagesystems.io">
                            support@sagesystems.io
                        </a>{' '}
                        to request access to, correct, or delete any personal information
                        that you have provided to us. We cannot delete your personal
                        information except by also deleting your user account. We may not
                        accommodate a request to change information if we believe the
                        change would violate any law or legal requirement or cause the
                        information to be incorrect.
                        <br />
                        <br /> If you delete your User Contributions from the App, copies
                        of your User Contributions may remain viewable in cached and
                        archived pages, or might have been copied or stored by other App
                        users. Proper access and use of information provided on the App,
                        including User Contributions, is governed by our{' '}
                        <a href="https://sagesystems.io/mobile-app-eula">
                            Mobile Application End User License Agreement
                        </a>
                        . <br />
                        <br />
                        Residents in certain states, such as California, may have
                        additional personal information rights and choices. Please see
                        Your State Privacy Rights for more information.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Your State Privacy Rights
                        </div>
                        State consumer privacy laws may provide their residents with
                        additional rights regarding our use of their personal information.
                        To learn more about California’s “Shine the Light” law (Civil Code
                        Section § 1798.83) permits users of our App that are California
                        residents to request certain information regarding our disclosure
                        of personal information to third parties for their direct
                        marketing purposes. To make such a request, please send an email
                        to{' '}
                        <a href="mailto:support@sagesystems.io">support@sagesystems.io</a>{' '}
                        or write us at: 8961 Quality Road Bonita Springs, FL 34135.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Data Security
                        </div>
                        We have implemented measures designed to secure your personal
                        information from accidental loss and from unauthorized access,
                        use, alteration, and disclosure. All information you provide to us
                        is encrypted using the Google Cloud Platform, Google
                        Authenticator, and SSL technology.
                        <br />
                        <br /> The safety and security of your information also depends on
                        you. Where we have given you (or where you have chosen) a password
                        for access to certain parts of our App, you are responsible for
                        keeping this password confidential. We ask you not to share your
                        password with anyone.
                        <br />
                        <br /> Unfortunately, the transmission of information via the
                        internet and mobile platforms is not completely secure. Although
                        we do our best to protect your personal information, we cannot
                        guarantee the security of your personal information transmitted
                        through our App. Any transmission of personal information is at
                        your own risk. We are not responsible for circumvention of any
                        privacy settings or security measures we provide.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Changes to Our Privacy Policy
                        </div>
                        We may update our privacy policy from time to time. If we make
                        material changes to how we treat our users’ personal information,
                        we will post the new privacy policy on this page with a notice
                        that the privacy policy has been updated and/or notify you by
                        email and/or text message to the primary email address and/or
                        phone number specified in your account. The date the privacy
                        policy was last revised is identified at the top of the page. You
                        are responsible for ensuring we have an up-to-date active and
                        deliverable email address and/or phone number for you and for
                        periodically visiting this privacy policy to check for any
                        changes.
                        <div
                            className="text-dark-gray font-weight-semi-bold my-3"
                            style={{ textDecoration: 'underline', fontSize: '18px' }}
                        >
                            Contact Information
                        </div>
                        <div className="mb-5">
                            To ask questions or comment about this privacy policy and our
                            privacy practices, contact us at:
                            <br />
                            <br /> 8961 Quality Road
                            <br /> Bonita Springs, FL 34135
                            <br />{' '}
                            <a href="mailto:support@sagesystems.io">
                                support@sagesystems.io
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
