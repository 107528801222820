import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmReservation } from '../components';
import { logoutUser } from '../store/actions/Auth';

export default function ConfirmReservationPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    return (
        <div className="space-2 vh-100 bg-dark">
            <ConfirmReservation />
        </div>
    );
}
