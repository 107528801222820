// React
import React from 'react';

// Components
import CallInterval from './CallInterval';

// Packages
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

// Router
import * as routes from '../../router/config/routes';

const CallController = ({ muteAudio, setMuteAudio, handleGateAccess }) => {
    const history = useHistory();

    const handleCallEnd = () => {
        history.replace(`${routes.AUTHENTICATED}${routes.DASHBOARD}`, {});
    };

    return (
        <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
                <Button
                    color="primary"
                    type="button"
                    // disabled={!accessEnabled}
                    className="transition-3d-hover btn-sm btn-primary mr-5"
                    onClick={handleGateAccess}
                >
                    <span className="mx-5">Grant Access</span>
                </Button>
                <Button
                    type="button"
                    className="transition-3d-hover btn-sm btn-danger"
                    onClick={handleCallEnd}
                >
                    End Call
                </Button>
            </div>
            <div>
                <CallInterval />
                <Button
                    type="button"
                    className="transition-3d-hover btn-sm btn-outline-secondary"
                    style={{ borderColor: muteAudio ? '#DE4437' : '#77838f' }}
                    onClick={() => setMuteAudio(!muteAudio)}
                >
                    <i
                        style={{ color: muteAudio ? '#DE4437' : '#77838f' }}
                        className="fa-solid fa-volume-xmark"
                    />
                </Button>
            </div>
        </div>
    );
};

export default CallController;
