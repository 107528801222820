// React
import React, { useEffect } from 'react';

// Components
import { AddRegistrationForm } from '../components';

// Packages
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { resetAddingNewRegistration } from '../store/actions/Registration';

// Router
import * as routes from '../router/config/routes';

const AddRegistration = ({ resetAddingNewRegistration }) => {
    const history = useHistory();
    const location = useLocation();
    const url = location?.pathname;
    const subUrl = url.split(/[\s/]+/);
    const shortCode = subUrl[subUrl.length - 1];

    if (subUrl && !shortCode.length) {
        history.push({
            pathname: `${routes.LANDING}`
        });
    }

    useEffect(() => {
        return () => {
            resetAddingNewRegistration('hard');
        };
    }, [resetAddingNewRegistration]);

    return (
        <div className="vh-100 bg-dark">
            <AddRegistrationForm shortCode={shortCode} />
        </div>
    );
};

export default connect(null, { resetAddingNewRegistration })(AddRegistration);
