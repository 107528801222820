import React from 'react';

const InviteValidationError = ({ error }) => {
    return (
        <div className="w-75 mx-md-auto pt-8">
            <div className="d-flex align-items-center justify-content-center flex-column">
                <img src="../assets/sage-icon.svg" alt="logo" height={101} width={86} />
                <div className="mb-3">
                    <div
                        style={{ fontSize: '32px' }}
                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold mt-3 mb-8"
                    >
                        Validation error!
                    </div>
                    <div
                        style={{ fontSize: '16px' }}
                        className="d-flex justify-content-center text-muted font-weight-normal mt-5 mb-10"
                    >
                        {error}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteValidationError;
