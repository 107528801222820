import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import * as selectors from './Selectors';

import {
    GET_USER_ORG,
    LOGOUT_USER,
    CREATE_ORG,
    GET_ORGS_COLLECTION,
    SET_ACTIVE_USER_ORG,
    SET_PRIMARY_ORG,
    GET_ACTIVE_ORG_COLLECTION
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import {
    storeOrgData,
    createOrgSuccess,
    createOrgFailure,
    getOrgCollectionSuccess,
    getOrgCollectionFailure,
    settingActiveOrgSuccess,
    settingActiveOrgFailure
} from '../actions/Org';

import {
    getImportedMembersSuccess,
    getInvitedMembersSuccess,
    getCommunityMembersSuccess
} from '../actions/Managers';

import { watchOrgDevicesRTDBSuccess } from '../actions/Panels';

import { updateAccessLog } from '../actions/AccessLog';

import { db, func, rtdb } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';
import { createNewAuthUsers, getExistingAndNewUsers } from './Managers';

const orgs = db.collection('orgs');
const users = db.collection('users');
const guestInvites = db.collection('guest_invites');

const sendgridEmailRequest = func.httpsCallable('sendgridEmailRequest');

//////////////// Get User Organization ////////////////

const isSuper = role => {
    if (role === 'super_admin') return true;
    return false;
};

export function* orgCollectionWatch(user) {
    let unsubscribeUserOrgData;
    const orgCollectionChannel = eventChannel(emit => {
        unsubscribeUserOrgData = isSuper(user.type)
            ? orgs.onSnapshot(function (querySnapshot) {
                  if (querySnapshot) {
                      var org = [];
                      querySnapshot.forEach(function (doc) {
                          var contactName;
                          if (doc.data().managers.length) {
                              const primary = doc
                                  .data()
                                  .managers.filter(manager => manager.primary === true);
                              contactName = primary.length
                                  ? `${primary[0].first_name} ${primary[0].last_name}`
                                  : null;
                          }
                          org.push({ ...doc.data(), contact_name: contactName });
                      });
                      emit(org);
                  } else {
                      const doc = { exists: false };
                      emit({ doc });
                  }
              })
            : orgs
                  .where('org_id', 'in', Object.keys(user.properties))
                  .onSnapshot(function (querySnapshot) {
                      if (querySnapshot) {
                          var org = [];
                          querySnapshot.forEach(function (doc) {
                              var contactName;
                              if (doc.data().managers.length) {
                                  const primary = doc
                                      .data()
                                      .managers.filter(
                                          manager => manager.primary === true
                                      );
                                  contactName = primary.length
                                      ? `${primary[0].first_name} ${primary[0].last_name}`
                                      : null;
                              }
                              org.push({ ...doc.data(), contact_name: contactName });
                          });
                          emit(org);
                      } else {
                          const doc = { exists: false };
                          emit({ doc });
                      }
                  });
        return unsubscribeUserOrgData;
    });
    try {
        while (true) {
            const { userSignOut, userOrgData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userOrgData: take(orgCollectionChannel)
            });

            if (userSignOut) {
                orgCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(storeOrgData(userOrgData));
            }
        }
    } catch (error) {
        log('Org Error: getting user org collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubscribeUserOrgData(); // Detach firebase listener
        if (yield cancelled()) {
            orgCollectionChannel.close(); // Detach saga event emitter
            unsubscribeUserOrgData(); // Detach firebase listener
        }
    }
}

export function* activeOrgCollectionWatch(id) {
    const activeOrgImports = rtdb.ref(`registration/${id}`);
    // const activeOrgInvites = rtdb.ref(`orgs/${id}/invites`);
    const activeOrgMembers = rtdb.ref(`orgs/${id}/members`);
    const activeOrgAccessLog = rtdb.ref(`orgs/${id}/access_log`);
    const activeOrgPanels = rtdb.ref(`orgs/${id}/panels`);

    const AddOrgImportsChannel = eventChannel(emit => {
        const unsubscribeOrgImportsData = activeOrgImports.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()) {
                        const key = childSnapshot.val().id
                            ? childSnapshot.val().id
                            : childSnapshot.key;
                        const data = {
                            id: key,
                            first_name: childSnapshot.val().first_name.toLowerCase(),
                            last_name: childSnapshot.val().last_name.toLowerCase(),
                            email: childSnapshot.val().email
                                ? childSnapshot.val().email.toLowerCase()
                                : false,
                            phone: childSnapshot.val().phone
                                ? childSnapshot.val().phone
                                : false,
                            registered_at: childSnapshot.val().registered_at
                                ? childSnapshot.val().registered_at
                                : false,
                            address: childSnapshot.val().address
                        };
                        emit(data);
                    }
                }
            }
        );
        return unsubscribeOrgImportsData;
    });

    const ChangeOrgImportsChannel = eventChannel(emit => {
        const unsubscribeOrgImportsData = activeOrgImports.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()) {
                        const key = childSnapshot.val().id
                            ? childSnapshot.val().id
                            : childSnapshot.key;
                        const data = {
                            id: key,
                            first_name: childSnapshot.val().first_name.toLowerCase(),
                            last_name: childSnapshot.val().last_name.toLowerCase(),
                            email: childSnapshot.val().email
                                ? childSnapshot.val().email.toLowerCase()
                                : false,
                            phone: childSnapshot.val().phone
                                ? childSnapshot.val().phone
                                : false,
                            registered_at: childSnapshot.val().registered_at
                                ? childSnapshot.val().registered_at
                                : false,
                            address: childSnapshot.val().address
                        };
                        emit(data);
                    }
                }
            }
        );
        return unsubscribeOrgImportsData;
    });

    const RemoveOrgImportsChannel = eventChannel(emit => {
        const unsubscribeOrgImportsData = activeOrgImports.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()) {
                        const key = childSnapshot.val().id
                            ? childSnapshot.val().id
                            : childSnapshot.key;
                        const data = {
                            id: key,
                            first_name: childSnapshot.val().first_name.toLowerCase(),
                            last_name: childSnapshot.val().last_name.toLowerCase(),
                            email: childSnapshot.val().email
                                ? childSnapshot.val().email.toLowerCase()
                                : false,
                            phone: childSnapshot.val().phone
                                ? childSnapshot.val().phone
                                : false,
                            registered_at: childSnapshot.val().registered_at
                                ? childSnapshot.val().registered_at
                                : false,
                            address: childSnapshot.val().address
                        };
                        emit(data);
                    }
                }
            }
        );
        return unsubscribeOrgImportsData;
    });

    const AddOrgMembersChannel = eventChannel(emit => {
        const unsubscribeOrgMembersData = activeOrgMembers.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()) {
                        const data = {
                            ...childSnapshot.val(),
                            first_name: childSnapshot.val().first_name.toLowerCase(),
                            last_name: childSnapshot.val().last_name.toLowerCase(),
                            email: childSnapshot.val().email
                                ? childSnapshot.val().email.toLowerCase()
                                : false
                        };
                        emit(data);
                    }
                }
            }
        );
        return unsubscribeOrgMembersData;
    });

    const ChangeOrgMembersChannel = eventChannel(emit => {
        const unsubscribeOrgMembersData = activeOrgMembers.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()) {
                        const data = {
                            ...childSnapshot.val(),
                            first_name: childSnapshot.val().first_name.toLowerCase(),
                            last_name: childSnapshot.val().last_name.toLowerCase(),
                            email: childSnapshot.val().email
                                ? childSnapshot.val().email.toLowerCase()
                                : false
                        };
                        emit(data);
                    }
                }
            }
        );
        return unsubscribeOrgMembersData;
    });

    const RemoveOrgMembersChannel = eventChannel(emit => {
        const unsubscribeOrgMembersData = activeOrgMembers.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()) {
                        const data = {
                            ...childSnapshot.val(),
                            first_name: childSnapshot.val().first_name.toLowerCase(),
                            last_name: childSnapshot.val().last_name.toLowerCase(),
                            email: childSnapshot.val().email
                                ? childSnapshot.val().email.toLowerCase()
                                : false
                        };
                        emit(data);
                    }
                }
            }
        );
        return unsubscribeOrgMembersData;
    });

    const OrgPanelsChannel = eventChannel(emit => {
        const unsubscribeOrgPanelData = activeOrgPanels.on('value', querySnapshot => {
            if (querySnapshot && querySnapshot.val()) {
                var panels = [];
                panels = Object.values(querySnapshot.val());
                emit(panels);
            } else {
                emit([]);
            }
        });
        return unsubscribeOrgPanelData;
    });

    const OrgAccessLogChannel = eventChannel(emit => {
        const unsubscribeOrgAccessLogData = activeOrgAccessLog.on(
            'value',
            querySnapshot => {
                if (querySnapshot && querySnapshot.val()) {
                    const log = querySnapshot.val();
                    const accessLogList = [];
                    for (const record in log) {
                        accessLogList.push({ logItemId: record, ...log[record] });
                    }
                    emit(accessLogList);
                } else {
                    emit([]);
                }
            }
        );
        return unsubscribeOrgAccessLogData;
    });

    const detachSagaEmitters = () => {
        AddOrgImportsChannel.close();
        ChangeOrgImportsChannel.close();
        RemoveOrgImportsChannel.close();
        AddOrgMembersChannel.close();
        ChangeOrgMembersChannel.close();
        RemoveOrgMembersChannel.close();
        OrgPanelsChannel.close();
        OrgAccessLogChannel.close();
    };

    const detachFBListeners = () => {
        activeOrgImports.off('child_added');
        activeOrgImports.off('child_changed');
        activeOrgImports.off('child_removed');
        activeOrgMembers.off('child_added');
        activeOrgMembers.off('child_changed');
        activeOrgMembers.off('child_removed');
        activeOrgPanels.off('value');
        activeOrgAccessLog.off('value');
    };

    try {
        while (true) {
            const currentImports = yield select(selectors._importedMembers);
            const currentMembers = yield select(selectors._communityMembers);

            const imports = currentImports ? [...currentImports] : [];
            const members = currentMembers ? [...currentMembers] : [];

            const getImportIndex = data => {
                const index = imports.findIndex(imp => imp.id === data.id);
                return index;
            };

            const getMemberIndex = data => {
                const index = members.findIndex(mem => mem.uid === data.uid);
                return index;
            };

            let importIndex;
            let memberIndex;

            const {
                userSignOut,
                resettingOrg,
                addOrgImportsData,
                changeOrgImportsData,
                removeOrgImportsData,
                addOrgMembersData,
                changeOrgMembersData,
                removeOrgMembersData,
                orgPanelsData,
                orgAccessLogData
            } = yield race({
                userSignOut: take(LOGOUT_USER),
                resettingOrg: take(SET_ACTIVE_USER_ORG),
                removeOrgMembersData: take(RemoveOrgMembersChannel),
                removeOrgImportsData: take(RemoveOrgImportsChannel),
                addOrgImportsData: take(AddOrgImportsChannel),
                changeOrgImportsData: take(ChangeOrgImportsChannel),
                addOrgMembersData: take(AddOrgMembersChannel),
                changeOrgMembersData: take(ChangeOrgMembersChannel),
                orgPanelsData: take(OrgPanelsChannel),
                orgAccessLogData: take(OrgAccessLogChannel)
            });

            if (userSignOut) {
                detachSagaEmitters(); // Detaching saga event emitters
            } else if (resettingOrg) {
                // detachSagaEmitters();
                // detachFBListeners();
            } else if (addOrgImportsData) {
                importIndex = getImportIndex(addOrgImportsData);
                if (importIndex < 0) imports.push(addOrgImportsData);
                yield put(getImportedMembersSuccess(imports));
            } else if (changeOrgImportsData) {
                importIndex = getImportIndex(changeOrgImportsData);
                if (importIndex < 0) {
                    imports.push(changeOrgImportsData);
                } else {
                    imports[importIndex] = changeOrgImportsData;
                }
                yield put(getImportedMembersSuccess(imports));
            } else if (removeOrgImportsData) {
                importIndex = getImportIndex(removeOrgImportsData);
                if (importIndex >= 0) imports.splice(importIndex, 1);
                yield put(getImportedMembersSuccess(imports));
            } else if (addOrgMembersData) {
                memberIndex = getMemberIndex(addOrgMembersData);
                if (memberIndex < 0) members.push(addOrgMembersData);
                yield put(getCommunityMembersSuccess(members));
            } else if (changeOrgMembersData) {
                memberIndex = getMemberIndex(changeOrgMembersData);
                if (memberIndex < 0) {
                    members.push(changeOrgMembersData);
                } else {
                    members[memberIndex] = changeOrgMembersData;
                }
                yield put(getCommunityMembersSuccess(members));
            } else if (removeOrgMembersData) {
                memberIndex = getMemberIndex(removeOrgMembersData);
                if (memberIndex >= 0) members.splice(memberIndex, 1);
                yield put(getCommunityMembersSuccess(members));
            } else if (orgPanelsData) {
                yield put(watchOrgDevicesRTDBSuccess(orgPanelsData));
            } else if (orgAccessLogData) {
                yield put(updateAccessLog(orgAccessLogData));
            }
        }
    } catch (error) {
        //TODO: Error Handling
        log('Orgs Error: getting imports/invites/members/panels/access_log data (RTDB)', {
            error
        });
    } finally {
        detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            detachFBListeners(); // Detaching firebase listeners
        }
    }
}

export function* createOrgDocument({ payload }) {
    try {
        const { managers, ...restFormData } = payload;
        const validManagers = managers.filter(manager => !!manager.email);
        const managersEmpty = !validManagers.length;
        const newOrgId = orgs.doc().id;
        const newOrgRef = orgs.doc(`${newOrgId}`);

        const {
            orgName,
            address1,
            address2,
            city,
            state,
            zip,
            orgOfficePhone,
            orgOfficePhoneExt
        } = restFormData;

        const newOrgDocument = {
            org_id: newOrgId,
            org_name: orgName,
            rentalManagerEnabled: false,
            managers: [],
            phone: {
                primary_ext: orgOfficePhoneExt || null,
                secondary_ext: null,
                secondary: null,
                fax: null,
                primary: orgOfficePhone || null
            },
            address: {
                state: state || null,
                zip: zip || null,
                address_1: address1 || null,
                address_2: address2 || null,
                city: city || null,
                country: null
            }
        };

        if (managersEmpty) {
            yield orgs
                .doc(`${newOrgId}`)
                .set(newOrgDocument)
                .then(() => {
                    console.log('Added document with ID: ', newOrgId);
                })
                .catch(err => put(createOrgFailure(err)));

            yield put(createOrgSuccess(orgName));
            return;
        }

        if (!managersEmpty) {
            const { newUsers, existingUsers } = yield getExistingAndNewUsers(
                validManagers
            );

            if (existingUsers.length) {
                existingUsers.forEach(user => {
                    users
                        .doc(`${user.uid}`)
                        .set(
                            {
                                properties: {
                                    ...user?.properties,
                                    [newOrgId]: {
                                        property_id: null,
                                        notifications: true,
                                        org_id: newOrgId,
                                        address: {
                                            state: state || null,
                                            zip: zip || null,
                                            address_1: address1 || null,
                                            address_2: address2 || null,
                                            city: city || null,
                                            country: null
                                        }
                                    }
                                }
                            },
                            { merge: true }
                        )
                        .catch(err => console.error(err));

                    newOrgDocument.managers.push({
                        id: user.uid,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        primary: user.managerFormID === 0
                    });
                });
            }

            if (newUsers.length) {
                const processed = yield createNewAuthUsers(newUsers);
                const emailDataArray = newUsers.map(
                    ({ email, first_name, last_name }) => {
                        const matchingAuthUser = processed.find(
                            authUser => authUser.email === email
                        );

                        return {
                            email,
                            first_name,
                            last_name,
                            authLink: `https://sagesystems.io/accept-invitation?inviteCode=${matchingAuthUser.code}&email=${email}`
                        };
                    }
                );

                const { success } = yield sendConfirmEmail(emailDataArray);

                if (processed && success) {
                    const usersToAdd = newUsers.map(user => {
                        const matchingAuthUser = processed.find(
                            authUser => authUser.email === user.email
                        );
                        const newUserDocument = {
                            active_call: null,
                            voip_token: null,
                            phone: user.mobilePhone || null,
                            email: matchingAuthUser.email,
                            type: 'manager',
                            first_name: user.first_name || null,
                            last_name: user.last_name || null,
                            notifications: {
                                vendors: true,
                                org_vendors: true,
                                family: true,
                                guests: true,
                                news: true,
                                favorites: true
                            },
                            uid: matchingAuthUser.id,
                            role: '',
                            primary_org: newOrgId,
                            avatar: null,
                            fcm_token: null,
                            properties: {
                                [newOrgId]: {
                                    property_id: null,
                                    notifications: true,
                                    org_id: newOrgId,
                                    address: {
                                        state: state || null,
                                        zip: zip || null,
                                        address_1: address1 || null,
                                        address_2: address2 || null,
                                        city: city || null,
                                        country: null
                                    }
                                }
                            }
                        };

                        newOrgDocument.managers.push({
                            first_name: user.first_name || null,
                            last_name: user.last_name || null,
                            email: matchingAuthUser.email,
                            primary: user.managerFormID === 0,
                            id: matchingAuthUser.id
                        });

                        return newUserDocument;
                    });

                    if (usersToAdd && usersToAdd.length) {
                        yield usersToAdd.forEach(newUser => {
                            users
                                .doc(`${newUser.uid}`)
                                .set({
                                    ...newUser
                                })
                                .catch(error => {
                                    console.error(`Error creating user: ${error}`);
                                });
                        });
                    }
                }
            }

            yield newOrgRef
                .set(newOrgDocument)
                .then(() => {
                    console.log('Added document with ID: ', newOrgId);
                })
                .catch(err => console.error(err));

            yield put(createOrgSuccess(orgName));
        }
    } catch (err) {
        yield put(createOrgFailure(err));
    }
}

export function* getOrgsCollection() {
    try {
        const allOrgs = yield orgs
            .get()
            .then(snapshot => snapshot.docs.map(doc => doc.data()))
            .catch(err => {
                console.error(err);
            });
        yield put(getOrgCollectionSuccess(allOrgs));
    } catch (error) {
        console.error(error);
        yield put(getOrgCollectionFailure(error));
    }
}

export async function getOrgById(orgId) {
    try {
        const orgRef = orgs.doc(`${orgId}`);
        const orgData = await orgRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    return doc.data();
                }
            })
            .catch(err => {
                console.error(err);
            });
        return orgData;
    } catch (err) {
        console.error(err);
    }
}

export async function updateOrgManagers(orgId, userId, userData) {
    try {
        const orgRef = orgs.doc(`${orgId}`);
        const orgData = await getOrgById(orgId);
        const managersEmpty = !orgData?.managers.length;

        orgRef
            .set(
                {
                    managers: [
                        ...orgData.managers,
                        {
                            id: userId,
                            first_name: userData.first_name,
                            last_name: userData.last_name,
                            email: userData.email,
                            primary: managersEmpty
                        }
                    ]
                },
                { merge: true }
            )
            .catch(err => console.error(err));
    } catch (err) {
        console.error(err);
    }
}

export async function sendConfirmEmail(recipients) {
    try {
        const res = await sendgridEmailRequest({ recipients });
        return res?.data;
    } catch (error) {
        console.error(error);
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////// Setting Active Org /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const settingActiveOrgRequest = ({ active_org_id, active_station_id, userId }) => {
    const userRef = users.doc(userId);

    return new Promise((resolve, reject) => {
        userRef
            .update({
                active_org_id,
                active_station_id: active_station_id ? active_station_id : null
            })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* settingActiveOrg({ payload }) {
    const { org_id } = payload;
    const userData = yield select(selectors._userData);
    const active_org_id = org_id;
    const active_station_id = userData.active_station_id; // Needs to be looked at later
    const userId = userData.uid;

    const { res, error } = yield call(() =>
        settingActiveOrgRequest({ active_org_id, active_station_id, userId })
    );
    if (res) {
        yield put(settingActiveOrgSuccess(payload));
    } else {
        yield put(settingActiveOrgFailure(error));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Org Invites Collection ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* orgInvitesCollectionWatch({ payload }) {
    const org = payload;
    const invitors = org?.managers?.length ? org.managers.map(manager => manager.id) : [];
    let unsubscribeOrgInvitesData;
    const orgInvitesCollectionChannel = eventChannel(emit => {
        unsubscribeOrgInvitesData = guestInvites
            .where('creator_id', 'in', invitors)
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot) {
                    var invites = [];
                    querySnapshot.forEach(function (doc) {
                        invites.push({
                            ...doc.data(),
                            first_name: doc.data().first_name.toLowerCase(),
                            last_name: doc.data().last_name.toLowerCase()
                        });
                    });
                    emit(invites);
                } else {
                    const doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeOrgInvitesData;
    });
    try {
        while (true) {
            const { userSignOut, orgInvitesData } = yield race({
                userSignOut: take(LOGOUT_USER),
                orgInvitesData: take(orgInvitesCollectionChannel)
            });

            if (userSignOut) {
                orgInvitesCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(getInvitedMembersSuccess(orgInvitesData));
            }
        }
    } catch (error) {
        log('Org Error: getting org invites collection data (FS)', {
            error,
            org,
            invitors
        });
    } finally {
        unsubscribeOrgInvitesData(); // Detach firebase listener
        if (yield cancelled()) {
            orgInvitesCollectionChannel.close(); // Detach saga event emitter
            unsubscribeOrgInvitesData(); // Detach firebase listener
        }
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getAllOrgs() {
    yield takeLatest(GET_ORGS_COLLECTION, getOrgsCollection);
}

export function* createOrg() {
    yield takeLatest(CREATE_ORG, createOrgDocument);
}

export function* getOrgCollection() {
    yield takeLatest(GET_USER_ORG, orgCollectionWatch);
}

export function* getActiveOrgCollection() {
    yield takeLatest(GET_ACTIVE_ORG_COLLECTION, activeOrgCollectionWatch);
}

export function* setActiveOrg() {
    yield takeLatest(SET_ACTIVE_USER_ORG, settingActiveOrg);
}

export function* getOrgInvitesCollection() {
    yield takeLatest(SET_PRIMARY_ORG, orgInvitesCollectionWatch);
}

export default function* rootSaga() {
    yield all([
        fork(getOrgCollection),
        fork(getAllOrgs),
        fork(createOrg),
        fork(setActiveOrg),
        fork(getActiveOrgCollection),
        fork(getOrgInvitesCollection)
    ]);
}
