import React from 'react';

const SuperReports = () => {
    return (
        <div className="d-flex flex-column position-relative flex-auto">
            <div className="main">
                <h2>Reporting</h2>
            </div>
        </div>
    );
};

export default SuperReports;
