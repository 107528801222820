import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import { getAccesKeysDocSuccess } from '../../store/actions/AccessKeys';

import { updatingMember, updatingRegister } from '../../store/actions/Members';
import ContactDetailsForm from './ContactDetailsForm';

const ContactModal = ({
    isOpen,
    toggleOpen,
    modalProps,
    accessKeyDoc,
    orgId,
    withAccess,
    loading,
    type,
    updatingMember,
    updatingRegister
}) => {
    const dispatch = useDispatch();
    const [selectedTab, onClick] = useState(type ? `${type} details` : `request details`);
    const tabs = [
        {
            value: type ? `${type} details` : `request details`,
            id: 'pills-four-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-four'
        }
        // {
        //     value: 'Configuration Details',
        //     id: 'pills-five-tab',
        //     dataToggle: 'pill',
        //     role: 'tab',
        //     ariaControls: 'pills-five'
        // }
    ];

    const toggleModal = () => {
        if (isOpen) {
            dispatch(getAccesKeysDocSuccess(null));
        }
        toggleOpen();
    };

    return (
        <Modal
            style={{ minWidth: '70%' }}
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            toggle={toggleModal}
        >
            <ModalBody>
                <div className="main container">
                    <div className="col-12">
                        <div className="row w-100">
                            <ul
                                className="nav nav-pills nav-justified bg-white"
                                role="tablist"
                            >
                                {tabs.map(tab => (
                                    <li
                                        className="nav-item text-nowrap"
                                        key={tab.id}
                                        onClick={() => onClick(tab.value)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div
                                            className={
                                                selectedTab === tab.value
                                                    ? 'nav-link font-weight-medium active'
                                                    : 'nav-link font-weight-medium'
                                            }
                                            id={tab.id}
                                            data-toggle={tab.dataToggle}
                                            role={tab.role}
                                            aria-controls={tab.ariaControls}
                                            aria-selected={
                                                selectedTab === tab.value
                                                    ? 'true'
                                                    : 'false'
                                            }
                                        >
                                            <div
                                                className="d-flex justify-content-center align-items-center"
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                {tab.value}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row m-3">
                    <div className="w-100">
                        {/* {selectedTab === 'Contact Details' && ( */}
                        <ContactDetailsForm
                            initialValues={modalProps}
                            submitForm={values => {
                                type
                                    ? updatingMember({ member: { ...values } })
                                    : updatingRegister({ member: { ...values } });
                                toggleModal();
                            }}
                            orgId={orgId}
                            accessKeyDoc={accessKeyDoc}
                            toggleModal={toggleModal}
                            withAccess={withAccess}
                            loading={loading}
                            userType={modalProps?.role ? modalProps.role : 'Resident'}
                        />
                        {/* )} */}
                        {/* {selectedTab === 'Configuration Details' && (
                            <ConfigurationDetailsForm
                                initialValues={modalProps}
                                submitForm={values => console.log(values)}
                                orgId={orgId}
                                accessKeyDoc={accessKeyDoc}
                                toggleModal={toggleModal}
                                withAccess={withAccess}
                                loading={loading}
                                userType={type}
                            />
                        )} */}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = ({ accessKey: { accessKeyDoc, accessKeyLoading } }) => {
    return { accessKeyDoc, loading: accessKeyLoading };
};

export default connect(mapStateToProps, { updatingMember, updatingRegister })(
    ContactModal
);
