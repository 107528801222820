import React from 'react';
import { connect } from 'react-redux';
import Loader from '../common/Loader';

const DeviceList = ({ devices, deviceTypes, setDevice }) => {
    if (!deviceTypes) {
        return (
            <div className="mx-3 w-100 h-50 d-flex align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    const sortedDevices =
        devices && devices.length ? devices.sort((a, b) => a.index - b.index) : [];

    return devices && devices.length ? (
        <div className="mx-3 w-100 device-card-container">
            {sortedDevices.map(device => {
                const { active, device_type, id, ip_address, name } = device;
                const deviceType = deviceTypes[device_type];
                return (
                    <div
                        key={id}
                        onClick={() => {
                            setDevice(device);
                        }}
                        className="device-card m-3"
                    >
                        <div className="device-info-section">
                            <div className="mb-0 device-name text-lh-sm font-weight-semi-bold">
                                {name}
                            </div>
                            <p className="device-info-text text-lh-md text-nowrap mb-0">{`ID: ${id}`}</p>
                            <p className="device-info-text text-lh-md text-nowrap mb-0">{`IP Address: ${ip_address}`}</p>
                            <p className="device-info-text text-lh-md text-nowrap mb-3">{`Type: ${deviceType?.type}`}</p>
                            {active ? (
                                <span className="badge badge-success badge-device-status font-weight-semi-bold">
                                    Online
                                </span>
                            ) : (
                                <span className="badge badge-danger badge-device-status font-weight-semi-bold">
                                    Offline
                                </span>
                            )}
                        </div>
                        <div className="m-2">
                            <img
                                src={deviceType?.image}
                                className="device-image"
                                alt=""
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    ) : (
        <div className="mx-3 w-100 h-50 text-muted d-flex align-items-center justify-content-center">
            No devices have been created yet.
        </div>
    );
};

const mapStateToProps = ({ panel: { deviceTypes, orgDevices } }) => ({
    deviceTypes,
    devices: orgDevices
});

export default connect(mapStateToProps)(DeviceList);
