import React from 'react';

const SuperSettings = () => {
    return (
        <div className="d-flex flex-column position-relative flex-auto">
            <div className="main">
                <h2>Settings</h2>
            </div>
        </div>
    );
};

export default SuperSettings;
