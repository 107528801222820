import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { Button } from 'reactstrap';
import reservationTemplateCSV from '../../assets/reservationTemplateCSV.csv';
import Papa from 'papaparse';
import ReservationImportReport from './ReservationImportReport';
import { connect, useDispatch } from 'react-redux';
import Loader from '../common/Loader';
import { useLocation } from 'react-router-dom';
import { importReservations } from '../../store/actions/Reservations';
import ReservationImportError from './ReservationImportError';

const ReservationUploaderCSV = ({ importing, importSuccess, importError }) => {
    const location = useLocation();
    const creatorData = location.state;
    const [csvData, setCsvData] = useState(null);
    const dispatch = useDispatch();

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                const { data } = Papa.parse(reader.result);
                const nonEmptyRows = data.filter(row => {
                    if (!row.length || (row.length === 1 && !row[0])) return 0;

                    return row;
                });

                setCsvData(nonEmptyRows);
            };
            reader.readAsText(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const importFile = () =>
        dispatch(importReservations({ reservationsData: csvData, creatorData }));

    if (importing) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (importSuccess) {
        return <ReservationImportReport report={importSuccess} />;
    }

    if (importError) {
        return <ReservationImportError error={importError} />;
    }

    return (
        <div className="container">
            <div className="row pt-5">
                <div className="col-12 m-auto">
                    <h3 className="font-weight-bold">Import Data</h3>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12 m-auto">
                    <div className="dropzone" {...getRootProps()}>
                        <input
                            style={{ height: '100%', width: '100%' }}
                            {...getInputProps()}
                        />
                        {!csvData && (
                            <h5>
                                <span className="import-file">Upload</span> or drop a CSV
                                file to import.
                            </h5>
                        )}
                        {csvData && (
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <i className="fa fa-file fa-3x text-green m-5" />
                                <h5>File selected. Press import to continue.</h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-12 m-auto">
                    <a
                        href={reservationTemplateCSV}
                        download="CSV_reservation_template"
                        className="link-text"
                    >
                        Download the CSV template here.
                    </a>
                </div>
            </div>
            <div className="row pt-8">
                <div className="col-8 m-auto justify-content-center text-center">
                    <span className="font-weight-semi-bold text-danger">Important: </span>
                    each data row must include&nbsp;
                    <span className="font-weight-semi-bold">first name</span>,&nbsp;
                    <span className="font-weight-semi-bold">last name</span>&nbsp;
                    and&nbsp;
                    <span className="font-weight-semi-bold">
                        check-in/check-out&nbsp;date
                    </span>
                    <br />
                    <br />A detailed report will be displayed once the import is complete.
                </div>
            </div>
            <div className="row pt-10">
                <div className="col-12 m-auto d-flex justify-content-center">
                    <Button
                        disabled={!csvData}
                        type="button"
                        color="primary"
                        className="btn btn-wide transition-3d-hover"
                        onClick={importFile}
                    >
                        Import
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ reservations: { importing, importSuccess, importError } }) => {
    return { importing, importSuccess, importError };
};

export default connect(mapStateToProps)(ReservationUploaderCSV);
