// React
import React, { useEffect } from 'react';

// Components
import { AddMemberForm } from '../components';

// Packages
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

// Router
import {
    AUTHENTICATED,
    DASHBOARD,
    RENTAL_DASHBOARD,
    RENTAL_MANAGER
} from '../router/config/routes';

// Redux - Actions, Reducers, Sagas
import { resettingNewMember } from '../store/actions/Members';
import OtcForm from '../components/Community/OtcForm';

const AddMember = ({ isSuperAdmin, userData, communityMembers, resettingNewMember }) => {
    const location = useLocation();
    const type = location?.state?.type || null;
    const members =
        communityMembers && communityMembers?.length
            ? {
                  email: communityMembers.map(member =>
                      member?.email ? member.email.toLowerCase() : null
                  ),
                  phone: communityMembers.map(member =>
                      member?.phone ? member.phone.toString() : null
                  )
              }
            : { email: [], phone: [] };

    useEffect(() => {
        return () => {
            resettingNewMember('member');
        };
    }, [resettingNewMember]);

    if (!userData && !type) return null;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    if (type) {
        if (type !== 'otc') {
            return <AddMemberForm type={type} members={members} />;
        } else {
            return <OtcForm type={type} members={members} />;
        }
    }

    return <Redirect to={`${AUTHENTICATED}${DASHBOARD}`} />;
};

const mapStateToProps = ({ user, manager }) => {
    const { userData } = user;
    const { communityMembers } = manager;
    return {
        userData,
        communityMembers
    };
};

export default connect(mapStateToProps, { resettingNewMember })(AddMember);
