import React, { useState } from 'react';

import { Container, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import ConfirmFooter from '../common/ConfirmFooter';
import ImageUploadEdit from './ImageUploadEdit';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    updateUserProfileInfo,
    updateUserProfileInfoFailure
} from '../../store/actions/Managers';
import Loader from '../common/Loader';
import Swal from 'sweetalert2';
import UserInfoUpdateSuccess from './UserInfoUpdateSuccess';
import UserInfoUpdateFailure from './UserInfoUpdateFailure';
import { verifyAuth } from '../../store/sagas/Auth';

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-1',
        cancelButton: 'btn btn-danger m-1'
    },
    buttonsStyling: false,
    title: 'Email changed!',
    text: 'Authenticate with password to update email.',
    input: 'password',
    inputLabel: 'Password',
    inputPlaceholder: 'Enter your password',
    inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
    },
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    reverseButtons: true
});

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const SettingsForm = ({
    userData,
    updatingAccountInfo,
    updatingAccountInfoSuccess,
    updatingAccountInfoError
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { email, first_name, last_name, phone, office_phone, type, uid } = userData;
    const [imgSrc, setImgSrc] = useState('');

    const roles = {
        super_admin: 'Admin',
        manager: 'Manager',
        rental_manager: 'Rental Manager',
        operator: 'Operator'
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        email: Yup.string().trim().lowercase().email(errorMessage.email.valid),
        phone: Yup.object().shape({
            country: Yup.string().trim(),
            number: Yup.string().trim(),
            code: Yup.string().trim()
        }),
        office_phone: Yup.object().shape({
            country: Yup.string().trim(),
            number: Yup.string().trim(),
            code: Yup.string().trim(),
            ext: Yup.string().trim()
        })
    });

    const validateMobilePhone = ({ phone: { code, number, country } }) => {
        const phoneSchema = Yup.string().phone(country);
        const isValid = number ? phoneSchema.isValidSync(code + number) : true;
        return isValid ? undefined : { phone: { number: 'invalid number' } };
    };

    const validateOfficePhone = ({ office_phone: { code, number, country } }) => {
        const phoneSchema = Yup.string().phone(country);
        const isValid = number ? phoneSchema.isValidSync(code + number) : true;
        return isValid ? undefined : { office_phone: { number: 'invalid number' } };
    };

    const validatePhones = values => {
        const isMobileValid = validateMobilePhone(values);
        const isOfficeValid = validateOfficePhone(values);
        const errors = { ...isMobileValid, ...isOfficeValid };
        return errors;
    };

    const submit = async values => {
        const data = { ...values, uid };
        if (userData.email !== values.email) {
            const { value, isConfirmed } = await swalWithBootstrapButtons.fire();

            if (isConfirmed && value) {
                const isVerified = await verifyAuth(userData.email, value);

                if (isVerified && isVerified.message) {
                    dispatch(updateUserProfileInfoFailure(isVerified.message));
                }
                if (isVerified && isVerified.user) {
                    dispatch(updateUserProfileInfo(data));
                }
            }
        } else {
            dispatch(updateUserProfileInfo(data));
        }
    };

    if (updatingAccountInfo) {
        return (
            <Container className="d-flex flex-column align-items-center flex-grow-1 justify-content-center">
                <Loader />
            </Container>
        );
    }

    if (updatingAccountInfoError) {
        return <UserInfoUpdateFailure error={updatingAccountInfoError} />;
    }

    if (updatingAccountInfoSuccess) {
        return <UserInfoUpdateSuccess />;
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={{
                        first_name: first_name || '',
                        last_name: last_name || '',
                        email: email || '',
                        type: type || '',
                        office_phone: office_phone || {
                            ext: '',
                            number: '',
                            code: '1',
                            country: 'US'
                        },
                        phone: phone || {
                            number: '',
                            code: '1',
                            country: 'US'
                        }
                    }}
                    onSubmit={submit}
                    validate={validatePhones}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-70 mx-md-auto">
                            <div className="mb-4">
                                <h2 className="h5 font-weight-normal mb-0">
                                    <span className="d-flex justify-content-start h3 font-weight-semi-bold">
                                        Profile Settings
                                    </span>
                                </h2>
                                <ImageUploadEdit
                                    imgSrc={imgSrc}
                                    setImgSrc={setImgSrc}
                                    userData={userData}
                                />
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="first name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            id="first_name"
                                            // placeholder="First Name"
                                            aria-label="first_name"
                                            error={returnError(errors, 'first_name')}
                                            value={values.first_name}
                                            onBlur={handleBlur('first_name')}
                                            onChange={handleChange('first_name')}
                                            invalid={
                                                returnTouched(touched, 'first_name') &&
                                                !!returnError(errors, 'first_name')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="last name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="last_name"
                                            // placeholder="Last Name"
                                            aria-label="last_name"
                                            error={returnError(errors, 'last_name')}
                                            value={values.last_name}
                                            onBlur={handleBlur('last_name')}
                                            onChange={handleChange('last_name')}
                                            invalid={
                                                returnTouched(touched, 'last_name') &&
                                                !!returnError(errors, 'last_name')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-start">
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        fieldTitle="email address"
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        // placeholder="Email"
                                        aria-label="email"
                                        error={returnError(errors, 'email')}
                                        value={values.email}
                                        onBlur={handleBlur('email')}
                                        onChange={handleChange('email')}
                                        invalid={
                                            returnTouched(touched, 'email') &&
                                            !!returnError(errors, 'email')
                                        }
                                        note="If you are changing your email the new address will be your login username. "
                                    />
                                </div>
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        disabled
                                        fieldTitle="account type"
                                        type="text"
                                        className="form-control"
                                        name="type"
                                        id="type"
                                        // placeholder="type"
                                        aria-label="type"
                                        error={returnError(errors, 'type')}
                                        value={roles[values.type]}
                                        onBlur={handleBlur('type')}
                                        onChange={handleChange('type')}
                                        invalid={
                                            returnTouched(touched, 'type') &&
                                            !!returnError(errors, 'type')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 2 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <ContryPhoneCodeSelect
                                            fieldTitle="Office phone"
                                            type="text"
                                            className="form-control"
                                            name="office_phone.number"
                                            id="office_phone.number"
                                            aria-label="office_phone.number"
                                            phoneError={returnError(
                                                errors,
                                                'office_phone.number'
                                            )}
                                            codeValue={values?.office_phone?.code}
                                            phoneValue={values?.office_phone?.number}
                                            countryValue={values?.office_phone?.country}
                                            onBlurPhone={handleBlur(
                                                'office_phone.number'
                                            )}
                                            onChangePhone={e => {
                                                handleChange('office_phone.number')(e);
                                            }}
                                            invalid={
                                                touched?.office_phone?.number &&
                                                !!errors?.office_phone?.number
                                            }
                                            onBlurCode={handleBlur('office_phone.code')}
                                            onChangeCode={value =>
                                                setFieldValue('office_phone.code', value)
                                            }
                                            onChangeCountry={value =>
                                                setFieldValue(
                                                    'office_phone.country',
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 0.5 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="ext"
                                            type="text"
                                            className="form-control"
                                            name="office_phone.ext"
                                            id="office_phone.ext"
                                            // placeholder="office_phone.ext"
                                            aria-label="office_phone.ext"
                                            error={returnError(
                                                errors,
                                                'office_phone.ext'
                                            )}
                                            value={values.office_phone.ext}
                                            onBlur={handleBlur('office_phone.ext')}
                                            onChange={handleChange('office_phone.ext')}
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'office_phone.ext'
                                                ) &&
                                                !!returnError(errors, 'office_phone.ext')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 0.5 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    />
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 2 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <ContryPhoneCodeSelect
                                            fieldTitle="Mobile phone"
                                            type="text"
                                            className="form-control"
                                            name="phone.number"
                                            id="phone.number"
                                            aria-label="phone.number"
                                            phoneError={returnError(
                                                errors,
                                                'phone.number'
                                            )}
                                            codeValue={values?.phone?.code}
                                            phoneValue={values?.phone?.number}
                                            countryValue={values?.phone?.country}
                                            onBlurPhone={handleBlur('phone.number')}
                                            onChangePhone={e => {
                                                handleChange('phone.number')(e);
                                            }}
                                            invalid={
                                                touched?.phone?.number &&
                                                !!errors?.phone?.number
                                            }
                                            onBlurCode={handleBlur('phone.code')}
                                            onChangeCode={value =>
                                                setFieldValue('phone.code', value)
                                            }
                                            onChangeCountry={value =>
                                                setFieldValue('phone.country', value)
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    />
                                </div>
                            </div>
                            <div className="d-flex row justify-content-center align-items-center my-5">
                                <ConfirmFooter
                                    disabled={!Object.values(touched).length}
                                    cancel={history.goBack}
                                    submitFunc={handleSubmit}
                                    submitText="Save"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({
    user: { userData },
    manager: { updatingAccountInfo, updatingAccountInfoSuccess, updatingAccountInfoError }
}) => {
    return {
        userData,
        updatingAccountInfo,
        updatingAccountInfoSuccess,
        updatingAccountInfoError
    };
};

export default connect(mapStateToProps)(SettingsForm);
