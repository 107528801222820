import React from 'react';
import { ResetPasswordForm } from '../components';

export default function ResetPasswordAuthenticatedPage() {
    return (
        <div className="d-flex flex-column align-items-start flex-grow-1 justify-content-start container">
            <ResetPasswordForm />
        </div>
    );
}
