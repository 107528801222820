// React
import React, { useState } from 'react';

// Components
import TabSwitch from '../components/Community/TabSwitch';
import CommunityHeader from '../components/Community/CommunityHeader';

// Packages
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Router
import { RENTAL_DASHBOARD, RENTAL_MANAGER } from '../router/config/routes';

const Community = ({
    primaryOrg,
    activeOrg,
    userData,
    communityMembers,
    userType,
    imported
}) => {
    const [selectedTab, onClick] = useState('Residents');
    const allowImport = userType === 'manager' || userType === 'super_admin';
    const orgData = userData?.type === 'manager' ? activeOrg : primaryOrg;

    if (!userData) return null;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    const tabs = [
        {
            value: 'Residents',
            id: 'pills-four-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-four'
        },
        // {
        //     value: 'Vendors',
        //     id: 'pills-five-tab',
        //     dataToggle: 'pill',
        //     role: 'tab',
        //     ariaControls: 'pills-five'
        // },
        // {
        //     value: 'Members',
        //     id: 'pills-six-tab',
        //     dataToggle: 'pill',
        //     role: 'tab',
        //     ariaControls: 'pills-six'
        // },
        {
            value: 'Invite Requests',
            id: 'pills-seven-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-seven'
        },
        {
            value: 'Pending',
            id: 'pills-eight-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-eight'
        },
        {
            value: 'Codes',
            id: 'pills-nine-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-nine'
        }
    ];

    return (
        <div className="main">
            <CommunityHeader
                org_name={orgData?.org_name}
                org_id={orgData?.org_id}
                allowImport={allowImport}
                isSuperAdmin={userType === 'super_admin'}
            />
            <div className="d-flex w-100 mb-3">
                <ul className="nav nav-pills nav-justified bg-white w-50" role="tablist">
                    {tabs.map(tab => (
                        <li
                            className="nav-item"
                            key={tab.id}
                            onClick={() => onClick(tab.value)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className={
                                    selectedTab === tab.value
                                        ? 'nav-link font-weight-medium active'
                                        : 'nav-link font-weight-medium'
                                }
                                id={tab.id}
                                data-toggle={tab.dataToggle}
                                role={tab.role}
                                aria-controls={tab.ariaControls}
                                aria-selected={
                                    selectedTab === tab.value ? 'true' : 'false'
                                }
                            >
                                <div className="d-md-flex justify-content-md-center align-items-md-center">
                                    {tab.value}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="d-flex w-100">
                <TabSwitch
                    tab={selectedTab}
                    tabProps={{
                        orgId: orgData?.org_id,
                        userData,
                        userType,
                        communityMembers,
                        imported
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ manager, user }) => {
    const { communityMembers, importedMembers } = manager;
    return {
        communityMembers,
        activeOrg: user.activeOrg,
        imported: importedMembers
    };
};

export default connect(mapStateToProps, {})(Community);
