import React, { useEffect } from 'react';

import { Container, Col, InputGroup, Input, InputGroupText } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import RentalManagerFooter from './RentalManagerFooter';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import {
    cancelReservation,
    reinstateReservation,
    resetReservationsActionState,
    updateReservation
} from '../../store/actions/Reservations';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loader from '../common/Loader';
import { parseCheckInOutDateTime } from '../../utils/Helpers';
import { timeStampNow, timeStampNowSeconds } from '../../config/Firebase';
import Swal from 'sweetalert2';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-1',
        cancelButton: 'btn btn-danger m-1'
    },
    buttonsStyling: false
});

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const renterLink = 'https://sagesystems.io/confirm-reservation';

const ReservationDetails = ({
    reservations,
    cancellingReservation,
    cancellingReservationSuccess,
    cancellingReservationError,
    reinstatingReservation,
    reinstatingReservationSuccess,
    reinstatingReservationError,
    updatingReservation,
    updatingReservationSuccess,
    updatingReservationError
}) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { resDetails } = location.state;
    const [copied, setCopied] = useState(false);
    const [currentRes, setCurrentRes] = useState(resDetails);

    const isEditable = () => {
        if (
            currentRes &&
            currentRes.access_expires?.seconds > timeStampNowSeconds() &&
            !currentRes.validated
        ) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (resDetails && reservations?.length) {
            const res = reservations.find(
                el => el.reservation_id === resDetails.reservation_id
            );
            setCurrentRes(res);
        }
    }, [reservations, resDetails]);

    const reinstateReservationRecord = () => {
        swalWithBootstrapButtons
            .fire({
                title: 'Are you sure?',
                text: 'Reinstating this reservation will create new access.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm reinstation',
                cancelButtonText: 'Go back',
                reverseButtons: true
            })
            .then(result => {
                if (result.isConfirmed) {
                    dispatch(reinstateReservation(currentRes));
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    swalWithBootstrapButtons.fire(
                        'Abort',
                        'Access recreation canceled.',
                        'error'
                    );
                }
            });
    };

    const cancelReservationRecord = () => {
        swalWithBootstrapButtons
            .fire({
                title: 'Are you sure?',
                text: 'Cancelling this reservation will revoke all access.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm cancellation',
                cancelButtonText: 'Go back',
                reverseButtons: true
            })
            .then(result => {
                if (result.isConfirmed) {
                    dispatch(cancelReservation(currentRes));
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    swalWithBootstrapButtons.fire(
                        'Abort',
                        'Access revoking canceled.',
                        'error'
                    );
                }
            });
    };

    const submit = values => {
        const inDate = Array.isArray(values.check_in_date)
            ? values.check_in_date[0]
            : values.check_in_date;

        const inTime = Array.isArray(values.check_in_time)
            ? {
                  hours: values.check_in_time[0].getHours(),
                  minutes: values.check_in_time[0].getMinutes()
              }
            : {
                  hours: values.check_in_time.getHours(),
                  minutes: values.check_in_time.getMinutes()
              };
        const outDate = Array.isArray(values.check_out_date)
            ? values.check_out_date[0]
            : values.check_out_date;

        const outTime = Array.isArray(values.check_out_time)
            ? {
                  hours: values.check_out_time[0].getHours(),
                  minutes: values.check_out_time[0].getMinutes()
              }
            : {
                  hours: values.check_out_time.getHours(),
                  minutes: values.check_out_time.getMinutes()
              };

        const accessStartsAt = parseCheckInOutDateTime(inDate.toDateString(), inTime);
        const accessExpiresAt = parseCheckInOutDateTime(outDate.toDateString(), outTime);

        const phone = values.phone?.number
            ? {
                  number: Number.parseInt(values.phone.number),
                  code: Number.parseInt(values.phone.code),
                  country: values.phone.country
              }
            : null;

        const editedReservation = {
            ...currentRes,
            ...values,
            access_begins: accessStartsAt,
            access_expires: accessExpiresAt,
            check_in_date: inDate.toDateString(),
            check_in_time: inTime,
            check_out_date: outDate.toDateString(),
            check_out_time: outTime,
            created_at: timeStampNow(),
            email: values.email || null,
            first_name: values.first_name,
            last_name: values.last_name,
            phone,
            phone_number: values.phone?.number
                ? `${values.phone.code}${values.phone.number}`
                : null,
            address: { ...currentRes.address, ...values.address }
        };
        swalWithBootstrapButtons
            .fire({
                title: 'Are you sure?',
                text: 'Changed information will be rewritten.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm update',
                cancelButtonText: 'Go back',
                reverseButtons: true
            })
            .then(result => {
                if (result.isConfirmed) {
                    dispatch(updateReservation(editedReservation));
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    swalWithBootstrapButtons.fire(
                        'Abort',
                        'Reservation updating canceled.',
                        'error'
                    );
                }
            });
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        check_in_date: Yup.string().trim().required(errorMessage.fromDate.required),
        check_out_date: Yup.string().trim().required(errorMessage.toDate.required),
        email: Yup.string().trim().lowercase().email(errorMessage.email.valid),
        phone: Yup.object().shape({
            country: Yup.string().trim(),
            number: Yup.string().trim(),
            code: Yup.string().trim().required()
        })
    });

    const validatePhone = ({ phone: { code, number, country } }) => {
        const phoneSchema = Yup.string().phone(country).required();
        const isValid = phoneSchema.isValidSync(code + number);
        return isValid ? undefined : { phone: { number: 'invalid number' } };
    };

    const getNextDay = date => date.fp_incr(1);

    if (cancellingReservationSuccess) {
        swalWithBootstrapButtons
            .fire('Success!', 'Reservation canceled', 'success')
            .then(result => dispatch(resetReservationsActionState()));
    }

    if (cancellingReservationError) {
        swalWithBootstrapButtons
            .fire('Error', `${cancellingReservationError}`, 'error')
            .then(result => dispatch(resetReservationsActionState()));
    }

    if (reinstatingReservationSuccess) {
        swalWithBootstrapButtons
            .fire('Success!', 'Reservation reinstated', 'success')
            .then(result => dispatch(resetReservationsActionState()));
    }

    if (reinstatingReservationError) {
        swalWithBootstrapButtons
            .fire('Error', `${reinstatingReservationError}`, 'error')
            .then(result => dispatch(resetReservationsActionState()));
    }

    if (updatingReservationSuccess) {
        swalWithBootstrapButtons
            .fire('Success!', 'Reservation updated', 'success')
            .then(result => dispatch(resetReservationsActionState()));
    }

    if (updatingReservationError) {
        swalWithBootstrapButtons
            .fire('Error', `${updatingReservationError}`, 'error')
            .then(result => dispatch(resetReservationsActionState()));
    }

    if (cancellingReservation || reinstatingReservation || updatingReservation) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={{
                        ...currentRes,
                        check_in_date:
                            parseCheckInOutDateTime(
                                currentRes.check_in_date,
                                currentRes.check_in_time
                            ) || '',
                        check_out_date:
                            parseCheckInOutDateTime(
                                currentRes.check_out_date,
                                currentRes.check_out_time
                            ) || '',
                        check_in_time:
                            parseCheckInOutDateTime(
                                currentRes.check_in_date,
                                currentRes.check_in_time
                            ) || '',
                        check_out_time:
                            parseCheckInOutDateTime(
                                currentRes.check_out_date,
                                currentRes.check_out_time
                            ) || '',
                        address: {
                            address_1: currentRes?.address?.address_1 || '',
                            address_2: currentRes?.address?.address_2 || ''
                        },
                        phone: {
                            number: currentRes?.phone?.number || '',
                            code: currentRes?.phone?.code || '1',
                            country: currentRes?.phone?.country || 'US'
                        },
                        email: currentRes.email || ''
                    }}
                    onSubmit={submit}
                    validate={validatePhone}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-70 mx-md-auto">
                            <div className="mb-4">
                                <h2 className="h5 d-flex flex-row align-items-center font-weight-normal mb-0">
                                    <div className="d-flex align-items-center justify-content-center h3 mb-0 font-weight-semi-bold">
                                        {`${currentRes.first_name} ${currentRes.last_name}`}
                                    </div>
                                    <div className="ml-3">
                                        {!currentRes.active ? (
                                            <div className="badge badge-danger badge-status">
                                                Cancelled
                                            </div>
                                        ) : currentRes.access_expires?.seconds >
                                          timeStampNowSeconds() ? (
                                            <div className="badge badge-success badge-status">
                                                Active
                                            </div>
                                        ) : (
                                            <div className="badge badge-other badge-status">
                                                Completed
                                            </div>
                                        )}
                                    </div>
                                </h2>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="first name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            id="first_name"
                                            // placeholder="First Name"
                                            aria-label="first_name"
                                            error={returnError(errors, 'first_name')}
                                            value={values.first_name}
                                            onBlur={handleBlur('first_name')}
                                            onChange={handleChange('first_name')}
                                            invalid={
                                                returnTouched(touched, 'first_name') &&
                                                !!returnError(errors, 'first_name')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="last name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="last_name"
                                            // placeholder="Last Name"
                                            aria-label="last_name"
                                            error={returnError(errors, 'last_name')}
                                            value={values.last_name}
                                            onBlur={handleBlur('last_name')}
                                            onChange={handleChange('last_name')}
                                            invalid={
                                                returnTouched(touched, 'last_name') &&
                                                !!returnError(errors, 'last_name')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-in date"
                                            required={true}
                                            type="text"
                                            className="form-control"
                                            name="check_in_date"
                                            id="check_in_date"
                                            placeholder="Select..."
                                            aria-label="check_in_date"
                                            error={returnError(errors, 'check_in_date')}
                                            value={values.check_in_date}
                                            selected={values.check_in_date}
                                            onChange={date =>
                                                setFieldValue('check_in_date', date)
                                            }
                                            invalid={
                                                returnTouched(touched, 'check_in_date') &&
                                                !!returnError(errors, 'check_in_date')
                                            }
                                            datePickerField={true}
                                            withDate={true}
                                            withTime={false}
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-in time"
                                            required={true}
                                            type="text"
                                            className="form-control"
                                            name="check_in_time"
                                            id="check_in_time"
                                            placeholder="Select..."
                                            aria-label="check_in_time"
                                            error={returnError(errors, 'check_in_time')}
                                            value={values.check_in_time}
                                            selected={values.check_in_time}
                                            onChange={date =>
                                                setFieldValue('check_in_time', date)
                                            }
                                            invalid={
                                                returnTouched(touched, 'check_in_time') &&
                                                !!returnError(errors, 'check_in_time')
                                            }
                                            datePickerField={true}
                                            withDate={false}
                                            withTime={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-out date"
                                            required={true}
                                            type="text"
                                            className="form-control"
                                            name="check_out_date"
                                            id="check_out_date"
                                            placeholder="Select..."
                                            aria-label="check_out_date"
                                            error={returnError(errors, 'check_out_date')}
                                            value={
                                                values.check_out_date ||
                                                (values.check_in_date &&
                                                    setFieldValue('check_out_date', [
                                                        getNextDay(
                                                            Array.isArray(
                                                                values.check_in_date
                                                            )
                                                                ? values.check_in_date[0]
                                                                : values.check_in_date
                                                        )
                                                    ]))
                                            }
                                            selected={
                                                values.check_out_date ||
                                                (values.check_in_date &&
                                                    setFieldValue('check_out_date', [
                                                        getNextDay(
                                                            Array.isArray(
                                                                values.check_in_date
                                                            )
                                                                ? values.check_in_date[0]
                                                                : values.check_in_date
                                                        )
                                                    ]))
                                            }
                                            onChange={date =>
                                                setFieldValue('check_out_date', date)
                                            }
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'check_out_date'
                                                ) &&
                                                !!returnError(errors, 'check_out_date')
                                            }
                                            datePickerField={true}
                                            datePickerOptions={{
                                                minDate:
                                                    values.check_in_date &&
                                                    getNextDay(
                                                        Array.isArray(
                                                            values.check_in_date
                                                        )
                                                            ? values.check_in_date[0]
                                                            : values.check_in_date
                                                    ),
                                                defaultDate:
                                                    values.check_in_date &&
                                                    getNextDay(
                                                        Array.isArray(
                                                            values.check_in_date
                                                        )
                                                            ? values.check_in_date[0]
                                                            : values.check_in_date
                                                    )
                                            }}
                                            withDate={true}
                                            withTime={false}
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-out time"
                                            required={true}
                                            type="text"
                                            className="form-control"
                                            name="check_out_time"
                                            id="check_out_time"
                                            placeholder="Select..."
                                            aria-label="check_out_time"
                                            error={returnError(errors, 'check_out_time')}
                                            value={values.check_out_time}
                                            selected={values.check_out_time}
                                            onChange={date =>
                                                setFieldValue('check_out_time', date)
                                            }
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'check_out_time'
                                                ) &&
                                                !!returnError(errors, 'check_out_time')
                                            }
                                            datePickerField={true}
                                            withDate={false}
                                            withTime={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 3 }}
                                        className="js-form-message col-8 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="Street address"
                                            type="text"
                                            className="form-control"
                                            name="address.address_1"
                                            id="address.address_1"
                                            // placeholder="Street address"
                                            aria-label="street_address"
                                            error={returnError(
                                                errors,
                                                'address.address_1'
                                            )}
                                            value={values?.address?.address_1}
                                            onBlur={handleBlur('address.address_1')}
                                            onChange={handleChange('address.address_1')}
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'address.address_1'
                                                ) &&
                                                !!returnError(errors, 'address.address_1')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-4 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="Unit"
                                            type="text"
                                            className="form-control"
                                            name="address.address_2"
                                            id="address.address_2"
                                            // placeholder="Unit"
                                            aria-label="unit"
                                            error={returnError(
                                                errors,
                                                'address.address_2'
                                            )}
                                            value={values?.address?.address_2}
                                            onBlur={handleBlur('address.address_2')}
                                            onChange={handleChange('address.address_2')}
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'address.address_2'
                                                ) &&
                                                !!returnError(errors, 'address.address_2')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <ContryPhoneCodeSelect
                                            disabled={!isEditable()}
                                            fieldTitle="Mobile phone"
                                            type="text"
                                            className="form-control"
                                            name="phone.number"
                                            id="phone.number"
                                            aria-label="phone.number"
                                            phoneError={returnError(
                                                errors,
                                                'phone.number'
                                            )}
                                            codeValue={values?.phone?.code}
                                            phoneValue={values?.phone?.number}
                                            countryValue={values?.phone?.country}
                                            onBlurPhone={handleBlur('phone.number')}
                                            onChangePhone={e => {
                                                handleChange('phone.number')(e);
                                            }}
                                            invalid={
                                                touched?.phone?.number &&
                                                !!errors?.phone?.number
                                            }
                                            onBlurCode={handleBlur('phone.code')}
                                            onChangeCode={value =>
                                                setFieldValue('phone.code', value)
                                            }
                                            onChangeCountry={value =>
                                                setFieldValue('phone.country', value)
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="email"
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            // placeholder="Email"
                                            aria-label="email"
                                            error={returnError(errors, 'email')}
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                            invalid={
                                                returnTouched(touched, 'email') &&
                                                !!returnError(errors, 'email')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 3 }}
                                        className="js-form-message d-flex mx-2 form-group"
                                    >
                                        <InputGroup>
                                            <Input
                                                placeholder="reservation link"
                                                value={renterLink}
                                                onChange={() => {}}
                                                disabled
                                            />
                                            <InputGroupText
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        renterLink
                                                    );
                                                    setCopied(true);
                                                }}
                                                className={
                                                    copied
                                                        ? 'copy-link-btn-copied'
                                                        : 'copy-link-btn'
                                                }
                                            >
                                                <i
                                                    className={`fa-regular ${
                                                        copied ? 'fa-check' : 'fa-copy'
                                                    } mr-2`}
                                                />
                                                {copied ? 'copied' : 'copy'}
                                            </InputGroupText>
                                        </InputGroup>
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message d-flex form-group"
                                    />
                                </div>
                            </div>
                            <div className="d-flex row justify-content-center align-items-center my-5">
                                <RentalManagerFooter
                                    active={currentRes.active}
                                    cancel={history.goBack}
                                    submitFunc={handleSubmit}
                                    cancelReserv={cancelReservationRecord}
                                    reinstate={reinstateReservationRecord}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({
    reservations: {
        reservations,
        cancellingReservation,
        cancellingReservationSuccess,
        cancellingReservationError,
        reinstatingReservation,
        reinstatingReservationSuccess,
        reinstatingReservationError,
        updatingReservation,
        updatingReservationSuccess,
        updatingReservationError
    }
}) => {
    return {
        reservations,
        cancellingReservation,
        cancellingReservationSuccess,
        cancellingReservationError,
        reinstatingReservation,
        reinstatingReservationSuccess,
        reinstatingReservationError,
        updatingReservation,
        updatingReservationSuccess,
        updatingReservationError
    };
};

export default connect(mapStateToProps)(ReservationDetails);
