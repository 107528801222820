import {
    CREATE_ACCESS_API_CALL,
    CREATE_ACCESS_API_SUCCESS,
    CREATE_ACCESS_API_MATCHED_ACCOUNT,
    CREATE_ACCESS_API_VERIFY_NUMBER,
    CREATE_ACCESS_API_NEW_VERIFICATION,
    CREATE_ACCESS_API_VERIFICATION_SUCCESS,
    CREATE_ACCESS_API_ERROR_MESSAGE,
    CREATE_ACCESS_API_NEW_VERIFICATION_ADDED,
    CREATE_ACCESS_API_START_OVER
} from './types';

export const createAccessAPICall = data => {
    return {
        type: CREATE_ACCESS_API_CALL,
        payload: data
    };
};

export const createAccessAPISuccess = () => {
    return {
        type: CREATE_ACCESS_API_SUCCESS
    };
};

export const createAccessAPIMatchedAccount = data => {
    return {
        type: CREATE_ACCESS_API_MATCHED_ACCOUNT,
        payload: data
    };
};

export const createAccessAPIVerifyNumber = data => {
    return {
        type: CREATE_ACCESS_API_VERIFY_NUMBER,
        payload: data
    };
};

export const createAccessAPINewVerification = data => {
    return {
        type: CREATE_ACCESS_API_NEW_VERIFICATION,
        payload: data
    };
};

export const createAccessAPINewVerificationAdded = code => {
    return {
        type: CREATE_ACCESS_API_NEW_VERIFICATION_ADDED,
        payload: code
    };
};

export const createAccessAPIVerificationSuccess = () => {
    return {
        type: CREATE_ACCESS_API_VERIFICATION_SUCCESS
    };
};

export const createAccessAPIErrorMessage = message => {
    return {
        type: CREATE_ACCESS_API_ERROR_MESSAGE,
        payload: message
    };
};

export const createAccessAPIStartOver = () => {
    return {
        type: CREATE_ACCESS_API_START_OVER
    };
};
