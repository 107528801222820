import {
    VALIDATE_INVITE,
    VALIDATE_INVITE_SUCCESS,
    VALIDATE_INVITE_FAILURE,
    SET_INVITE_PROCESSING_STATUS,
    DECLINE_INVITE,
    DECLINE_INVITE_SUCCESS,
    DECLINE_INVITE_FAILURE,
    ACCEPT_INVITE_FAILURE,
    ACCEPT_INVITE_SUCCESS,
    ACCEPT_INVITE,

} from './types';

export const validateInvite = data => {
    return {
        type: VALIDATE_INVITE,
        payload: data
    };
};

export const validateInviteSuccess = doc => {
    return {
        type: VALIDATE_INVITE_SUCCESS,
        payload: doc
    };
};

export const validateInviteFailure = error => {
    return {
        type: VALIDATE_INVITE_FAILURE,
        payload: error
    };
};

export const setInviteExpirationStatus = status => {
    return {
        type: SET_INVITE_PROCESSING_STATUS,
        payload: status
    };
};

export const declineInvite = data => {
    return {
        type: DECLINE_INVITE,
        payload: data
    };
};

export const declineInviteSuccess = () => {
    return {
        type: DECLINE_INVITE_SUCCESS
    };
};

export const declineInviteFailure = error => {
    return {
        type: DECLINE_INVITE_FAILURE,
        payload: error
    };
};

export const acceptInvite = data => {
    return {
        type: ACCEPT_INVITE,
        payload: data
    };
};

export const acceptInviteSuccess = () => {
    return {
        type: ACCEPT_INVITE_SUCCESS
    };
};

export const acceptInviteFailure = error => {
    return {
        type: ACCEPT_INVITE_FAILURE,
        payload: error
    };
};
