import React from 'react';
import { Button } from 'reactstrap';

export default function ConfirmFooter({ submitFunc, disabled, cancel, submitText }) {
    return (
        <nav
            style={{ height: '100px' }}
            className="d-flex flex-md-row mt-auto align-items-center flex-grow-1 p-1 bg-white fixed-bottom border-top justify-content-end z-10"
        >
            <div className="col-6">
                <div className="row m-auto">
                    <Button
                        className="transition-3d-hover cancel-button m-3 font-weight-semi-bold btn-wide"
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={disabled}
                        type="submit"
                        color="primary"
                        className="transition-3d-hover m-3 font-weight-semi-bold btn-wide"
                        onClick={submitFunc}
                    >
                        {submitText}
                    </Button>
                </div>
            </div>
        </nav>
    );
}
