import React, { useState, useRef, useEffect } from 'react';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { connect, useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { updateUserAvatar } from '../../store/actions/Managers';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import Loader from '../common/Loader';

const AvatarEditModal = ({
    image,
    userData,
    setImgSrc,
    setOpen,
    isOpen,
    toggleOpen,
    uploadingAvatar,
    uploadAvatarSuccess,
    uploadAvatarError
}) => {
    const dispatch = useDispatch();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [crop, setCrop] = useState({
        aspect: 1 / 1
    });

    const onImgLoad = () => {
        const { width, height } = imgRef.current;
        const defaultCropSize = width < height ? width / 2 : height / 2;
        setCrop({
            ...crop,
            unit: 'px',
            width: defaultCropSize,
            height: defaultCropSize,
            x: width / 2 - defaultCropSize / 2,
            y: height / 2 - defaultCropSize / 2
        });
    };

    useEffect(() => {
        if (uploadAvatarSuccess && !isLoaded) {
            setImgSrc(uploadAvatarSuccess);
            setIsLoaded(true);
            setOpen(false);
        }
    }, [setOpen, isLoaded, setIsLoaded, uploadAvatarSuccess, setImgSrc]);

    useDebounceEffect(
        async () => {
            if (
                crop?.width &&
                crop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(imgRef.current, previewCanvasRef.current, crop);
            }
        },
        100,
        [crop]
    );

    const updateAvatar = () => {
        const cropped = previewCanvasRef.current.toDataURL('image/jpeg');
        dispatch(
            updateUserAvatar({
                userId: userData.uid,
                imgFile: cropped
            })
        );
    };

    return (
        <Modal
            style={{ width: '50%' }}
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            toggle={toggleOpen}
        >
            <ModalBody>
                <div className="container">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div className="d-flex w-100 justify-content-start h5 font-weight-semi-bold border-bottom p-1 pb-2">
                            Change your avatar
                        </div>
                        <div style={{ margin: '10px' }}>
                            <ReactCrop
                                aspect={1 / 1}
                                crop={crop}
                                onChange={crop => {
                                    setCrop(crop);
                                }}
                            >
                                <img
                                    src={image}
                                    style={{ maxHeight: '350px' }}
                                    alt=""
                                    onLoad={onImgLoad}
                                    ref={imgRef}
                                />
                            </ReactCrop>
                        </div>
                        {image && (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    height: '350px',
                                    width: '350px',
                                    margin: '10px'
                                }}
                            >
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: crop.width,
                                        height: crop.height
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <nav
                        style={{ height: '80px' }}
                        className={`d-flex align-items-center w-100 p-1 bg-white border-top ${
                            uploadingAvatar
                                ? 'justify-content-center'
                                : 'justify-content-end'
                        }`}
                    >
                        {uploadingAvatar ? (
                            <Loader />
                        ) : (
                            <>
                                <Button
                                    className="transition-3d-hover cancel-button m-2 font-weight-semi-bold btn-sm"
                                    onClick={toggleOpen}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    className="transition-3d-hover m-2 font-weight-semi-bold btn-sm"
                                    onClick={updateAvatar}
                                >
                                    Update avatar
                                </Button>
                            </>
                        )}
                    </nav>
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = ({
    manager: { uploadingAvatar, uploadAvatarSuccess, uploadAvatarError }
}) => {
    return { uploadingAvatar, uploadAvatarSuccess, uploadAvatarError };
};

export default connect(mapStateToProps)(AvatarEditModal);
