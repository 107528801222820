import {
    SET_USER_ORG,
    CREATE_ORG,
    CREATE_ORG_SUCCESS,
    CREATE_ORG_FAILURE,
    RESET_ORG_CREATION_STATE,
    SET_PRIMARY_ORG,
    ADD_INVITE_TO_ORG,
    ADD_INVITE_TO_ORG_SUCCESS,
    ADD_INVITE_TO_ORG_FAILURE,
    RESET_INVITE_ADDING_STATE,
    GET_ORGS_COLLECTION_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    orgs: null,
    creatingOrg: false,
    orgCreationError: null,
    newOrgName: null,
    primaryOrg: null,
    addingInvite: false,
    inviteAddingError: null,
    inviteAddingSuccess: false
};

const alphabetizedOrgs = orgs => {
    if (orgs.length > 1) return orgs.sort((a, b) => a.org_name.localeCompare(b.org_name));
    return orgs;
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORGS_COLLECTION_SUCCESS:
        case SET_USER_ORG:
            return {
                ...state,
                orgs: alphabetizedOrgs(action.payload)
            };
        case CREATE_ORG:
            return {
                ...state,
                creatingOrg: true,
                newOrgName: null
            };
        case CREATE_ORG_SUCCESS:
            return {
                ...state,
                creatingOrg: false,
                newOrgName: action.payload
            };
        case CREATE_ORG_FAILURE:
            return {
                ...state,
                creatingOrg: false,
                orgCreationError: action.payload
            };
        case RESET_ORG_CREATION_STATE:
            return {
                ...state,
                creatingOrg: false,
                orgCreationError: null,
                newOrgName: null
            };
        case SET_PRIMARY_ORG:
            return {
                ...state,
                primaryOrg: action.payload
            };
        case ADD_INVITE_TO_ORG:
            return {
                ...state,
                addingInvite: true,
                inviteAddingError: null,
                inviteAddingSuccess: false
            };
        case ADD_INVITE_TO_ORG_SUCCESS:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: null,
                inviteAddingSuccess: action.payload
            };
        case ADD_INVITE_TO_ORG_FAILURE:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: action.payload,
                inviteAddingSuccess: false
            };
        case RESET_INVITE_ADDING_STATE:
            return {
                ...state,
                addingInvite: false,
                inviteAddingError: null,
                inviteAddingSuccess: false
            };
        default:
            return state;
    }
};

export default orgReducer;
