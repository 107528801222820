import React from 'react';
import { Container } from 'reactstrap';

export default function AccessSuccess() {
    return (
        <div className="space-3 vh-100 bg-dark">
            <Container className="col-small">
                <div className="w-md-75 mx-md-auto p-2">
                    <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                        <div className="d-flex flex-column mb-2 align-items-center justify-content-center">
                            <div className="row align-items-center justify-content-around my-5">
                                <div className="col-12 mt-5">
                                    <div>
                                        <img
                                            src="../assets/success.png"
                                            alt="Success"
                                            style={{ width: '100%' }}
                                            className="mb-5"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
