// Redux, Redux-Sagas
import {
    all,
    call,
    fork,
    put,
    take,
    cancelled,
    takeLatest
    // select
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

// import * as selectors from './Selectors';

// Firebase
import { auth, EmailAuthProvider, func } from '../../config/Firebase';

// Actions, Action Types
import {
    CHECK_USER_AUTHENTICATED,
    LOGIN_USER_EMAIL_PASSWORD,
    LOGOUT_USER,
    NON_AUTH_RESET_USER_PASSWORD,
    VERIFY_ACTION_CODE,
    EXECUTE_RESET_PASSWORD_FLIGHT,
    UPDATE_AUTH_USER_PASSWORD,
    UPDATE_AUTH_USER_EMAIL
} from '../actions/types';

import {
    userIsAuthenticated,
    userNotAuthenticated,
    loginUserFailure,
    logoutUserSuccess,
    sendResetPasswordLinkSuccess,
    sendResetPasswordLinkFailure,
    actionCodeValidationSuccess,
    actionCodeValidationFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    passwordUpdateFailure,
    passwordUpdateSuccess,
    emailUpdateSuccess,
    emailUpdateFailure
} from '../actions/Auth';

// Constants
import { errorMessage } from '../../utils/Constants';

import { userCollectionWatch } from './User';

// Loggers
// import { log } from '../../utils/Loggers';

const executeResetPassword = func.httpsCallable('resetPasswordRequest');

////////////////////////////////////// Checking/Watching for Authenticated User ///////////////////////////////////////

export function* userAuthWatch() {
    let unsubscribeAuth;
    const userAuthChannel = eventChannel(emit => {
        unsubscribeAuth = auth.onAuthStateChanged(user => {
            if (user) {
                user.exists = true;
                emit({ user });
            } else {
                user = { exists: false };
                emit({ user });
            }
        });
        return unsubscribeAuth;
    });
    try {
        while (true) {
            const authenticated = yield take(userAuthChannel);
            if (authenticated.user.exists === true) {
                yield put(userIsAuthenticated(authenticated.user));
                yield fork(userCollectionWatch, authenticated.user);
                userAuthChannel.close(); //Unregistering as we dont need to listen continuously
            } else {
                yield put(userNotAuthenticated());
                userAuthChannel.close();
            }
        }
    } catch (error) {
        console.log('Auth Error: getting user auth channel', {
            error
        });
    } finally {
        unsubscribeAuth(); // Detach firebase listener
        // Detach saga event emitter if the saga was cancelled
        if (yield cancelled()) {
            userAuthChannel.close();
            unsubscribeAuth();
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////// Log In With Email and Password ////////////////////////////////////////////

export const loginUserEmailPasswordRequest = async (email, password, operator) =>
    operator
        ? { authenticated: true }
        : await auth
              .signInWithEmailAndPassword(email, password)
              .then(response => {
                  return { authenticated: response };
              })
              .catch(error => {
                  return { error };
              });

export function* loginUserEmailPassword({ payload }) {
    const { email, password } = payload;
    const { authenticated, error } = yield call(() =>
        loginUserEmailPasswordRequest(email, password)
    );
    if (authenticated) {
        yield put(userIsAuthenticated(authenticated.user));
        yield fork(userCollectionWatch, authenticated.user);
        return true;
    } else {
        let errorObj;
        if (error.code === 'auth/wrong-password') {
            errorObj = {
                error,
                message: errorMessage.emailPasswordCombo
            };
            console.log('Auth Error: signing in with username & password', {
                error,
                email,
                password
            });
        } else if (error.code === 'auth/user-not-found') {
            errorObj = {
                error,
                message: errorMessage.emailNoUser
            };
            console.log('Auth Error: signing in with nonexistent account', {
                error,
                email,
                password
            });
        }

        yield put(loginUserFailure(errorObj));
        return false;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////// Reset User Password Request Link Const and Func ///////////////////////////////////

const sendResetUserPasswordLinkRequest = async email => {
    const request = await executeResetPassword({ email });
    return { resetPasswordLink: request };
};

function* sendResetUserPasswordLink({ payload }) {
    const { email } = payload;
    const { resetPasswordLink, error } = yield call(
        sendResetUserPasswordLinkRequest,
        email
    );
    if (resetPasswordLink) {
        yield put(sendResetPasswordLinkSuccess());
    } else {
        if (error.code === 'auth/user-not-found') {
            console.log(
                'Reset Password Link Error: email not associated with existing account',
                {
                    error,
                    email
                }
            );
            yield put(sendResetPasswordLinkFailure());
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////// Reset User Password Request Const and Func /////////////////////////////////////

const verifyActionCodeRequest = oobCode => {
    return auth
        .verifyPasswordResetCode(oobCode)
        .then(email => {
            return { validCode: email };
        })
        .catch(error => {
            return { error };
        });
};

function* verifyActionCode({ payload }) {
    const { oobCode } = payload;
    const { validCode, error } = yield call(verifyActionCodeRequest, oobCode);
    if (validCode) {
        yield put(actionCodeValidationSuccess());
    } else {
        console.log('Reset Password Link Error: link has expired or is invalid', {
            error,
            oobCode
        });
        yield put(actionCodeValidationFailure());
    }
}

const resetUserPasswordRequest = async (oobCode, password) => {
    return auth
        .confirmPasswordReset(oobCode, password)
        .then(() => {
            return { resetPassword: true };
        })
        .catch(error => {
            return { error };
        });
};

function* resetUserPassword({ payload }) {
    const { oobCode, password } = payload;
    const { resetPassword, error } = yield call(
        resetUserPasswordRequest,
        oobCode,
        password
    );
    if (resetPassword) {
        yield put(resetPasswordSuccess());
    } else {
        console.log('Reset Password Error: unsuccessful password reset', {
            error,
            oobCode,
            password
        });
        yield put(resetPasswordFailure());
        // if (error.code === 'auth/user-not-found') {
        //     // yield put(sendResetPasswordLinkFailure());
        //     //Log sentry of people trying to request password reset with no actual account
        // }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////// Create User With Email and Password Const and Func ///////////////////////////////////

// const createUserWithEmailPasswordRequest = async (email, password) =>
//   await auth.createUserWithEmailAndPassword(email, password)
//     .then(authUser => authUser)
//     .catch(error => error);

// function* createUserWithEmailPassword({ payload }) {
//   const { email, password } = payload;
//   try {
//     const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
//     if (signUpUser.message) {
//       console.log(signUpUser.message)
//       // yield put(showAuthMessage(signUpUser.message));
//     } else {
//       yield put(userSignUpSuccess(signUpUser));
//     }
//   } catch (error) {
//     console.log(error)
//     // yield put(showAuthMessage(error));
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////// Log User Out ////////////////////////////////////////////////

const loggingUserOutRequest = async () =>
    await auth
        .signOut()
        .then(authUser => authUser)
        .catch(error => error);

function* loggingUserOut() {
    try {
        const logoutUser = yield call(loggingUserOutRequest);
        if (logoutUser?.message) {
            console.log(logoutUser.message);
        } else {
            yield put(logoutUserSuccess());
        }
    } catch (error) {
        console.log('Auth Error: user unsuccessful in logging out', {
            error
        });
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function verifyAuth(email, password) {
    try {
        const currentAuthUser = await auth.currentUser;

        if (!currentAuthUser) {
            throw new Error('Auth link may be expired.');
        } else {
            const credential = await EmailAuthProvider.credential(email, password);
            return currentAuthUser
                .reauthenticateWithCredential(credential)
                .then(res => {
                    return res;
                })
                .catch(err => {
                    console.log(err);
                    return err;
                });
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export function* updateUserPassword({ payload }) {
    try {
        const currentAuthUser = yield auth.currentUser;

        if (!currentAuthUser) {
            throw new Error('Auth link may be expired.');
        }

        const updated = yield currentAuthUser
            .updatePassword(payload)
            .then(() => true)
            .catch(err => {
                throw err;
            });

        if (updated) {
            yield put(passwordUpdateSuccess());
        }
    } catch (error) {
        yield put(passwordUpdateFailure(`Password was not set. ${error}`));
        return false;
    }
}

export function* updateUserEmail({ payload }) {
    try {
        const currentAuthUser = yield auth.currentUser;

        if (!currentAuthUser) {
            throw new Error('Auth link may be expired.');
        }

        const updated = yield currentAuthUser
            .updateEmail(payload)
            .then(() => true)
            .catch(err => {
                throw err;
            });

        if (updated) {
            yield put(emailUpdateSuccess());
            return true;
        }
        yield put(emailUpdateFailure('Email was not updated. Try again later.'));
        return false;
    } catch (error) {
        yield put(emailUpdateFailure(`Email was not updated. ${error}`));
        return false;
    }
}

/////////////////////////////////////////// Function Generators For Root Saga /////////////////////////////////////////

export function* getUserAuth() {
    yield takeLatest(CHECK_USER_AUTHENTICATED, userAuthWatch);
}

export function* loginUser() {
    yield takeLatest(LOGIN_USER_EMAIL_PASSWORD, loginUserEmailPassword);
}

// export function* createUserAccount() {
//   yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
// }

export function* sendingResetPasswordLink() {
    yield takeLatest(NON_AUTH_RESET_USER_PASSWORD, sendResetUserPasswordLink);
}

export function* logOutUser() {
    yield takeLatest(LOGOUT_USER, loggingUserOut);
}

export function* verifyingActionCode() {
    yield takeLatest(VERIFY_ACTION_CODE, verifyActionCode);
}

export function* resettingUserPassword() {
    yield takeLatest(EXECUTE_RESET_PASSWORD_FLIGHT, resetUserPassword);
}

export function* updateAuthPassword() {
    yield takeLatest(UPDATE_AUTH_USER_PASSWORD, updateUserPassword);
}

export function* updateAuthEmail() {
    yield takeLatest(UPDATE_AUTH_USER_EMAIL, updateUserEmail);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////// Root Saga //////////////////////////////////////////////////////

export default function* rootSaga() {
    yield all([
        fork(loginUser),
        fork(getUserAuth),
        // fork(createUserAccount),
        fork(sendingResetPasswordLink),
        fork(logOutUser),
        fork(verifyingActionCode),
        fork(resettingUserPassword),
        fork(updateAuthPassword),
        fork(updateAuthEmail)
    ]);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
