import React from 'react';

// Packages
import { Button } from 'reactstrap';

// SVG
import errorImg from '../../assets/error.svg';

const FailureScreen = ({ error, actionTitle, action }) => {
    return (
        <div className="d-flex flex-column flex-grow-1 w-100 h-100 align-items-center justify-content-center">
            <img style={{ height: '140px', width: '140px' }} src={errorImg} alt="" />
            <h2 className="font-weight-semi-bold">Something went wrong!</h2>
            {error && <p className="font-weight-semi-bold">{error}</p>}
            <div className="row mx-auto mt-3">
                <Button
                    style={{ backgroundColor: '#424242' }}
                    className="transition-3d-hover d-flex align-items-center font-weight-semi-bold m-3 btn btn-sm btn-primary"
                    onClick={action}
                >
                    {actionTitle}
                </Button>
            </div>
        </div>
    );
};

export default FailureScreen;
