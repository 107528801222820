import React from 'react';

import { Container, Button, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import { connect, useDispatch } from 'react-redux';
import { useState } from 'react';
import ConfirmReservationLogin from './ConfirmReservationLogin';
import ExistingAccountFound from './ExistingAccountFound';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';
import VerificationCodeForm from './VerificationCodeForm';

import { createAccessAPICall } from '../../store/actions/ConfirmReservation';
import { useEffect } from 'react';

const returnError = (errors, field) => errors[field];

const AddRenterAccessForm = ({
    reservation,
    existingAccount,
    newVerificationReady,
    verified
}) => {
    const dispatch = useDispatch();
    const [screen, setScreen] = useState('renter-form');

    const validatePhone = ({ code, number, country }) => {
        const phoneSchema = yup.string().phone(country).required();
        const isValid = phoneSchema.isValidSync(code + number);
        return isValid ? undefined : { number: 'invalid number' };
    };

    const submit = async values => {
        dispatch(createAccessAPICall({ values, invite: reservation }));
    };

    useEffect(() => {
        if (verified && existingAccount) {
            dispatch(
                createAccessAPICall({
                    values: existingAccount.phone,
                    invite: reservation
                })
            );
        }
    }, [verified, dispatch, reservation, existingAccount]);

    const validationSchema = yup.object().shape({
        country: yup.string().trim(),
        number: yup.string().trim(),
        code: yup.string().trim().required()
    });

    if ((existingAccount && screen === 'sign-in') || screen === 'sign-in') {
        return (
            <ConfirmReservationLogin goToScreen={setScreen} reservation={reservation} />
        );
    }

    if (newVerificationReady && !verified) {
        return <VerificationCodeForm reservation={reservation} />;
    }

    if (existingAccount && !verified) {
        return <ExistingAccountFound goToScreen={setScreen} reservation={reservation} />;
    }

    if (screen === 'renter-form' || verified) {
        return (
            <Container className="space-2 col-small">
                <Col>
                    <Formik
                        initialValues={{
                            country: reservation.phone?.country || 'US',
                            code: reservation.phone?.code || '1',
                            number: reservation.phone?.number || ''
                        }}
                        onSubmit={submit}
                        validate={validatePhone}
                        validationSchema={validationSchema}
                        validateOnChange
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            touched,
                            values
                        }) => (
                            <Form className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                                <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                                    <img
                                        src="../assets/sage-icon.png"
                                        alt="logo"
                                        width={80}
                                    />
                                    <div className="d-flex w-md-75 flex-column mb-2 align-items-center justify-content-center">
                                        <div
                                            style={{ fontSize: '32px' }}
                                            className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                                        >
                                            Reservation found!
                                        </div>
                                        <div
                                            style={{ fontSize: '16px' }}
                                            className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal my-3"
                                        >
                                            Great! Please provide your mobile phone number
                                            to complete your account setup. You will
                                            receive a text with an invite to the download
                                            the app for accessing the gates and facilities
                                            during your stay at {reservation.org_name}.
                                        </div>
                                        <div className="mt-1 mb-4">
                                            <div
                                                className="link-muted link-text text-capitalize font-weight-normal"
                                                onClick={() => {
                                                    setScreen('sign-in');
                                                }}
                                            >
                                                Already have an account? Sign in.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="js-form-message form-group mx-5">
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="js-form-message w-50 form-group mb-0">
                                            <ContryPhoneCodeSelect
                                                required={true}
                                                fieldTitle="Mobile phone"
                                                type="text"
                                                className="form-control"
                                                name="number"
                                                id="number"
                                                aria-label="number"
                                                phoneError={returnError(errors, 'number')}
                                                codeValue={values.code}
                                                phoneValue={values.number}
                                                countryValue={values.country}
                                                onBlurPhone={handleBlur('number')}
                                                onChangePhone={e => {
                                                    handleChange('number')(e);
                                                }}
                                                invalid={
                                                    touched.number && !!errors.number
                                                }
                                                onBlurCode={handleBlur('code')}
                                                onChangeCode={value =>
                                                    setFieldValue('code', value)
                                                }
                                                onChangeCountry={value =>
                                                    setFieldValue('country', value)
                                                }
                                                note="By submitting this form and signing up for texts, you consent to receive text messages (e.g. invite codes) 
                                                from SAGE Systems. Msg & data rates may apply. 
                                                Unsubscribe at any time by replying STOP."
                                            />
                                            <a
                                                href="https://sagesystems.io/privacy"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Privacy Policy
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex row justify-content-end align-items-center border-top my-5 mx-2">
                                    <Button
                                        disabled={touched.number && !!errors.number}
                                        type="submit"
                                        color="primary"
                                        className="transition-3d-hover mt-5 mr-2 btn"
                                        onClick={handleSubmit}
                                    >
                                        Send invite
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Container>
        );
    }
};

const mapStateToProps = ({
    confirmAccess: { existingAccount, newVerificationReady, verified }
}) => ({ existingAccount, newVerificationReady, verified });

export default connect(mapStateToProps)(AddRenterAccessForm);
