import { combineReducers } from 'redux';
import AuthReducer from './Auth';
import InitialReducer from './Initial';
import OrgReducer from './Org';
import PanelReducer from './Panels';
import UserReducer from './User';
import ManagerReducer from './Manager';
import AccessKeysReducer from './AccessKeys';
import PostReducer from './Post';
import AccessLogReducer from './AccessLog';
import InvitesReducer from './Invites';
import ReservationsReducer from './Reservation';
import ConfirmReservationReducer from './ConfirmReservation';
import CallsReducer from './Calls';
import MembersReducer from './Members';
import SuperAdminReducer from './SuperAdmin';
import RegistrationReducer from './Registration';
import OtcReducer from './Otc';
import RemovalReducer from './Removal';

import { LOGOUT_USER } from '../actions/types';
import guestsReducer from './Guests';

const appReducer = combineReducers({
    auth: AuthReducer,
    init: InitialReducer,
    org: OrgReducer,
    panel: PanelReducer,
    user: UserReducer,
    manager: ManagerReducer,
    accessKey: AccessKeysReducer,
    post: PostReducer,
    access: AccessLogReducer,
    invites: InvitesReducer,
    reservations: ReservationsReducer,
    confirmAccess: ConfirmReservationReducer,
    calls: CallsReducer,
    guests: guestsReducer,
    members: MembersReducer,
    super: SuperAdminReducer,
    register: RegistrationReducer,
    otc: OtcReducer,
    removal: RemovalReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        const { init } = state;
        state = { init };
    }
    return appReducer(state, action);
};

export default rootReducer;
