// React
import React from 'react';

// Packages
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Router
import { RENTAL_DASHBOARD, RENTAL_MANAGER } from '../router/config/routes';

const Vendors = ({ orgId, userData }) => {
    if (!userData) return null;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    return (
        <div className="main">
            <h5 className="my-3">Vendors List</h5>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return {
        userData: user.userData
    };
};

export default connect(mapStateToProps)(Vendors);
