import React from 'react';
import { useStopwatch } from 'react-timer-hook';

const CallInterval = () => {
    const { seconds, minutes, hours } = useStopwatch({ autoStart: true });
    return (
        <span className="m-4">{`0${hours}:${minutes < 10 ? 0 : ''}${minutes}:${
            seconds < 10 ? 0 : ''
        }${seconds}`}</span>
    );
};

export default CallInterval;
