import {
    ADD_RESERVATION,
    ADD_RESERVATION_SUCCESS,
    ADD_RESERVATION_FAILURE,
    VALIDATE_RESERVATION,
    VALIDATE_RESERVATION_SUCCESS,
    VALIDATE_RESERVATION_FAILURE,
    GET_RESERVATIONS,
    GET_RESERVATIONS_SUCCESS,
    GET_RESERVATIONS_FAILURE,
    RESET_ADD_RESERVATION_STATE,
    WATCH_RESERVATIONS,
    CREATE_TENANT_ACCESS,
    CREATE_TENANT_ACCESS_FAILURE,
    CREATE_TENANT_ACCESS_SUCCESS,
    CANCEL_RESERVATION,
    CANCEL_RESERVATION_FAILURE,
    CANCEL_RESERVATION_SUCCESS,
    REINSTATE_RESERVATION,
    REINSTATE_RESERVATION_FAILURE,
    REINSTATE_RESERVATION_SUCCESS,
    UPDATE_RESERVATION,
    UPDATE_RESERVATION_SUCCESS,
    UPDATE_RESERVATION_FAILURE,
    RESET_RESERVATION_ACTIONS_STATE,
    IMPORT_RESERVATIONS_CSV,
    IMPORT_RESERVATIONS_CSV_FAILURE,
    IMPORT_RESERVATIONS_CSV_SUCCESS,
    RESET_IMPORT_RESERVATIONS_STATE,
    STORE_GUARD_RESERVATION_ID
} from './types';

export const addReservation = data => {
    return {
        type: ADD_RESERVATION,
        payload: data
    };
};

export const addReservationSuccess = data => {
    return {
        type: ADD_RESERVATION_SUCCESS,
        payload: data
    };
};

export const addReservationFailure = error => {
    return {
        type: ADD_RESERVATION_FAILURE,
        payload: error
    };
};

export const resetAddReservationState = () => {
    return {
        type: RESET_ADD_RESERVATION_STATE
    };
};

export const validateReservation = data => {
    return {
        type: VALIDATE_RESERVATION,
        payload: data
    };
};

export const validateReservationSuccess = data => {
    return {
        type: VALIDATE_RESERVATION_SUCCESS,
        payload: data
    };
};

export const validateReservationFailure = error => {
    return {
        type: VALIDATE_RESERVATION_FAILURE,
        payload: error
    };
};

export const getReservations = () => {
    return {
        type: GET_RESERVATIONS
    };
};

export const getReservationsSuccess = data => {
    return {
        type: GET_RESERVATIONS_SUCCESS,
        payload: data
    };
};

export const getReservationsFailure = error => {
    return {
        type: GET_RESERVATIONS_FAILURE,
        payload: error
    };
};

export const watchReservationsCollection = data => {
    return {
        type: WATCH_RESERVATIONS,
        payload: data
    };
};

export const createTenantAccess = data => {
    return {
        type: CREATE_TENANT_ACCESS,
        payload: data
    };
};

export const createTenantAccessSuccess = data => {
    return {
        type: CREATE_TENANT_ACCESS_SUCCESS,
        payload: data
    };
};

export const createTenantAccessFailure = error => {
    return {
        type: CREATE_TENANT_ACCESS_FAILURE,
        payload: error
    };
};

export const cancelReservation = data => {
    return {
        type: CANCEL_RESERVATION,
        payload: data
    };
};

export const cancelReservationSuccess = () => {
    return {
        type: CANCEL_RESERVATION_SUCCESS
    };
};

export const cancelReservationFailure = error => {
    return {
        type: CANCEL_RESERVATION_FAILURE,
        payload: error
    };
};

export const reinstateReservation = data => {
    return {
        type: REINSTATE_RESERVATION,
        payload: data
    };
};

export const reinstateReservationSuccess = () => {
    return {
        type: REINSTATE_RESERVATION_SUCCESS
    };
};

export const reinstateReservationFailure = error => {
    return {
        type: REINSTATE_RESERVATION_FAILURE,
        payload: error
    };
};

export const updateReservation = data => {
    return {
        type: UPDATE_RESERVATION,
        payload: data
    };
};

export const updateReservationSuccess = () => {
    return {
        type: UPDATE_RESERVATION_SUCCESS
    };
};

export const updateReservationFailure = error => {
    return {
        type: UPDATE_RESERVATION_FAILURE,
        payload: error
    };
};

export const resetReservationsActionState = () => {
    return {
        type: RESET_RESERVATION_ACTIONS_STATE
    };
};

export const importReservations = data => {
    return {
        type: IMPORT_RESERVATIONS_CSV,
        payload: data
    };
};

export const importReservationsSuccess = data => {
    return {
        type: IMPORT_RESERVATIONS_CSV_SUCCESS,
        payload: data
    };
};

export const importReservationsFailure = error => {
    return {
        type: IMPORT_RESERVATIONS_CSV_FAILURE,
        payload: error
    };
};

export const resetReservationImportState = () => {
    return {
        type: RESET_IMPORT_RESERVATIONS_STATE
    };
};

export const storeGuardResId = id => {
    return {
        type: STORE_GUARD_RESERVATION_ID,
        payload: id
    };
};
