import {
    VALIDATE_INVITE,
    VALIDATE_INVITE_SUCCESS,
    VALIDATE_INVITE_FAILURE,
    SET_INVITE_PROCESSING_STATUS,
    DECLINE_INVITE,
    DECLINE_INVITE_SUCCESS,
    DECLINE_INVITE_FAILURE,
    ACCEPT_INVITE,
    ACCEPT_INVITE_SUCCESS,
    ACCEPT_INVITE_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    validating: false,
    validated: null,
    validationErrors: null,
    inviteProcessingStatus: null,
    inviteDeclining: false,
    declined: false,
    inviteDecliningError: null,
    inviteAccepting: false,
    inviteAcceptingError: null,
    accepted: false
};

const invitesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VALIDATE_INVITE:
            return {
                ...state,
                validating: true
            };
        case VALIDATE_INVITE_SUCCESS:
            return {
                ...state,
                validating: false,
                validated: action.payload
            };
        case VALIDATE_INVITE_FAILURE:
            return {
                ...state,
                validating: false,
                validationErrors: action.payload
            };
        case SET_INVITE_PROCESSING_STATUS:
            return {
                ...state,
                validating: false,
                inviteProcessingStatus: action.payload
            };
        case DECLINE_INVITE:
            return {
                ...state,
                inviteDeclining: true
            };
        case DECLINE_INVITE_SUCCESS:
            return {
                ...state,
                inviteDeclining: false,
                declined: true
            };
        case DECLINE_INVITE_FAILURE:
            return {
                ...state,
                inviteDeclining: false,
                inviteDecliningError: action.payload
            };
        case ACCEPT_INVITE:
            return {
                ...state,
                inviteAccepting: true
            };
        case ACCEPT_INVITE_SUCCESS:
            return {
                ...state,
                inviteAccepting: false,
                accepted: true
            };
        case ACCEPT_INVITE_FAILURE:
            return {
                ...state,
                inviteAccepting: false,
                inviteAcceptingError: action.payload
            };
        default:
            return state;
    }
};

export default invitesReducer;
