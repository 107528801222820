import React from 'react';
import { Container, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { errorMessage } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import { connect } from 'react-redux';
import Loader from '../common/Loader';
import AddRemovalSuccess from './AddRemovalSuccess';
import { Button } from 'reactstrap';
import {
    requestAccountRemoval,
    resetRequestAccountRemoval
} from '../../store/actions/Removal';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const AddRemovalForm = ({
    loading,
    error,
    email,
    success,
    requestAccountRemoval,
    resetRequestAccountRemoval
}) => {
    const submit = values => {
        requestAccountRemoval({ email: values.email });
    };

    const initialValues = {
        email: email || ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required)
    });

    if (loading) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (success || error) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <AddRemovalSuccess error={error} resetForm={resetRequestAccountRemoval} />
            </div>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                    validateOnChange
                    enableReinitialize
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-75 mx-md-auto border form-round bg-white form-shadow p-2">
                            <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                                <img
                                    src="../assets/sage-icon.png"
                                    alt="logo"
                                    width={80}
                                />
                                <div className="d-flex w-md-75 flex-column mb-3 align-items-center justify-content-center">
                                    <div
                                        style={{ fontSize: '32px' }}
                                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                                    >
                                        Exit from SAGE
                                    </div>
                                    <div
                                        style={{ fontSize: '16px' }}
                                        className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal mb-4"
                                    >
                                        <span>
                                            To request account removal, please enter the
                                            email address thats associated with your Sage
                                            Account to be removed. Once you have submitted
                                            the form, your community management team will
                                            begin to verify your request and promptly
                                            remove your account. Thank you for using Sage!
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group mx-4 mx-lg-7">
                                <div className="row">
                                    <div className="js-form-message col-md-12 col-lg-6 form-group d-flex justify-content-center align-items-center">
                                        <LabeledFormField
                                            fieldTitle="email"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder="Email Address"
                                            aria-label="email"
                                            error={returnError(errors, 'email')}
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                            invalid={
                                                returnTouched(touched, 'email') &&
                                                !!returnError(errors, 'email')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex row justify-content-end align-items-center border-top my-5 mx-2">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="transition-3d-hover mt-5 mr-2 btn"
                                    onClick={handleSubmit}
                                >
                                    Submit Request
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({ removal }) => {
    const { loading, error, email, success } = removal;
    return {
        loading,
        error,
        email,
        success
    };
};

export default connect(mapStateToProps, {
    requestAccountRemoval,
    resetRequestAccountRemoval
})(AddRemovalForm);
