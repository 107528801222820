import React from 'react';

import { Container, Col, InputGroup, Input, InputGroupText } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import ConfirmFooter from '../common/ConfirmFooter';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { addReservation } from '../../store/actions/Reservations';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loader from '../common/Loader';
import AddReservationSuccess from './AddReservationSuccess';
import { tsFromJsDate } from '../../utils/Helpers';
import { timeStampNow } from '../../config/Firebase';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const renterLink = 'https://sagesystems.io/confirm-reservation';

const AddReservationForm = ({ adding, addingSuccess, addingError, locationProps }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { org_id, org_name, userId, firstName, lastName } = locationProps
        ? locationProps.state
        : location.state;
    const [copied, setCopied] = useState(false);

    const submit = values => {
        const phone = values.phone?.number
            ? {
                  number: Number.parseInt(values.phone.number),
                  code: Number.parseInt(values.phone.code),
                  country: values.phone.country
              }
            : null;

        const reservation = {
            access_begins: tsFromJsDate(values.check_in_date[0]),
            access_created: null,
            access_days: null,
            access_end_time: null,
            access_expires: tsFromJsDate(values.check_out_date[0]),
            access_start_time: null,
            active: true,
            address: {
                address_1: values.address_1 || null,
                address_2: values.address_2 || null,
                city: null,
                latitude: null,
                longitude: null,
                state: null,
                zip: null
            },
            check_in_date: values.check_in_date[0].toDateString(),
            check_in_time: values.check_in_time.length
                ? {
                      hours: values.check_in_time[0].getHours(),
                      minutes: values.check_in_time[0].getMinutes()
                  }
                : { hours: 0, minutes: 0 },
            check_out_date: values.check_out_date[0].toDateString(),
            check_out_time: values.check_out_time.length
                ? {
                      hours: values.check_out_time[0].getHours(),
                      minutes: values.check_out_time[0].getMinutes()
                  }
                : { hours: 23, minutes: 59 },
            company_name: null,
            confirmation: null,
            consumer_id: null,
            created_at: timeStampNow(),
            key_id: null,
            image: null,
            invite_id: null,
            invite_status: null,
            invited_at: null,
            creator_first_name: firstName,
            creator_id: userId,
            creator_last_name: lastName,
            email: values.email || null,
            event_id: null,
            favorite: false,
            first_name: values.first_name,
            invite_code: null,
            last_name: values.last_name,
            org_id,
            org_name,
            phone,
            phone_number: values.phone?.number
                ? `${values.phone.code}${values.phone.number}`
                : null,
            role: 'tenant',
            suspended: false,
            validated: false
        };
        dispatch(addReservation({ data: [reservation], guard: false }));
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        check_in_date: Yup.string().trim().required(errorMessage.fromDate.required),
        check_out_date: Yup.string().trim().required(errorMessage.toDate.required),
        street_address: Yup.string().trim(),
        unit: Yup.string().trim(),
        email: Yup.string().trim().lowercase().email(errorMessage.email.valid),
        phone: Yup.object().shape({
            country: Yup.string().trim(),
            number: Yup.string().trim(),
            code: Yup.string().trim()
        })
    });

    const validatePhone = ({ phone: { code, number, country } }) => {
        const phoneSchema = Yup.string().phone(country);
        const isValid = number ? phoneSchema.isValidSync(code + number) : true;
        return isValid ? undefined : { phone: { number: 'invalid number' } };
    };

    const getNextDay = date => date.fp_incr(1);

    if (adding) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <Loader />
            </div>
        );
    }

    if (addingSuccess) {
        return (
            <div className="d-flex flex-grow-1 w-100 h-100 align-items-center justify-content-center">
                <AddReservationSuccess />
            </div>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        check_in_date: '',
                        check_in_time: '',
                        check_out_date: '',
                        check_out_time: '',
                        address_1: '',
                        address_2: '',
                        email: '',
                        phone: {
                            number: '',
                            code: '1',
                            country: 'US'
                        }
                    }}
                    onSubmit={submit}
                    validate={validatePhone}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-70 mx-md-auto">
                            <div className="mb-4">
                                <h2 className="h5 font-weight-normal mb-0">
                                    <span className="d-flex justify-content-start h3 font-weight-semi-bold">
                                        New Reservation
                                    </span>
                                </h2>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="first name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            id="first_name"
                                            // placeholder="First Name"
                                            aria-label="first_name"
                                            error={returnError(errors, 'first_name')}
                                            value={values.first_name}
                                            onBlur={handleBlur('first_name')}
                                            onChange={handleChange('first_name')}
                                            invalid={
                                                returnTouched(touched, 'first_name') &&
                                                !!returnError(errors, 'first_name')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="last name"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="last_name"
                                            // placeholder="Last Name"
                                            aria-label="last_name"
                                            error={returnError(errors, 'last_name')}
                                            value={values.last_name}
                                            onBlur={handleBlur('last_name')}
                                            onChange={handleChange('last_name')}
                                            invalid={
                                                returnTouched(touched, 'last_name') &&
                                                !!returnError(errors, 'last_name')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-in date"
                                            required={true}
                                            type="text"
                                            className="form-control"
                                            name="check_in_date"
                                            id="check_in_date"
                                            placeholder="Select..."
                                            aria-label="check_in_date"
                                            error={returnError(errors, 'check_in_date')}
                                            value={values.check_in_date}
                                            selected={values.check_in_date}
                                            onChange={date =>
                                                setFieldValue('check_in_date', date)
                                            }
                                            invalid={
                                                returnTouched(touched, 'check_in_date') &&
                                                !!returnError(errors, 'check_in_date')
                                            }
                                            datePickerField={true}
                                            withDate={true}
                                            withTime={false}
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-in time"
                                            required={false}
                                            type="text"
                                            className="form-control"
                                            name="check_in_time"
                                            id="check_in_time"
                                            placeholder="Select..."
                                            aria-label="check_in_time"
                                            error={returnError(errors, 'check_in_time')}
                                            value={values.check_in_time}
                                            selected={values.check_in_time}
                                            onChange={date =>
                                                setFieldValue('check_in_time', date)
                                            }
                                            invalid={
                                                returnTouched(touched, 'check_in_time') &&
                                                !!returnError(errors, 'check_in_time')
                                            }
                                            datePickerField={true}
                                            withDate={false}
                                            withTime={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-out date"
                                            required={true}
                                            type="text"
                                            className="form-control"
                                            name="check_out_date"
                                            id="check_out_date"
                                            placeholder="Select..."
                                            aria-label="check_out_date"
                                            error={returnError(errors, 'check_out_date')}
                                            value={
                                                values.check_out_date ||
                                                (values.check_in_date &&
                                                    setFieldValue('check_out_date', [
                                                        getNextDay(
                                                            values.check_in_date[0]
                                                        )
                                                    ]))
                                            }
                                            selected={
                                                values.check_out_date ||
                                                (values.check_in_date &&
                                                    setFieldValue('check_out_date', [
                                                        getNextDay(
                                                            values.check_in_date[0]
                                                        )
                                                    ]))
                                            }
                                            onChange={date =>
                                                setFieldValue('check_out_date', date)
                                            }
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'check_out_date'
                                                ) &&
                                                !!returnError(errors, 'check_out_date')
                                            }
                                            datePickerField={true}
                                            datePickerOptions={{
                                                minDate:
                                                    values.check_in_date &&
                                                    getNextDay(values.check_in_date[0]),
                                                defaultDate:
                                                    values.check_in_date &&
                                                    getNextDay(values.check_in_date[0])
                                            }}
                                            withDate={true}
                                            withTime={false}
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="check-out time"
                                            required={false}
                                            type="text"
                                            className="form-control"
                                            name="check_out_time"
                                            id="check_out_time"
                                            placeholder="Select..."
                                            aria-label="check_out_time"
                                            error={returnError(errors, 'check_out_time')}
                                            value={values.check_out_time}
                                            selected={values.check_out_time}
                                            onChange={date =>
                                                setFieldValue('check_out_time', date)
                                            }
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'check_out_time'
                                                ) &&
                                                !!returnError(errors, 'check_out_time')
                                            }
                                            datePickerField={true}
                                            withDate={false}
                                            withTime={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 3 }}
                                        className="js-form-message col-8 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="Street address"
                                            type="text"
                                            className="form-control"
                                            name="address_1"
                                            id="address_1"
                                            // placeholder="Street address"
                                            aria-label="street_address"
                                            error={returnError(errors, 'address_1')}
                                            value={values.address_1}
                                            onBlur={handleBlur('address_1')}
                                            onChange={handleChange('address_1')}
                                            invalid={
                                                returnTouched(touched, 'address_1') &&
                                                !!returnError(errors, 'address_1')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-4 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="Unit"
                                            type="text"
                                            className="form-control"
                                            name="address_2"
                                            id="address_2"
                                            // placeholder="Unit"
                                            aria-label="unit"
                                            error={returnError(errors, 'address_2')}
                                            value={values.address_2}
                                            onBlur={handleBlur('address_2')}
                                            onChange={handleChange('address_2')}
                                            invalid={
                                                returnTouched(touched, 'address_2') &&
                                                !!returnError(errors, 'address_2')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <ContryPhoneCodeSelect
                                            fieldTitle="Mobile phone"
                                            type="text"
                                            className="form-control"
                                            name="phone.number"
                                            id="phone.number"
                                            aria-label="phone.number"
                                            phoneError={returnError(
                                                errors,
                                                'phone.number'
                                            )}
                                            codeValue={values?.phone?.code}
                                            phoneValue={values?.phone?.number}
                                            countryValue={values?.phone?.country}
                                            onBlurPhone={handleBlur('phone.number')}
                                            onChangePhone={e => {
                                                handleChange('phone.number')(e);
                                            }}
                                            invalid={
                                                touched?.phone?.number &&
                                                !!errors?.phone?.number
                                            }
                                            onBlurCode={handleBlur('phone.code')}
                                            onChangeCode={value =>
                                                setFieldValue('phone.code', value)
                                            }
                                            onChangeCountry={value =>
                                                setFieldValue('phone.country', value)
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="email"
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            // placeholder="Email"
                                            aria-label="email"
                                            error={returnError(errors, 'email')}
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                            invalid={
                                                returnTouched(touched, 'email') &&
                                                !!returnError(errors, 'email')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 3 }}
                                        className="js-form-message d-flex mx-2 form-group"
                                    >
                                        <InputGroup>
                                            <Input
                                                placeholder="reservation link"
                                                value={renterLink}
                                                onChange={() => {}}
                                                disabled
                                            />
                                            <InputGroupText
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        renterLink
                                                    );
                                                    setCopied(true);
                                                }}
                                                className={
                                                    copied
                                                        ? 'copy-link-btn-copied'
                                                        : 'copy-link-btn'
                                                }
                                            >
                                                <i
                                                    className={`fa-regular ${
                                                        copied ? 'fa-check' : 'fa-copy'
                                                    } mr-2`}
                                                />
                                                {copied ? 'copied' : 'copy'}
                                            </InputGroupText>
                                        </InputGroup>
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message d-flex form-group"
                                    />
                                </div>
                            </div>
                            <div className="d-flex row justify-content-center align-items-center my-5">
                                <ConfirmFooter
                                    cancel={history.goBack}
                                    submitFunc={handleSubmit}
                                    submitText="Save"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({
    reservations: { addingReservation, addingReservationSuccess, addingReservationError }
}) => {
    return {
        adding: addingReservation,
        addingSuccess: addingReservationSuccess,
        addingError: addingReservationError
    };
};

export default connect(mapStateToProps)(AddReservationForm);
