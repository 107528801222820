// React
import React, { useState } from 'react';

// Components
import { Loader } from '../components';

// Packages
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import RentalHeader from '../components/RentalManagerDashboard/RentalHeader';
import ReservationsList from '../components/RentalManagerDashboard/ReservationsList';
import ReservationFilterInput from '../components/RentalManagerDashboard/ReservationFilterInput';

const RentalDashboard = ({
    primaryOrg,
    reservations,
    userData,
    locationProps,
    match
}) => {
    const location = useLocation();
    const routerStateProps = locationProps ? locationProps.state : location.state;
    const [searchString, setSearchString] = useState('');
    const [sortType, setSortType] = useState({
        key: 'Active',
        component: <span>Active</span>,
        value: 'active'
    });

    if (!reservations)
        return (
            <div className="d-flex flex-column position-relative flex-auto">
                <div className="main w-100">
                    <Loader />
                </div>
            </div>
        );

    return (
        <div className="d-flex main flex-column position-relative flex-auto">
            <RentalHeader
                isSuperAdmin={userData?.type === 'super_admin'}
                {...primaryOrg}
                {...routerStateProps}
                match={match}
            />
            <ReservationFilterInput
                handleChange={setSearchString}
                changeSortType={setSortType}
                activeType={sortType}
            />
            <ReservationsList
                reservations={reservations}
                searchString={searchString}
                sortType={sortType.value}
                match={match}
            />
        </div>
    );
};

const mapStateToProps = ({ reservations, user }) => {
    return {
        reservations: reservations.reservations,
        userData: user.userData
    };
};

export default withRouter(connect(mapStateToProps, {})(RentalDashboard));
