// React
import React, { useState, useEffect } from 'react';

// Components
import { WidgetContainer, OrgsList, OrgsSearchFilter, Loader } from '../components';

// Packages
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Redux- Actions, Reducers, Sagas
import { settingPrimaryOrg } from '../store/actions/Org';

// Router
import * as routes from '../router/config/routes';

const SuperDashboard = ({ userData, orgs, settingPrimaryOrg }) => {
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        settingPrimaryOrg(null);
    });

    if (!userData && !orgs && !orgs.length)
        return (
            <div className="d-flex flex-column position-relative flex-auto">
                <div className="main">
                    <Loader />
                </div>
            </div>
        );

    return (
        <div className="d-flex flex-column position-relative flex-auto">
            <div className="main">
                <div className="mb-4 d-flex justify-content-end">
                    <Link
                        className="transition-3d-hover btn btn-sm btn-primary"
                        to={`${routes.SUPER_AUTHENTICATED}${routes.SUPER_ADMIN}${routes.ADD_NEW_ORG}`}
                    >
                        <i className="fa fa-plus mr-1" /> Organization
                    </Link>
                </div>
                <WidgetContainer />
                <OrgsSearchFilter handleChange={setSearchString} />
                <OrgsList searchString={searchString} />
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, org }) => {
    return {
        userData: user.userData,
        orgs: org.orgs
    };
};

export default connect(mapStateToProps, { settingPrimaryOrg })(SuperDashboard);
