// React
import React, { useEffect } from 'react';

// Components
import { TextField } from '../components';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Label, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Redux - Actions, Reducers, Sagas
import { loginUserEmailPassword, clearAuthError } from '../store/actions/Auth';

// Router
import * as routes from '../router/config/routes';

// Utils
// import { errorMessage, passwordRegExp } from '../utils/Constants';
import { errorMessage } from '../utils/Constants';

const Login = ({ loginUserEmailPassword, clearAuthError, error, loading }) => {
    useEffect(() => {
        return () => {
            clearAuthError();
        };
    }, [clearAuthError]);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        password: Yup.string()
            // .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
            // .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
            // .matches(passwordRegExp.numeric, errorMessage.password.numeric)
            // .min(8, errorMessage.password.length)
            .required(errorMessage.password.required)
    });
    return (
        <div className="space-2 vh-100 bg-dark">
            <Col>
                <div className="d-flex justify-content-center">
                    <Link
                        className="d-flex w-75 w-md-40 w-lg-30 align-items-center justify-content-center position-relative mt-11 mb-8"
                        to="/"
                        aria-label="SAGE"
                    >
                        <img
                            src="assets/sage.png"
                            className="logo"
                            style={{ width: '100%', maxWidth: '320px' }}
                            alt="logo"
                        />
                    </Link>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={({ email, password }) => {
                        loginUserEmailPassword({ email, password });
                        clearAuthError();
                    }}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                    }) => (
                        <Form className="w-80 w-lg-40 mx-auto">
                            <div className="mb-3">
                                <h2 className="h3 text-primary font-weight-normal mb-0">
                                    <span className="font-weight-semi-bold">
                                        Welcome{' '}
                                    </span>
                                    Back
                                </h2>
                                {error && (
                                    <span
                                        style={{
                                            width: '100%',
                                            marginTop: '0.25rem',
                                            fontSize: '80%',
                                            color: '#de4437'
                                        }}
                                    >
                                        {error.message}
                                    </span>
                                )}
                            </div>
                            <div className="js-form-message form-group mb-5">
                                <Label className="form-label text-white" for="email">
                                    Email address
                                </Label>
                                <TextField
                                    required
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    placeholder="Email address"
                                    aria-label="Email address"
                                    error={errors.email}
                                    value={values.email}
                                    onBlur={handleBlur('email')}
                                    onChange={handleChange('email')}
                                    invalid={touched.email && !!errors.email}
                                />
                            </div>
                            <div className="js-form-message form-group mb-5">
                                <Label className="form-label text-white" for="password">
                                    <span className="d-flex justify-content-between align-items-center">
                                        Password
                                        <Link
                                            className="link-muted text-capitalize font-weight-normal"
                                            to={`${routes.RESET_PASSWORD}`}
                                        >
                                            Forgot Password?
                                        </Link>
                                    </span>
                                </Label>
                                <TextField
                                    required
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    id="password"
                                    placeholder="********"
                                    aria-label="********"
                                    error={errors.password}
                                    value={values.password}
                                    onBlur={handleBlur('password')}
                                    onChange={handleChange('password')}
                                    invalid={touched.password && !!errors.password}
                                />
                            </div>
                            <div className="row align-items-center mb-5">
                                <div className="d-none d-md-block col-md-6">
                                    <span className="small text-muted">
                                        Don&apos;t have an account?
                                    </span>
                                    <Link className="small" to="/">
                                        {' '}
                                        Learn more
                                    </Link>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="transition-3d-hover btn-block"
                                        onClick={handleSubmit}
                                    >
                                        Login
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Col>
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    const { error, loading } = auth;
    return { error, loading };
};

export default withRouter(
    connect(mapStateToProps, { loginUserEmailPassword, clearAuthError })(Login)
);
