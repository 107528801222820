import React, { useEffect } from 'react';

import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetReservationImportState } from '../../store/actions/Reservations';

const ReservationImportReport = ({ report }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const generateReport = reportData => {
        if (!reportData) return null;
        const report = Papa.unparse(reportData);
        return report;
    };

    const downloadReport = () => {
        const file = new Blob([generateReport(report)], {
            type: 'text/csv;charset=utf-8;'
        });

        saveAs(file, 'import report.csv');
    };

    useEffect(() => {
        return () => dispatch(resetReservationImportState());
    }, [dispatch]);

    return (
        <div className="container">
            <div className="row pt-8">
                <div className="col-6 m-auto text-center">
                    <h3 className="text-green font-weight-semi-bold">
                        Data imported successfully!
                    </h3>
                </div>
            </div>
            {Boolean(report.length) && (
                <>
                    <div className="row pt-3">
                        <div className="col-6 m-auto text-center">
                            <p>
                                Some data could not be imported due to missing fields.
                                Please address the issues and import again.
                            </p>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-8 m-auto text-center p-2">
                            <table className="table">
                                <tbody>
                                    {report.map(({ name, errors }) => {
                                        return (
                                            <tr key={name}>
                                                <td>{name}</td>
                                                {errors.map(err => {
                                                    return (
                                                        <td
                                                            className="text-red"
                                                            key={err}
                                                        >
                                                            {err}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
            {report.length ? (
                <div className="row pt-10">
                    <div className="col-12 m-auto d-flex justify-content-center">
                        <Button
                            type="button"
                            color="primary"
                            className="btn btn-sm m-2 transition-3d-hover"
                            onClick={() => history.goBack()}
                        >
                            Back to rental dashboard
                        </Button>
                        <Button
                            type="button"
                            color="primary"
                            className="btn btn-sm m-2 transition-3d-hover"
                            onClick={downloadReport}
                        >
                            Download Report
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="row pt-10">
                    <div className="col-12 m-auto d-flex justify-content-center">
                        <Button
                            type="button"
                            color="primary"
                            className="btn btn-sm transition-3d-hover"
                            onClick={() => history.goBack()}
                        >
                            Back to rental dashboard
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReservationImportReport;
