import { ADD_ACCESS_KEYS_DOCUMENT, GET_ACCESS_KEYS_DOCUMENT } from '../actions/types';

import { all, fork, takeLatest, put } from 'redux-saga/effects';

import { db, timeStampNow, toFirebaseTimestamp } from '../../config/Firebase';
import {
    addAccesKeysDocFailure,
    addAccesKeysDocSuccess,
    getAccesKeysDocFailure,
    getAccesKeysDocSuccess
} from '../actions/AccessKeys';

const accessKeys = db.collection('access_keys');

export function* getAccessKeysById({ payload }) {
    try {
        const accessKeyRef = accessKeys.doc(`${payload}`);
        const doc = yield accessKeyRef.get();
        const key = doc.data();
        yield put(getAccesKeysDocSuccess(key));
    } catch (err) {
        console.error(err);
        yield put(getAccesKeysDocFailure(err));
    }
}

export function* deleteAccessKey({ payload }) {
    try {
        const accessKeyRef = accessKeys.doc(`${payload}`);
        const isDeleted = yield accessKeyRef
            .delete()
            .then(() => true)
            .catch(err => {
                console.log('Access key deletion error:', err);
                return false;
            });

        if (isDeleted) {
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
    }
}

export function* addAccessKey({ payload }) {
    try {
        const {
            access_days,
            access_end_time,
            access_begins,
            access_expires,
            access_start_time,
            address,
            company_name,
            consumer_id,
            creator_first_name,
            creator_id,
            creator_last_name,
            email,
            favorite,
            first_name,
            image,
            last_name,
            org_id,
            phone,
            role,
            suspended
        } = payload;

        const accessKeyDoc = accessKeys.doc();
        const newAccessKey = {
            access_created: timeStampNow(),
            access_days,
            access_end_time,
            access_expires: toFirebaseTimestamp(access_expires),
            access_begins: toFirebaseTimestamp(access_begins),
            access_start_time,
            address,
            company_name,
            consumer_id,
            creator_first_name,
            creator_id,
            creator_last_name,
            email,
            favorite,
            first_name,
            image,
            key_id: accessKeyDoc.id,
            last_name,
            org_id,
            phone,
            plates: [],
            role,
            suspended,
            vehicles: []
        };
        const isCreated = yield accessKeyDoc
            .set(newAccessKey)
            .then(() => true)
            .catch(error => {
                console.error(`Error creating access key document: ${error}`);
                return false;
            });

        if (isCreated) {
            yield put(addAccesKeysDocSuccess());
            return newAccessKey;
        }

        yield put(addAccesKeysDocFailure('Access key creation error.'));
        return false;
    } catch (error) {
        console.log(error);
        yield put(addAccesKeysDocFailure(`${error}`));
    }
}

export function suspendAccess(accessKey) {
    try {
        const accessKeyRef = accessKeys.doc(`${accessKey}`);

        accessKeyRef.update({ suspended: true });
    } catch (error) {
        console.log(error);
    }
}

////////////////////////

export function* createAccess() {
    yield takeLatest(ADD_ACCESS_KEYS_DOCUMENT, addAccessKey);
}

export function* getAccessKey() {
    yield takeLatest(GET_ACCESS_KEYS_DOCUMENT, getAccessKeysById);
}

export default function* rootSaga() {
    yield all([fork(getAccessKey), fork(createAccess)]);
}
