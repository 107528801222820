import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import orgSagas from './Org';
import panelSagas from './Panels';
import userSagas from './User';
import managerSagas from './Managers';
import accessKeysSaga from './AccessKeys';
import postsSaga from './Posts';
import invitesSaga from './Invites';
import reservationsSaga from './Reservations';
import confirmReservationSaga from './ConfirmReservation';
import callsSaga from './Calls';
import membersSagas from './Members';
import superAdminSagas from './SuperAdmin';
import registrationSagas from './Registration';
import guestSaga from './Guests';
import otcSagas from './Otc';
import removalSagas from './Removal';
import intercomSagas from './Intercom';

export default function* rootSaga() {
    yield all([
        authSagas(),
        userSagas(),
        orgSagas(),
        panelSagas(),
        managerSagas(),
        accessKeysSaga(),
        postsSaga(),
        invitesSaga(),
        reservationsSaga(),
        confirmReservationSaga(),
        callsSaga(),
        registrationSagas(),
        guestSaga(),
        membersSagas(),
        superAdminSagas(),
        otcSagas(),
        removalSagas(),
        intercomSagas()
    ]);
}
