// React
import React, { useState, useCallback } from 'react';

// Components
import { Room } from '../components';

// Hooks
import { useMountEffect } from '../hooks/useMountEffect';

// Packages
import Video from 'twilio-video';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Router
import * as routes from '../router/config/routes';

const PanelVideo = ({ call, userData, activeOrg, active }) => {
    const [room, setRoom] = useState(null);
    const token = userData?.twilio?.token || null;
    const orgName = activeOrg?.org_name || '';
    const history = useHistory();

    const connectCall = useCallback(() => {
        if (call) {
            if (token) {
                Video.connect(token, {
                    name: call.panel_id
                })
                    .then(room => {
                        setRoom(room);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        } else {
            history.replace(`${routes.AUTHENTICATED}${routes.DASHBOARD}`, {});
        }
    }, [token, call, history]);

    useMountEffect(connectCall);

    if (!room) return <div>Connecting...</div>;
    return (
        <Room
            panelName={call?.panel_name}
            room={room}
            setRoom={setRoom}
            call={call}
            userData={userData}
            orgName={orgName}
            active={active}
        />
    );
};

const mapStateToProps = ({ calls, user }) => {
    const { userData, activeOrg } = user;
    const { call } = calls;
    return { userData, activeOrg, call };
};

export default connect(mapStateToProps, {})(PanelVideo);
