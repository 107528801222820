// React
import React from 'react';

// Components
import { Loader, ToolBar } from '../components';

// Packages
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

// Router
import * as routes from '../router/config/routes';
import AddReservation from './AddReservation';
import RentalDashboard from './RentalDashboard';
import ReservationDetails from '../components/RentalManagerDashboard/ReservationDetails';
import AddReservationFromCSV from './AddReservationFromCSV';

const RentalManager = ({ userData, match }) => {
    if (!userData) return <Loader />;
    return (
        <div style={{ height: '100vh' }}>
            <div
                className="d-flex flex-column w-100 h-100 flex-auto"
                style={{ minWidth: '0' }}
            >
                <ToolBar />
                <div className="d-flex justify-content-center m-4 h-100">
                    <Switch>
                        <Redirect
                            exact
                            from={`${match.url}`}
                            to={`${match.url}${routes.RENTAL_DASHBOARD}`}
                        />
                        <Route
                            path={`${match.url}${routes.RENTAL_DASHBOARD}`}
                            render={props => (
                                <RentalDashboard
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.GUEST_RESERVATION}`}
                            render={props => (
                                <AddReservation
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.GUEST_RESERVATION_BULK}`}
                            render={props => (
                                <AddReservationFromCSV
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}${routes.RESERVATION_DETAILS_EDIT}`}
                            render={props => (
                                <ReservationDetails
                                    {...props}
                                    primaryOrg={userData.primaryOrg}
                                />
                            )}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default withRouter(connect(mapStateToProps, {})(RentalManager));
