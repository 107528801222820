import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWindowSize } from '../../hooks/useWindowSize';

const OrgSelectorList = ({ orgs, selectOrg }) => {
    const { windowHeight } = useWindowSize();
    return (
        <div style={{ height: [windowHeight] - 453, overflowY: 'auto' }}>
            <InfiniteScroll dataLength={orgs.length} loader={<h4>Loading...</h4>}>
                {orgs.map(org => {
                    const { org_id, devices, status, org_name } = org;
                    return (
                        <div
                            className="org-selector-item m-2"
                            onClick={() => selectOrg(org)}
                            key={org_id}
                        >
                            <div className="org-item justify-content-between align-items-center">
                                <div className="col-5">
                                    <h2 className="h6 font-weight-semi-bold mb-0">
                                        {org_name}
                                    </h2>
                                    {status === 'Active' ? (
                                        <small className="d-block text-muted">
                                            Access Panels: {devices && devices.length}
                                        </small>
                                    ) : (
                                        <small className="d-block text-danger">
                                            Access Panels: {devices && devices.length}
                                        </small>
                                    )}
                                </div>
                                <div className="col-2">
                                    {status === 'Active' ? (
                                        <span className="badge badge-success badge-status">
                                            Online
                                        </span>
                                    ) : (
                                        <span className="badge badge-danger badge-status">
                                            Offline
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default OrgSelectorList;
