import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import {
    ADD_NEW_MANAGER,
    ADD_NEW_RENTAL_MANAGER,
    // ADD_NEW_RESIDENT,
    // ADD_NEW_VENDOR,
    AUTHENTICATED,
    IMPORT_MEMBERS_CSV,
    RENTAL_DASHBOARD,
    SUPER_ADMIN,
    SUPER_AUTHENTICATED
} from '../../router/config/routes';
import DropdownButton from '../common/DropdownButton';

const OrgHeader = ({
    allowImport,
    org_id,
    org_name,
    userId,
    isSuperAdmin,
    rentalManagerEnabled,
    invite_code,
    firstName,
    lastName,
    creatingPanelIntercom
}) => {
    const menuOptions = [
        {
            key: 'Manager',
            component: (
                <Link
                    to={{
                        pathname: isSuperAdmin
                            ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${ADD_NEW_MANAGER}`
                            : `${AUTHENTICATED}${ADD_NEW_MANAGER}`,
                        state: {
                            orgId: org_id,
                            inviteCode: invite_code,
                            orgName: org_name
                        }
                    }}
                >
                    Manager
                </Link>
            )
        }
        // {
        //     key: 'Resident',
        //     component: (
        //         <Link
        //             to={
        //                 isSuperAdmin
        //                     ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${ADD_NEW_RESIDENT}`
        //                     : `${AUTHENTICATED}${ADD_NEW_RESIDENT}`
        //             }
        //         >
        //             Resident
        //         </Link>
        //     )
        // },
        // {
        //     key: 'Vendor',
        //     component: (
        //         <Link
        //             to={
        //                 isSuperAdmin
        //                     ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${ADD_NEW_VENDOR}`
        //                     : `${AUTHENTICATED}${ADD_NEW_VENDOR}`
        //             }
        //         >
        //             Vendor
        //         </Link>
        //     )
        // }
    ];

    if (rentalManagerEnabled) {
        menuOptions.push({
            key: 'Rental manager',
            component: (
                <Link
                    to={{
                        pathname: isSuperAdmin
                            ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${ADD_NEW_RENTAL_MANAGER}`
                            : `${AUTHENTICATED}${ADD_NEW_RENTAL_MANAGER}`,
                        state: {
                            orgId: org_id,
                            inviteCode: invite_code,
                            orgName: org_name
                        }
                    }}
                >
                    Rental manager
                </Link>
            )
        });
    }

    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex flex-column">
                <h4 className="mb-0">{org_name}</h4>
                <p className="font-size-1 text-muted mb-0 visibility-hidden">{org_id}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                {rentalManagerEnabled && (
                    <Link
                        to={{
                            pathname: isSuperAdmin
                                ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${RENTAL_DASHBOARD}`
                                : `${AUTHENTICATED}${RENTAL_DASHBOARD}`,
                            state: {
                                isSuperAdmin,
                                org_id,
                                org_name,
                                userId,
                                firstName,
                                lastName
                            }
                        }}
                    >
                        <Button
                            color="primary"
                            type="button"
                            className="transition-3d-hover btn-sm btn-primary mr-4"
                        >
                            <i className="fa-solid fa-house-night mr-1" /> Rental Manager
                        </Button>
                    </Link>
                )}
                {allowImport && (
                    <Link
                        to={{
                            pathname: isSuperAdmin
                                ? `${SUPER_AUTHENTICATED}${SUPER_ADMIN}${IMPORT_MEMBERS_CSV}`
                                : `${AUTHENTICATED}${IMPORT_MEMBERS_CSV}`,
                            state: { orgId: org_id }
                        }}
                    >
                        <Button
                            color="primary"
                            type="button"
                            className="transition-3d-hover btn-sm btn-primary mr-4"
                        >
                            <i className="fa fa-file-import mr-1" /> Import Data
                        </Button>
                    </Link>
                )}
                <DropdownButton
                    className="m-4"
                    menuOptionComponents={menuOptions}
                    toggleComponent={
                        <>
                            <i className="fa fa-plus mr-1" /> New Access
                        </>
                    }
                />
                <Button
                    color="primary"
                    type="button"
                    className="transition-3d-hover btn-sm btn-primary ml-4"
                    onClick={creatingPanelIntercom}
                >
                    <i className="fa-solid fa-phone-volume mr-1" /> Intercom
                </Button>
            </div>
        </div>
    );
};

export default OrgHeader;
