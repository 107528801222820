import React, { useState } from 'react';
import ContactsListSearch from './ContactsListSearch';

import CommunityMembersList from './CommunityMembersList';
import ContactModal from './ContactModal';
import Loader from '../common/Loader';

const Members = ({ selectedTab, orgId, communityMembers }) => {
    const [searchString, setSearchsString] = useState('');
    const [open, setOpen] = useState(false);
    const [modalProps, setModalProps] = useState(null);
    const [selectList, setSelectList] = useState([]);
    const [selectAllFlag, setSelectAllFlag] = useState(false);

    const toggle = () => {
        if (open) {
            setOpen(false);
            setModalProps(null);
        }
        if (!open) setOpen(true);
    };

    const modalOpen = props => {
        setModalProps(props);
        toggle();
    };

    const selectAll = () => {
        if (selectAllFlag) {
            setSelectList([]);
            setSelectAllFlag(false);
            return;
        }
        setSelectList(communityMembers.map(member => member.uid));
        setSelectAllFlag(true);
    };

    const addToSelectList = selected => {
        if (selected === null) {
            setSelectList([]);
            return;
        }
        const alreadyAdded = selectList.includes(selected);
        if (alreadyAdded) {
            setSelectList(selectList.filter(el => el !== selected));
        } else setSelectList([...selectList, selected]);
    };

    if (communityMembers) {
        return (
            <div>
                <div className="my-4 w-100 d-flex justify-content-between">
                    <div className="d-flex justify-content-start w-30">
                        <ContactsListSearch
                            className="contacts-list-search"
                            handleChange={value => {
                                setSearchsString(value);
                            }}
                            listLabel={selectedTab}
                        />
                    </div>
                    <div
                        className={
                            selectAllFlag ? 'invite-all badge-green' : 'invite-all'
                        }
                        onClick={selectAll}
                    >
                        <i className="fa fa-check-circle mr-2" aria-hidden="true" />
                        Select All
                    </div>
                </div>
                <CommunityMembersList
                    modalOpen={modalOpen}
                    orgId={orgId}
                    selectHandler={addToSelectList}
                    searchString={searchString}
                    selected={selectList}
                    communityMembers={communityMembers.filter(
                        member => member.role === 'member'
                    )}
                />
                <ContactModal
                    isOpen={open}
                    orgId={orgId}
                    toggleOpen={toggle}
                    modalProps={modalProps}
                    withAccess={modalProps && modalProps?.access_key}
                    type={'member'}
                />
            </div>
        );
    }

    return <Loader />;
};

export default Members;
