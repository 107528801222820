// React
import React from 'react';

// Components
import { NewManagerForm } from '../components';

// Packages
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

// Router
import {
    AUTHENTICATED,
    DASHBOARD,
    RENTAL_DASHBOARD,
    RENTAL_MANAGER
} from '../router/config/routes';

const AddManager = ({ isSuperAdmin, userData }) => {
    const location = useLocation();

    if (!userData) return null;

    if (userData && userData.type === 'rental_manager') {
        return <Redirect to={`${RENTAL_MANAGER}${RENTAL_DASHBOARD}`} />;
    }

    if (location.state) {
        const { orgId, inviteCode, orgName } = location.state;

        return (
            <NewManagerForm
                isSuperAdmin={isSuperAdmin}
                orgId={orgId}
                orgName={orgName}
                inviteCode={inviteCode}
            />
        );
    }

    return <Redirect to={`${AUTHENTICATED}${DASHBOARD}`} />;
};

const mapStateToProps = ({ user }) => {
    return {
        userData: user.userData
    };
};

export default connect(mapStateToProps)(AddManager);
