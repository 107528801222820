import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetReservationImportState } from '../../store/actions/Reservations';

const ReservationImportError = ({ error }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetReservationImportState());
    });

    return (
        <div className="container">
            <div className="row pt-8">
                <div className="col-6 m-auto text-center">
                    <h3 className="text-red font-weight-semi-bold">
                        Data import failed!
                    </h3>
                </div>
            </div>
            {error && (
                <div className="row pt-3">
                    <div className="col-6 m-auto text-center">
                        <p>{error}</p>
                    </div>
                </div>
            )}
            <div className="row pt-10">
                <div className="col-12 m-auto d-flex justify-content-center">
                    <Button
                        type="button"
                        color="primary"
                        className="btn btn-sm transition-3d-hover"
                        onClick={() => history.goBack()}
                    >
                        Back to rental dashboard
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ReservationImportError;
