import React from 'react';

import { Button } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    errorMessage,
    nameRegExp,
    passwordRegExp,
    phoneExtRegExp,
    phoneRegExp
} from '../../utils/Constants';
import LabeledFormField from './LabeledFormField';
import Loader from '../common/Loader';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const FeedbackLine = ({ flag, message }) => (
    <div className={`mb-0 text-note ${flag ? 'text-active-green' : 'text-muted'}`}>
        <i className="fa fa-check mr-3" aria-hidden="true" />
        {message}
    </div>
);

export const PasswordFieldFeedback = ({ password }) => {
    const specialChars = /[#&!%$*]/;

    const enoughLength = password.length >= 8;
    const containsCapitalLetter = password.match(/[A-Z]/g);
    const containsDigits = password.match(/\d/g);
    const containsSpecialChars = specialChars.test(password);

    return (
        <>
            <FeedbackLine flag={enoughLength} message="At least 8 characters please" />
            <FeedbackLine
                flag={containsCapitalLetter}
                message="At least 1 capital letter please"
            />
            <FeedbackLine
                flag={containsDigits}
                message="Add a number for more security"
            />
            <FeedbackLine
                flag={containsSpecialChars}
                message="Use a # & ! % $ or * to make it super secure"
            />
        </>
    );
};

const FirstAccessFormNew = ({ inviteData, submit, decline, orgId }) => {
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        last_name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        email: Yup.string().trim().lowercase().email(errorMessage.email.valid),
        // .required(errorMessage.email.required),
        officePhone: Yup.string()
            .trim()
            .matches(phoneRegExp.format, errorMessage.phone.valid)
            .required(errorMessage.email.required),
        officePhoneExt: Yup.string()
            .trim()
            .matches(phoneExtRegExp.format, errorMessage.phoneExt.valid),
        mobilePhone: Yup.string()
            .trim()
            .matches(phoneRegExp.format, errorMessage.phone.valid)
            .required(errorMessage.phone.required),
        password: Yup.string()
            .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
            .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
            .matches(passwordRegExp.numeric, errorMessage.password.numeric)
            .min(8, errorMessage.password.length)
            .required(errorMessage.password.required)
    });

    if (!inviteData) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={{
                first_name: inviteData.first_name || '',
                last_name: inviteData.last_name || '',
                officePhone: '',
                officePhoneExt: '',
                mobilePhone: '',
                email: inviteData.email || '',
                password: '',
                type: inviteData.type || ''
            }}
            onSubmit={submit}
            validationSchema={validationSchema}
            validateOnChange
        >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                <>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <img
                            src="../assets/sage-icon.svg"
                            alt="logo"
                            height={101}
                            width={86}
                        />
                        <div className="mb-3">
                            <div
                                style={{ fontSize: '32px' }}
                                className="d-flex justify-content-center text-dark-gray font-weight-semi-bold my-3"
                            >
                                Welcome to SAGE
                            </div>
                            <div
                                style={{ fontSize: '16px' }}
                                className="d-flex justify-content-center text-muted font-weight-normal my-3"
                            >
                                To set up your account, we just need a little information
                                first.
                            </div>
                        </div>
                    </div>
                    <Form className="w-75 mx-md-auto">
                        <div className="d-flex flex-column align-items-stretch">
                            <div className="d-flex flex-row justify-content-start">
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        fieldTitle="first name"
                                        required
                                        type="text"
                                        className="form-control"
                                        name="first_name"
                                        id="first_name"
                                        placeholder="First Name"
                                        aria-label="first_name"
                                        error={returnError(errors, 'first_name')}
                                        value={values.first_name}
                                        onBlur={handleBlur('first_name')}
                                        onChange={handleChange('first_name')}
                                        invalid={
                                            returnTouched(touched, 'first_name') &&
                                            !!returnError(errors, 'first_name')
                                        }
                                    />
                                </div>
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        fieldTitle="last name"
                                        required
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Last Name"
                                        aria-label="last_name"
                                        error={returnError(errors, 'last_name')}
                                        value={values.last_name}
                                        onBlur={handleBlur('last_name')}
                                        onChange={handleChange('last_name')}
                                        invalid={
                                            returnTouched(touched, 'last_name') &&
                                            !!returnError(errors, 'last_name')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-start">
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        note="This can be changed later in your profile settings."
                                        disabled
                                        fieldTitle="email address"
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        aria-label="email"
                                        error={returnError(errors, 'email')}
                                        value={values.email}
                                        onBlur={handleBlur('email')}
                                        onChange={handleChange('email')}
                                        invalid={
                                            returnTouched(touched, 'email') &&
                                            !!returnError(errors, 'email')
                                        }
                                    />
                                </div>
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        disabled
                                        fieldTitle="account type"
                                        type="text"
                                        className="form-control"
                                        name="type"
                                        id="type"
                                        placeholder="manager, vendor, resi or member"
                                        aria-label="type"
                                        error={returnError(errors, 'type')}
                                        value={values.type}
                                        onBlur={handleBlur('type')}
                                        onChange={handleChange('type')}
                                        invalid={
                                            returnTouched(touched, 'type') &&
                                            !!returnError(errors, 'type')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-start">
                                <div
                                    style={{ flex: 6 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        fieldTitle="office phone"
                                        required
                                        type="text"
                                        className="form-control"
                                        name="officePhone"
                                        id="officePhone"
                                        placeholder="Office Phone"
                                        aria-label="officePhone"
                                        error={returnError(errors, 'officePhone')}
                                        value={values.officePhone}
                                        onBlur={handleBlur('officePhone')}
                                        onChange={handleChange('officePhone')}
                                        invalid={
                                            returnTouched(touched, 'officePhone') &&
                                            !!returnError(errors, 'officePhone')
                                        }
                                    />
                                </div>
                                <div
                                    style={{ flex: 2 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        fieldTitle="ext"
                                        type="text"
                                        className="form-control"
                                        name="officePhoneExt"
                                        id="officePhoneExt"
                                        placeholder=""
                                        aria-label="officePhoneExt"
                                        error={returnError(errors, 'officePhoneExt')}
                                        value={values.officePhoneExt}
                                        onBlur={handleBlur('officePhoneExt')}
                                        onChange={handleChange('officePhoneExt')}
                                        invalid={
                                            returnTouched(touched, 'officePhoneExt') &&
                                            !!returnError(errors, 'officePhoneExt')
                                        }
                                    />
                                </div>
                                <div
                                    style={{ flex: 4 }}
                                    className="js-form-message d-flex form-group"
                                />
                            </div>
                            <div className="d-flex flex-row justify-content-start">
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        fieldTitle="mobile phone"
                                        required
                                        type="text"
                                        className="form-control"
                                        name="mobilePhone"
                                        id="mobilePhone"
                                        placeholder="Mobile Phone"
                                        aria-label="mobilePhone"
                                        error={returnError(errors, 'mobilePhone')}
                                        value={values.mobilePhone}
                                        onBlur={handleBlur('mobilePhone')}
                                        onChange={handleChange('mobilePhone')}
                                        invalid={
                                            returnTouched(touched, 'mobilePhone') &&
                                            !!returnError(errors, 'mobilePhone')
                                        }
                                    />
                                </div>
                                <div
                                    style={{ flex: 1 }}
                                    className="js-form-message col-6 d-flex form-group"
                                >
                                    <LabeledFormField
                                        fieldTitle="create password"
                                        required
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        id="password"
                                        placeholder=""
                                        aria-label="password"
                                        error={returnError(errors, 'password')}
                                        value={values.password}
                                        onBlur={handleBlur('password')}
                                        onChange={handleChange('password')}
                                        invalid={
                                            returnTouched(touched, 'password') &&
                                            !!returnError(errors, 'password')
                                        }
                                        noFeedback
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-start">
                                <div className="m-2" style={{ flex: 1 }} />
                                <div className="m-2" style={{ flex: 1 }}>
                                    {PasswordFieldFeedback(values)}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-3 pt-3 border-top">
                            <div style={{ flex: 1 }} className="m-2">
                                <Button
                                    onClick={() => decline({ ...inviteData, orgId })}
                                    className="transition-3d-hover cancel-button font-weight-semi-bold"
                                >
                                    Decline Invite
                                </Button>
                            </div>
                            <div
                                style={{ flex: 1 }}
                                className="d-flex justify-content-end m-2"
                            >
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="transition-3d-hover font-weight-semi-bold"
                                    onClick={handleSubmit}
                                >
                                    Save and continue
                                </Button>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default FirstAccessFormNew;
