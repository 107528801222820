import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { updateOrgDeviceDetailsResetState } from '../../store/actions/Panels';

const EditDeviceDetailsFailure = ({ error }) => {
    const dispatch = useDispatch();

    return (
        <div className="w-75 mx-md-auto pt-8">
            <div className="d-flex align-items-center justify-content-center flex-column">
                <img src="../../../assets/sage-icon.png" alt="logo" height={101} />
                <div className="mb-3">
                    <div
                        style={{ fontSize: '32px' }}
                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold mt-3 mb-8"
                    >
                        Error!
                    </div>
                    <div
                        style={{ fontSize: '16px' }}
                        className="d-flex justify-content-center text-muted font-weight-normal mt-5 mb-10"
                    >
                        {error}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center mt-3 pt-3">
                <div style={{ flex: 1 }} className="d-flex justify-content-center m-2">
                    <Button
                        color="primary"
                        className="transition-3d-hover font-weight-semi-bold"
                        onClick={() => {
                            dispatch(updateOrgDeviceDetailsResetState());
                        }}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditDeviceDetailsFailure;
