import React from 'react';

import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import 'yup-phone';

import { Container, Col, Button } from 'reactstrap';
import { Formik, Form, Field } from 'formik';

import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import ConfirmFooter from '../common/ConfirmFooter';
import Loader from '../common/Loader';
import EditDeviceDetailsFailure from './EditDeviceDetailsFailure';
import EditDeviceDetailsSuccess from './EditDeviceDetailsSuccess';
import { updateOrgDeviceDetails } from '../../store/actions/Panels';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const DeviceDetailsForm = ({
    device,
    orgDevices,
    setDevice,
    deviceTypes,
    orgId,
    deviceInfoUpdating,
    deviceInfoUpdatingSuccess,
    deviceInfoUpdatingError
}) => {
    const { active, device_type, id, ip_address, name } = orgDevices.find(
        panel => panel.id === device.id
    );
    const dispatch = useDispatch();

    const submit = values => {
        const payload = {
            deviceInfo: {
                ...values,
                active: values.active === 'online',
                device_type: values.device_type.value
            },
            orgId
        };
        dispatch(updateOrgDeviceDetails(payload));
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required(),
        ip_address: Yup.string().trim(),
        device_type: Yup.string().trim(),
        id: Yup.string().trim()
    });

    if (deviceInfoUpdating) {
        return (
            <Container className="space-1">
                <Col>
                    <Loader />
                </Col>
            </Container>
        );
    }

    if (deviceInfoUpdatingError) {
        return (
            <Container className="space-1">
                <Col>
                    <EditDeviceDetailsFailure error={deviceInfoUpdatingError} />
                </Col>
            </Container>
        );
    }

    if (deviceInfoUpdatingSuccess) {
        return (
            <Container className="space-1">
                <Col>
                    <EditDeviceDetailsSuccess onClose={() => setDevice(null)} />
                </Col>
            </Container>
        );
    }

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={{
                        name,
                        ip_address,
                        device_type: {
                            label: deviceTypes[device_type].type,
                            value: device_type
                        },
                        id,
                        active: active ? 'online' : 'offline'
                    }}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                    validateOnChange
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values
                    }) => (
                        <Form className="w-md-50 mx-md-auto">
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="device nickname"
                                            required
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            id="name"
                                            aria-label="name"
                                            error={returnError(errors, 'name')}
                                            value={values.name}
                                            onBlur={handleBlur('name')}
                                            onChange={handleChange('name')}
                                            invalid={
                                                returnTouched(touched, 'name') &&
                                                !!returnError(errors, 'name')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="ip address"
                                            type="text"
                                            className="form-control"
                                            name="ip_address"
                                            id="ip_address"
                                            aria-label="ip_address"
                                            error={returnError(errors, 'ip_address')}
                                            value={values.ip_address}
                                            onBlur={handleBlur('ip_address')}
                                            onChange={handleChange('ip_address')}
                                            invalid={
                                                returnTouched(touched, 'ip_address') &&
                                                !!returnError(errors, 'ip_address')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="js-form-message form-group">
                                <div className="d-flex flex-row justify-content-start">
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            dropDownField
                                            dropdownOptions={
                                                deviceTypes &&
                                                Object.entries(deviceTypes).map(
                                                    deviceType => {
                                                        const [value, modelDesc] =
                                                            deviceType;
                                                        return {
                                                            label: modelDesc.type,
                                                            value
                                                        };
                                                    }
                                                )
                                            }
                                            fieldTitle="device type"
                                            type="text"
                                            className="form-control"
                                            name="device_type"
                                            id="device_type"
                                            aria-label="device_type"
                                            error={returnError(errors, 'device_type')}
                                            value={values.device_type}
                                            selected={values.device_type}
                                            onChange={data => {
                                                setFieldValue('device_type', data);
                                            }}
                                            invalid={
                                                returnTouched(touched, 'device_type') &&
                                                !!returnError(errors, 'device_type')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ flex: 1 }}
                                        className="js-form-message col-6 d-flex form-group"
                                    >
                                        <LabeledFormField
                                            fieldTitle="device id"
                                            type="text"
                                            className="form-control"
                                            name="id"
                                            id="id"
                                            aria-label="id"
                                            error={returnError(errors, 'id')}
                                            value={values.id}
                                            selected={values.id}
                                            onChange={date => setFieldValue('id', date)}
                                            invalid={
                                                returnTouched(touched, 'id') &&
                                                !!returnError(errors, 'id')
                                            }
                                            disabled
                                            note="Device IDs are automatically generated and can not be changed."
                                        />
                                    </div>
                                </div>
                                <div className="js-form-message form-group">
                                    <div className="d-flex flex-row justify-content-start">
                                        <div
                                            style={{ flex: 1 }}
                                            className="js-form-message col-6 d-flex form-group flex-column"
                                        >
                                            {active ? (
                                                <span className="badge badge-success badge-device-status font-weight-semi-bold">
                                                    Online
                                                </span>
                                            ) : (
                                                <span className="badge badge-danger badge-device-status font-weight-semi-bold">
                                                    Offline
                                                </span>
                                            )}
                                            <div className="d-flex flex-column  mt-2">
                                                <label className="radio-button">
                                                    <Field
                                                        type="radio"
                                                        className="radio-button__input"
                                                        name="active"
                                                        value="online"
                                                    />
                                                    <span className="radio-button__control" />
                                                    <span className="radio-button__label font-weight-semi-bold">
                                                        Online Mode
                                                    </span>
                                                </label>
                                                <label className="radio-button">
                                                    <Field
                                                        type="radio"
                                                        className="radio-button__input"
                                                        name="active"
                                                        value="offline"
                                                    />
                                                    <span className="radio-button__control" />
                                                    <span className="radio-button__label font-weight-semi-bold">
                                                        Manual Offline Mode
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            style={{ flex: 1 }}
                                            className="js-form-message col-6 d-flex form-group align-items-center justify-content-center"
                                        >
                                            <Button
                                                type="button"
                                                className="transition-3d-hover font-weight-semi-bold test-connection-button text-nowrap"
                                                onClick={() => {}}
                                            >
                                                Test Connection
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ConfirmFooter
                                cancel={() => setDevice(null)}
                                submitFunc={handleSubmit}
                                submitText="Save"
                            />
                        </Form>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

const mapStateToProps = ({
    panel: {
        orgDevices,
        deviceTypes,
        deviceInfoUpdating,
        deviceInfoUpdatingSuccess,
        deviceInfoUpdatingError
    }
}) => ({
    orgDevices,
    deviceTypes,
    deviceInfoUpdating,
    deviceInfoUpdatingSuccess,
    deviceInfoUpdatingError
});

export default connect(mapStateToProps)(DeviceDetailsForm);
