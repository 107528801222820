import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from '../store';

const store = configureStore();

const AppProviders = ({ children }) => {
    return <ReduxProvider store={store}>{children}</ReduxProvider>;
};

export default AppProviders;
