import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWindowSize } from '../../hooks/useWindowSize';
import Loader from '../common/Loader';

const OtcList = ({ orgId, searchString, selectHandler, selected, filteredCodes }) => {
    const { windowHeight } = useWindowSize();

    const getDateFromTimestamp = timestamp => {
        const date = new Date(timestamp * 1000);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yy = date.getFullYear().toString().substr(2, 2);
        const time = date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        });

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return `${mm}-${dd}-${yy}, ${time}`;
    };

    return (
        <div style={{ height: [windowHeight] - 450, overflowY: 'auto' }}>
            {filteredCodes !== null && (
                <InfiniteScroll dataLength={filteredCodes.length} loader={<Loader />}>
                    {filteredCodes.map(otc => {
                        const { code_id, code, expires_at, name } = otc;
                        return (
                            <div
                                key={code_id}
                                className="contact-list-item"
                                onClick={e => {
                                    e.stopPropagation();
                                    selectHandler(code_id);
                                }}
                            >
                                <div className="col-2">
                                    <span className="d-block mb-0 text-capitalize">
                                        {name}
                                    </span>
                                </div>
                                <div className="col-2">
                                    <small>{code}</small>
                                </div>
                                <div className="col-2">
                                    <small>
                                        {getDateFromTimestamp(expires_at.seconds)}
                                    </small>
                                </div>
                                <div className="col-1" style={{ padding: 0 }}>
                                    <div
                                        className={`invite-badge ${
                                            selected.includes(code_id)
                                                ? 'badge-green'
                                                : 'badge-gray'
                                        }`}
                                    >
                                        <i
                                            className="fa fa-check-circle mr-2 "
                                            aria-hidden="true"
                                        />
                                        Select
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </InfiniteScroll>
            )}
        </div>
    );
};

export default OtcList;
