import {
    ADD_NEW_MEMBER,
    GET_EXISTING_MEMBER,
    REMOVE_MEMBERS,
    UPDATE_MEMBER,
    UPDATE_REGISTER,
    REMOVE_REGISTERS,
    REMOVE_PENDING,
    SEND_INVITES
} from '../actions/types';

import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';

import { db, rtdb, timeStampNow } from '../../config/Firebase';

import {
    setExistingMember,
    addingNewMemberSuccess,
    addingNewMemberFailure,
    addingNewMember
} from '../actions/Members';

import { generateInviteCode } from '../../utils/Helpers';

import { ACCESS_KEY_SCHEMA, GUEST_INVITE_SCHEMA } from '../../utils/Constants';
import * as selectors from './Selectors';

const users = db.collection('users');
const guest_invites = db.collection('guest_invites');
const access_keys = db.collection('access_keys');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Members ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const existingByEmail = email => {
    return new Promise((resolve, reject) => {
        users
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                if (!querySnapshot.size) {
                    resolve([]);
                }

                const emailExist = [];
                querySnapshot.forEach((doc, index) => {
                    emailExist.push(doc.data());
                    resolve(emailExist);
                });
            })
            .catch(error => reject(error));
    });
};

const existingByPhone = async phone => {
    return new Promise((resolve, reject) => {
        users
            .where('phone_number', '==', phone)
            .get()
            .then(querySnapshot => {
                if (!querySnapshot.size) {
                    resolve([]);
                }

                const phoneExist = [];
                querySnapshot.forEach((doc, index) => {
                    phoneExist.push(doc.data());
                    resolve(phoneExist);
                });
            })
            .catch(error => reject(error));
    });
};

const checkExistingMemberRequest = async member => {
    const { phone, email } = member;
    try {
        const emailExistence = email ? await existingByEmail(email) : [];
        const phoneExistence = phone ? await existingByPhone(phone) : [];

        const existence = [...emailExistence, ...phoneExistence];
        const filteredExistence = existence.reduce((reducedExistence, current) => {
            if (!reducedExistence.some(x => x.uid === current.uid)) {
                reducedExistence.push(current);
            }
            return reducedExistence;
        }, []);

        return { existing: filteredExistence };
    } catch (error) {
        return { error };
    }
};

export function* checkExistingMember({ payload }) {
    const { member } = payload;
    const { existing, error } = yield call(() =>
        checkExistingMemberRequest({ email: member.email, phone: member.phone_number })
    );
    if (existing) {
        if (existing.length) {
            yield put(setExistingMember(existing));
        } else {
            yield put(
                addingNewMember({
                    member,
                    exist: false
                })
            );
        }
    } else {
        // Error Handling for sentry with put and maybe UI message
        console.log(
            'Check Existing Error: error checking for existing member when adding',
            {
                error
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Members ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addNewMembersRequest = async ({ invite, user, org }) => {
    return new Promise((resolve, reject) => {
        const guestInviteDocRef = guest_invites.doc();
        const guestInviteKeyId = guestInviteDocRef.id;
        const data = GUEST_INVITE_SCHEMA;
        const firstName = invite.first_name;
        const lastName = invite.last_name;
        const email = invite.email ? invite.email : null;
        const phone = invite.phone ? invite.phone : null;
        const phoneNumber = invite.phone_number ? invite.phone_number : null;
        const inviteCode = generateInviteCode();
        const role = invite.type;

        const address =
            role === 'resi'
                ? {
                      address_1: invite.address_1,
                      address_2:
                          invite.address_2 && invite.address_2.trim() !== ''
                              ? invite.address_2
                              : null,
                      city: null,
                      latitude: null,
                      longitude: null,
                      state: null,
                      zip: null
                  }
                : org.address;
        const orgId = org.org_id;
        const orgName = org.org_name;

        const guestInviteData = Object.assign(data, {
            access_days: role === 'resi' ? null : ['mon', 'tue', 'wed', 'thu', 'fri'],
            access_end_time:
                role === 'resi'
                    ? null
                    : {
                          hours: 17,
                          minutes: 0
                      },
            access_expires: null,
            access_start_time:
                role === 'resi'
                    ? null
                    : {
                          hours: 9,
                          minutes: 0
                      },
            address: address,
            creator_first_name: user.first_name,
            creator_id: user.uid,
            creator_last_name: user.last_name,
            email: email,
            first_name: firstName,
            invite_code: inviteCode,
            invite_id: guestInviteKeyId,
            invite_status: 'pending',
            invited_at: timeStampNow(),
            last_name: lastName,
            org_id: orgId,
            org_name: orgName,
            phone: phone,
            phone_number: phoneNumber,
            role: role,
            suspended: false,
            type: role === 'resi' ? 'member' : 'short-term'
        });

        guestInviteDocRef
            .set(guestInviteData)
            .then(() => {
                // console.log(`Invite Created: ${inviteCode}`);

                resolve({ res: true });
            })
            .catch(error => {
                reject({ error: 'Error: creating guest invite' });
            });
    });
};

const addExistingMembersRequest = async ({ member, user, org, exist }) => {
    return new Promise((resolve, reject) => {
        const role = member.type;
        const userDocRef = users.doc(exist.uid);
        const orgId = org.org_id;
        const orgName = org.org_name;
        const accessKeyDocRef = access_keys.doc();
        const accessKeyId = accessKeyDocRef.id;
        const registerRef = rtdb.ref(`orgs/${orgId}/members/${exist.uid}`);
        const data = ACCESS_KEY_SCHEMA;
        const tsNow = timeStampNow();
        const type = role === 'resi' ? 'member' : 'short-term';
        const properties =
            role === 'vendor'
                ? null
                : exist?.properties
                ? {
                      ...exist.properties,
                      [orgId]: {
                          address: {
                              address_1: member.address_1,
                              address_2: member.address_2,
                              city: '',
                              latitude: null,
                              longitude: null,
                              state: '',
                              zip: ''
                          },
                          notifications: true,
                          org_id: orgId
                      }
                  }
                : {
                      [orgId]: {
                          address: {
                              address_1: member.address_1,
                              address_2: member.address_2,
                              city: '',
                              latitude: null,
                              longitude: null,
                              state: '',
                              zip: ''
                          },
                          notifications: true,
                          org_id: orgId
                      }
                  };

        const addFrag = member.address_1 ? member.address_1.split(' ') : null;
        const streetNum = addFrag ? addFrag[0] : '';

        const accessKeyData = Object.assign(data, {
            access_begins: null,
            access_created: tsNow,
            access_days: role === 'resi' ? null : ['mon', 'tue', 'wed', 'thu', 'fri'],
            access_end_time:
                role === 'resi'
                    ? null
                    : {
                          hours: 17,
                          minutes: 0
                      },
            access_expires: null,
            access_start_time:
                role === 'resi'
                    ? null
                    : {
                          hours: 9,
                          minutes: 0
                      },
            address:
                role === 'vendor'
                    ? org.address
                    : {
                          address_1: member.address_1,
                          address_2: member.address_2,
                          city: '',
                          latitude: null,
                          longitude: null,
                          state: '',
                          zip: ''
                      },
            company_name: role === 'vendor' ? member.company_name : '',
            consumer_id: exist.uid,
            creator_first_name: user.first_name,
            creator_id: user.uid,
            creator_last_name: user.last_name,
            email: exist.email,
            favorite: false,
            first_name: exist.first_name,
            image: null,
            key_id: accessKeyId,
            last_name: exist.last_name,
            org_id: orgId,
            org_name: orgName,
            phone: exist.phone,
            phone_number: exist.phone_number,
            photo_id: null,
            plates: [],
            role: role,
            suspended: false,
            type: type,
            vehicles: []
        });

        const memberData = {
            access_group: 1,
            access_key: true,
            address: role === 'resi' ? member.address : '',
            directory: role === 'resi' ? true : false,
            email: exist?.email ? exist.email : '',
            first_name: exist.first_name,
            last_name: exist.last_name,
            phone: exist?.phone?.number ? exist.phone.number : '',
            role: role,
            street_num: streetNum,
            suspended: false,
            uid: exist.uid
        };

        if (role === 'resi' && properties) {
            userDocRef.update({ properties }).catch(error => reject(error));
        }

        accessKeyDocRef
            .set(accessKeyData)
            .then(() => {
                registerRef.set(memberData, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        resolve({ res: true });
                    }
                });
            })
            .catch(error => {
                reject({ error: 'Error: adding Firestore User Doc' });
            });
    });
};

export function* addMembers({ payload }) {
    const { member, exist } = payload;
    const userData = yield select(selectors._userData);
    const org = yield select(selectors._activeOrg);

    const { res, error } = !exist
        ? yield call(() => addNewMembersRequest({ invite: member, user: userData, org }))
        : yield call(() =>
              addExistingMembersRequest({ member, user: userData, org, exist: exist[0] })
          );

    if (res) {
        yield put(addingNewMemberSuccess());
    } else {
        yield put(addingNewMemberFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error adding ${exist ? 'existing' : 'new'} member (FS)`, {
            error
        });
    }
}

const removeMembersRequest = ({ members, org }) => {
    const memberRef = rtdb.ref();
    const accessKeyRef = db.collection('access_keys');
    return new Promise((resolve, reject) => {
        var remove = {};
        members.forEach((member, index) => {
            Object.assign(remove, {
                [`orgs/${org.org_id}/members/${member}`]: null
            });
            accessKeyRef
                .where('org_id', '==', org.org_id)
                .where('consumer_id', '==', member)
                .where('role', '==', 'resi')
                .get()
                .then(keys => {
                    if (keys.empty) {
                        // console.log('no access keys');
                    } else {
                        keys.forEach((doc, index) => {
                            const keyId = doc.data().key_id;
                            accessKeyRef.doc(keyId).delete();
                        });
                    }
                })
                .catch(error => {
                    console.log('Error getting Access Keys to delete', error);
                    reject({ error });
                });
            if (index + 1 === members.length) {
                memberRef.update({ ...remove });
                resolve({ res: true });
            }
        });
    });
};

export function* removeMembers({ payload }) {
    const { members } = payload;
    const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() => removeMembersRequest({ members, org }));
    if (res) {
        console.log(res);
    } else {
        // console.log('error completing remove');
        // yield put(removingMembersFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error removing members (FS/RTDB)`, {
            error,
            members,
            org
        });
    }
}

const updateMemberRequest = ({ member, org }) => {
    // console.log('updating member request', member);
    const registerRef = rtdb.ref(`orgs/${org.org_id}/members`);
    const accessKeyRef = db.collection('access_keys');
    return new Promise((resolve, reject) => {
        const address = member.full_address;
        const addFrag = address.split(' ');
        registerRef.child(`${member.uid}`).update({
            email: member.email.toLowerCase().trim(),
            first_name: member.first_name.toLowerCase().trim(),
            address: member.full_address.trim(),
            last_name: member.last_name.toLowerCase().trim(),
            phone: parseInt(member.phone.trim()),
            role: member.role,
            street_num: addFrag[0],
            suspended: member.suspended
        });
        accessKeyRef
            .where('org_id', '==', org.org_id)
            .where('consumer_id', '==', member.uid)
            .where('role', '==', `${member.role}`)
            .get()
            .then(keys => {
                if (keys.empty) {
                    // console.log('no access keys');
                    resolve({ res: true });
                } else {
                    keys.forEach((doc, index) => {
                        const keyId = doc.data().key_id;
                        accessKeyRef.doc(keyId).update({ suspended: member.suspended });
                    });
                    resolve({ res: true });
                }
            })
            .catch(error => {
                console.log('Error getting Access Keys to delete', error);
                reject({ error });
            });
    });
};

export function* updateMember({ payload }) {
    const { member } = payload;
    const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() => updateMemberRequest({ member, org }));
    if (res) {
        console.log(res);
        // console.log('update member is complete!!!');
        // yield put(removingMembersSuccess());
    } else {
        // console.log('error completing remove');
        // yield put(removingMembersFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error updating member (FS/RTDB)`, {
            error,
            member,
            org
        });
    }
}

const removeRegistersRequest = ({ registers, org }) => {
    const registerRef = rtdb.ref();
    return new Promise((resolve, reject) => {
        var remove = {};
        registers.forEach((member, index) => {
            Object.assign(remove, {
                [`registration/${org.org_id}/${member.id}`]: null
            });
            if (index + 1 === registers.length) {
                registerRef.update({ ...remove });
            }
        });
    });
};

export function* removeRegisters({ payload }) {
    const { registers } = payload;
    const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() => removeRegistersRequest({ registers, org }));
    if (res) {
        console.log(res);
    } else {
        // console.log('error completing remove');
        // yield put(removingMembersFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error removing registers (FS/RTDB)`, {
            error,
            registers,
            org
        });
    }
}

const updateRegisterRequest = ({ member, org }) => {
    // console.log('updating register request', member);
    const registerRef = rtdb.ref(`registration/${org.org_id}`);
    return new Promise((resolve, reject) => {
        registerRef.child(`${member.id}`).update(
            {
                email: member.email.toLowerCase().trim(),
                first_name: member.first_name.toLowerCase().trim(),
                address: member.full_address.trim(),
                last_name: member.last_name.toLowerCase().trim(),
                phone: parseInt(member.phone.trim())
            },
            error => {
                if (error) {
                    reject({ error });
                } else {
                    resolve({ res: true });
                }
            }
        );
    });
};

export function* updateRegister({ payload }) {
    const { member } = payload;
    const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() => updateRegisterRequest({ member, org }));
    if (res) {
        console.log(res);
        // yield put(removingMembersSuccess());
    } else {
        // console.log('error completing remove');
        // yield put(removingMembersFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error updating member (FS/RTDB)`, {
            error,
            member,
            org
        });
    }
}

const removePendingRequest = ({ pending }) => {
    const guestInviteRef = db.collection('guest_invites');
    return new Promise((resolve, reject) => {
        pending.forEach(invite => {
            guestInviteRef.doc(invite).delete();
        });
        resolve({ res: true });
    });
};

export function* removePending({ payload }) {
    const { pending } = payload;
    const { res, error } = yield call(() => removePendingRequest({ pending }));
    if (res) {
        console.log(res);
        // yield put(removingMembersSuccess());
    } else {
        // console.log('error completing remove');
        // yield put(removingMembersFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error removing pending (FS/RTDB)`, {
            error,
            pending
        });
    }
}

const sendInvitesRequest = ({ invites, user, org }) => {
    const registrationRef = rtdb.ref(`registration/${org.org_id}`);
    return new Promise((resolve, reject) => {
        const generated = [];
        invites.forEach((invite, index) => {
            registrationRef.child(`${invite.id}`).remove();
            const guestInviteDocRef = guest_invites.doc();
            const guestInviteKeyId = guestInviteDocRef.id;
            const data = GUEST_INVITE_SCHEMA;
            const firstName = invite.first_name.toLowerCase();
            const lastName = invite.last_name.toLowerCase();
            const email = invite.email ? invite.email.toLowerCase() : null;
            const phone = invite.phone
                ? { code: 1, country: 'US', number: invite.phone }
                : null;
            const phoneNumber = invite.phone ? `${1}${invite.phone}` : null;
            const inviteCode = generateInviteCode();

            generated.push(`${inviteCode}`);

            const address = {
                address_1: invite.address ? invite.address : null,
                address_2: null,
                city: null,
                latitude: null,
                longitude: null,
                state: null,
                zip: null
            };
            const orgId = org.org_id;
            const orgName = org.org_name;

            const guestInviteData = Object.assign(data, {
                address: address,
                creator_first_name: user.first_name,
                creator_id: user.uid,
                creator_last_name: user.last_name,
                email: email,
                first_name: firstName,
                invite_code: inviteCode,
                invite_id: guestInviteKeyId,
                invite_status: 'pending',
                invited_at: timeStampNow(),
                last_name: lastName,
                org_id: orgId,
                org_name: orgName,
                phone: phone,
                phone_number: phoneNumber,
                role: 'resi',
                suspended: false,
                type: 'member'
            });

            guestInviteDocRef
                .set(guestInviteData)
                .then(() => {
                    if (index + 1 === invites.length) {
                        // console.log(`Total Invites Created: ${invites.length}`);
                        // console.log(JSON.stringify({ generated }, null, 0));
                        resolve({ res: true });
                    }
                })
                .catch(error => {
                    reject({ error: 'Error: creating guest invite' });
                });
        });
    });
};

export function* sendInvites({ payload }) {
    const { invites } = payload;
    const userData = yield select(selectors._userData);
    const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() =>
        sendInvitesRequest({ invites, user: userData, org })
    );
    if (res) {
        console.log(res);
        // yield put(removingMembersSuccess());
    } else {
        // console.log('error completing remove');
        // yield put(removingMembersFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error updating member (FS/RTDB)`, {
            error,
            invites,
            org,
            userData
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* checkingExistingMember() {
    yield takeLatest(GET_EXISTING_MEMBER, checkExistingMember);
}

export function* addingMembers() {
    yield takeLatest(ADD_NEW_MEMBER, addMembers);
}

export function* removingMembers() {
    yield takeLatest(REMOVE_MEMBERS, removeMembers);
}

export function* updatingMember() {
    yield takeLatest(UPDATE_MEMBER, updateMember);
}

export function* removingRegisters() {
    yield takeLatest(REMOVE_REGISTERS, removeRegisters);
}

export function* updatingRegister() {
    yield takeLatest(UPDATE_REGISTER, updateRegister);
}

export function* sendingInvites() {
    yield takeLatest(SEND_INVITES, sendInvites);
}

export function* removingPending() {
    yield takeLatest(REMOVE_PENDING, removePending);
}

export default function* rootSaga() {
    yield all([
        fork(checkingExistingMember),
        fork(addingMembers),
        fork(removingMembers),
        fork(updatingMember),
        fork(updatingRegister),
        fork(removingRegisters),
        fork(sendingInvites),
        fork(removingPending)
    ]);
}
