import React from 'react';
import { Container, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { errorMessage, nameRegExp } from '../../utils/Constants';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import { AUTHENTICATED, GUEST_MANAGER } from '../../router/config/routes';
import { timeStampJs, timeStampNow } from '../../config/Firebase';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const AddOtcForm = ({ createOtc }) => {
    const submit = values => {
        const now = timeStampNow();
        const eightHoursInSeconds = 8 * 3600;
        const eightHoursAhead = new timeStampJs(
            now.seconds + eightHoursInSeconds,
            now.nanoseconds
        );
        const code = Math.floor(100000 + Math.random() * 900000).toString();
        createOtc({
            name: values.name,
            created_at: now,
            expires_at: eightHoursAhead,
            code
        });
    };

    const initialValues = {
        name: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.fullNameOrCompany.required)
    });

    return (
        <Container className="space-1">
            <Col>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                    validateOnChange
                    enableReinitialize
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                    }) => (
                        <div className="w-md-75 mx-md-auto card bg-white form-shadow p-2">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <div>Create One Time Access Code</div>
                                <Link
                                    to={`${AUTHENTICATED}${GUEST_MANAGER}`}
                                    className="d-flex align-items-center"
                                >
                                    <span className="material-icons-round mr-1">
                                        close
                                    </span>
                                    Cancel
                                </Link>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-center">
                                    <p className="text-center w-75">
                                        Use the one time access codes for your
                                        non-recurring visitors, delivery drivers, car
                                        services and other needs.
                                    </p>
                                </div>
                                <div className="mb-5">
                                    <Form className="p-2">
                                        <div className="js-form-message form-group mb-0 mb-s-4 mx-4 mx-lg-7">
                                            <div className="w-100 w-sm-50 mx-auto">
                                                <LabeledFormField
                                                    fieldTitle="name"
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Full name or Company"
                                                    aria-label="name"
                                                    error={returnError(errors, 'name')}
                                                    value={values.name}
                                                    onBlur={handleBlur('name')}
                                                    onChange={handleChange('name')}
                                                    invalid={
                                                        returnTouched(touched, 'name') &&
                                                        !!returnError(errors, 'name')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="card-footer d-flex flex-column flex-sm-row-reverse align-items-center justify-content-between w-100">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary w-100 w-sm-auto mb-3 mb-sm-0"
                                    onClick={handleSubmit}
                                >
                                    Create Code
                                </Button>
                            </div>
                        </div>
                    )}
                </Formik>
            </Col>
        </Container>
    );
};

export default AddOtcForm;
