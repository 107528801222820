import {
    ADD_NEW_GUEST,
    ADD_NEW_GUEST_SUCCESS,
    ADD_NEW_GUEST_FAILURE,
    RESET_ADD_NEW_GUEST,
    SET_GUEST_INVITES,
    REMOVE_GUEST,
    REMOVE_GUEST_SUCCESS,
    REMOVE_GUEST_FAILURE,
    RESET_REMOVE_GUEST,
    SET_GUEST_ACCESS_KEYS,
    SET_ONE_TIME_ACCESS_CODES,
    ADD_ONE_TIME_ACCESS_CODE,
    ADD_ONE_TIME_ACCESS_CODE_SUCCESS,
    ADD_ONE_TIME_ACCESS_CODE_FAILURE,
    RESET_ADD_ONE_TIME_ACCESS_CODE,
    REVOKE_ONE_TIME_ACCESS_CODE,
    REVOKE_ONE_TIME_ACCESS_CODE_SUCCESS,
    REVOKE_ONE_TIME_ACCESS_CODE_FAILURE,
    RESET_REVOKE_ONE_TIME_ACCESS_CODE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    otcError: null,
    guestInvites: null,
    oneTimeAccessCodes: null,
    guestAccessKeys: null,
    addOtcSuccess: null,
    addGuestSuccess: null,
    revokeOtcSuccess: null,
    revokeOtcError: null,
    removeGuestSuccess: null
};

const guestsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_GUEST_INVITES:
            return {
                ...state,
                guestInvites: action.payload
            };
        case SET_ONE_TIME_ACCESS_CODES:
            return {
                ...state,
                oneTimeAccessCodes: action.payload
            };
        case SET_GUEST_ACCESS_KEYS:
            return {
                ...state,
                guestAccessKeys: action.payload
            };
        case ADD_ONE_TIME_ACCESS_CODE:
            return {
                ...state,
                loading: true
            };
        case ADD_ONE_TIME_ACCESS_CODE_SUCCESS:
            return {
                ...state,
                loading: null,
                otcError: null,
                addOtcSuccess: true
            };
        case RESET_ADD_ONE_TIME_ACCESS_CODE:
            return {
                ...state,
                loading: null,
                otcError: null,
                addOtcSuccess: null
            };
        case ADD_ONE_TIME_ACCESS_CODE_FAILURE:
            return {
                ...state,
                loading: null,
                otcError: action.payload
            };
        case REVOKE_ONE_TIME_ACCESS_CODE:
            return {
                ...state,
                loading: true
            };
        case REVOKE_ONE_TIME_ACCESS_CODE_SUCCESS:
            return {
                ...state,
                loading: null,
                revokeOtcError: null,
                revokeOtcSuccess: true
            };
        case RESET_REVOKE_ONE_TIME_ACCESS_CODE:
            return {
                ...state,
                loading: null,
                revokeOtcError: null,
                revokeOtcSuccess: null
            };
        case REVOKE_ONE_TIME_ACCESS_CODE_FAILURE:
            return {
                ...state,
                loading: null,
                revokeOtcError: action.payload
            };
        case REMOVE_GUEST:
        case ADD_NEW_GUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_NEW_GUEST_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                addGuestSuccess: true
            };
        case ADD_NEW_GUEST_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case RESET_ADD_NEW_GUEST:
            return {
                ...state,
                loading: null,
                error: null,
                addGuestSuccess: null
            };
        case RESET_REMOVE_GUEST:
            return {
                ...state,
                loading: null,
                error: null,
                removeGuestSuccess: null
            };
        case REMOVE_GUEST_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                removeGuestSuccess: true
            };
        case REMOVE_GUEST_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        default:
            return state;
    }
};

export default guestsReducer;
