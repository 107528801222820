import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { useWindowSize } from '../../hooks/useWindowSize';
import Loader from '../common/Loader';

const getTimeStringFromTimestamp = date => {
    const timeNow = new Date();
    const incomingTimestamp = new Date(date);

    if (timeNow.getDate() === incomingTimestamp.getDate()) {
        return `Today, ${new Intl.DateTimeFormat('en-US', {
            timeStyle: 'short'
        }).format(incomingTimestamp)}`;
    }

    if (timeNow.getDate() - 1 === incomingTimestamp.getDate()) {
        return `Yesterday, ${new Intl.DateTimeFormat('en-US', {
            timeStyle: 'short'
        }).format(incomingTimestamp)}`;
    }

    return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format(incomingTimestamp);
};

const AccessLog = ({ accessLog }) => {
    const { windowHeight } = useWindowSize();

    if (!accessLog) {
        return (
            <div className="w-50">
                <h5 className="my-3">Access Log</h5>
                <div style={{ height: [windowHeight] - 453, overflowY: 'auto' }}>
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <div className="w-100 p-2">
            <h5 className="my-3">Access Log</h5>
            <div style={{ height: [windowHeight] - 490, overflowY: 'auto' }}>
                <InfiniteScroll
                    dataLength={accessLog.length}
                    loader={<h4>Loading...</h4>}
                >
                    <div>
                        {accessLog.map(
                            ({
                                logItemId,
                                access_time,
                                access_time_seconds,
                                company,
                                consumer_id,
                                first_name,
                                last_name,
                                role
                            }) => {
                                return (
                                    <div
                                        key={logItemId}
                                        className="p-2 my-2 mr-2 border-bottom"
                                    >
                                        <h2 className="h6 mb-0 text-gray">
                                            {role === 'vendor'
                                                ? `${company} is on property.`
                                                : `${first_name} ${last_name} accessed the gate.`}
                                        </h2>
                                        <small className="d-block text-dark-gray">
                                            {getTimeStringFromTimestamp(access_time)}
                                        </small>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );
};

const mapStateToProps = ({ access: { accessLog } }) => {
    return { accessLog };
};

export default connect(mapStateToProps)(AccessLog);
