import {
    GET_ORG_OTC_SUCCESS,
    ADD_OTC,
    ADD_OTC_SUCCESS,
    ADD_OTC_FAILURE,
    RESET_OTC_STATE,
    REVOKE_OTC,
    REVOKE_OTC_SUCCESS,
    REVOKE_OTC_FAILURE
} from '../actions/types';

export const addOtc = otcInfo => {
    return {
        type: ADD_OTC,
        payload: otcInfo
    };
};

export const addOtcSuccess = otc => {
    return {
        type: ADD_OTC_SUCCESS,
        payload: otc
    };
};

export const addOtcFailure = error => {
    return {
        type: ADD_OTC_FAILURE,
        payload: error
    };
};

export const revokeOtc = codes => {
    return {
        type: REVOKE_OTC,
        payload: codes
    };
};

export const revokeOtcSuccess = () => {
    return {
        type: REVOKE_OTC_SUCCESS
    };
};

export const revokeOtcFailure = error => {
    return {
        type: REVOKE_OTC_FAILURE,
        payload: error
    };
};

export const resetOtcState = () => {
    return {
        type: RESET_OTC_STATE
    };
};

export const getOtcCodesSuccess = otcCodes => {
    return {
        type: GET_ORG_OTC_SUCCESS,
        payload: otcCodes
    };
};
