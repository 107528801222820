import {
    GET_EXISTING_MEMBER,
    SET_EXISTING_MEMBER,
    ADD_NEW_MEMBER,
    ADD_NEW_MEMBER_SUCCESS,
    ADD_NEW_MEMBER_FAILURE,
    RESET_ADD_NEW_MEMBER,
    REMOVE_MEMBERS,
    UPDATE_MEMBER,
    UPDATE_REGISTER,
    REMOVE_REGISTERS,
    SEND_INVITES,
    REMOVE_PENDING
} from './types';

export const checkingExistingMember = member => {
    return {
        type: GET_EXISTING_MEMBER,
        payload: member
    };
};

export const setExistingMember = existing => {
    return {
        type: SET_EXISTING_MEMBER,
        payload: existing
    };
};

export const addingNewMember = member => {
    return {
        type: ADD_NEW_MEMBER,
        payload: member
    };
};

export const addingNewMemberSuccess = () => {
    return {
        type: ADD_NEW_MEMBER_SUCCESS
    };
};

export const addingNewMemberFailure = error => {
    return {
        type: ADD_NEW_MEMBER_FAILURE,
        payload: error
    };
};

export const resettingNewMember = type => {
    return {
        type: RESET_ADD_NEW_MEMBER,
        payload: type
    };
};

export const removingMembers = members => {
    return {
        type: REMOVE_MEMBERS,
        payload: members
    };
};

export const updatingMember = member => {
    return {
        type: UPDATE_MEMBER,
        payload: member
    };
};

export const removingRegisters = registers => {
    return {
        type: REMOVE_REGISTERS,
        payload: registers
    };
};

export const updatingRegister = register => {
    return {
        type: UPDATE_REGISTER,
        payload: register
    };
};

export const sendingInvites = invites => {
    return {
        type: SEND_INVITES,
        payload: invites
    };
};

export const removingPending = pending => {
    return {
        type: REMOVE_PENDING,
        payload: pending
    };
};

// export const addingNewMemberSuccess = () => {
//     return {
//         type: ADD_NEW_MEMBER_SUCCESS
//     };
// };

// export const addingNewMemberFailure = error => {
//     return {
//         type: ADD_NEW_MEMBER_FAILURE,
//         payload: error
//     };
// };
