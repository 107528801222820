import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const AddMemberSuccess = ({ type, found }) => {
    const history = useHistory();
    const message =
        type === 'resi'
            ? found
                ? 'Resident added to community!'
                : 'Resident invited to community!'
            : found
            ? 'Vendor added to community!'
            : 'Vendor invited to community!';
    return (
        <div className="w-75 mx-md-auto pt-8">
            <div className="d-flex align-items-center justify-content-center flex-column">
                <img src="../../assets/sage-icon.png" alt="logo" height={101} />
                <div className="mb-3">
                    <div
                        style={{ fontSize: '32px' }}
                        className="d-flex justify-content-center text-dark-gray font-weight-semi-bold mt-3 mb-8"
                    >
                        Success!
                    </div>
                    <div
                        style={{ fontSize: '16px' }}
                        className="d-flex justify-content-center text-muted font-weight-normal mt-5 mb-10"
                    >
                        {message}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center mt-3 pt-3">
                <div style={{ flex: 1 }} className="d-flex justify-content-center m-2">
                    <Button
                        color="primary"
                        className="transition-3d-hover font-weight-semi-bold"
                        onClick={() => history.goBack()}
                    >
                        Finished
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddMemberSuccess;
