import {
    REQUEST_ACCOUNT_REMOVAL,
    REQUEST_ACCOUNT_REMOVAL_SUCCESS,
    REQUEST_ACCOUNT_REMOVAL_FAILURE,
    RESET_REQUEST_ACCOUNT_REMOVAL
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    email: null,
    success: null
};

const removalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_ACCOUNT_REMOVAL:
            return {
                ...state,
                email: action.payload.email,
                loading: true
            };
        case REQUEST_ACCOUNT_REMOVAL_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                success: true
            };
        case REQUEST_ACCOUNT_REMOVAL_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case RESET_REQUEST_ACCOUNT_REMOVAL:
            return {
                ...state,
                loading: null,
                error: null,
                email: action.payload === 'soft' ? state.email : null,
                success: null
            };
        default:
            return state;
    }
};

export default removalReducer;
