import React from 'react';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import InfiniteScroll from 'react-infinite-scroll-component';

const WidgetContainer = ({ aggregatePanel }) => {
    if (!aggregatePanel) return null;
    return (
        <div className="mb-5">
            <div className="card-deck">
                <div className="card col-md-12 p-4 shadow-2">
                    <div className="card-body">
                        <div className="row g-0">
                            <div className="col-md-6 pb-4">
                                <h6 className="widget-label">Panel Status</h6>
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="col-6 border-right">
                                        <p className="widget-data text-green mr-3 mt-0">
                                            {numeral(aggregatePanel.online_panels).format(
                                                '0,0'
                                            )}
                                            <small className="ml-1">online</small>
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p className="widget-data text-red ml-3 mt-0">
                                            {numeral(
                                                aggregatePanel.offline_panels
                                            ).format('0,0')}
                                            <small className="ml-1">offline</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 border-left border-right pb-4 ps-3">
                                <h6 className="widget-label">Units</h6>
                                <p className="widget-data">
                                    {numeral(aggregatePanel.active_units).format('0,0')}
                                </p>
                            </div>
                            <div className="col-md-3 pb-4 pt-4 pt-md-0 ps-md-3">
                                <h6 className="widget-label">Credentials</h6>
                                <p className="widget-data">
                                    {numeral(aggregatePanel.active_credentials).format(
                                        '0,0'
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ panel }) => {
    const { aggregatePanel } = panel;
    return { aggregatePanel };
};

export default withRouter(connect(mapStateToProps, {})(WidgetContainer));
