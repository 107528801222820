import { all, fork, takeLatest, put } from 'redux-saga/effects';
import { apiPost, apiPatch } from '../../api';
import { timeStampNow, toFirebaseTimestamp } from '../../config/Firebase';
import { generateUid, getTimestampDifferenceInDays } from '../../utils/Helpers';
import {
    validateInviteFailure,
    validateInviteSuccess,
    setInviteExpirationStatus,
    declineInviteFailure,
    declineInviteSuccess,
    acceptInviteSuccess,
    acceptInviteFailure
} from '../actions/Invites';

import {
    ADD_INVITE_TO_ORG,
    VALIDATE_INVITE,
    DECLINE_INVITE,
    ACCEPT_INVITE
} from '../actions/types';
import { addNewOrgManagerDocument, getExistingAndNewUsers } from './Managers';

import { rtdb } from '../../config/Firebase';
import { sendConfirmEmail } from './Org';
import { addInviteToOrgFailure, addInviteToOrgSuccess } from '../actions/Org';
import { loginUserEmailPassword } from './Auth';

const getRtdbInvitesRef = orgId => rtdb.ref(`invites/${orgId}/invitations`);

export function* acceptInvite({ payload }) {
    try {
        const {
            declined_at,
            email,
            first_name,
            invite_id,
            invited_at,
            last_name,
            type,
            password,
            org_id,
            authUser
        } = payload;

        yield* addNewOrgManagerDocument({ payload: { memberInfo: payload, org_id } });

        if (!authUser) {
            yield* loginUserEmailPassword({
                payload: {
                    email,
                    password
                }
            });
        }

        const updateResult = yield updateOrgInvitations(org_id, {
            [invite_id]: {
                accepted: true,
                accepted_at: timeStampNow(),
                declined_at,
                email,
                existing: true,
                first_name,
                invite_id,
                invited_at,
                last_name,
                status: 'accepted',
                type
            }
        });

        if (updateResult && !updateResult.success) {
            yield put(acceptInviteFailure(updateResult.error));
            return;
        }

        yield put(acceptInviteSuccess());
    } catch (error) {
        console.log(error);
        yield put(acceptInviteFailure(error));
    }
}

export function* declineInvite({ payload }) {
    try {
        const { orgId, invite_id, inviteCode, user_id, type } = payload;
        const inviter_Id = type === 'guest' ? user_id : orgId;
        const res = yield apiPatch(`/invites/${inviter_Id}/decline-invitation`, {
            invite_code: inviteCode,
            invitation_id: invite_id
        });

        if (res && res.error) {
            yield put(declineInviteFailure(res.error.response.data));
        }
        yield put(declineInviteSuccess());
    } catch (error) {
        yield put(declineInviteFailure(error));
    }
}

export function* validateNewUserInvite({ payload }) {
    try {
        const { orgId, inviteId, inviteCode } = payload;
        const res = yield apiPost(`/invites/${orgId}/invitations/${inviteId}`, {
            invite_code: inviteCode
        });

        if (res && res.error) {
            yield put(validateInviteFailure(res.error.response.data));
        } else {
            const { status, invited_at, accepted_at } = res.data;
            const expired =
                getTimestampDifferenceInDays(
                    timeStampNow().toMillis(),
                    toFirebaseTimestamp(invited_at).toMillis()
                ) > 5;

            if (expired) {
                yield put(setInviteExpirationStatus('expired'));
                return;
            }

            if (status === 'accepted' || accepted_at) {
                yield put(setInviteExpirationStatus('accepted'));
                return;
            }

            yield put(validateInviteSuccess(res.data));
        }
    } catch (error) {
        validateInviteFailure(error);
    }
}

export async function updateOrgInvitations(orgId, invitations) {
    try {
        const invitationsRef = getRtdbInvitesRef(orgId);
        let inviteUpdateError = null;
        const setUpdateError = err => {
            inviteUpdateError = { success: false, error: err };
        };

        for (const invite in invitations) {
            await invitationsRef
                .child(invite)
                .set(invitations[invite])
                .catch(setUpdateError);
        }

        if (!inviteUpdateError) {
            return { success: true };
        }

        return inviteUpdateError;
    } catch (err) {
        return { success: false, error: err };
    }
}

export function* createNewUserInvite({ payload }) {
    try {
        const { memberInfo, org_id, org_name, inviteCode } = payload;
        const { newUsers, existingUsers } = yield getExistingAndNewUsers([memberInfo]);
        const invitations = {};

        newUsers.forEach(user => {
            const inviteId = generateUid();
            const nonExistingData = {
                accepted: false,
                accepted_at: null,
                declined_at: null,
                email: user.email,
                existing: false,
                first_name: user.first_name,
                invite_id: inviteId,
                invited_at: timeStampNow(),
                last_name: user.last_name,
                status: 'pending',
                type: user.type
            };
            invitations[inviteId] = nonExistingData;
        });
        existingUsers.forEach(user => {
            const inviteId = generateUid();
            const existingData = {
                accepted: false,
                accepted_at: null,
                declined_at: null,
                email: user.email,
                existing: true,
                first_name: user.first_name,
                invite_id: inviteId,
                invited_at: timeStampNow(),
                last_name: user.last_name,
                status: 'pending',
                type: user.type
            };
            invitations[inviteId] = existingData;
        });

        const updateResult = yield updateOrgInvitations(org_id, invitations);

        if (updateResult && !updateResult.success) {
            yield put(addInviteToOrgFailure(updateResult.error));
            return;
        }

        const recipientsList = [];

        for (const invite in invitations) {
            const { first_name, last_name, email, invite_id, type } = invitations[invite];
            recipientsList.push({
                email,
                org_name,
                invitee_name: `${first_name} ${last_name}`,
                authLink: `https://sagesystems.io/accept-invitation?inviteCode=${inviteCode}&inviteId=${invite_id}&email=${email}&firstName=${first_name}&lastName=${last_name}&orgId=${org_id}&orgName=${org_name}`,
                type: type === 'manager' ? 'Community' : 'Rental'
            });
        }

        const sentEmailInvitations = yield sendConfirmEmail(recipientsList);
        // console.log(sentEmailInvitations);
        if (sentEmailInvitations && sentEmailInvitations.success) {
            yield put(addInviteToOrgSuccess(memberInfo.email));
        }
    } catch (error) {
        console.log('Error', error);
        yield put(addInviteToOrgFailure(error));
    }
}

export function* acceptInvitation() {
    yield takeLatest(ACCEPT_INVITE, acceptInvite);
}

export function* declineInvitation() {
    yield takeLatest(DECLINE_INVITE, declineInvite);
}

export function* addNewInvitation() {
    yield takeLatest(ADD_INVITE_TO_ORG, createNewUserInvite);
}

export function* validateInvitation() {
    yield takeLatest(VALIDATE_INVITE, validateNewUserInvite);
}

export default function* rootSaga() {
    yield all([
        fork(validateInvitation),
        fork(addNewInvitation),
        fork(declineInvitation),
        fork(acceptInvitation)
    ]);
}
