import React, { useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmFooter from '../common/ConfirmFooter';

import { Button, Container } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import { PasswordFieldFeedback } from '../AcceptInvitation/FirstAccessFormNew';
import { verifyAuth } from '../../store/sagas/Auth';
import {
    resetUpdateAuthPasswordState,
    updateAuthUserPassword
} from '../../store/actions/Auth';
import Loader from '../common/Loader';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const ResetPasswordForm = ({ authUser, updating, success }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        current_password: Yup.string()
            .trim()
            .required('"Current Password" is a required field'),
        new_password: Yup.string().trim().required('"New Password" is a required field')
    });

    useEffect(() => {
        if (!updating && success) {
            setLoading(false);
        }
    }, [updating, success]);

    const submitForm = async formValues => {
        setLoading(true);
        const isVerified = await verifyAuth(authUser.email, formValues.current_password);

        if (isVerified && isVerified.message) {
            setError(isVerified.message);
            setLoading(false);
        }
        if (isVerified && isVerified.user) {
            dispatch(updateAuthUserPassword(formValues.new_password));
        }
    };

    if ((updating && !success) || loading) {
        return (
            <Container className="d-flex flex-column align-items-center flex-grow-1 justify-content-center">
                <Loader />
            </Container>
        );
    }

    if (success) {
        return (
            <Container className="space-2 col-small">
                <div
                    className="w-md-75 mx-md-auto form-round bg-white p-2"
                    style={{ minHeight: '50vh' }}
                >
                    <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                        <img src="../../assets/sage-icon.png" alt="logo" width={80} />
                        <div className="d-flex w-md-75 flex-column mb-2 align-items-center justify-content-center">
                            <div
                                style={{ fontSize: '32px' }}
                                className="d-flex text-center align-items-center justify-content-center text-dark-gray font-weight-semi-bold my-3"
                            >
                                Success!
                            </div>
                            <div
                                style={{ fontSize: '16px' }}
                                className="d-flex text-center align-items-center justify-content-center text-muted font-weight-normal my-3"
                            >
                                Your password has been changed.
                            </div>
                        </div>
                        <Button
                            color="primary"
                            className="transition-3d-hover m-3 font-weight-semi-bold mt-5 btn-wide"
                            onClick={() => {
                                dispatch(resetUpdateAuthPasswordState());
                                history.goBack();
                            }}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Container>
        );
    }

    return (
        <Container className="d-flex flex-column align-items-start flex-grow-1 justify-content-start">
            <Formik
                initialValues={{
                    current_password: '',
                    new_password: ''
                }}
                onSubmit={submitForm}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({
                    handleSubmit,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    values
                }) => (
                    <Form className="col-6 mx-auto">
                        <div className="row">
                            <div className="pl-6 mb-4 row mt-4">
                                <h3 className="font-weight-semi-bold">Reset Password</h3>
                                {error && (
                                    <span
                                        style={{
                                            width: '100%',
                                            marginTop: '0.25rem',
                                            fontSize: '80%',
                                            color: '#de4437'
                                        }}
                                    >
                                        {error}
                                    </span>
                                )}
                            </div>
                            <div className="col-12">
                                <div className="row m-auto">
                                    <div className="js-form-message col-12 form-group">
                                        <LabeledFormField
                                            fieldTitle="current password"
                                            required
                                            type="password"
                                            className="form-control"
                                            name="current_password"
                                            id="current_password"
                                            placeholder=""
                                            aria-label="current_password"
                                            error={returnError(
                                                errors,
                                                'current_password'
                                            )}
                                            value={values.current_password}
                                            onBlur={handleBlur('current_password')}
                                            onChange={handleChange('current_password')}
                                            invalid={
                                                returnTouched(
                                                    touched,
                                                    'current_password'
                                                ) &&
                                                !!returnError(errors, 'current_password')
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-12 form-group">
                                        <LabeledFormField
                                            fieldTitle="new password"
                                            required
                                            type="password"
                                            className="form-control"
                                            name="new_password"
                                            id="new_password"
                                            placeholder=""
                                            aria-label="new_password"
                                            error={returnError(errors, 'new_password')}
                                            value={values.new_password}
                                            onBlur={handleBlur('new_password')}
                                            onChange={handleChange('new_password')}
                                            invalid={
                                                returnTouched(touched, 'new_password') &&
                                                !!returnError(errors, 'new_password')
                                            }
                                            noFeedback
                                        />
                                    </div>
                                </div>
                                <div className="row m-auto">
                                    <div className="js-form-message col-12 form-group">
                                        {PasswordFieldFeedback({
                                            password: values.new_password
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <ConfirmFooter
                                    cancel={() => history.goBack()}
                                    submitFunc={handleSubmit}
                                    submitText="Save"
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

const mapStateToProps = ({
    auth: { authUser, updatingPassword, updatingPasswordSuccess }
}) => {
    return { authUser, updating: updatingPassword, success: updatingPasswordSuccess };
};

export default connect(mapStateToProps)(ResetPasswordForm);
