import React from 'react';
import { AcceptInvitation } from '../components';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AcceptInvitationPage() {
    const query = useQuery();
    const inviteCode = query.get('inviteCode');
    const inviteId = query.get('inviteId');
    const email = query.get('email');
    const firstName = query.get('firstName');
    const lastName = query.get('lastName');
    const orgId = query.get('orgId');
    const orgName = query.get('orgName');

    return (
        <Container className="space-1">
            <AcceptInvitation
                queryData={{
                    inviteCode,
                    inviteId,
                    email,
                    firstName,
                    lastName,
                    orgId,
                    orgName
                }}
                code={inviteCode}
                email={email}
            />
        </Container>
    );
}
