import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NumberFormat from 'react-number-format';
import { useWindowSize } from '../../hooks/useWindowSize';
import Loader from '../common/Loader';

const ContactList = ({
    orgId,
    modalOpen,
    inviteHandler,
    invited,
    imported,
    searchString,
    filteredContacts
}) => {
    const { windowHeight } = useWindowSize();

    const getDateFromTimestamp = timestamp => {
        const date = new Date(timestamp * 1000);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yy = date.getFullYear().toString().substr(2, 2);
        const time = date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        });

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return `${mm}-${dd}-${yy}, ${time}`;
    };

    return (
        <div style={{ height: [windowHeight] - 450, overflowY: 'auto' }}>
            {filteredContacts !== null && (
                <InfiniteScroll dataLength={filteredContacts.length} loader={<Loader />}>
                    {filteredContacts.map(contact => {
                        const {
                            id,
                            address,
                            email,
                            first_name,
                            last_name,
                            phone,
                            role,
                            registered_at
                        } = contact;
                        return (
                            <div
                                key={id}
                                onClick={() => modalOpen(contact)}
                                className="contact-list-item"
                            >
                                <div className="col-2">
                                    <span className="d-block mb-0 text-capitalize">{`${last_name}, ${first_name}`}</span>
                                    <small className="d-block text-muted">
                                        {address}
                                    </small>
                                </div>
                                <div className="col-2">
                                    <small>
                                        <NumberFormat
                                            value={phone ? phone : ''}
                                            displayType={'text'}
                                            format="###-###-####"
                                            mask="_"
                                        />
                                    </small>
                                </div>
                                <div className="col-2">
                                    <small>{email}</small>
                                </div>
                                <div className="col-1">
                                    <small>{role ? role : 'resi'}</small>
                                </div>
                                <div className="col-2">
                                    <small>
                                        {registered_at
                                            ? getDateFromTimestamp(registered_at)
                                            : ''}
                                    </small>
                                </div>
                                <div className="col-1">
                                    <div
                                        onClick={e => {
                                            e.stopPropagation();
                                            inviteHandler(contact);
                                        }}
                                        className={`invite-badge ${
                                            invited
                                                .map(contact => contact.id)
                                                .includes(id)
                                                ? 'badge-green'
                                                : 'badge-gray'
                                        }`}
                                    >
                                        <i
                                            className="fa fa-check-circle mr-2 "
                                            aria-hidden="true"
                                        />
                                        Select
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </InfiniteScroll>
            )}
        </div>
    );
};

export default ContactList;
