import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = props => {
    return <span className="button-spinner-border" role="status" aria-hidden="true" />;
};

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
    type: 'grow',
    size: 'lg',
    color: 'primary'
};

export default Loader;
