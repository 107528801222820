import React from 'react';
import { Button } from 'reactstrap';

const ContentTabHeader = ({ org_id, org_name, toggleOpen }) => {
    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex flex-column">
                <h4 className="mb-0">{org_name}</h4>
                <p className="font-size-1 text-muted mb-0">{org_id}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <Button
                    color="primary"
                    type="button"
                    className="transition-3d-hover btn-sm btn-primary m-4"
                    onClick={toggleOpen}
                >
                    New Post
                </Button>
            </div>
        </div>
    );
};

export default ContentTabHeader;
