import React from 'react';
import { Button, Container, Col } from 'reactstrap';

const AddGuestSuccess = ({ error, resetForm }) => {
    const heading = error ? `Hmmm, thats awkward...` : `Success!`;
    const message = error
        ? `There was an issue while trying to add your guest.  Please double check your information and try submitting again.  If this issue persists, please contact your Community Manager for further assistance.`
        : `Your guest has been invited to access the community!`;
    const button = error ? `Try again` : `Add Another Guest`;

    return (
        <Container className="space-1">
            <Col>
                <div className="w-md-75 mx-md-auto card bg-white form-shadow p-2">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div className="mb-3">
                            <div
                                style={{ fontSize: '42px' }}
                                className="d-flex justify-content-center text-muted font-weight-semi-bold mt-3 mb-8"
                            >
                                {heading}
                            </div>
                            <div
                                style={{ fontSize: '20px' }}
                                className="d-flex justify-content-center font-weight-normal mt-5 mb-5 px-5"
                            >
                                {message}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-2 mb-4">
                        <div
                            style={{ flex: 1 }}
                            className="d-flex justify-content-center m-2"
                        >
                            <Button
                                color="primary"
                                onClick={() =>
                                    error ? resetForm('soft') : resetForm('hard')
                                }
                            >
                                {button}
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>
        </Container>
    );
};

export default AddGuestSuccess;
