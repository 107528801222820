// React
import React from 'react';

// Packages
import Select from 'react-select';

const DropdownIndicator = () => {
    return <span className="select-icon" />;
};

const IndicatorSeparator = () => {
    return null;
};

const SelectField = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    onChange,
    options,
    classNamePrefix,
    styles,
    value
}) => {
    return (
        <Select
            autoComplete="off"
            required={required}
            type={type}
            className={className}
            classNamePrefix={classNamePrefix}
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            styles={styles}
            components={{
                DropdownIndicator,
                IndicatorSeparator
            }}
        />
    );
};

export default SelectField;

/**
 *
 *
 * // React
import React from 'react';

// Packages
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';

const SelectField = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    myStyle,
    options,
    touched
}) => {
    return (
        <div style={myStyle}>
            <Field
                autoComplete="off"
                required={required}
                type={type}
                className={className}
                style={{
                    borderColor:
                        error && touched
                            ? '#de4437'
                            : value
                            ? 'rgba(0, 201, 167, 0.5)'
                            : ''
                }}
                name={name}
                id={id}
                placeholder={placeholder}
                error={error}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                as="select"
            >
                <option value="">Select</option>

                {options.map(option => (
                    <option key={option.name} value={option.value}>
                        {`${option.name}`}
                    </option>
                ))}
            </Field>
            <FormFeedback style={{ display: error && touched ? 'block' : 'none' }}>
                {error}
            </FormFeedback>
        </div>
    );
};

export default SelectField;
 */
