import { ADD_GUEST_INVITES, ADD_GUEST_INVITES_SUCCESS } from './types';

export const addGuestInvites = () => {
    return {
        type: ADD_GUEST_INVITES
    };
};

export const addGuestInvitesSuccess = () => {
    return {
        type: ADD_GUEST_INVITES_SUCCESS
    };
};
