// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { FormFeedback, Label } from 'reactstrap';

import { countries } from '../../utils/Constants';
import TextField from './TextField';

const ContryPhoneCodeSelect = ({
    fieldTitle,
    name,
    type,
    id,
    required,
    disabled,
    onChangePhone,
    onBlurPhone,
    onChangeCode,
    onChangeCountry,
    note,
    className,
    countryValue,
    codeValue,
    phoneValue,
    codeError,
    phoneError,
    phoneTouched,
    codeTouched,
    errorSectionMinHeight,
    invalid
}) => {
    const selectedCountry = countryValue
        ? countries.find(country => countryValue === country.value)
        : countries[0];
    const composedCodeValue = `${selectedCountry.flagIcon} +${selectedCountry.phoneCode}`;

    return (
        <div className="js-form-message d-flex flex-grow-1 w-100 flex-column form-group">
            <Label
                className={`form-field-label d-flex flex-row ${disabled && 'text-muted'}`}
                for={name}
            >
                {required ? (
                    <>
                        {fieldTitle}
                        <span style={{ color: 'red', fontSize: '11px' }}>&nbsp;*</span>
                    </>
                ) : (
                    fieldTitle
                )}
            </Label>
            <div className="d-flex flex-row justify-content-center align-items-center">
                <Field
                    disabled={disabled}
                    autoComplete="off"
                    type={type}
                    className={`${className} mr-2`}
                    style={{
                        borderColor:
                            codeError && codeTouched
                                ? '#de4437'
                                : codeValue
                                ? 'rgba(0, 201, 167, 0.5)'
                                : '',
                        flex: 0.8
                    }}
                    name="code"
                    error={codeError}
                    value={composedCodeValue}
                    onChange={e => {
                        const val = e.target.value;
                        const [flag, code] = val.split(' ');
                        const countryCode = Number.parseInt(code.substring(1));
                        const country = countries.find(
                            country => flag === country.flagIcon
                        );
                        if (country) {
                            onChangeCountry(country.value);
                            onChangeCode(countryCode);
                        }
                    }}
                    as="select"
                >
                    {countries.map(country => {
                        const { flagIcon, phoneCode } = country;
                        return (
                            <option key={flagIcon}>{`${flagIcon} +${phoneCode}`}</option>
                        );
                    })}
                </Field>
                <div style={{ display: 'flex', flex: 3 }}>
                    <TextField
                        disabled={disabled}
                        autoComplete="off"
                        required={required}
                        type={type}
                        className={`${className} w-100`}
                        style={{
                            borderColor:
                                phoneError && phoneTouched
                                    ? '#de4437'
                                    : phoneValue
                                    ? 'rgba(0, 201, 167, 0.5)'
                                    : ''
                        }}
                        name={name}
                        id={id}
                        placeholder="123-456-7890"
                        error={phoneError}
                        value={phoneValue}
                        onBlur={onBlurPhone}
                        onChange={onChangePhone}
                        invalid={invalid}
                        noFeedback
                    />
                </div>
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="mr-2 pr-2" style={{ flex: 1.05 }} />
                <div
                    style={{
                        display: 'flex',
                        flex: 3,
                        minHeight: errorSectionMinHeight || '40px'
                    }}
                >
                    <FormFeedback
                        style={{ display: phoneError && phoneTouched ? 'block' : 'none' }}
                    >
                        {phoneError}
                    </FormFeedback>
                </div>
            </div>
            {note && <p className="text-muted text-note mb-0">{note}</p>}
        </div>
    );
};

export default ContryPhoneCodeSelect;
