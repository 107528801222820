import React from 'react';
import Imported from './Imported';
import Members from './Members';
import Pending from './Pending';
import Residents from './Residents';
import Otc from './Otc';
// import Vendors from './Vendors';

const TabSwitch = ({ tab, tabProps }) => {
    return (
        <div className="mx-3 w-100">
            {tab === 'Residents' && <Residents selectedTab={tab} {...tabProps} />}
            {/* {tab === 'Vendors' && <Vendors selectedTab={tab} {...tabProps} />} */}
            {tab === 'Members' && <Members selectedTab={tab} {...tabProps} />}
            {tab === 'Invite Requests' && <Imported selectedTab={tab} {...tabProps} />}
            {tab === 'Pending' && <Pending selectedTab={tab} {...tabProps} />}
            {tab === 'Codes' && <Otc selectedTab={tab} {...tabProps} />}
        </div>
    );
};

export default TabSwitch;
