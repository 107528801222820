import {
    STORE_CALLS_COLLECTION,
    FIELD_PANEL_CALL,
    END_PANEL_CALL,
    FIELD_PANEL_CALL_SUCCESS,
    END_PANEL_CALL_SUCCESS,
    FIELD_PANEL_CALL_FAILURE,
    END_PANEL_CALL_FAILURE,
    ALLOW_GATE_ACCESS,
    ALLOW_GATE_ACCESS_SUCCESS,
    ALLOW_GATE_ACCESS_FAILURE
} from './types';

export const storeCallsCollection = calls => {
    return {
        type: STORE_CALLS_COLLECTION,
        payload: calls
    };
};

export const fieldPanelCall = data => {
    return {
        type: FIELD_PANEL_CALL,
        payload: data
    };
};

export const endPanelCall = data => {
    return {
        type: END_PANEL_CALL,
        payload: data
    };
};

export const fieldPanelCallSuccess = () => {
    return {
        type: FIELD_PANEL_CALL_SUCCESS
    };
};

export const endPanelCallSuccess = () => {
    return {
        type: END_PANEL_CALL_SUCCESS
    };
};

export const fieldPanelCallFailure = error => {
    return {
        type: FIELD_PANEL_CALL_FAILURE,
        payload: error
    };
};

export const endPanelCallFailure = error => {
    return {
        type: END_PANEL_CALL_FAILURE,
        payload: error
    };
};

export const allowGateAccess = data => {
    return {
        type: ALLOW_GATE_ACCESS,
        payload: data
    };
};

export const allowGateAccessSuccess = () => {
    return {
        type: ALLOW_GATE_ACCESS_SUCCESS
    };
};

export const allowGateAccessFailure = error => {
    return {
        type: ALLOW_GATE_ACCESS_FAILURE,
        payload: error
    };
};
