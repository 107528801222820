// React
import React, { useCallback, useEffect, useState, useMemo } from 'react';

// Components
import Participant from './Participant';
import CallController from './CallController';
import ContryPhoneCodeSelect from '../common/CountryPhoneCodeSelect';
import LabeledFormField from '../AcceptInvitation/LabeledFormField';
import ButtonLoader from '../common/ButtonLoader';
import NumberFormat from 'react-number-format';

// Firebase
import { timeStampNow } from '../../config/Firebase';

// Packages
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import { Notyf } from 'notyf';
import { useHistory } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { endPanelCall, allowGateAccess } from '../../store/actions/Calls';
import {
    createAccessAPICall,
    createAccessAPINewVerification,
    createAccessAPIVerifyNumber,
    createAccessAPIStartOver
} from '../../store/actions/ConfirmReservation';
import { createTenantAccess, addReservation } from '../../store/actions/Reservations';

// Router
import * as routes from '../../router/config/routes';

// Utils
import { nameRegExp } from '../../utils/Constants';
import { tsFromJsDate } from '../../utils/Helpers';

const returnError = (errors, field) => errors[field];

const returnTouched = (touched, field) => touched[field];

const Room = ({
    panelName,
    room,
    setRoom,
    call,
    userData,
    endPanelCall,
    allowGateAccess,
    orgName,
    reservations,
    residents,
    createAccessAPICall,
    existingAccount,
    verifyCode,
    verified,
    createAccessAPINewVerification,
    accessCreated,
    createTenantAccess,
    createAccessAPIVerifyNumber,
    createAccessAPIStartOver,
    addingTenantSuccess,
    loading,
    accessGranted,
    error,
    active,
    panels,
    addReservation,
    addReservationSuccess,
    firstName,
    lastName,
    userId,
    orgData,
    guardReservationId
}) => {
    const [activePanel, setActivePanel] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [muteAudio, setMuteAudio] = useState(false);
    const [register, setRegister] = useState(null);
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [focused, setFocused] = useState(null);
    const [accessEnabled, setAccessEnabled] = useState(false);
    const [confirmAccessEnabled, setConfirmAccessEnabled] = useState(false);
    const [confirmState, setConfirmState] = useState('res_list');
    const [existing, setExisting] = useState(null);
    const searchList = [...residents, ...reservations];
    const [verificationObject, setVerificationObject] = useState(null);
    const [consumerId, setConsumerId] = useState(null);
    const [guest, setGuest] = useState({ companyName: null, visitorName: null });
    const history = useHistory();
    const sortedPanels =
        panels && panels.length ? panels.sort((a, b) => a.index - b.index) : [];

    const listHeight = window?.screen?.height ? window.screen.height * 0.43 : 0;

    const isVirtualGuard = userId && userId === 'txzMjUEqNPaIuU7DNMeGCmA3Rt53';

    const notyf = useMemo(
        () =>
            new Notyf({
                duration: 10000,
                position: { x: 'right', y: 'top' },
                className: 'pointer',
                types: [
                    {
                        type: 'success',
                        icon: {
                            className: 'fa-solid fa-check pointer',
                            tag: 'i',
                            color: '#ffffff',
                            backgroundColor: '#3388ff'
                        }
                    },
                    {
                        type: 'error',
                        icon: {
                            className: 'fa-solid fa-xmark pointer',
                            tag: 'i',
                            color: '#ffffff',
                            backgroundColor: '#DE4437'
                        }
                    }
                ]
            }),

        []
    );

    const validatePhone = ({ code, number, country }) => {
        const phoneSchema = yup.string().phone(country).required();
        const isValid = phoneSchema.isValidSync(code + number);
        return isValid ? undefined : { number: 'A valid phone number is required' };
    };

    const validateFocused = ({ companyName, visitorName }) => {
        setGuest({ companyName, visitorName });
        if (
            (!visitorName && isVirtualGuard && activePanel) ||
            (visitorName && visitorName.trim() === '' && isVirtualGuard && activePanel) ||
            (visitorName && visitorName.trim() !== '' && activePanel)
        ) {
            if (!accessEnabled) {
                setAccessEnabled(true);
            }
        } else {
            setAccessEnabled(false);
        }
    };

    const submit = values => {
        createAccessAPICall({ values, invite: focused });
    };

    const verifySubmit = values => {
        createAccessAPIVerifyNumber({
            ...verificationObject,
            code: verifyCode
        });
    };

    const createResSubmit = values => {
        const tnFloor = new Date();
        tnFloor.setHours(0);
        tnFloor.setMinutes(0);
        tnFloor.setSeconds(0);

        const tnCiel = new Date();
        tnCiel.setHours(23);
        tnCiel.setMinutes(59);
        tnCiel.setSeconds(59);

        const tnAddTen = new Date(tnCiel);
        tnAddTen.setDate(tnAddTen.getDate() + 10);

        const reservation = {
            access_begins: tsFromJsDate(tnFloor),
            access_created: null,
            access_days: null,
            access_end_time: null,
            access_expires: tsFromJsDate(tnAddTen),
            access_start_time: null,
            active: true,
            address: {
                address_1: focused.address || null,
                address_2: null,
                city: null,
                latitude: null,
                longitude: null,
                state: null,
                zip: null
            },
            check_in_date: tnFloor.toDateString(),
            check_in_time: { hours: 0, minutes: 0 },
            check_out_date: tnAddTen.toDateString(),
            check_out_time: { hours: 23, minutes: 59 },
            company_name: null,
            confirmation: null,
            consumer_id: null,
            created_at: timeStampNow(),
            key_id: null,
            image: null,
            invite_id: null,
            invite_status: null,
            invited_at: null,
            creator_first_name: firstName,
            creator_id: userId,
            creator_last_name: lastName,
            email: null,
            event_id: null,
            favorite: false,
            first_name: values.firstName,
            invite_code: null,
            last_name: values.lastName,
            org_id: orgData.org_id,
            org_name: orgData.org_name,
            phone: {
                code: values.code,
                country: values.country,
                number: parseInt(values.number)
            },
            phone_number: `${values.code}${values.number}`,
            reservation_id: null,
            role: 'tenant',
            suspended: false,
            type: 'short-term',
            validated: false
        };

        setFocused({ ...reservation, tenant: true });
        setRegister(null);
        addReservation({ data: [reservation], guard: true });
    };

    // May need this in future (Twilio SMS phone verification code)
    // const resendCode = async () => {
    //     // const isSent = await createVeryficationForMobileNumber(resendVerificationObj);
    //     // if (!isSent?.data?.message && !isSent?.error) {
    //     //     setSent(true);
    //     // }
    // };

    const verifyValidationSchema = yup.object().shape({
        verificationCode: yup.string().trim().required('Verification code required')
    });

    const validationSchema = yup.object().shape({
        country: yup.string().trim(),
        number: yup.string().trim(),
        code: yup.string().trim().required()
    });

    const createResValidationSchema = yup.object().shape({
        country: yup.string().trim(),
        number: yup.string().trim(),
        code: yup.string().trim().required(),
        firstName: yup
            .string()
            .trim()
            .matches(nameRegExp.format, 'Please enter a valid first name')
            .required('A first name is required to create a reservation'),
        lastName: yup
            .string()
            .trim()
            .matches(nameRegExp.format, 'Please enter a valid first name')
            .required('A last name is required to create a reservation')
    });

    const focusedValidationSchema = yup.object().shape({
        companyName: yup.string().trim(),
        visitorName: isVirtualGuard
            ? yup.string().trim().matches(nameRegExp.format, 'Please enter a valid name')
            : yup
                  .string()
                  .trim()
                  .matches(nameRegExp.format, 'Please enter a valid name')
                  .required('A name is required before granting access')
    });

    const handleReset = () => {
        setFocused(null);
        setConsumerId(null);
        setExisting(null);
        setRegister(null);
        setConfirmState('res_list');
        createAccessAPIStartOver();
        setAccessEnabled(false);
        setSearchSuggestions([]);
        setGuest({ companyName: null, visitorName: null });
        setActivePanel(null);
    };

    const reservationLastNameSearch = input => {
        if (input.trim() !== '') {
            if (!isNaN(input[0]) || input.includes('-')) {
                // Address
                const filteredName = () => {
                    const nameSuggestions = [];
                    searchList.filter(
                        ({
                            first_name,
                            last_name,
                            check_in_date,
                            access_begins,
                            active,
                            reservation_id,
                            role,
                            uid,
                            phone,
                            address,
                            validated
                        }) => {
                            if (
                                typeof address === 'string' &&
                                address.toLowerCase().includes(input.toLowerCase())
                            ) {
                                if (active || role === 'resi') {
                                    nameSuggestions.push({
                                        first_name,
                                        last_name,
                                        check_in_date: check_in_date || null,
                                        utc_seconds: access_begins?.seconds || null,
                                        id: reservation_id || uid,
                                        phone,
                                        address,
                                        validated,
                                        res: reservation_id ? true : false
                                    });
                                }
                            }
                            return true;
                        }
                    );
                    return nameSuggestions;
                };

                const suggestions = filteredName();

                const sorted = suggestions.sort((a, b) => {
                    return a.utc_seconds - b.utc_seconds;
                });

                setSearchSuggestions(sorted);
            } else {
                // Last Name
                const filteredName = () => {
                    const nameSuggestions = [];
                    searchList.filter(
                        ({
                            first_name,
                            last_name,
                            check_in_date,
                            access_begins,
                            active,
                            reservation_id,
                            role,
                            uid,
                            phone,
                            address,
                            validated
                        }) => {
                            if (last_name.toLowerCase().includes(input.toLowerCase())) {
                                if (active || role === 'resi') {
                                    nameSuggestions.push({
                                        first_name,
                                        last_name,
                                        check_in_date: check_in_date || null,
                                        utc_seconds: access_begins?.seconds || null,
                                        id: reservation_id || uid,
                                        phone,
                                        address,
                                        validated,
                                        res: reservation_id ? true : false
                                    });
                                }
                            }
                            return true;
                        }
                    );
                    return nameSuggestions;
                };

                const suggestions = filteredName();

                const sorted = suggestions.sort((a, b) => {
                    return a.utc_seconds - b.utc_seconds;
                });

                setSearchSuggestions(sorted);
            }
        } else {
            setSearchSuggestions([]);
        }
    };

    const handleSelectedReservation = id => {
        const res = reservations.filter(res => res.reservation_id === id)[0];
        setFocused({ ...res, tenant: true });
        if (res.validated) {
            const { first_name, last_name, email, phone, phone_number, reservation_id } =
                res;
            setRegister({
                first_name,
                last_name,
                email,
                phone,
                phone_number,
                id: reservation_id
            });
            setConsumerId(reservation_id);
            setConfirmState('res_focus');
        } else {
            setRegister(null);
            setConsumerId(res.reservation_id);
            setConfirmState('input_confirm');
        }
    };

    const handleSelectedMember = id => {
        const member = residents.filter(res => res.uid === id)[0];
        setFocused(member);
        setRegister(member);
        setConsumerId(member.uid);
        setConfirmState('res_focus');
    };

    // Send SMS Verification Code for phone number ownership
    const handleSmsVerification = () => {
        setConfirmState('validate_phone');
        createAccessAPINewVerification({
            creatingNew: true,
            country: existing.phone?.country || 'US',
            code: existing.phone?.code || '1',
            number: existing.phone?.number || ''
        });
    };

    // Manual Operator Confirm
    const handleConfirmReservation = () => {
        createTenantAccess({
            ...focused,
            email: existing.email,
            password: '',
            operator: true
        });
    };

    useEffect(() => {
        const launchSuccessNotification = () =>
            notyf
                .success(`Success: Access has been granted!`)
                .on('click', ({ target, event }) => {
                    notyf.dismissAll();
                });

        const launchFailureNotification = () =>
            notyf
                .error(`Error: An error has occurred, the gate may not have opened.`)
                .on('click', ({ target, event }) => {
                    notyf.dismissAll();
                });

        if (accessGranted) {
            launchSuccessNotification();
        }
        if (error) {
            launchFailureNotification();
        }
    }, [error, accessGranted, notyf]);

    useEffect(() => {
        if (existingAccount) {
            setExisting(existingAccount);
            setVerificationObject({
                type: 'phone',
                value: `${existingAccount.phone.code}${existingAccount.phone.number}`,
                matched_user_id: existingAccount.uid
            });
            setConfirmState('existing_account');
        }
    }, [existingAccount, setConfirmState, setExisting]);

    useEffect(() => {
        if (verified && existingAccount) {
            createAccessAPICall({
                values: existingAccount.phone,
                invite: focused
            });
        }
        if (accessCreated) {
            setConsumerId(focused.reservation_id);
            setConfirmState('confirmed_success');
        }
        if (addingTenantSuccess) {
            setRegister(existing);
            setConsumerId(existing.uid);
            setConfirmState('confirmed_success');
        }
    }, [
        verified,
        focused,
        existing,
        existingAccount,
        createAccessAPICall,
        accessCreated,
        addingTenantSuccess,
        consumerId
    ]);

    useEffect(() => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);

        // Disable Local Video
        room.localParticipant.videoTracks.forEach(trackPublication => {
            trackPublication.track.disable();
        });

        return () => {
            if (room) {
                room.off('participantConnected', participantConnected);
                room.off('participantDisconnected', participantDisconnected);
                room.disconnect();
            }
        };
    }, [room]);

    useEffect(() => {
        if (active && active.length === 0) {
            history.replace(`${routes.AUTHENTICATED}${routes.DASHBOARD}`, {});
        }
    }, [active, history]);

    useEffect(() => {
        if (
            addReservationSuccess &&
            guardReservationId &&
            focused &&
            !focused?.reservation_id
        ) {
            setFocused({ ...focused, reservation_id: guardReservationId });
            setRegister(null);
            setConsumerId(guardReservationId);
            createAccessAPICall({ values: { ...focused.phone }, invite: focused });
        }
    }, [
        focused,
        addReservationSuccess,
        guardReservationId,
        setFocused,
        setRegister,
        setConsumerId,
        createAccessAPICall
    ]);

    useEffect(() => {
        return () => {
            if (call) endPanelCall({ panelCall: call, userData });
        };
    }, [endPanelCall, call, userData]);

    const handleAudioTrack = useCallback(
        mute => {
            if (mute) {
                room.localParticipant.audioTracks.forEach(trackPublication => {
                    trackPublication.track.disable();
                });
            } else {
                room.localParticipant.audioTracks.forEach(trackPublication => {
                    trackPublication.track.enable();
                });
            }
        },
        [room]
    );

    useEffect(() => {
        handleAudioTrack(muteAudio);
    }, [muteAudio, handleAudioTrack]);

    const handleGateAccess = () => {
        const data = activePanel
            ? {
                  callData: {
                      ...call,
                      panel_name: activePanel.name,
                      panel_id: activePanel.id
                  },
                  userData,
                  register,
                  consumerId: !consumerId ? userId : consumerId,
                  guest
              }
            : {
                  callData: { ...call },
                  userData,
                  register,
                  consumerId: !consumerId ? userId : consumerId,
                  guest
              };
        allowGateAccess({ ...data });
        handleReset();
    };

    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant} />
    ));

    const panelSelection = ({ companyName, visitorName }) => (
        <div className="mt-5">
            <Label className={`form-field-label d-flex flex-row`}>
                <>
                    {`Select Gate`}
                    <span style={{ color: 'red', fontSize: '11px' }}>&nbsp;*</span>
                </>
            </Label>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr'
                }}
            >
                {sortedPanels.map((panel, index) => (
                    <Button
                        key={panel.id}
                        type="button"
                        color={
                            activePanel && activePanel?.id === panel.id
                                ? 'primary'
                                : 'secondary'
                        }
                        className={`transition-3d-hover ${
                            index <= 2 ? 'mt-2' : 'mt-5'
                        } mr-2 btn`}
                        onClick={() => {
                            if (confirmState === 'confirmed_success') {
                                if (activePanel) {
                                    setActivePanel(null);
                                    setConfirmAccessEnabled(false);
                                } else {
                                    setActivePanel(panel);
                                    setConfirmAccessEnabled(true);
                                }
                            } else {
                                if (activePanel && activePanel?.id === panel.id) {
                                    setActivePanel(null);
                                    setAccessEnabled(false);
                                } else {
                                    setActivePanel(panel);
                                    if (
                                        (visitorName &&
                                            visitorName.trim() === '' &&
                                            isVirtualGuard) ||
                                        (visitorName && visitorName.trim() !== '') ||
                                        (!visitorName && isVirtualGuard)
                                    )
                                        setAccessEnabled(true);
                                }
                            }
                        }}
                    >
                        {panel.name}
                    </Button>
                ))}
            </div>
        </div>
    );

    const ConfirmationState = () => {
        switch (confirmState) {
            case 'res_focus':
                return (
                    <div className="col" style={{ animation: 'fadeIn .5s' }}>
                        <div className="d-flex flex-column">
                            <Formik
                                initialValues={{
                                    companyName: '',
                                    visitorName: ''
                                }}
                                onSubmit={submit}
                                validate={validateFocused}
                                validationSchema={focusedValidationSchema}
                                validateOnChange
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    setFieldValue,
                                    handleSubmit,
                                    touched,
                                    values
                                }) => (
                                    <>
                                        <Form className="d-flex col-12 justify-content-between align-items-center p-0">
                                            <LabeledFormField
                                                fieldTitle="visitor name"
                                                required={!isVirtualGuard}
                                                type="text"
                                                className="form-control col-10"
                                                name="visitorName"
                                                id="visitorName"
                                                placeholder={`Visitor name`}
                                                aria-label="visitorName"
                                                error={returnError(errors, 'visitorName')}
                                                value={values.visitorName}
                                                onBlur={handleBlur('visitorName')}
                                                onChange={handleChange('visitorName')}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        'visitorName'
                                                    ) &&
                                                    !!returnError(errors, 'visitorName')
                                                }
                                            />
                                            <LabeledFormField
                                                fieldTitle="company name"
                                                type="text"
                                                className="form-control col-10"
                                                name="companyName"
                                                id="companyName"
                                                placeholder={`Company name (if commercial)`}
                                                aria-label="companyName"
                                                error={returnError(errors, 'companyName')}
                                                value={values.companyName}
                                                onBlur={handleBlur('companyName')}
                                                onChange={handleChange('companyName')}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        'companyName'
                                                    ) &&
                                                    !!returnError(errors, 'companyName')
                                                }
                                            />
                                        </Form>

                                        {panelSelection({
                                            visitorName: values.visitorName,
                                            companyName: values.companyName
                                        })}

                                        {focused.role === 'resi' && (
                                            <Button
                                                style={{ width: '100%' }}
                                                type="button"
                                                color={'secondary'}
                                                className={`transition-3d-hover mt-5 btn`}
                                                onClick={() =>
                                                    setConfirmState('create_res')
                                                }
                                            >
                                                Create Reservation
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                );
            case 'res_list':
                return (
                    <div
                        style={{
                            animation: 'fadeIn .5s',
                            maxHeight: `${listHeight}px`,
                            overflow: 'hidden',
                            overflowY: 'scroll'
                        }}
                    >
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="fas fa-search" />
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name="text"
                                id="searchReservation"
                                placeholder="Search by Last Name or Address"
                                aria-label="search"
                                aria-describedby="searchReservation"
                                autoComplete="off"
                                onChange={e => reservationLastNameSearch(e.target.value)}
                            />
                        </div>
                        {searchSuggestions.length === 0 && (
                            <h2 className="h6 mb-0 text-muted text-center">
                                Start typing to find a reservation or member...
                            </h2>
                        )}
                        {searchSuggestions.map(
                            ({
                                first_name,
                                last_name,
                                check_in_date,
                                utc_seconds,
                                id,
                                address,
                                phone,
                                res,
                                validated
                            }) => (
                                <div
                                    key={id}
                                    className="org-item justify-content-evenly align-items-center"
                                >
                                    <div className="col-3">
                                        <h2
                                            className="h6 mb-0 text-nowrap text-truncate"
                                            style={{ textTransform: 'capitalize' }}
                                        >{`${last_name}${
                                            first_name ? ', ' + first_name : ''
                                        }`}</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className="h6 mb-0 text-nowrap text-truncate">
                                            {check_in_date ? check_in_date : address}
                                        </h2>
                                    </div>

                                    {res ? (
                                        <>
                                            <div className="d-flex col-3 justify-content-center align-items-center">
                                                <h2 className="h6 mb-0">{''}</h2>
                                            </div>
                                            <div className="d-flex col-3 pointer justify-content-start align-items-center pl-0">
                                                <Button
                                                    color={'primary'}
                                                    className="font-weight-semi-bold btn-block btn-sm"
                                                    onClick={() =>
                                                        handleSelectedReservation(id)
                                                    }
                                                >
                                                    {res && validated
                                                        ? 'Checked In'
                                                        : 'Confirm'}
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex col-3 justify-content-center align-items-center">
                                                <h2 className="h6 mb-0">{phone}</h2>
                                            </div>
                                            <div className="d-flex col-3 justify-content-start align-items-center pl-0">
                                                <Button
                                                    color={'primary'}
                                                    className="font-weight-semi-bold btn-block btn-sm"
                                                    onClick={() =>
                                                        handleSelectedMember(id)
                                                    }
                                                >
                                                    Resident
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )
                        )}
                        {isVirtualGuard &&
                            panelSelection({
                                visitorName: null,
                                companyName: null
                            })}
                    </div>
                );
            case 'input_confirm':
                return (
                    <div style={{ animation: 'fadeIn .5s' }}>
                        <Formik
                            initialValues={{
                                country: focused.phone?.country || 'US',
                                code: focused.phone?.code || '1',
                                number: focused.phone?.number || ''
                            }}
                            onSubmit={submit}
                            validate={validatePhone}
                            validationSchema={validationSchema}
                            validateOnChange
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                setFieldValue,
                                handleSubmit,
                                touched,
                                values
                            }) => (
                                <Form>
                                    <ContryPhoneCodeSelect
                                        required={true}
                                        fieldTitle="Mobile phone"
                                        type="text"
                                        className="form-control"
                                        name="number"
                                        id="number"
                                        aria-label="number"
                                        phoneError={returnError(errors, 'number')}
                                        phoneTouched={returnTouched(touched, 'number')}
                                        codeValue={values.code}
                                        phoneValue={values.number}
                                        countryValue={values.country}
                                        onBlurPhone={handleBlur('number')}
                                        onChangePhone={e => {
                                            handleChange('number')(e);
                                        }}
                                        invalid={touched.number && !!errors.number}
                                        onBlurCode={handleBlur('code')}
                                        onChangeCode={value =>
                                            setFieldValue('code', value)
                                        }
                                        onChangeCountry={value =>
                                            setFieldValue('country', value)
                                        }
                                    />
                                    <div className="d-flex row justify-content-end align-items-center my-5 mx-2">
                                        <Button
                                            disabled={touched.number && !!errors.number}
                                            type="submit"
                                            color="primary"
                                            className="transition-3d-hover mt-5 mr-2 btn"
                                            onClick={handleSubmit}
                                        >
                                            {loading && <ButtonLoader />}
                                            Confirm reservation
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                );
            case 'existing_account':
                return (
                    <div className="col" style={{ animation: 'fadeIn .5s' }}>
                        <h2 className="h5 font-weight-normal mb-1">
                            <span className="d-flex justify-content-start h5 font-weight-semi-bold">
                                Found Existing Account:
                            </span>
                        </h2>

                        <h6 className="mb-0" style={{ color: '#505050' }}>
                            {`${existing.last_name}, ${existing.first_name}`}
                        </h6>
                        <h6 className="mb-0" style={{ color: '#505050' }}>
                            {existing.email}
                        </h6>
                        <NumberFormat
                            value={existing.phone.number}
                            displayType={'text'}
                            format="###-###-####"
                            mask="_"
                        />
                        <h5 className="mb-0 small mt-5">
                            <i>
                                The phone number entered is currently associated with the
                                following account. Confirm this is the correct individual.
                                To transfer the phone number to a new individual, they
                                must validate by receiving a code via SMS. Standard rates
                                apply.{' '}
                            </i>
                        </h5>

                        <div className="d-flex justify-content-between align-items-center">
                            <Button
                                type="button"
                                color="primary"
                                className="transition-3d-hover mt-5 mr-2 btn"
                                onClick={handleConfirmReservation}
                            >
                                {loading && <ButtonLoader />}
                                Confirm reservation
                            </Button>
                            <Button
                                type="button"
                                color="secondary"
                                className="transition-3d-hover mt-5 mr-2 btn"
                                onClick={handleSmsVerification}
                            >
                                {loading && <ButtonLoader />}
                                Send SMS Verification
                            </Button>
                        </div>
                    </div>
                );
            case 'validate_phone':
                return (
                    <div style={{ animation: 'fadeIn .5s' }}>
                        <Formik
                            initialValues={{
                                verificationCode: ''
                            }}
                            onSubmit={verifySubmit}
                            validationSchema={verifyValidationSchema}
                            validateOnChange
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values
                            }) => (
                                <Form>
                                    <h2 className="h5 font-weight-normal mb-1">
                                        <span className="d-flex justify-content-start h5 font-weight-semi-bold">
                                            {`Guest Code: ${verifyCode}`}
                                        </span>
                                    </h2>
                                    <LabeledFormField
                                        type="text"
                                        className="form-control"
                                        name="verificationCode"
                                        id="verificationCode"
                                        placeholder={`Validate guest code: ${verifyCode}`}
                                        aria-label="verificationCode"
                                        error={returnError(errors, 'verificationCode')}
                                        value={values.verificationCode}
                                        onBlur={handleBlur('verificationCode')}
                                        onChange={handleChange('verificationCode')}
                                        invalid={
                                            returnTouched(touched, 'verificationCode') &&
                                            !!returnError(errors, 'verificationCode')
                                        }
                                    />
                                    <p className="mb-3">
                                        Ask the guest to confirm this code. Do not share
                                        it with them.
                                    </p>

                                    <Button
                                        disabled={
                                            !values.verificationCode ||
                                            values.verificationCode.length < 6 ||
                                            values.verificationCode.length > 6
                                        }
                                        type="submit"
                                        color="primary"
                                        className="transition-3d-hover mt-5 mr-2 btn"
                                        onClick={handleSubmit}
                                    >
                                        {loading && <ButtonLoader />}
                                        Verify and confirm
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                );
            case 'confirmed_success':
                return (
                    <div
                        className="col justify-content-center align-items-center"
                        style={{ animation: 'fadeIn .5s' }}
                    >
                        <h2 className="h5 font-weight-normal mb-1">
                            <span className="d-flex justify-content-start h5 font-weight-semi-bold">
                                Reservation Confirmed!
                            </span>
                        </h2>
                        <span className="d-flex justify-content-start h5 mb-1 font-weight-semi-bold">
                            Inform new guests that they will receive a text message with
                            instructions and a code to download the mobile app to gain
                            community access throughout their stay.
                        </span>
                        {panelSelection({ companyName: '', visitorName: '' })}
                        <div className="d-flex justify-content-start align-items-center">
                            <Button
                                type="button"
                                color="primary"
                                className="transition-3d-hover mt-5 mr-2 btn"
                                onClick={handleGateAccess}
                                disabled={!confirmAccessEnabled}
                            >
                                {loading && <ButtonLoader />}
                                Grant Access
                            </Button>
                        </div>
                    </div>
                );
            case 'create_res':
                return (
                    <div className="col" style={{ animation: 'fadeIn .5s' }}>
                        <div className="d-flex flex-column">
                            <Formik
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    country: '',
                                    code: '',
                                    number: ''
                                }}
                                onSubmit={createResSubmit}
                                validate={validatePhone}
                                validationSchema={createResValidationSchema}
                                validateOnChange
                                enableReinitialize
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    setFieldValue,
                                    handleSubmit,
                                    touched,
                                    values
                                }) => (
                                    <Form>
                                        <div className="row d-flex justify-content-start align-items-center p-0">
                                            <LabeledFormField
                                                fieldTitle="first name"
                                                required
                                                type="text"
                                                className="form-control col-10"
                                                name="firstName"
                                                id="firstName"
                                                placeholder={`First name`}
                                                aria-label="firstName"
                                                error={returnError(errors, 'firstName')}
                                                value={values.firstName}
                                                onBlur={handleBlur('firstName')}
                                                onChange={handleChange('firstName')}
                                                invalid={
                                                    returnTouched(touched, 'firstName') &&
                                                    !!returnError(errors, 'firstName')
                                                }
                                            />
                                        </div>
                                        <div className="row d-flex justify-content-start align-items-center p-0">
                                            <LabeledFormField
                                                fieldTitle="last name"
                                                type="text"
                                                className="form-control col-10"
                                                name="lastName"
                                                id="lastName"
                                                placeholder={`Last name`}
                                                aria-label="lastName"
                                                error={returnError(errors, 'lastName')}
                                                value={values.lastName}
                                                onBlur={handleBlur('lastName')}
                                                onChange={handleChange('lastName')}
                                                invalid={
                                                    returnTouched(touched, 'lastName') &&
                                                    !!returnError(errors, 'lastName')
                                                }
                                            />
                                        </div>
                                        <div className="row d-flex justify-content-start align-items-center p-0">
                                            <ContryPhoneCodeSelect
                                                required={true}
                                                fieldTitle="Mobile phone"
                                                type="text"
                                                className="form-control"
                                                name="number"
                                                id="number"
                                                aria-label="number"
                                                phoneError={returnError(errors, 'number')}
                                                phoneTouched={returnTouched(
                                                    touched,
                                                    'number'
                                                )}
                                                codeValue={values.code}
                                                phoneValue={values.number}
                                                countryValue={values.country}
                                                onBlurPhone={handleBlur('number')}
                                                onChangePhone={e => {
                                                    handleChange('number')(e);
                                                }}
                                                invalid={
                                                    touched.number && !!errors.number
                                                }
                                                onBlurCode={handleBlur('code')}
                                                onChangeCode={value =>
                                                    setFieldValue('code', value)
                                                }
                                                onChangeCountry={value =>
                                                    setFieldValue('country', value)
                                                }
                                            />
                                        </div>
                                        <div className="row d-flex justify-content-start align-items-center p-0">
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="transition-3d-hover mt-5 btn"
                                                onClick={handleSubmit}
                                            >
                                                Create Reservation
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="main">
            <div className="d-flex flex-column position-relative flex-auto">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex flex-column">
                        <h4 className="mb-0">{`${panelName}`}</h4>
                        <p className="font-size-1 text-muted mb-0">{room?.name}</p>
                    </div>
                    <div style={{ display: confirmState === 'res_list' ? 'none' : '' }}>
                        <Button
                            className="transition-3d-hover btn"
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                boxShadow: 'none'
                            }}
                            onClick={handleReset}
                        >
                            <i className="fa-solid fa-xmark text-muted" />
                        </Button>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex flex-column w-50 p-2">
                        <div className="remote-participants">
                            <div className="bg-dark remote-participant-video d-flex justify-content-center align-items-center">
                                <div className="video-loader">
                                    {remoteParticipants?.length ? (
                                        <div>{remoteParticipants}</div>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <img
                                                    src="../../assets/camera.svg"
                                                    alt="camera"
                                                    style={{
                                                        width: '80px'
                                                    }}
                                                />
                                            </div>
                                            <div>Video Loading...</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <CallController
                            accessEnabled={accessEnabled}
                            muteAudio={muteAudio}
                            setMuteAudio={setMuteAudio}
                            handleGateAccess={handleGateAccess}
                        />
                    </div>

                    <div className="d-flex flex-column w-50 p-2">
                        {focused && (
                            <div className="mb-5 p-0">
                                {focused.tenant ? (
                                    <div className="d-flex align-content-center justify-content-between">
                                        <div className="col-6">
                                            <h6
                                                className="mb-0"
                                                style={{
                                                    color: '#505050',
                                                    fontSize: '20px',
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {`${focused.last_name}, ${focused.first_name}`}
                                            </h6>
                                            <h6
                                                className="mb-0"
                                                style={{
                                                    color: '#505050',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                {focused.email}
                                            </h6>
                                            <NumberFormat
                                                value={
                                                    focused?.phone?.number
                                                        ? `${focused.phone.number}`
                                                        : focused?.phone
                                                        ? `${focused.phone}`
                                                        : ''
                                                }
                                                displayType={'text'}
                                                format="###-###-####"
                                                mask="_"
                                            />
                                            <h6
                                                style={{
                                                    color: '#505050',
                                                    opacity: 0.5,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {`check in: ${focused.check_in_date}`}
                                            </h6>
                                            <h6
                                                style={{
                                                    color: '#505050',
                                                    opacity: 0.5,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {`check out: ${focused.check_out_date}`}
                                            </h6>
                                        </div>
                                        <div className="col-6 d-flex flex-column align-items-end">
                                            <span className="badge badge-tenant font-weight-semi-bold pl-3 pr-3">
                                                Tenant
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <h6
                                            className="mb-0"
                                            style={{
                                                color: '#505050',
                                                fontSize: '20px',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {`${focused.last_name}, ${focused.first_name}`}
                                        </h6>
                                        <h6
                                            className="mb-0"
                                            style={{ color: '#505050', fontSize: '16px' }}
                                        >
                                            {focused.email}
                                        </h6>
                                        <h6
                                            style={{ color: '#505050', fontSize: '16px' }}
                                        >
                                            {focused?.phone?.number
                                                ? `+${focused.phone.code}${focused.phone.number}`
                                                : focused?.phone
                                                ? `${focused.phone}`
                                                : ''}
                                        </h6>
                                    </>
                                )}

                                <hr />
                            </div>
                        )}
                        <div>{ConfirmationState()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({
    reservations,
    manager,
    confirmAccess,
    calls,
    panel,
    user,
    org
}) => {
    return {
        reservations: reservations.reservations,
        addReservationSuccess: reservations.addingReservationSuccess,
        guardReservationId: reservations.guardReservationId,
        residents: manager.communityMembers.filter(member => member.role === 'resi'),
        existingAccount: confirmAccess.existingAccount,
        verifyCode: confirmAccess.verifyCode,
        verified: confirmAccess.verified,
        accessCreated: confirmAccess.accessCreated,
        addingTenantSuccess: reservations.addingTenantSuccess,
        loading: confirmAccess.apiCallInProgress,
        accessGranted: calls.accessGranted,
        error: calls.error,
        panels: panel.orgDevices,
        firstName: user.userData.first_name,
        lastName: user.userData.last_name,
        userId: user.userData.uid,
        orgData: org.primaryOrg
    };
};

export default connect(mapStateToProps, {
    endPanelCall,
    allowGateAccess,
    createAccessAPICall,
    createAccessAPINewVerification,
    createTenantAccess,
    createAccessAPIVerifyNumber,
    createAccessAPIStartOver,
    addReservation
})(Room);
