import React from 'react';

import { AddGuestForm } from '../components';

const AddGuest = () => {
    return (
        <div className="container-fluid min-vh-100 space-1 bg-dark">
            <div className="d-flex mt-2 align-items-center justify-content-center flex-column">
                <img src="../assets/sage-icon.png" alt="logo" width={80} />
            </div>
            <AddGuestForm />
        </div>
    );
};

export default AddGuest;
