import {
    GET_ORG_OTC_SUCCESS,
    ADD_OTC,
    ADD_OTC_SUCCESS,
    ADD_OTC_FAILURE,
    REVOKE_OTC,
    REVOKE_OTC_SUCCESS,
    REVOKE_OTC_FAILURE,
    RESET_OTC_STATE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    otcCodes: null,
    otcCode: null
};

const alphabetizeByName = codes => {
    if (codes && codes.length > 1) {
        const sortedCodes = codes.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        return sortedCodes;
    } else {
        return codes;
    }
};

const otcReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORG_OTC_SUCCESS:
            return {
                ...state,
                otcCodes: alphabetizeByName(action.payload)
            };
        case ADD_OTC:
            return {
                ...state,
                loading: true,
                error: false
            };
        case ADD_OTC_SUCCESS:
            return {
                ...state,
                otcCode: action.payload,
                loading: false,
                error: false
            };
        case ADD_OTC_FAILURE:
            return {
                ...state,
                otcCode: null,
                loading: false,
                error: action.payload
            };
        case REVOKE_OTC:
            return {
                ...state,
                loading: true,
                error: false
            };
        case REVOKE_OTC_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case REVOKE_OTC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case RESET_OTC_STATE:
            return {
                ...state,
                otcCode: INITIAL_STATE.otcCode,
                loading: INITIAL_STATE.loading,
                error: INITIAL_STATE.error
            };
        default:
            return state;
    }
};

export default otcReducer;
