// React
import React from 'react';

// Components
import { TextField } from '..';

// Packages
import { Button, Label } from 'reactstrap';
import { FieldArray } from 'formik';

const returnError = (errors, index, field) => errors?.managers?.[index]?.[field];

const returnTouched = (touched, index, field) => touched?.managers?.[index]?.[field];

const ManagerForm = ({
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    idCount,
    setIdCount
}) => {
    return (
        <div
            style={{ maxHeight: '650px', overflowY: 'scroll' }}
            className="w-md-50 p-5 mx-md-auto"
        >
            <FieldArray name="managers">
                {({ insert, remove, push }) => (
                    <div className="row m-auto">
                        {values.managers.length > 0 &&
                            values.managers.map((manager, index) => (
                                <div key={manager.managerFormID} className="col-12">
                                    <div className="row m-auto">
                                        <div className="js-form-message col-6 form-group">
                                            <Label
                                                className="form-label"
                                                for={`managers.${index}.first_name`}
                                            >
                                                manager first name
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name={`managers.${index}.first_name`}
                                                id={`managers.${index}.first_name`}
                                                placeholder=""
                                                aria-label={`managers.${index}.first_name`}
                                                error={returnError(
                                                    errors,
                                                    index,
                                                    'first_name'
                                                )}
                                                value={values.managers[index].first_name}
                                                onBlur={handleBlur(
                                                    `managers.${index}.first_name`
                                                )}
                                                onChange={handleChange(
                                                    `managers.${index}.first_name`
                                                )}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        index,
                                                        'first_name'
                                                    ) &&
                                                    !!returnError(
                                                        errors,
                                                        index,
                                                        'first_name'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="js-form-message col-6 form-group">
                                            <Label
                                                className="form-label"
                                                for={`managers.${index}.last_name`}
                                            >
                                                manager last name
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name={`managers.${index}.last_name`}
                                                id={`managers.${index}.last_name`}
                                                placeholder=""
                                                aria-label={`managers.${index}.last_name`}
                                                error={returnError(
                                                    errors,
                                                    index,
                                                    'last_name'
                                                )}
                                                value={values.managers[index].last_name}
                                                onBlur={handleBlur(
                                                    `managers.${index}.last_name`
                                                )}
                                                onChange={handleChange(
                                                    `managers.${index}.last_name`
                                                )}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        index,
                                                        'last_name'
                                                    ) &&
                                                    !!returnError(
                                                        errors,
                                                        index,
                                                        'last_name'
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row m-auto">
                                        <div className="js-form-message col-9 form-group">
                                            <Label
                                                className="form-label"
                                                for={`managers.${index}.officePhone`}
                                            >
                                                office phone
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name={`managers.${index}.officePhone`}
                                                id={`managers.${index}.officePhone`}
                                                placeholder=""
                                                aria-label={`managers.${index}.officePhone`}
                                                error={returnError(
                                                    errors,
                                                    index,
                                                    'officePhone'
                                                )}
                                                value={values.managers[index].officePhone}
                                                onBlur={handleBlur(
                                                    `managers.${index}.officePhone`
                                                )}
                                                onChange={handleChange(
                                                    `managers.${index}.officePhone`
                                                )}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        index,
                                                        'officePhone'
                                                    ) &&
                                                    !!returnError(
                                                        errors,
                                                        index,
                                                        'officePhone'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="js-form-message col-3 form-group">
                                            <Label
                                                className="form-label"
                                                for={`managers.${index}.officePhoneExt`}
                                            >
                                                ext
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name={`managers.${index}.officePhoneExt`}
                                                id={`managers.${index}.officePhoneExt`}
                                                placeholder=""
                                                aria-label={`managers.${index}.officePhoneExt`}
                                                error={returnError(
                                                    errors,
                                                    index,
                                                    'officePhoneExt'
                                                )}
                                                value={
                                                    values.managers[index].officePhoneExt
                                                }
                                                onBlur={handleBlur(
                                                    `managers.${index}.officePhoneExt`
                                                )}
                                                onChange={handleChange(
                                                    `managers.${index}.officePhoneExt`
                                                )}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        index,
                                                        'officePhoneExt'
                                                    ) &&
                                                    !!returnError(
                                                        errors,
                                                        index,
                                                        'officePhoneExt'
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row m-auto">
                                        <div className="js-form-message col-12 form-group">
                                            <Label
                                                className="form-label"
                                                for={`managers.${index}.mobilePhone`}
                                            >
                                                manager mobile
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name={`managers.${index}.mobilePhone`}
                                                id={`managers.${index}.mobilePhone`}
                                                placeholder=""
                                                aria-label={`managers.${index}.mobilePhone`}
                                                error={returnError(
                                                    errors,
                                                    index,
                                                    'mobilePhone'
                                                )}
                                                value={values.managers[index].mobilePhone}
                                                onBlur={handleBlur(
                                                    `managers.${index}.mobilePhone`
                                                )}
                                                onChange={handleChange(
                                                    `managers.${index}.mobilePhone`
                                                )}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        index,
                                                        'mobilePhone'
                                                    ) &&
                                                    !!returnError(
                                                        errors,
                                                        index,
                                                        'mobilePhone'
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-auto border-bottom pb-2 mb-3">
                                        <div className="js-form-message col-12 form-group">
                                            <Label
                                                className="form-label"
                                                for={`managers.${index}.email`}
                                            >
                                                manager email
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name={`managers.${index}.email`}
                                                id={`managers.${index}.email`}
                                                placeholder=""
                                                aria-label={`managers.${index}.email`}
                                                error={returnError(
                                                    errors,
                                                    index,
                                                    'email'
                                                )}
                                                value={values.managers[index].email}
                                                onBlur={handleBlur(
                                                    `managers.${index}.email`
                                                )}
                                                onChange={handleChange(
                                                    `managers.${index}.email`
                                                )}
                                                invalid={
                                                    returnTouched(
                                                        touched,
                                                        index,
                                                        'email'
                                                    ) &&
                                                    !!returnError(errors, index, 'email')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <div className="row pl-5">
                            <div className="d-flex justify-content-start">
                                <Button
                                    className="transition-3d-hover button-underlined-gray font-weight-semi-bold"
                                    onClick={() => {
                                        push({
                                            managerFormID: idCount,
                                            first_name: '',
                                            last_name: '',
                                            officePhone: '',
                                            officePhoneExt: '',
                                            mobilePhone: '',
                                            email: ''
                                        });
                                        setIdCount(idCount + 1);
                                    }}
                                >
                                    + Add another manager
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </FieldArray>
        </div>
    );
};

export default ManagerForm;
