import React from 'react';

import DropdownButton from '../common/DropdownButton';
import { Link } from 'react-router-dom';
import { GUEST_RESERVATION, GUEST_RESERVATION_BULK } from '../../router/config/routes';

const RentalHeader = ({ org_id, org_name, userId, firstName, lastName, match }) => {
    const menuOptions = [
        {
            key: 'individual',
            component: (
                <Link
                    to={{
                        pathname: `${match.url
                            .split('/')
                            .slice(0, -1)
                            .join('/')}${GUEST_RESERVATION}`,
                        state: {
                            org_id,
                            org_name,
                            firstName,
                            lastName,
                            userId
                        }
                    }}
                >
                    Individual
                </Link>
            )
        },
        {
            key: 'bulk upload',
            component: (
                <Link
                    to={{
                        pathname: `${match.url
                            .split('/')
                            .slice(0, -1)
                            .join('/')}${GUEST_RESERVATION_BULK}`,
                        state: {
                            org_id,
                            org_name,
                            firstName,
                            lastName,
                            userId
                        }
                    }}
                >
                    Bulk upload
                </Link>
            )
        }
    ];
    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex flex-column">
                <h4 className="mb-0">{org_name}</h4>
                <p className="font-size-1 text-muted mb-0 visibility-hidden">{org_id}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <DropdownButton
                    className="m-4"
                    menuOptionComponents={menuOptions}
                    toggleComponent={
                        <>
                            <i className="fa-solid fa-house-night mr-1" /> New Reservation
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default RentalHeader;
