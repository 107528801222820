// React
import React, { useEffect, useState } from 'react';

// Components
import { TextField, Loader } from '..';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Button, Label, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Redux - Actions, Reducers, Sagas
import {
    executeResetPasswordFlight,
    validateActionCode,
    resettingPasswordRequestState
} from '../../store/actions/Auth';

// Router
import * as routes from '../../router/config/routes';

// Utils
// import { errorMessage, passwordRegExp } from '../utils/Constants';
import { errorMessage, passwordRegExp } from '../../utils/Constants';

const ResetPassword = ({
    oobCode,
    email,
    uid,
    loading,
    validActionCode,
    validatingCode,
    validateActionCode,
    resetPasswordSuccess,
    resetPasswordFailure,
    executeResetPasswordFlight,
    resettingPasswordRequestState
}) => {
    const [mount, setMount] = useState(null);
    const [validLink, setValidLink] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (oobCode) validateActionCode({ oobCode });
    }, [oobCode, validateActionCode]);

    useEffect(() => {
        if (!validatingCode) {
            setMount(true);
            setValidLink(validActionCode);
        }
    }, [validActionCode, validatingCode]);

    useEffect(() => {
        return () => {
            resettingPasswordRequestState();
        };
    }, [resettingPasswordRequestState]);

    const validationSchema = Yup.object({
        password: Yup.string()
            .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
            .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
            .matches(passwordRegExp.numeric, errorMessage.password.numeric)
            .min(8, errorMessage.password.length)
            .required(errorMessage.password.required),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required(errorMessage.password.required)
    });

    if (mount && !validLink) {
        return (
            <div className="space-2 vh-100 bg-dark">
                <Col>
                    <div className="d-flex justify-content-center">
                        <Link
                            className="d-flex w-75 w-md-40 w-lg-30 align-items-center justify-content-center position-relative mt-11 mb-8"
                            to="/"
                            aria-label="SAGE"
                        >
                            <img
                                src="assets/sage.png"
                                className="logo"
                                style={{ width: '100%', maxWidth: '320px' }}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="w-80 w-lg-40 mx-auto">
                        <div className="mb-7">
                            <h2 className="h3 text-primary font-weight-normal mb-0">
                                Invalid Link
                            </h2>
                            <p>
                                Unfortunately, this link is invalid or has expired. Please
                                return to Forgot Password and request a new Reset Password
                                link.
                            </p>
                        </div>
                        <div className="row align-items-center mb-5">
                            <div className="col-12 text-right">
                                <Button
                                    color="primary"
                                    className="transition-3d-hover"
                                    onClick={() =>
                                        history.replace({
                                            pathname: `${routes.LANDING}`
                                        })
                                    }
                                >
                                    Back to Forgot Password
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        );
    } else if (mount && validLink) {
        return (
            <div className="space-2 vh-100 bg-dark">
                {!resetPasswordSuccess && !resetPasswordFailure ? (
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Link
                                className="d-flex w-75 w-md-40 w-lg-30 align-items-center justify-content-center position-relative mt-11 mb-8"
                                to="/"
                                aria-label="SAGE"
                            >
                                <img
                                    src="assets/sage.png"
                                    className="logo"
                                    style={{ width: '100%', maxWidth: '320px' }}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <Formik
                            initialValues={{
                                password: '',
                                passwordConfirmation: ''
                            }}
                            onSubmit={({ password }) => {
                                executeResetPasswordFlight({ oobCode, uid, password });
                            }}
                            validationSchema={validationSchema}
                            validateOnChange
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values
                            }) => (
                                <Form className="w-80 w-lg-40 mx-auto">
                                    <div className="mb-7">
                                        <h2 className="h3 text-primary font-weight-normal mb-0">
                                            Update Password
                                        </h2>
                                        <p>
                                            Create a new password for Sage Account{' '}
                                            <span className="text-primary">{email}</span>
                                        </p>
                                    </div>
                                    <div className="js-form-message form-group">
                                        <Label className="form-label" for="password">
                                            New Password
                                        </Label>
                                        <TextField
                                            required
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            id="password"
                                            placeholder="********"
                                            aria-label="********"
                                            error={errors.password}
                                            value={values.password}
                                            onBlur={handleBlur('password')}
                                            onChange={handleChange('password')}
                                            invalid={
                                                touched.password && !!errors.password
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message form-group">
                                        <Label className="form-label" for="password">
                                            Re-type New Password
                                        </Label>
                                        <TextField
                                            required
                                            type="password"
                                            className="form-control"
                                            name="passwordConfirmation"
                                            id="passwordConfirmation"
                                            placeholder="********"
                                            aria-label="********"
                                            error={errors.passwordConfirmation}
                                            value={values.passwordConfirmation}
                                            onBlur={handleBlur('passwordConfirmation')}
                                            onChange={handleChange(
                                                'passwordConfirmation'
                                            )}
                                            invalid={
                                                touched.passwordConfirmation &&
                                                !!errors.passwordConfirmation
                                            }
                                        />
                                    </div>
                                    <div className="row align-items-center mb-5">
                                        <div className="col-12 text-right">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="transition-3d-hover"
                                                onClick={handleSubmit}
                                            >
                                                Update password
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                ) : (
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Link
                                className="d-flex w-75 w-md-40 w-lg-30 align-items-center justify-content-center position-relative mt-11 mb-8"
                                to="/"
                                aria-label="SAGE"
                            >
                                <img
                                    src="assets/sage.png"
                                    className="logo"
                                    style={{ width: '100%', maxWidth: '320px' }}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="w-80 w-lg-40 mx-auto">
                            <div className="mb-7">
                                <h2 className="h3 text-primary font-weight-normal mb-0">
                                    {resetPasswordSuccess
                                        ? 'Password Update Success'
                                        : 'Password Update Failed'}
                                </h2>
                                <p>
                                    {resetPasswordSuccess
                                        ? 'Your password has been updated successfully!  Please return to the Login screen.'
                                        : `We're sorry but your password did not update successfully.  Please return to the Forgot Password screen and try again.`}
                                </p>
                                <Link className="small" to={`${routes.LOGIN}`}>
                                    <Button
                                        color="primary"
                                        className="transition-3d-hover"
                                    >
                                        Back to login
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                )}
                {loading && <Loader />}
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = ({ auth }) => {
    const {
        loading,
        validActionCode,
        validatingCode,
        resetPasswordSuccess,
        resetPasswordFailure
    } = auth;
    return {
        loading,
        validActionCode,
        validatingCode,
        resetPasswordSuccess,
        resetPasswordFailure
    };
};

export default withRouter(
    connect(mapStateToProps, {
        executeResetPasswordFlight,
        validateActionCode,
        resettingPasswordRequestState
    })(ResetPassword)
);
