// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';

const RoleSelect = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    myStyle,
    touched
}) => {
    const roles = ['resident', 'vendor'];

    return (
        <div style={myStyle}>
            <Field
                autoComplete="off"
                required={required}
                type={type}
                className={className}
                style={{
                    borderColor:
                        error && touched
                            ? '#de4437'
                            : value
                            ? 'rgba(0, 201, 167, 0.5)'
                            : ''
                }}
                name={name}
                id={id}
                placeholder={placeholder}
                error={error}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                as="select"
            >
                <option value="">Select</option>
                {roles.map(role => (
                    <option key={role} value={role}>
                        {role}
                    </option>
                ))}
            </Field>
            <FormFeedback style={{ display: error && touched ? 'block' : 'none' }}>
                {error}
            </FormFeedback>
        </div>
    );
};

export default RoleSelect;
