import { REQUEST_ACCOUNT_REMOVAL } from '../actions/types';

import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import { func } from '../../config/Firebase';

import {
    requestAccountRemovalSuccess,
    requestAccountRemovalFailure
} from '../actions/Removal';

const createNewRemovalRequest = func.httpsCallable('createNewRemovalRequest');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Add New Registration ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addRemovalRequest = async ({ email }) => {
    // console.log(member, shortCode);
    try {
        const { data } = await createNewRemovalRequest({
            email
        });
        // console.log(data, 'got data');
        if (data?.res) {
            return { res: data.res };
        } else {
            return { error: data.error };
        }
    } catch (error) {
        console.log(error, 'removalError');
        return { error };
    }
};

export function* addRemoval({ payload }) {
    const { email } = payload;

    // const org = yield select(selectors._activeOrg);
    const { res, error } = yield call(() => addRemovalRequest({ email }));
    if (res) {
        // console.log(res, 'end success');
        yield put(requestAccountRemovalSuccess());
    } else {
        console.log(error, 'end error');
        yield put(requestAccountRemovalFailure(error));
        // Error Handling for sentry with put and maybe UI message
        console.log(`Error: error adding new account removal request (RTDB)`, {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* addingRemoval() {
    yield takeLatest(REQUEST_ACCOUNT_REMOVAL, addRemoval);
}

export default function* rootSaga() {
    yield all([fork(addingRemoval)]);
}
