import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import ContactList from './ContactList';
import ContactModal from './ContactModal';
import ContactsListSearch from './ContactsListSearch';
import Swal from 'sweetalert2';
import { sendingInvites, removingRegisters } from '../../store/actions/Members';

const Imported = ({
    selectedTab,
    orgId,
    imported,
    sendingInvites,
    removingRegisters
}) => {
    const [searchString, setSearchsString] = useState('');
    const [modalProps, setModalProps] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectAllFlag, setSelectAllFlag] = useState(false);
    const [selectList, setSelectList] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);

    const toggle = () => {
        if (open) {
            setOpen(false);
            setModalProps(null);
        }
        if (!open) setOpen(true);
    };

    const modalOpen = props => {
        setModalProps(props);
        toggle();
    };

    const sendInvites = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-danger m-1'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons
            .fire({
                title: 'Are you sure?',
                text: 'You are about to send invitation(s) to join this community.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, send it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            })
            .then(result => {
                if (result.isConfirmed) {
                    selectList.forEach(register => {
                        sendingInvites({ invites: [register] });
                    });
                    swalWithBootstrapButtons.fire(
                        'Sent!',
                        `${
                            selectList.length > 1
                                ? 'Invites have been sent.'
                                : 'Invite has been sent.'
                        }`,
                        'success'
                    );

                    setSelectList([]);
                    setSelectAllFlag(false);
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Invite sending cancelled.',
                        'error'
                    );
                }
            });
    };

    const selectAll = () => {
        if (selectAllFlag) {
            setSelectList([]);
            setSelectAllFlag(false);
            return;
        }
        filteredContacts && filteredContacts.length
            ? setSelectList(filteredContacts)
            : setSelectList(imported);
        setSelectAllFlag(true);
    };

    const addToSelectList = selected => {
        if (selected === null) {
            setSelectList([]);
            return;
        }
        const alreadyAdded = selectList.map(el => el.id).includes(selected.id);
        if (alreadyAdded) {
            setSelectList(selectList.filter(el => el.id !== selected.id));
        } else setSelectList([...selectList, selected]);
    };

    const handleRemove = () => {
        selectList.forEach(register => {
            removingRegisters({ registers: [register] });
        });
        setSelectList([]);
        setSelectAllFlag(false);
    };

    const importedContactSearch = useCallback(
        input => {
            if (!input.trim()) {
                setFilteredContacts([]);
                return;
            }

            const filtered = imported.filter(contact => {
                const { first_name, last_name, email, address } = contact;
                const lowerCaseSearchString = input.trim().toLowerCase();

                if (
                    first_name &&
                    first_name.toLowerCase().includes(lowerCaseSearchString)
                )
                    return true;
                if (last_name && last_name.toLowerCase().includes(lowerCaseSearchString))
                    return true;
                if (email && email.toLowerCase().includes(lowerCaseSearchString))
                    return true;
                if (address && address.toLowerCase().includes(lowerCaseSearchString))
                    return true;

                return false;
            });

            setFilteredContacts(filtered);
        },
        [imported]
    );

    useEffect(() => {
        if (imported && searchString) {
            importedContactSearch(searchString);
        } else {
            if (imported) {
                setFilteredContacts(imported);
            }
        }
    }, [searchString, setFilteredContacts, imported, importedContactSearch]);

    return (
        <div>
            <div className="my-4 w-100 d-flex justify-content-between">
                <div className="d-flex justify-content-start w-30">
                    <ContactsListSearch
                        className="contacts-list-search"
                        handleChange={value => {
                            setSearchsString(value);
                            setSelectList([]);
                            if (selectAllFlag) {
                                selectAll();
                            }
                        }}
                        listLabel={selectedTab}
                    />
                </div>

                <div
                    className={selectAllFlag ? 'invite-all badge-green' : 'invite-all'}
                    onClick={selectAll}
                >
                    <i className="fa fa-check-circle mr-2" aria-hidden="true" />
                    Select All
                </div>
            </div>
            <ContactList
                orgId={orgId}
                inviteHandler={addToSelectList}
                invited={selectList}
                searchString={searchString}
                modalOpen={modalOpen}
                imported={imported}
                filteredContacts={filteredContacts}
            />
            <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="text-muted">{`${imported?.length ? imported.length : 0} ${
                    imported?.length && imported.length === 1 ? 'Request' : 'Requests'
                }`}</div>
                <div>
                    <Button
                        disabled={!selectList.length}
                        color="danger"
                        className="transition-3d-hover m-3 btn-sm"
                        onClick={handleRemove}
                    >
                        {selectList.length > 1 ? `Remove Requests` : 'Remove Request'}
                    </Button>
                    <Button
                        disabled={!selectList.length}
                        color="primary"
                        className="transition-3d-hover m-3 btn-sm"
                        onClick={sendInvites}
                    >
                        {selectList.length > 1 ? `Send Invites` : 'Send Invite'}
                    </Button>
                </div>
            </div>
            <ContactModal
                isOpen={open}
                toggleOpen={toggle}
                orgId={orgId}
                modalProps={modalProps}
            />
        </div>
    );
};

const mapStateToProps = ({ manager }) => {
    const { importedMembers } = manager;
    return { imported: importedMembers };
};

export default withRouter(
    connect(mapStateToProps, { sendingInvites, removingRegisters })(Imported)
);
