import React from 'react';

import { AddGuestSuccess } from '../components';

const AddGuestSuccessPage = () => {
    return (
        <div className="space-1 vh-100 bg-dark">
            <div className="d-flex mt-2 align-items-center justify-content-center flex-column">
                <img src="../assets/sage-icon.png" alt="logo" width={80} />
            </div>
            <AddGuestSuccess />
        </div>
    );
};

export default AddGuestSuccessPage;
