import { all, fork, takeLatest, take, cancelled, race, put } from 'redux-saga/effects';

import { GET_USER_COLLECTION, LOGOUT_USER } from '../actions/types';

import { eventChannel } from 'redux-saga';

import { db, func } from '../../config/Firebase';

import { orgCollectionWatch, activeOrgCollectionWatch } from './Org';

import { listenToAccessKeysAndGuestInvites } from './Guests';

import { callsCollectionWatch } from './Calls';

import { reservationsCollectionWatch } from './Reservations';

import { storeUserCollection } from '../actions/User';

import { checkExpiration } from '../../utils/Helpers';

const users = db.collection('users');
const refreshTwilioToken = func.httpsCallable('refreshTwilioAccessToken');

////////////// Get User Profile ////////////////

export function* userCollectionWatch(user) {
    let unsubcscribeUserCollectionData;
    const userCollectionChannel = eventChannel(emit => {
        unsubcscribeUserCollectionData = users
            .doc(`${user.uid}`)
            .onSnapshot(function (doc) {
                if (doc) {
                    emit(doc.data());
                } else {
                    doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubcscribeUserCollectionData;
    });
    try {
        while (true) {
            const { userSignOut, userCollectionData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userCollectionData: take(userCollectionChannel)
            });

            if (userSignOut) {
                userCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(storeUserCollection(userCollectionData));
                if (userCollectionData.role === 'resi') {
                    yield fork(listenToAccessKeysAndGuestInvites, userCollectionData);
                    return;
                }
                if (userCollectionData?.twilio) {
                    if (checkExpiration(userCollectionData.twilio.updated_at.seconds)) {
                        refreshTwilioToken({ userId: userCollectionData.uid });
                    }
                }
                // yield fork(userCollectionData);
                // if (userCollectionData.type === 'super_admin') {
                yield fork(orgCollectionWatch, userCollectionData); // org list
                yield fork(activeOrgCollectionWatch, userCollectionData.active_org_id); // org community data
                if (userCollectionData?.active_station_id)
                    yield fork(callsCollectionWatch, userCollectionData); // active and pending calls list
                yield fork(reservationsCollectionWatch, {
                    payload: userCollectionData.active_org_id
                        ? userCollectionData.active_org_id
                        : userCollectionData.primary_org
                });
                // yield fork(panelCollectionWatch, userCollectionData); // admin panel data aggregate doc
                // }
            }
        }
    } catch (error) {
        console.log('User Error: getting user collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubcscribeUserCollectionData(); // Detach firebase listener
        if (yield cancelled()) {
            userCollectionChannel.close(); // Detach saga event emitter
            unsubcscribeUserCollectionData(); // Detach firebase listener
        }
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getUserCollection() {
    yield takeLatest(GET_USER_COLLECTION, userCollectionWatch);
}

export default function* rootSaga() {
    yield all([fork(getUserCollection)]);
}
