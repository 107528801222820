import {
    ADD_RESERVATION,
    CANCEL_RESERVATION,
    CREATE_TENANT_ACCESS,
    GET_RESERVATIONS,
    IMPORT_RESERVATIONS_CSV,
    LOGOUT_USER,
    REINSTATE_RESERVATION,
    UPDATE_RESERVATION,
    VALIDATE_RESERVATION,
    WATCH_RESERVATIONS
} from '../actions/types';

import {
    all,
    fork,
    take,
    takeLatest,
    put,
    cancelled,
    race,
    call
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import { db, func, timeStampNow } from '../../config/Firebase';
import {
    addReservationFailure,
    addReservationSuccess,
    cancelReservationFailure,
    cancelReservationSuccess,
    createTenantAccessFailure,
    createTenantAccessSuccess,
    getReservationsFailure,
    getReservationsSuccess,
    importReservationsFailure,
    importReservationsSuccess,
    reinstateReservationFailure,
    reinstateReservationSuccess,
    updateReservationFailure,
    updateReservationSuccess,
    validateReservationFailure,
    validateReservationSuccess,
    storeGuardResId
} from '../actions/Reservations';
import { apiPost } from '../../api';
import { createNewAuthUsers, getExistingAndNewUsers } from './Managers';
import { addAccessKey, deleteAccessKey } from './AccessKeys';
import { loginUserEmailPasswordRequest } from './Auth';
import { parseTimeFromCSV, tsFromJsDate } from '../../utils/Helpers';
import {
    emailRegex,
    nonAlpha,
    nonAlphaLastName,
    noSlashDash
} from '../../utils/Constants';

const reservations = db.collection('reservations');
const guestInvites = db.collection('guest_invites');
const accessKeys = db.collection('access_keys');
const users = db.collection('users');

const sendTenantInvite = func.httpsCallable('sendgridTenantInviteEmailRequest');

export function* reservationsCollectionWatch({ payload }) {
    let unsubscribeReservationsData;
    const reservationsCollectionChannel = eventChannel(emit => {
        unsubscribeReservationsData = reservations
            .where('org_id', '==', payload)
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot) {
                    const reservations = [];
                    querySnapshot.forEach(function (doc) {
                        reservations.push(doc.data());
                    });
                    emit(reservations);
                } else {
                    const doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeReservationsData;
    });
    try {
        while (true) {
            const { userSignOut, reservationData } = yield race({
                userSignOut: take(LOGOUT_USER),
                reservationData: take(reservationsCollectionChannel)
            });

            if (userSignOut) {
                reservationsCollectionChannel.close();
            } else {
                yield put(getReservationsSuccess(reservationData));
            }
        }
    } catch (error) {
        yield put(getReservationsFailure(error));
    } finally {
        unsubscribeReservationsData();
        if (yield cancelled()) {
            reservationsCollectionChannel.close();
            unsubscribeReservationsData();
        }
    }
}

export function* getReservationsCollections() {
    try {
        const allReservations = yield reservations
            .get()
            .then(snapshot => snapshot.docs.map(doc => doc.data()))
            .catch(err => {
                console.error(err);
            });
        yield put(getReservationsSuccess(allReservations));
    } catch (err) {
        console.error(err);
        yield put(getReservationsFailure(err));
    }
}

export function* addReservation({ payload }) {
    const { data, guard } = payload;
    try {
        const addResRequests = [];
        var guardResId = '';
        data.forEach(res => {
            const newReservationRef = reservations.doc();
            const newReservationDocument = {
                ...res,
                reservation_id: newReservationRef.id
            };
            addResRequests.push(newReservationRef.set(newReservationDocument));
            if (guard) guardResId = newReservationRef.id;
        });

        const allAdded = yield Promise.all(addResRequests)
            .then(() => {
                return true;
            })
            .catch(err => {
                console.error(err);
                return false;
            });
        if (allAdded) {
            if (guard) yield put(storeGuardResId(guardResId));
            yield put(addReservationSuccess());
            return true;
        }
        yield put(addReservationFailure());
        return false;
    } catch (error) {
        console.log(error);
        yield put(addReservationFailure());
        return false;
    }
}

export function* validateReservation({ payload }) {
    try {
        const matchingReservation = yield apiPost('/reservations/search', payload);

        if (matchingReservation.error) {
            yield put(validateReservationFailure(`${matchingReservation.error}`));
            return;
        }

        if (matchingReservation.data !== '') {
            yield put(validateReservationSuccess(matchingReservation.data));
            return;
        }

        yield put(
            validateReservationFailure(
                "We can't seem find this reservation. Please try again."
            )
        );
    } catch (error) {
        console.log(error);
        yield put(validateReservationFailure(`${error}`));
    }
}

export function* reinstateReservation({ payload }) {
    try {
        const createdAccessKey = yield* addAccessKey({
            payload: { ...payload, image: payload.image || null }
        });

        if (createdAccessKey) {
            const isUpdated = yield* updateReservationRecord({
                payload: { ...payload, active: true, key_id: createdAccessKey.key_id }
            });
            if (isUpdated) {
                yield put(reinstateReservationSuccess());
                return true;
            }
            yield put(reinstateReservationFailure('Reinstation error, try again later.'));
            return false;
        }
        yield put(reinstateReservationFailure('Reinstation error, try again later.'));
        return false;
    } catch (error) {
        yield put(reinstateReservationFailure('Reinstation error:', `${error}`));
        console.log(error);
    }
}

export function* cancelReservation({ payload }) {
    try {
        const { validated, reservation_id, key_id } = payload;

        const isCanceled = yield reservations
            .doc(`${reservation_id}`)
            .set({ active: false, key_id: null }, { merge: true })
            .then(() => true)
            .catch(error => {
                console.error(`Error setting 'validated' status: ${error}`);
                return false;
            });

        if (isCanceled && validated) {
            const isGuestInviteDeleted = yield guestInvites
                .doc(`${reservation_id}`)
                .delete()
                .then(() => true)
                .catch(err => {
                    console.log('Guest invite deletion error:', err);
                    return false;
                });

            const isAccessDeletedOrNotExisting = key_id
                ? yield* deleteAccessKey({ payload: key_id })
                : true;

            if (isAccessDeletedOrNotExisting && isGuestInviteDeleted) {
                yield put(cancelReservationSuccess());
                return true;
            }
            return false;
        }

        if (isCanceled) {
            yield put(cancelReservationSuccess());
            return true;
        }
        return false;
    } catch (error) {
        yield put(cancelReservationFailure(error));
    }
}

export function* updateReservationRecord({ payload }) {
    try {
        const {
            check_in_date,
            check_out_date,
            access_begins,
            access_expires,
            check_in_time,
            check_out_time,
            last_name,
            first_name,
            email,
            address,
            key_id
        } = payload;

        const isUpdatedReservation = yield reservations
            .doc(`${payload.reservation_id}`)
            .set(payload, { merge: true })
            .then(() => true)
            .catch(error => {
                console.error(`Reservation updating error: ${error}`);
                return false;
            });
        const isUpdatedInvite = yield guestInvites
            .doc(`${payload.reservation_id}`)
            .set(
                {
                    check_in_date,
                    check_out_date,
                    access_begins,
                    access_expires,
                    check_in_time,
                    check_out_time,
                    last_name,
                    first_name,
                    email,
                    address
                },
                { merge: true }
            )
            .then(() => true)
            .catch(error => {
                console.error(`Guest invite updating error: ${error}`);
                return false;
            });

        const isKeyUpdated = key_id
            ? accessKeys
                  .doc(`${key_id}`)
                  .set({ access_begins, access_expires }, { merge: true })
                  .then(() => true)
                  .catch(error => {
                      console.error(`Access key updating error: ${error}`);
                      return false;
                  })
            : true;

        if (isUpdatedReservation && isUpdatedInvite && isKeyUpdated) {
            yield put(updateReservationSuccess());
            return true;
        }
        yield put(
            updateReservationFailure('Reservation updating error, try again later.')
        );
        return false;
    } catch (error) {
        console.log(error);
        yield put(updateReservationFailure('Reservation updating error:', error));
    }
}

export function* importReservationsFromCSV({ payload }) {
    try {
        const { reservationsData, creatorData } = payload;
        const { org_id, org_name, userId, firstName, lastName } = creatorData;
        const { validRows, invalidRows } =
            parseAndValidateReservationsData(reservationsData);

        const validReservationObjects = validRows.map(res => {
            const checkInDateTime = new Date(
                res.check_in_date
                    ? res.check_in_date
                    : formatDate(res.StartDateGuestStay.trim())
            );
            if (res.check_in_time) {
                checkInDateTime.setHours(...parseTimeFromCSV(res.check_in_time));
            } else {
                checkInDateTime.setHours(0, 0);
            }
            const checkOutDateTime = new Date(
                res.check_out_date
                    ? res.check_out_date
                    : formatDate(res.EndDateGuestStay.trim())
            );
            if (res.check_out_time) {
                checkOutDateTime.setHours(...parseTimeFromCSV(res.check_out_time));
            } else {
                checkOutDateTime.setHours(23, 59);
            }
            const phone =
                res.phone_number && res.phone_code && res.phone_country
                    ? {
                          number: Number.parseInt(res.phone_number),
                          code: Number.parseInt(res.phone_code),
                          country: res.phone_country
                      }
                    : null;

            const phone_number =
                res.phone_number && res.phone_code
                    ? `${res.phone_code}${res.phone_number}`
                    : null;
            const validEmail =
                res.email && res.email.trim().length
                    ? emailRegex.test(res.email)
                        ? res.email.toLowerCase()
                        : null
                    : null;

            const hasFullName = res.GuestName
                ? res.GuestName.replace(nonAlpha, '').length !== 0
                    ? true
                    : false
                : false;
            const hasGuestFirstName = res.GuestFirstName
                ? res.GuestFirstName.replace(nonAlpha, '').length !== 0
                    ? true
                    : false
                : false;
            const hasGuestLastName = res.GuestLastName
                ? res.GuestLastName.replace(nonAlpha, '').length !== 0
                    ? true
                    : false
                : false;

            const guestFirstName = hasFullName
                ? formatFullName(res.GuestName).first_name
                : hasGuestFirstName
                ? res.GuestFirstName.replace(nonAlpha, '')
                : res.first_name.replace(nonAlpha, '').length !== 0
                ? res.first_name
                : null;

            const guestLastName = hasFullName
                ? formatFullName(res.GuestName).last_name
                : hasGuestLastName
                ? res.GuestLastName.replace(nonAlphaLastName, '')
                : res.last_name.replace(nonAlphaLastName, '').length !== 0
                ? res.last_name
                : null;

            const data = {
                access_begins: tsFromJsDate(checkInDateTime),
                access_created: null,
                access_days: null,
                access_end_time: null,
                access_expires: tsFromJsDate(checkOutDateTime),
                access_start_time: null,
                active: true,
                address: {
                    address_1: res.address_1 || null,
                    address_2: res.address_2 || null,
                    city: null,
                    latitude: null,
                    longitude: null,
                    state: null,
                    zip: null
                },
                check_in_date: checkInDateTime.toDateString(),
                check_in_time:
                    checkInDateTime.getHours() === 0 &&
                    checkOutDateTime.getMinutes() === 0
                        ? null
                        : {
                              hours: checkInDateTime.getHours(),
                              minutes: checkInDateTime.getMinutes()
                          },
                check_out_date: checkOutDateTime.toDateString(),
                check_out_time:
                    checkOutDateTime.getHours() === 0 &&
                    checkOutDateTime.getMinutes() === 0
                        ? null
                        : {
                              hours: checkOutDateTime.getHours(),
                              minutes: checkOutDateTime.getMinutes()
                          },
                company_name: null,
                confirmation: null,
                consumer_id: null,
                created_at: timeStampNow(),
                key_id: null,
                image: null,
                invite_id: null,
                invite_status: null,
                invited_at: null,
                creator_first_name: firstName,
                creator_id: userId,
                creator_last_name: lastName,
                email: validEmail,
                event_id: null,
                favorite: false,
                first_name: guestFirstName,
                invite_code: null,
                last_name: guestLastName,
                org_id,
                org_name,
                phone,
                phone_number,
                role: 'tenant',
                suspended: false,
                validated: false
            };

            return data;
        });

        const isAdded = yield* addReservation({ payload: validReservationObjects });

        if (isAdded) {
            yield put(importReservationsSuccess(invalidRows));
            return true;
        }

        yield put(importReservationsFailure('Import failed, try again later'));
    } catch (error) {
        console.log(error);
        yield put(importReservationsFailure(`${error}`));
    }
}

function parseAndValidateReservationsData(data) {
    const dataKeys = data.shift();
    const validRows = [];
    const invalidRows = [];
    for (let i = 0; i < data.length; i++) {
        const resRow = {};
        dataKeys.forEach((key, index) => {
            resRow[key] = data[i][index];
        });
        const validated = checkIfReservationValid(resRow);
        if (validated.errors.length) {
            invalidRows.push(validated);
        } else {
            validRows.push(resRow);
        }
    }

    return { validRows, invalidRows };
}

function checkIfReservationValid(reservation) {
    const hasFullName = reservation.GuestName
        ? reservation.GuestName.replace(nonAlpha, '').length !== 0
            ? true
            : false
        : false;
    const hasGuestFirstName = reservation.GuestFirstName
        ? reservation.GuestFirstName.replace(nonAlpha, '').length !== 0
            ? true
            : false
        : false;
    const hasGuestLastName = reservation.GuestLastName
        ? reservation.GuestLastName.replace(nonAlpha, '').length !== 0
            ? true
            : false
        : false;

    const firstName = hasFullName
        ? formatFullName(reservation.GuestName).first_name
        : hasGuestFirstName
        ? reservation.GuestFirstName.replace(nonAlpha, '')
        : reservation.first_name.replace(nonAlpha, '').length !== 0
        ? reservation.first_name
        : null;

    const lastName = hasFullName
        ? formatFullName(reservation.GuestName).last_name
        : hasGuestLastName
        ? reservation.GuestLastName.replace(nonAlpha, '')
        : reservation.last_name.replace(nonAlpha, '').length !== 0
        ? reservation.last_name
        : null;

    const checkIn = reservation.check_in_date
        ? reservation.check_in_date
        : reservation.StartDateGuestStay &&
          reservation.StartDateGuestStay.length &&
          formatDate(reservation.StartDateGuestStay)
        ? formatDate(reservation.StartDateGuestStay)
        : null;

    const checkOut = reservation.check_out_date
        ? reservation.check_out_date
        : reservation.EndDateGuestStay &&
          reservation.EndDateGuestStay.length &&
          formatDate(reservation.EndDateGuestStay)
        ? formatDate(reservation.EndDateGuestStay)
        : null;

    const error = {
        name: `${firstName ? firstName : '__________'} ${
            lastName ? lastName : '__________'
        }`,
        errors: []
    };

    if (!firstName) error.errors.push('missing first_name');
    if (!lastName) error.errors.push('missing last_name');
    if (!checkIn) error.errors.push('missing or poorly formatted check-in date');
    if (!checkOut) error.errors.push('missing or poorly formatted check-out date');
    // if (!reservation.check_in_time) error.errors.push('missing check_in_time');
    // if (!reservation.check_out_time) error.errors.push('missing check_out_time');
    return error;
}

const formatDate = serialNumberDate => {
    try {
        // Remove non-numeric characters using regular expression
        var serialNumberDateDigitsOnly = serialNumberDate.replace(/\D/g, '');
        // Check if serialNumberDateDigitsOnly is 5 digits long and does not contain "/" or "-"
        if (
            serialNumberDateDigitsOnly.length === 5 &&
            !noSlashDash.test(serialNumberDate)
        ) {
            console.log('serialNumberDateDigitsOnly is 5 digits long.');
        } else {
            console.log('serialNumberDateDigitsOnly is not 5 digits long.');
            return serialNumberDate;
        }
        // Convert the serial number to a 5-digit string
        var serialNumberStr = serialNumberDateDigitsOnly.padStart(5, '0');

        // Create a Date object for January 1, 1900
        var startDate = new Date('January 1, 1900');

        // Add the serial number of days to the start date
        startDate.setDate(startDate.getDate() + parseInt(serialNumberDateDigitsOnly, 10));

        // Format the date as desired
        var year = startDate.getFullYear();
        var month = startDate.getMonth() + 1; // Month is zero-based, so we add 1
        var day = startDate.getDate();

        // Create a formatted string for the date (e.g., MM/DD/YYYY)
        var formattedDate =
            (month < 10 ? '0' + month : month) +
            '/' +
            (day < 10 ? '0' + day : day) +
            '/' +
            year;
        console.log(serialNumberStr); // Output: "45067"
        console.log(formattedDate); // Output: "07/28/2022"
        return formattedDate;
    } catch (error) {
        console.error('An error occurred:', error);
        return null;
    }
};

// const formatPhone = phone => {
//     if (!phone || phone.trim() === '' || phone.length < 10) {
//         return null;
//     }
//     // only allow numbers
//     const sanitizedPhone = phone.replace(/[^0-9]/g, '');

//     // for US numbers add a 1 in front
//     if (sanitizedPhone.length === 10) {
//         return {
//             phone: {
//                 code: 1,
//                 country: 'US',
//                 number: parseInt(sanitizedPhone)
//             },
//             phone_number: `1${sanitizedPhone}`
//         };
//     } else {
//         const country = countries.filter(
//             country =>
//                 parseInt(sanitizedPhone.substring(0, sanitizedPhone.length - 10)) ===
//                 country.phoneCode
//         )[0];
//         const numberFrag = parseInt(sanitizedPhone.slice(-10));
//         const codeFrag = country ? country.phoneCode : null;
//         const countryFrag = country ? country.value : null;
//         return {
//             phone: {
//                 code: codeFrag,
//                 country: countryFrag,
//                 number: numberFrag
//             },
//             phone_number: `${codeFrag}${sanitizedPhone}`
//         };
//     }
// };

const formatFullName = name => {
    const nameFrag = name.split(' ');
    return {
        first_name: nameFrag[0].replace(nonAlpha, ''),
        last_name: nameFrag[nameFrag.length - 1].replace(nonAlphaLastName, '')
    };
};

export function* createNewTenantAuthAndAccess({ payload }) {
    try {
        const {
            address,
            phone,
            email,
            last_name,
            org_id,
            org_name,
            role,
            reservation_id,
            first_name,
            password,
            operator
        } = payload;

        const { newUsers, existingUsers } = yield getExistingAndNewUsers([payload]);

        if (newUsers.length) {
            const processed = yield createNewAuthUsers(newUsers);

            if (processed) {
                const matchingAuthUser = processed.find(
                    authUser => authUser.email === email
                );

                const tenantDocument = {
                    voip_token: null,
                    notifications: {
                        favorites: true,
                        family: true,
                        org_vendors: true,
                        guests: true,
                        vendors: true,
                        news: true
                    },
                    avatar: null,
                    properties: {
                        [org_id]: {
                            address,
                            notifications: true,
                            org_id: org_id,
                            property_id: null
                        }
                    },
                    primary_org: org_id,
                    active_call: null,
                    type: 'tenant',
                    email,
                    fcm_token: null,
                    phone,
                    last_name,
                    first_name,
                    role,
                    uid: matchingAuthUser.id
                };

                const isTenantCreated = yield users
                    .doc(`${tenantDocument.uid}`)
                    .set(tenantDocument)
                    .then(() => true)
                    .catch(error => {
                        console.error(`Error creating tenant document: ${error}`);
                        return false;
                    });

                const createdAccessKey = yield* addAccessKey({
                    payload: {
                        ...payload,
                        consumer_id: matchingAuthUser.id
                    }
                });

                if (isTenantCreated && createdAccessKey) {
                    const { key_id, access_created } = createdAccessKey;
                    const isValidated = yield reservations
                        .doc(`${reservation_id}`)
                        .set(
                            {
                                validated: true,
                                consumer_id: matchingAuthUser.id,
                                key_id,
                                access_created
                            },
                            { merge: true }
                        )
                        .then(() => {
                            sendTenantInvite({
                                email,
                                first_name,
                                last_name,
                                org_name
                            }).then(data => {
                                if (data.data.processed) return true;
                            });
                            return true;
                        })
                        .catch(error => {
                            console.error(`Error setting 'validated' status: ${error}`);
                            return false;
                        });
                    if (isValidated) {
                        yield put(createTenantAccessSuccess(payload));
                        return true;
                    }
                }
            }
        }
        if (existingUsers.length) {
            const matchingExistingUser = existingUsers.find(user => user.email === email);
            const { authenticated, error } = yield call(() =>
                loginUserEmailPasswordRequest(email, password, operator)
            );
            if (authenticated) {
                const createdAccessKey = yield* addAccessKey({
                    payload: {
                        ...payload,
                        consumer_id: matchingExistingUser.uid
                    }
                });

                if (createdAccessKey) {
                    const { key_id, access_created } = createdAccessKey;
                    const isValidated = yield reservations
                        .doc(`${reservation_id}`)
                        .set(
                            {
                                validated: true,
                                consumer_id: matchingExistingUser.uid,
                                key_id,
                                access_created
                            },
                            { merge: true }
                        )
                        .then(() => true)
                        .catch(error => {
                            console.error(`Error setting 'validated' status: ${error}`);
                            return false;
                        });
                    if (isValidated) {
                        yield put(createTenantAccessSuccess(payload));
                        return true;
                    }
                }
            } else {
                yield put(createTenantAccessFailure(`${error}`));
                return false;
            }
        }
    } catch (error) {
        console.log(error);
        yield put(createTenantAccessFailure(error));
    }
}

////////////////////////
export function* importReservations() {
    yield takeLatest(IMPORT_RESERVATIONS_CSV, importReservationsFromCSV);
}

export function* reinstateBooking() {
    yield takeLatest(REINSTATE_RESERVATION, reinstateReservation);
}

export function* updateBooking() {
    yield takeLatest(UPDATE_RESERVATION, updateReservationRecord);
}

export function* cancelBooking() {
    yield takeLatest(CANCEL_RESERVATION, cancelReservation);
}

export function* addTenantAccess() {
    yield takeLatest(CREATE_TENANT_ACCESS, createNewTenantAuthAndAccess);
}

export function* watchReservationsCollection() {
    yield takeLatest(WATCH_RESERVATIONS, reservationsCollectionWatch);
}

export function* validateReservationRecord() {
    yield takeLatest(VALIDATE_RESERVATION, validateReservation);
}

export function* createReservation() {
    yield takeLatest(ADD_RESERVATION, addReservation);
}

export function* getAllReservations() {
    yield takeLatest(GET_RESERVATIONS, getReservationsCollections);
}

export default function* rootSaga() {
    yield all([
        fork(getAllReservations),
        fork(createReservation),
        fork(validateReservationRecord),
        fork(watchReservationsCollection),
        fork(addTenantAccess),
        fork(cancelBooking),
        fork(reinstateBooking),
        fork(updateBooking),
        fork(importReservations)
    ]);
}
