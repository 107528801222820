import React, { useState } from 'react';
import ImgUploader from './ImgUploader';

import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import TextField from '../common/TextField';
import { Form, Formik } from 'formik';
import { addPost } from '../../store/actions/Posts';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Loader from '../common/Loader';

const NewPostModal = ({ isOpen, toggleOpen, org_id, uploading }) => {
    const [imgFile, setImgFile] = useState(null);
    const dispatch = useDispatch();

    const submitForm = values => {
        dispatch(addPost({ imgFile, values, org_id, callback: toggleOpen }));
        setImgFile(null);
    };

    return (
        <Modal
            style={{ minWidth: '70%' }}
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            toggle={toggleOpen}
        >
            <ModalBody>
                <div className="d-flex flex-column main">
                    {uploading ? (
                        <Loader />
                    ) : (
                        <div>
                            <ImgUploader imgFile={imgFile} onImageDrop={setImgFile} />
                            <Formik
                                initialValues={{
                                    post_content: '',
                                    title: '',
                                    org_id: org_id,
                                    public: false
                                }}
                                onSubmit={submitForm}
                            >
                                {({
                                    handleSubmit,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    touched,
                                    values
                                }) => (
                                    <Form>
                                        <div className="m-2 w-50">
                                            <Label className="form-label">
                                                Post Title
                                            </Label>
                                            <TextField
                                                required
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                id="title"
                                                placeholder="Title"
                                                aria-label="title"
                                                onChange={handleChange('title')}
                                                value={values.title}
                                            />
                                        </div>
                                        <div className="m-2">
                                            <Label className="form-label">
                                                Post Content
                                            </Label>
                                            <TextField
                                                required
                                                type="textarea"
                                                className="form-control"
                                                rows={8}
                                                name="post_content"
                                                id="post_content"
                                                placeholder="Start typing..."
                                                aria-label="post_content"
                                                onChange={handleChange('post_content')}
                                                value={values.post_content}
                                            />
                                        </div>
                                        <div className="m-2 justify-content-start">
                                            <FormGroup check>
                                                <Input
                                                    id="checkbox2"
                                                    type="checkbox"
                                                    onChange={handleChange('public')}
                                                    checked={!values.public}
                                                    value={values.public}
                                                />
                                                <Label className="form-label" check>
                                                    Save As Draft
                                                </Label>
                                            </FormGroup>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                className="transition-3d-hover cancel-button m-3 btn-wide"
                                                onClick={toggleOpen}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="transition-3d-hover text-nowrap m-3 btn-wide"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = ({ post: { addingPost } }) => {
    return { uploading: addingPost };
};

export default connect(mapStateToProps)(NewPostModal);
