import React from 'react';

// Packages
import { NavLink } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

const OrgNav = ({ match, superAdmin }) => {
    const mainLinks = [
        {
            label: 'Dashboard',
            icon: 'empty_dashboard',
            route: superAdmin
                ? `${match.url}${routes.SUPER_ORG_DASHBOARD}`
                : `${routes.AUTHENTICATED}${routes.DASHBOARD}`
        },
        {
            label: 'Community',
            icon: 'groups',
            route: superAdmin
                ? `${match.url}${routes.SUPER_ORG_COMMUNITY}`
                : `${routes.AUTHENTICATED}${routes.COMMUNITY}`
        },
        // {
        //     label: 'Access',
        //     icon: 'lock_open',
        //     route: superAdmin
        //         ? `${match.url}${routes.SUPER_ORG_ACCESS}`
        //         : `${routes.AUTHENTICATED}${routes.ACCESS_GROUPS}`
        // },
        {
            label: 'Devices',
            icon: 'smartphone',
            route: superAdmin
                ? `${match.url}${routes.SUPER_ORG_DEVICES}`
                : `${routes.AUTHENTICATED}${routes.DEVICES}`
        }
        // {
        //     label: 'Units',
        //     icon: 'apartment',
        //     route: superAdmin
        //         ? `${match.url}${routes.SUPER_ORG_UNITS}`
        //         : `${routes.AUTHENTICATED}${routes.UNITS}`
        // },
        // {
        //     label: 'Content',
        //     icon: 'content_copy',
        //     route: superAdmin
        //         ? `${match.url}${routes.SUPER_ORG_CONTENT}`
        //         : `${routes.AUTHENTICATED}${routes.CONTENT}`
        // }
    ];

    return (
        <div
            className="d-flex flex-column bg-dark align-items-center shadow-1 z-20"
            style={{ width: '100%', height: '100%', position: 'relative', zIndex: 15 }}
        >
            <div className="d-flex mt-3 sidenav" style={{ position: 'relative' }}>
                <div className="d-flex flex-column align-items-center">
                    {mainLinks.map(item => (
                        <div className="nav-item" key={item.label}>
                            <NavLink
                                to={item.route}
                                className="nav-link d-flex align-items-center mx-3"
                                activeClassName="active"
                            >
                                <span className="material-symbols-outlined mr-2">
                                    {item.icon}
                                </span>
                                {item.label}
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OrgNav;
